import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeMotDePasse from "./ChangeMotDePasse";
import {getCustomerUpdatePasswordLink} from "../../../../../../redux/selectors/links/links.selectors";
import {
    POST_CUSTOMER_UPDATE_PASSWORD,
    postUpdatePassword
} from "../../../../../../redux/actions/app/customer/customer.actions";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {validate} from "./validate";

const mapStateToProps = state => {
    return {
        validate,
        loading: getLoadingEntity(state, POST_CUSTOMER_UPDATE_PASSWORD),
        query: getCustomerUpdatePasswordLink(state),
        initialValues:{
            'old_password': '',
            'password': '',
            'password_confirmation': ''
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postUpdatePassword
}, dispatch)

const ChangeMotDePasseContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(ChangeMotDePasse)

export default ChangeMotDePasseContainer
