import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FLOTTE_DETAIL, getFlotteDetail } from '../../../../../../../../redux/actions/app/flotte/flotte.actions.js';
import { getFlotteDetailData } from '../../../../../../../../redux/selectors/flotte/flotte.selectors.js';
import { getFleetGetLink } from '../../../../../../../../redux/selectors/links/links.selectors.js';
import { getLoadingEntity } from '../../../../../../../../redux/selectors/ui/ui.selectors.js';
import loader from '../../../../../../../Commun/Spinners/Loader/Loader.jsx';
import FlotteDetail from './FlotteDetail';

const mapStateToProps = (state, ownProps) => {
    return {
        uri: _.replace(getFleetGetLink(state), '{id}', ownProps.match.params.vehiculeId),
        loaded: getLoadingEntity(state, FLOTTE_DETAIL) === false,
        vehiculeId: ownProps.match.params.vehiculeId,
        vehicule: getFlotteDetailData(state),
    };
};

const mapDispatchToProps = {
    getFlotteDetail,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, vehiculeId } = stateProps;
    const { getFlotteDetail } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => getFlotteDetail({ query: uri, vehiculeId }),
    };
};

const FlotteDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(FlotteDetail);

export default FlotteDetailContainer;
