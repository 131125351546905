import React from 'react'
import './hivernage.scss';
import {Link} from "react-router-dom";

const Hivernage = (props) => {
    const {id_contrat, is_eligible_hivernage, isWakam} = props

    return (
        is_eligible_hivernage && !isWakam &&
        <div className={'col-12 hivernage'}>
            <div className={'content'}>
                <Link to={`/mes-contrats/mon-contrat/${id_contrat}/hivernage`} className={'btn btn-primary full'}>
                    <span className={'icon-hivernage'}></span>
                    Gérer Ma période hivernale
                </Link>
            </div>
        </div>
    )
}

export default Hivernage
