import React from 'react';
import APILink from "../../../../../../Commun/APILink/APILink.jsx";

const DecCirconstanciee = (props) => {
    const {getDecCirconstanciee} = props

    return (
        <div className={'bloc-white text-danger'}>
            <div>
                <h4 className={'fw-bold text-danger d-inline-block mb-5'}>Déclaration circonstanciée</h4>
            </div>

            <div className="mb-5">
                <p>N'ayant pas de constat complété et/ou signé par le Tiers adverse, veuillez télécharger le
                    document <APILink className='btn btn-link' href={getDecCirconstanciee} target={'_blank'} download>en
                        cliquant sur le lien</APILink>,
                    le compléter et nous le renvoyer via votre espace après la validation du formulaire de déclaration. Sans
                    ce document, l'ouverture de votre dossier ne pourra se faire.</p>
            </div>
        </div>
    );
};

export default DecCirconstanciee;