export const validate = (values, societe) => {
    const errors = {}
    let requiredFields = [];

    if(!societe){
        requiredFields = [
            'nom',
            'pays',
            'ville'
        ]
    } else {
        requiredFields = [
            'statut_juridique',
            'siren_siret'
        ]
    }

    requiredFields.forEach(field => {
        if(!values[field] && field === 'pays') {
            errors[field] = 'Le champ est obligatoire, veuillez sélectionner un pays dans la liste'
        } else if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })
    
    return errors

}
