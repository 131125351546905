import {connect} from 'react-redux'
import {compose} from 'redux'
import Login from "./Login";
import {getUserLink, getWhitelabelByHOST} from "../../../redux/selectors/links/links.selectors";
import {fetchWhitelabel, WHITELABEL} from "../../../redux/actions/app/whitelabel/whitelabel.actions";
import loader from "../../Commun/Spinners/Loader/Loader";
import {getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";
import {getLogoLightUrlWhitelabel, getNameWhitelabel} from "../../../redux/selectors/whitelabel/whitelabel.selectors";
import {localStorageAMO} from "../../../redux/selectors/localStorage/localStorage";
import {fetchUser, USER} from "../../../redux/actions/app/user/user.actions";

const mapStateToProps = state => {
    const token = localStorageAMO && localStorageAMO.token ? localStorageAMO.token : null

    return {
        query: getWhitelabelByHOST(state),
        loaded: getLoadingEntity(state, WHITELABEL) === false && token ? getLoadingEntity(state, USER) === false : true,
        logo: getLogoLightUrlWhitelabel(state),
        name: getNameWhitelabel(state),
        urlUser: getUserLink(state),
        token
    }
}

const mapDispatchToProps = {
    fetchWhitelabel,
    fetchUser,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchWhitelabel, fetchUser } = dispatchProps
    const { query ,urlUser, token} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            fetchWhitelabel({query})
            if (token) fetchUser({query: urlUser})
        }
    }
}

const LoginContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Login)

export default LoginContainer
