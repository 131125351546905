import {SET_WHITELABEL} from "../../actions/app/whitelabel/whitelabel.actions";

export const whitelabelReducer = (whitelabel = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_WHITELABEL) {
        return payload.data
    } else {
        return whitelabel
    }
}
