import { Link } from 'react-router-dom';
import { formatImmatriculation } from '../../../../../../../../utils/functions.js';
import React from "react";
import APILink from "../../../../../../../Commun/APILink/APILink.jsx";
import _ from "lodash";

export default function FlotteDetail({ vehicule }) {
    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>
                    <Link to={'/mes-contrats/mon-contrat/' + vehicule.contrat}>
                        <i className="bi bi-arrow-left-circle me-2" />
                    </Link>
                    Numéro d'ahésion : {vehicule.adhesion}
                </h1>
                {vehicule.memo_link && <APILink className={"btn btn-primary max-rounded d-block d-lg-inline-block m-3"} href={vehicule.memo_link}>
                    Télécharger mon Mémo Véhicule Assuré
                </APILink> }
            </div>

            <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Véhicule</h4>
                </div>

                <div className={'mt-4 row'}>
                    <div className="d-flex justify-content-between col-6">
                        <p className={'fw-bold'}>Adhésion</p>
                        <p className={'text-secondary'}>{vehicule.adhesion}</p>
                    </div>

                    <div className="d-flex justify-content-between col-6">
                        <p className={'fw-bold'}>Véhicule assuré</p>
                        <p className={'text-secondary'}>
                            {vehicule.marque} {vehicule.modele}
                        </p>
                    </div>

                    <div className="d-flex justify-content-between col-6">
                        <p className={'fw-bold'}>Cylindrée</p>
                        <p className={'text-secondary'}>{vehicule.cylindree}</p>
                    </div>

                    <div className="d-flex justify-content-between col-6">
                        <p className={'fw-bold'}>Immatriculation</p>
                        <p className={'text-secondary'}>{formatImmatriculation(vehicule.immatriculation)}</p>
                    </div>

                    <div className="d-flex justify-content-between col-6">
                        <p className={'fw-bold'}>Date de mise en circulation</p>
                        <p className={'text-secondary'}>{vehicule.dateMEC}</p>
                    </div>

                    <div className="d-flex justify-content-between col-6">
                        <p className={'fw-bold'}>Date d'entrée</p>
                        <p className={'text-secondary'}>{vehicule.dateEntree}</p>
                    </div>

                    <div className="d-flex justify-content-between col-6">
                        <p className={'fw-bold'}>Date de sortie</p>
                        <p className={'text-secondary'}>{vehicule.dateSortie ?? '-'}</p>
                    </div>
                    <div className="d-flex justify-content-between col-6">
                        <p className={'fw-bold'}>Financement en leasing</p>
                        <p className={'text-secondary'}>{vehicule.leasing === 'T' ? 'OUI' : 'NON'}</p>
                    </div>
                </div>
                <Link to={'/mes-sinistres/je-declare-un-sinistre'} className={'btn-small btn btn-primary'}>Déclarer un
                    sinistre</Link>
            </div>

            <div className={'bloc-white mt-4'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Garanties</h4>
                </div>

                <div className={'mt-4 row'}>
                    {vehicule.garanties.map((garantie, index) => {
                        if (!_.includes(['FRAIS_GESTION', 'FRAIS_FRAC', 'FRAIS_VARIATION'], garantie.code_famille)){
                            return <p key={index} className={'text-secondary'}>
                                {garantie.libelle}
                            </p>
                        }
                    })}
                </div>
            </div>
        </div>
    );
}
