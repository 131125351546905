import {connect} from 'react-redux'
import {compose} from 'redux'
import SelectContract from "./SelectContract";
import {getDataContracts} from "../../../redux/selectors/contracts/contracts.selectors";
import {
    CONTRACT,
    CONTRACTS,
    fetchContract,
    fetchContracts
} from "../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import {getContractsGetLink, getContractsLink} from "../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";
import loader from "../Spinners/Loader/Loader";
import {getDataContract} from "../../../redux/selectors/contracts/contract.selector";

const mapStateToProps = state => {
    return {
        contracts: getDataContracts(state),
        query: getContractsLink(state),
        queryContract: getContractsGetLink(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        contractLoaded: getLoadingEntity(state, CONTRACT) === false,
        contract: getDataContract(state)
    }
}

const mapDispatchToProps = {
    fetchContract,
    fetchContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, contracts } = stateProps
    const { fetchContracts } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query}) : null,
    }
}

const SelectContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(SelectContract)

export default SelectContractContainer
