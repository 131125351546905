import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import Text from "../../../../../Commun/Inputs/Text/Text";
import SmallSpinner from "../../../../../Commun/Spinners/SmallSpinner";
import _ from "lodash";
import Autocomplete from "../../../../../Commun/Inputs/Autocomplete/Autocomplete";
import {getCitiesFromFrance, getCountries} from "../../../../../../utils/functions";
import AutoCompleteBirth from "../../../../../Commun/Inputs/AutoCompleteBirth/AutoCompleteBirth";
import Select from "../../../../../Commun/Inputs/Select/Select";
import {listFormeJuridique, tiersPayeurRelation} from "../../../../../../utils/optionsSelect";

const ChangeInformationsNaissance = (props) => {
    const { initialValues, query, loading, validate, postUpdateBirthInfosTiers, id, societe } = props
    const [countries, setCountries] = useState()
    const queryUrl = _.replace(query, '{id}', id)

    useEffect(() => {
        (async function fetchCountries(){
            let countries = await getCountries()

            const listcountries = countries.map(c => ({
                value: c.id,
                label: c.name
            }))

            setCountries(listcountries)
        })()
    }, [])

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je modifie mes informations personnelles</h1>
            </div>

            <Formik validate={values => validate(values, societe)} initialValues={initialValues} onSubmit={values => postUpdateBirthInfosTiers({query:queryUrl, form: values, successFunction: () => props.history.push('/mon-profil')})} >
                {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                    <form onSubmit={(data) => {
                        if (values.pays === 'FRA' && values.ville) {
                            getCitiesFromFrance(values.ville, 'ValidateFormBirth')
                        }
                        handleSubmit(data)
                    }}>
                        {
                            !societe ?
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className={'mb-2 mt-4'}>
                                            {
                                                countries && <Autocomplete
                                                    name={`pays`}
                                                    label="Pays de naissance"
                                                    data={countries}
                                                    onChange={handleChange}
                                                    value={values.pays}
                                                    defaultValue={_.find(countries, ['value', initialValues.pays])?.label}
                                                    setFieldValue={setFieldValue}
                                                    error={errors.pays}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col col-md-6">
                                        <div className={'mb-4 mt-4'}>
                                            { values.pays === 'FRA' ?
                                                <AutoCompleteBirth
                                                    name="ville"
                                                    label='Ville de naissance'
                                                    onChange={handleChange}
                                                    value={values.ville}
                                                    country={values.pays}
                                                    defaultValue={values.ville}
                                                    setFieldValue={setFieldValue}
                                                />
                                                :
                                                <Text
                                                    type='text'
                                                    name="ville"
                                                    label='Ville de naissance'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.ville}
                                                    error={errors.ville}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className={'mb-2 mt-4'}>
                                            <Text
                                                type={'text'}
                                                name="nom"
                                                label={'Nom de naissance'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.nom}
                                                error={errors.nom}
                                                maxLength={20}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="row align-items-end">
                                    <div className="col-md-6 col-12 mb-2 mt-4">
                                        <Select
                                            name={"statut_juridique"}
                                            label={'Statut juridique'}
                                            value={values.statut_juridique}
                                            error={errors.statut_juridique}
                                            required
                                        >
                                            <option value={""}>Sélectionnez le statut juridique de l'entreprise</option>
                                            {listFormeJuridique.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.name}</option>
                                            })}
                                        </Select>
                                    </div>
                                    <div className="col-md-6 col-12 mb-2 mt-4">
                                        <Text
                                            type={'tel'}
                                            name="siren_siret"
                                            label={'N° SIREN/SIRET'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.siren_siret}
                                            error={errors.siren_siret}
                                            required
                                        />
                                    </div>
                                    <div className="col col-md-6">
                                        <div className={'mb-4 mt-4'}>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="d-grid gap-2 mt-4">
                            {
                                loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                    Je valide mes informations personnelles
                                </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default ChangeInformationsNaissance;
