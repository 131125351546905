import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from "lodash";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import {
    getLastReceipt,
} from "../../../../../../redux/selectors/payments/receipts/receipts.selectors";
import {
    FETCH_RECEIPTS,
    fetchReceipts
} from "../../../../../../redux/actions/app/payments/receipts/receipts.actions";
import {getContractReceiptsLink} from "../../../../../../redux/selectors/links/links.selectors";
import Prelevements from "./Prelevements";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params

    return {
        contract_id: id,
        query: _.replace(getContractReceiptsLink(state), '{id}', id),
        loaded: getLoadingEntity(state, FETCH_RECEIPTS) === false,
        error: getErrorEntity(state, FETCH_RECEIPTS),
        derniere_quittance: getLastReceipt(state)
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    fetchReceipts
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const { fetchReceipts } = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchReceipts({query})
    }
}


const PrelevementsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Prelevements)


export default PrelevementsContainer