
import {
    API_END_POINT,
    APP_CLIENT_ID,
    APP_GRANT_TYPE,
    APP_PROVIDER,
    HOST_MB
} from "../../../../constants/constants";

export const LOGIN = '[Login]'
export const LOGOUT = '[Logout]'

export const POST_LOGIN = `${LOGIN} Post`
export const POST_LOGOUT = `${LOGOUT} Post`

export const postLogin = ({username, password}) => ({
    type: POST_LOGIN,
    payload: {
        data: API_END_POINT,
        body: ({username, password, owner_belair_id: HOST_MB, grant_type: APP_GRANT_TYPE, client_id: APP_CLIENT_ID, provider: APP_PROVIDER, scope: '*'})
    }
})

export const postLogout = ({query}) => ({
    type: POST_LOGOUT,
    payload: {
        data: query
    }
})
