import React from 'react';
import {Formik} from "formik";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";
import FormAjoutReparateurContainer from "./FormAjoutReparateur/FormAjoutReparateurContainer";

const AjoutReparateur = (props) => {
    const {loading, initialValues, query, postAccidentRepairer, redirect_url} = props

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Votre réparation</h1>
            </div>
            <Formik initialValues={initialValues} onSubmit={values => postAccidentRepairer({query, form: values, successFunction: () => props.history.push(redirect_url)})}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                    <form onSubmit={handleSubmit}>

                        <FormAjoutReparateurContainer
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                        />

                        <div className="d-grid gap-2 mt-4">
                            {
                                loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                    Je valide mes informations personnelles
                                </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default AjoutReparateur;