import _ from 'lodash';

export const validate = values => {
    const errors = {};

    if (!values.date_sortie) {
        errors.date_sortie = 'La date de sortie est obligatoire';
    }

    if (!values.selected_vehicules || values.selected_vehicules.length === 0) {
        errors.selected_vehicules = 'Veuillez sélectionner au moins un véhicule à supprimer';
    }

    return errors;
};
