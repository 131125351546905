import {connect} from 'react-redux'
import _ from "lodash";
import {ACCIDENT, fetchAccident} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {getAccidentLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import Sinistre from "./Sinistre";
import {compose} from "redux";

const mapStateToProps = (state, ownProps) => {
    const { id, ids } = ownProps.match.params

    return {
        query: _.replace(_.replace(getAccidentLink(state), '{id}', id), '{ids}', ids) ,
        loaded: getLoadingEntity(state, ACCIDENT) === false,
        error: getErrorEntity(state, ACCIDENT)
    }
}

const mapDispatchToProps = {
    fetchAccident
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {fetchAccident} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchAccident({query})
    }
}

const SinistreContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Sinistre)

export default SinistreContainer