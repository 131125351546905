import React from 'react';
import {Link, Route} from "react-router-dom";
import CotisationsContainer from "./Cotisations/CotisationsContainer";
import PrelevementsContainer from "./Prelevements/PrelevementsContainer";


const Paiements = () => {
    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-paiements'} className={'text-decoration-none'}>Mes paiements</Link></h6>
            </div>

            <Route exact path={'/mes-paiements'} component={CotisationsContainer} />
            <Route exact path={'/mes-paiements/mon-contrat/:id/mes-prelevements'} component={PrelevementsContainer} />
        </div>
    );
};

export default Paiements;
