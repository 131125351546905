import { createSelector } from 'reselect'

const receiptSelector = state => state.receipt

export const getDataReceipt = createSelector(
    receiptSelector,
    receipt => receipt.data || {}
)

export const getReceiptTableauPrelevement = createSelector(
    getDataReceipt,
    receipt => receipt.tableau
)