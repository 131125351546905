import {connect} from 'react-redux'
import ListeContracts from "./ListeContracts";
import {getDataContracts} from "../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = state => {
    return {
        contracts : getDataContracts(state)
    }
}

const ListeContractsContainer = connect(mapStateToProps)(ListeContracts)

export default ListeContractsContainer
