import {
    ACCIDENT,
    FETCH_ACCIDENT, GET_DOCUMENT_NEEDED,
    DOCUMENT_NEEDED,
    POST_ACCIDENT,
    setAccident, POST_ACCIDENT_REPAIRER
} from '../../../../actions/app/accidents/accidents.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../../actions/core/api/api.actions'
import {
    setDocumentsNeededForAccident,
    setError,
    setLoader, setPostAccidentResponse
} from '../../../../actions/app/ui/ui.actions'
import {setNotification} from '../../../../actions/core/notifications/notifications.actions'
import moment from "moment";
import _ from 'lodash'


export const accidentSelectedMiddleware = () => next => action => {
    next(action)

    const { payload } = action
    let message='';

    switch (action.type) {

        case FETCH_ACCIDENT:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: ACCIDENT}),
                setLoader({state: true, entity: ACCIDENT})
            ])
            break

        case `${ACCIDENT} ${API_SUCCESS}`:
            next([
                setAccident({data: payload.data}),
                setError({state: false, entity: ACCIDENT}),
                setLoader({state: false, entity: ACCIDENT})
            ])
            break

        case `${ACCIDENT} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACCIDENT}),
                setLoader({state: false, entity: ACCIDENT})
            ])
            break

        case POST_ACCIDENT:

            let data = new FormData()
            let newValues = _.cloneDeep(payload.body)

            /* Format form before POST */
            /* datesurv veux une date en / datesurvorigin est un objet moment */
            newValues.datesurv = moment(newValues.datesurvorigin).format('L')
            /* catsinorigin est slice parce que le select de la categorie est très précise */
            const arrayCategory = (newValues.catsinorigin).split('.')
            newValues.catsin = arrayCategory[0]
            newValues.catsinsubcategory = arrayCategory[1]

            if(newValues.birthdate_conduct){
                newValues.birthdate_conduct = moment(newValues.birthdate_conduct).format('L')
            }

            /* Gestion si un autre garage a été saisie */
            if (newValues.reparateur){
                if (newValues.reparateur === 'NONE'){
                    newValues.reparateur_autre = `${newValues.reparateurAutreNom} ${newValues.reparateurAutreAdresse}, ${newValues.cpreparateur} ${newValues.reparateurAutreVille}`
                    delete newValues.reparateur
                }
            }

            /* Envoie de la liste des documents pour le mail */
            if (payload.body.documents){
                payload.body.documents.forEach((doc, index) => {
                    data.append(`documents[${index}]`, doc.description)
                })
            }

            /* Mise à plat des files, adversaires, témoins, victimes */
            Object.keys(payload.body).forEach(key => {
                if (key === 'files') {
                    Object.keys(payload.body[key]).forEach((doc, index) => {
                        if (payload.body[key][doc].name !== undefined) {
                            data.append(`files[${doc}]`, payload.body[key][doc])
                            data.append(`files_meta[${index}][filename]`, payload.body[key][doc].name)
                            data.append(`files_meta[${index}][size]`, payload.body[key][doc].size)
                            data.append(`files_meta[${index}][mimetype]`, payload.body[key][doc].type)
                        }
                    })
                } else if (key === 'adversaires' || key === 'temoins' || key === 'victimes') {
                    payload.body[key].forEach((person, index) => {
                        Object.keys(person).forEach(info => {
                            data.append(`${key}[${index}][${info}]`, person[info])
                        })
                    })
                } else if (key === 'circonstances') {
                    Object.keys(newValues[key]).forEach(circ => {
                        data.append(`${key}[${circ}]`, true)
                    })
                }
                return true
            })

            Object.keys(newValues).forEach(key => {
                if (key !== 'files' && key !== 'adversaires' && key !== 'temoins' && key !== 'victimes' && key !== 'circonstances'){
                    data.append(key, newValues[key])
                }
            })

            //new Response(data).text().then(console.log)

            next([
                apiRequest({
                    body: data,
                    method: 'POST',
                    url: payload.data,
                    entity: POST_ACCIDENT,
                    otherData: payload.otherData
                }),
                setLoader({state: true, entity: POST_ACCIDENT})
            ])
            break

        case `${POST_ACCIDENT} ${API_SUCCESS}`:
            message = (payload.data && payload.data.data && payload.data.data.message) ? payload.data.data.message : 'Vos informations seront mises à jour sous 24h'

            next([
                setNotification({
                    entity: POST_ACCIDENT,
                    icon: 'success',
                    title: message,
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                }),
                setError({state: false, entity: POST_ACCIDENT}),
                setLoader({state: false, entity: POST_ACCIDENT}),
                setPostAccidentResponse({data: (payload.data && payload.data.data && payload.data.data.message ? payload.data.data.message : null)})
            ])
            break

        case `${POST_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ACCIDENT,
                    html: '<p>Une erreur est survenue : '+ payload.data.response.data.reason + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: POST_ACCIDENT}),
                setLoader({state: false, entity: POST_ACCIDENT})
            ])
            break

        case GET_DOCUMENT_NEEDED:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: DOCUMENT_NEEDED}),
                setLoader({state: true, entity: DOCUMENT_NEEDED})
            ])
            break;
        case `${DOCUMENT_NEEDED} ${API_SUCCESS}`:
            next([
                setDocumentsNeededForAccident({documents: payload.data}),
                setLoader({state: false, entity: DOCUMENT_NEEDED}),
                setError({state: false, entity: DOCUMENT_NEEDED})
            ])
            break;
        case `${DOCUMENT_NEEDED} ${API_ERROR}`:
            next([
                setLoader({state: false, entity: DOCUMENT_NEEDED}),
                setError({state: true, entity: DOCUMENT_NEEDED})
            ])
            break;

        case POST_ACCIDENT_REPAIRER:
            next([
                apiRequest({body: (payload.body), method: 'POST', url: payload.data, entity: POST_ACCIDENT_REPAIRER, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_ACCIDENT_REPAIRER})
            ])
            break

        case `${POST_ACCIDENT_REPAIRER} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_ACCIDENT_REPAIRER}),
                setLoader({state: false, entity: POST_ACCIDENT_REPAIRER}),
                setNotification({
                    entity: POST_ACCIDENT_REPAIRER,
                    html: '<p>Vos informations seront mises à jour sous 24h</p>',
                    type: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])
            break

        case `${POST_ACCIDENT_REPAIRER} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_ACCIDENT_REPAIRER}),
                setLoader({state: false, entity: POST_ACCIDENT_REPAIRER}),
                setNotification({
                    entity: POST_ACCIDENT_REPAIRER,
                    html: '<p>Une erreur est survenue : ' + payload.data.response.data.reason + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        default:
            break;
    }
    return null
}
