import React from 'react';
import { Formik } from "formik";
import {Link} from 'react-router-dom'
import Text from "../../../Commun/Inputs/Text/Text";
import SmallSpinner from "../../../Commun/Spinners/SmallSpinner";
import {APP_NAME} from "../../../../constants/constants.js";

const Connection = (props) => {
    const { postLogin, loading, erreur, isLogin, initialValues, name } = props

    if (isLogin){
        const urlOrigin = localStorage.getItem(`url-${APP_NAME}`)
        if (urlOrigin){
            localStorage.removeItem(`url-${APP_NAME}`);
            window.location = urlOrigin
        } else {
            window.location = '/'
        }
    }


    return (
        <Formik initialValues={initialValues} onSubmit={values => postLogin(values)}>
            {({handleSubmit, errors, touched, handleChange, handleBlur, values}) => (
                <form onSubmit={handleSubmit}>
                    <p className={'h2 mb-4'}>Bienvenue sur votre <span className={'text-primary-light'}>espace {name}</span></p>

                    <div className={'mb-4'}>
                        <Text
                            type={'text'}
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            required
                            label={'Votre adresse email'}
                        />

                        <Link to={'/login/oubli-email'} className={'d-inline-block mt-2'}>J'ai oublié mon email de connexion</Link>
                    </div>

                    <div className="mb-4">
                        <Text
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            required
                            label={'Votre mot de passe'}
                        />

                        <Link to={'/login/oubli-mot-de-passe'} className={'d-inline-block mt-2'}>Mot de passe oublié ?</Link>
                    </div>

                    <div className="d-grid gap-2">
                        {
                            loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                Se connecter
                            </button>
                        }
                        { erreur && <p className="text-danger">Utilisateur ou mot de passe invalide</p> }
                    </div>

                    <p className={'mt-4 text-center'}>Première connexion ? <Link to={'/login/oubli-mot-de-passe'}>Cliquez ici.</Link></p>
                </form>
            )}
        </Formik>
    );
};

export default Connection;
