import React from 'react';
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import Files from "../../../../../../Commun/Inputs/Files/Files";

const Constat = (props) => {
    const { errors, values, setFieldValue} = props

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Il y a-t-il un constat amiable?</h4>
            </div>

            <div className="mb-5">
                <RadioGroup
                    label={"Il y a-t-il un constat amiable ?"}
                    name={'constat_accident'}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: 'T', label: 'Oui'},
                        {value: 'F', label: 'Non'}
                    ]}
                    value={values.constat_accident}
                    error={errors.constat_accident}
                    required
                />
            </div>

            {
                values.constat_accident === "T" && <>
                    <div className="mb-5">
                        <RadioGroup
                            label={"Est-il signé par l'adversaire ?"}
                            name={'constat_sign_tiers'}
                            setFieldValue={setFieldValue}
                            options={[
                                {value: 'T', label: 'Oui'},
                                {value: 'F', label: 'Non'}
                            ]}
                            value={values.constat_sign_tiers}
                            error={errors.constat_sign_tiers}
                            required
                        />
                    </div>
                    <div className="mb-5">
                        <RadioGroup
                            label={"Est-il signé par vous ?"}
                            name={'constat_sign_assure'}
                            setFieldValue={setFieldValue}
                            options={[
                                {value: 'T', label: 'Oui'},
                                {value: 'F', label: 'Non'}
                            ]}
                            value={values.constat_sign_assure}
                            error={errors.constat_sign_assure}
                            required
                        />
                    </div>

                    <Files
                        label={'Constat amiable signé ou déclaration circonstanciée (Recto)'}
                        name={`files[C]`}
                        arrayName={'files'}
                        value={values.files}
                        setFieldValue={setFieldValue}
                        code={'C'}
                    />
                    <Files
                        label={'Constat amiable signé ou déclaration circonstanciée (Verso)'}
                        name={`files[CC]`}
                        arrayName={'files'}
                        value={values.files}
                        setFieldValue={setFieldValue}
                        code={'CC'}
                    />
                    <div className="alert alert-info mt-5" role="alert">
                        La réception de votre constat amiable est nécessaire pour l'ouverture de votre dossier sinistre. Il permettra également de déterminer votre responsabilité.
                    </div>
                </>
            }
        </div>
    );
};

export default Constat;
