export const REQUEST_DOCUMENT = '[Request Document]'

export const FETCH_REQUEST_DOCUMENT = `${REQUEST_DOCUMENT} Fetch`
export const SET_REQUEST_DOCUMENT = `${REQUEST_DOCUMENT} Set`

export const fetchRequestDocument = ({contract, query, libelle}) => ({
    type: FETCH_REQUEST_DOCUMENT,
    payload: {
        data: query,
        body: ({contract})
    },
    meta: {
        'analytics': {
            'event': 'click',
            'actionGoogle': 'clickButton',
            'libelle': libelle,
        },
        libelle
    }
})

export const setRequestDocument = ({state, entity}) => ({
    type: `${entity} ${SET_REQUEST_DOCUMENT}`,
    payload: state,
    meta: entity
})
