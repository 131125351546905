import React from 'react';
import {Link, Route} from "react-router-dom";
import ListeSinistresContainer from "./ListeSinistres/ListeSinistresContainer";
import DeclarationSinistreContainer from "./DeclarationSinistre/DeclarationSinistreContainer";
import SinistreContainer from "./Sinistre/SinistreContainer";
import ConfirmationSinistreContainer from "./DeclarationSinistre/ConfirmationSinistre/ConfirmationSinistreContainer";

const Sinistres = ({isACL, isWakam}) => {

    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-sinistres'} className={'text-decoration-none'}>Mes sinistres</Link></h6>
            </div>

            <Route path={'/mes-sinistres/contrat/:id/sinistre/:ids/'} component={SinistreContainer} />
            { !isACL && <Route exact path={'/mes-sinistres/je-declare-un-sinistre'} component={DeclarationSinistreContainer} /> }
            <Route exact path={'/mes-sinistres/je-declare-un-sinistre/rdv'} component={ConfirmationSinistreContainer} />
            <Route exact path={'/mes-sinistres'} component={ListeSinistresContainer} />
        </div>
    );
};

export default Sinistres;
