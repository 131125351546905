import React from 'react'
import './contact.scss';
import {Link} from "react-router-dom";

const Contact = () => {
    return (
        <div className={'col-12 bloc-contact mt-5 mb-5'}>
            <div className={'content'}>
                <h2 className={'title mb-4 fw-bold'}>Vous avez une question sur <br/><strong>votre contrat d'assurance ?</strong></h2>
                <p>Besoin d'un conseil sur votre contrat ? Vous souhaitez assurer un nouveau véhicule ou déclarer un sinistre ?</p>
                <p>Nos équipes sont présentes du lundi au vendredi de 9h à 18h pour répondre à vos demandes.</p>
                <p>Vous pouvez aussi nous contacter à tout moment par e-mail ou sur notre page Facebook.</p>
                <strong>Retrouver toutes nos modalités de contact en cliquant sur le bouton ci-dessous.</strong>
                <Link className={'btn btn-primary full mt-4'} to={'/contact'}>Nous contacter</Link>
            </div>
        </div>
    )
}

export default Contact
