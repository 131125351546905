import {connect} from 'react-redux'
import {compose} from 'redux'
import OubliEmail from "./OubliEmail";
import { validate } from './validate'
import {getCustomerCreateLink} from "../../../../redux/selectors/links/links.selectors";
import {postForgotEmail} from "../../../../redux/actions/app/customer/customer.actions";
import {HOST_MB} from "../../../../constants/constants";

const mapStateToProps = state => {
    return {
        query: getCustomerCreateLink(state),
        host: HOST_MB,
        validate
    }
}

const mapDispatchToProps = {
    postForgotEmail
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { postForgotEmail } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postForgotEmail: (values) => postForgotEmail({...values, query})
    }
}

const OubliEmailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(OubliEmail)

export default OubliEmailContainer
