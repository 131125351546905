import React from 'react';
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

const DetailArticle = (props) => {
    const { article = {} } = props

    return (
        <div className={'pt-4'}>
            <div>
                <div>
                    <h3 className={'m-0 pb-3'}>
                        <span className={'text-color-primary'}><Link to={'/mes-actualites'}><i className="bi bi-arrow-left-circle me-2" /></Link>{article.title}</span>
                    </h3>
                </div>

                <img src={article.image} alt={article.title} className={'img-fluid my-5'}/>

                <p className={'h4 mb-3'}>{article.lead}</p>

                <div dangerouslySetInnerHTML={{__html: article.content_html}} />
            </div>
        </div>
    );
};

DetailArticle.propTypes = {
    article: PropTypes.object
};

export default DetailArticle;
