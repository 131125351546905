import React from 'react';
import {Link} from "react-router-dom";
import ApplicationImg from '../../../../../../assets/images/avantages/appli-mobile-sentidrive.png'
import SentidriveLogo from '../../../../../../assets/images/avantages/sentidrive-home.png'


const Sentidrive = () => {
    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-avantages'} className={'text-decoration-none'}>Mes avantages</Link> - <Link to={'/mes-avantages/sentidrive'} className={'text-decoration-none'}>Sentidrive</Link></h6>
            </div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}><Link to={'/mes-avantages'}><i className="bi bi-arrow-left-circle me-2" /></Link>SENTIDRIVE</h1>
            </div>

            <div className={'row border border-color-primary p-5'}>
                <div className="col-md-4">
                    <img src={ApplicationImg} alt="Application" width={250} className='w-100'/>
                </div>
                <div className="col-md-8">
                    <img src={SentidriveLogo} alt="Abonnement Premium Offert"/>
                    <h4 className={'text-primary-light fw-bold mt-4'}>Remise de 15% sur l’un des traceurs développés par
                        Sentidrive : Sentinelle, Sentinelle Mini ou Sentinelle Air.</h4>
                    <p className={'mt-4 h5 fw-normal'}>Le traceur Sentidrive permet de localiser une moto ou un scooter
                        volé. C’est le meilleur moyen de protéger votre moto contre le vol.</p>

                    <p className={'mt-4 h5 fw-normal'}>Ses fonctionnalités sont nombreuses :</p>
                    <ul>
                        <li className={'h5 fw-normal'}>Géolocalisation en temps réel</li>
                        <li className={'h5 fw-normal'}>Détection de vibrations</li>
                        <li className={'h5 fw-normal'}>Historique de trajets</li>
                        <li className={'h5 fw-normal'}>Et bien plus encore…</li>
                    </ul>

                    <p className={'mt-4 h5 fw-bold'}>Comment en bénéficier ?</p>
                    <p className={'h5 fw-normal mt-1 d-inline-block'}><span className={'fw-bold'}>1.</span> Rendez vous sur le site <a href="https://sentidrive.com/" target={'_blank'}>https://sentidrive.com/</a></p>
                    <p className={'h5 fw-normal mt-1 d-inline-block'}><span className={'fw-bold'}>2.</span> Choisissez votre modèle de traceur Sentinelle</p>
                    <p className={'h5 fw-normal mt-1 d-inline-block'}><span className={'fw-bold'}>3.</span> Valider votre commande et payer en ligne. Une fois le paiement effectué, vous allez recevoir un email de confirmation demandant votre plaque d’immatriculation afin de vérifier votre éligibilité.</p>
                    <p className={'h5 fw-normal mt-1 d-inline-block'}><span className={'fw-bold'}>4.</span> Après vérification du contrat, vous recevrez votre remboursement par virement bancaire sous 24 à 48h.</p>

                    <p className={'mt-4 h5 fw-normal'}>Bonne route avec
                        APRIL&nbsp;Moto&nbsp;&&nbsp;SENTIDRIVE&nbsp;!</p>

                    <a className="btn btn-secondary mt-4 text-uppercase" target={'_blank'} href="https://sentidrive.com/avantage-april/">PROFITEZ DE L'OFFRE</a>
                </div>
            </div>

        </div>
    );
};

export default Sentidrive;
