import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import {getAccidentsTiersLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import Accidents from "./Accidents";
import {
    getAccidentsTiersData
} from "../../../../../../redux/selectors/accidents/accidents.selectors";
import {ACCIDENTS, fetchAccidents} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {getIdUser} from "../../../../../../redux/selectors/user/user.selectors";
import _ from "lodash";
import {getIsACLContract, getIsWakamContract} from "../../../../../../redux/selectors/contracts/contract.selector.js";

const mapStateToProps = state => {
    return {
        isWakam: getIsWakamContract(state),
        accidents: getAccidentsTiersData(state),
        query: getAccidentsTiersLink(state),
        loaded: getLoadingEntity(state, ACCIDENTS) === false,
        error: getErrorEntity(state, ACCIDENTS),
        userId: getIdUser(state),
        isACL: getIsACLContract(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAccidents: ({query}) => fetchAccidents({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchAccidents } = dispatchProps
    const { query, userId } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchAccidents({query: _.replace(query, '{id}', userId), id: userId}),
    }
}

const AccidentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Accidents)

export default AccidentsContainer
