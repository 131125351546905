import {connect} from 'react-redux'
import Hivernage from "./Hivernage.jsx";
import {
    getContractId,
    getIsEligibleHivernageContract,
    getIsWakamContract
} from "../../../../../../redux/selectors/contracts/contract.selector.js";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors.js";
import {CONTRACT} from "../../../../../../redux/actions/app/contracts/contracts.actions.js";

const mapStateToProps = state => {
    return {
        loaded: getLoadingEntity(state, CONTRACT) === false,
        id_contrat: getContractId(state),
        is_eligible_hivernage : getIsEligibleHivernageContract(state),
        isWakam: getIsWakamContract(state)
    }
}

const HivernageContainer = connect(mapStateToProps)(Hivernage)

export default HivernageContainer