import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import ParrainagesFAQ from './ParrainagesFAQ'
import { FAQ, fetchFaq } from '../../../../../../redux/actions/app/faq/faq.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import { getFaqLink } from '../../../../../../redux/selectors/links/links.selectors'
import {getFaqParrainages} from '../../../../../../redux/selectors/faq/faq.selectors'

const mapStateToProps = state => {
    return {
        faqs: getFaqParrainages(state),
        uri: getFaqLink(state),
        loaded: getLoadingEntity(state, FAQ) === false,
        error:  getErrorEntity(state, FAQ)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getFaq: (query) => fetchFaq({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { getFaq } = dispatchProps
    const { uri } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getFaq(uri)
    }
}

const ParrainagesFAQContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ParrainagesFAQ)

export default ParrainagesFAQContainer
