import React from 'react';
import {FieldArray} from "formik";
import Text from "../../../../../../Commun/Inputs/Text/Text";
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import PostalCodeCity from "../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";

const Temoins = (props) => {
    const { values, errors, setFieldValue, handleChange } = props

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Témoin(s)</h4>
            </div>

            <FieldArray
                name={'temoins'}
                render={arrayHelpers => (
                    <div>
                        {
                            values.temoins.map((victime, i) => (
                                <div key={i} className={"row"}>
                                    <div>
                                        <p className="h4 mb-5 d-inline-block">
                                            Témoin n°{i + 1}
                                        </p>
                                        {values.temoins.length === (i+1) && <span className={'ms-3 btn btn-secondary btn-sm py-2 px-3'}>
                                            <i
                                                className={'bi bi-trash h5'}
                                                onClick={() => arrayHelpers.remove(i)}
                                            />
                                        </span>}
                                    </div>

                                    <div className="mb-5 col-xl-12">
                                        <RadioGroup
                                            label={"Civilité"}
                                            name={`temoins.${i}.titre`}
                                            setFieldValue={setFieldValue}
                                            options={[
                                                {value: "M", label:"Monsieur"},
                                                {value: "Mme", label:"Madame"}
                                            ]}
                                            value={values.temoins[i].titre}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <Text
                                            name={`temoins.${i}.nom`}
                                            onChange={handleChange}
                                            label="Nom"
                                            value={values.temoins[i].nom}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <Text
                                            name={`temoins.${i}.prenom`}
                                            onChange={handleChange}
                                            label="Prénom"
                                            value={values.temoins[i].prenom}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <Text
                                            name={`temoins.${i}.portable`}
                                            onChange={handleChange}
                                            label="Téléphone"
                                            value={values.temoins[i].portable}
                                            type={'tel'}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <Text
                                            name={`temoins.${i}.adresse1`}
                                            onChange={handleChange}
                                            label="Adresse"
                                            value={values.temoins[i].adresse1}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <PostalCodeCity
                                            label={'Ville de résidence'}
                                            cp={`temoins.${i}.cp`}
                                            ville={`temoins.${i}.ville`}
                                            value={values.temoins[i].ville}
                                        />
                                    </div>
                                </div>
                            ))
                        }

                        <span className={'btn btn-primary'} onClick={() => arrayHelpers.push({})}>Ajouter un témoin</span>
                    </div>
                )}
            />
        </div>
    );
};

export default Temoins;
