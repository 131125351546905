import React from 'react';
import {Link, Route} from "react-router-dom";
import ListeQuestionsContainer from "./ListeQuestions/ListeQuestionsContainer";

const Questions = () => {

    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-questions'} className={'text-decoration-none'}>Mes questions</Link></h6>
            </div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link>Mes questions</h1>
            </div>

            <Route exact path={'/mes-questions'} component={ListeQuestionsContainer} />
        </div>
    );
};

export default Questions;
