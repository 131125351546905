import {SET_ACCIDENTS} from '../../actions/app/accidents/accidents.actions'

export const accidentsReducer = (accidents = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ACCIDENTS) {
        return payload
    } else{
        return accidents
    }
}
