export const RECEIPTS = '[Receipts]'

export const FETCH_RECEIPTS = `${RECEIPTS} Fetch`
export const SET_RECEIPTS = `${RECEIPTS} Set`
export const DOWNLOAD_RECEIPTS = `${RECEIPTS} Download`


export const fetchReceipts = ({query}) => ({
    type: FETCH_RECEIPTS,
    payload: {
        data: query
    }
})

export const setReceipts = ({data}) => ({
    type: SET_RECEIPTS,
    payload: {
        data
    }
})

export const getReceiptsDownload = () => ({
    type: DOWNLOAD_RECEIPTS,
    meta: {
        'analytics': {
            'event': 'click',
            'actionGoogle': 'clickButton',
            'libelle': 'Je télécharge mon historique des quittances',
        },
        entity: DOWNLOAD_RECEIPTS,
    }
})