import React, { useState } from 'react';
import moment from 'moment'
import _ from 'lodash'
import './Timeline.scss';
import Accident from '../../../assets/images/icons/accident.png';
import Document from '../../../assets/images/icons/document.svg';
import CloseFolder from '../../../assets/images/icons/close-folder.svg';
import CompleteFolder from '../../../assets/images/icons/complete-folder.svg';
import OpenFolder from '../../../assets/images/icons/folder-open.svg';
import Indemnisation from '../../../assets/images/icons/indemnisation.svg';
import Expert from '../../../assets/images/icons/expert.svg';
import RapportExpert from '../../../assets/images/icons/rapport-expert.svg';
import Search from '../../../assets/images/icons/search.svg';
import {isMobile} from "../../../utils/functions";

const defineStepAccident = (accident) => {
    let step = 0
    const documents = accident.documents

    if (accident.dateOuverture) {
        step = 2
    } else {
        return step + 1
    }

    if (accident.datePassageExpert) {
        step = 3
    } else {
        return step + 1
    }

    if (moment(accident.datePassageExpert) <= moment().add(15, 'days')) {
        step = 4
    } else {
        return step + 1
    }

    if (accident.dateRapportExpert) {
        step = 5
    } else {
        return step + 1
    }

    if (moment(accident.dateRapportExpert) <= moment().add(10, 'days')) {
        step = 6
    } else {
        return step + 1
    }

    if (documents.length > 0 && _.every(documents, { 'recu': true }) && _.every(documents, { 'traite': true })) {
        step = 7
    } else {
        return step + 1
    }

    if (accident.reglements.length > 0 && _.last(accident.reglements).reference === "VALIDE") {
        step = 8
    } else {
        return step + 1
    }

    if (accident.dateFermeture) {
        step = 9
    } else {
        return step + 1
    }

    return step + 1
}

const Timeline = ({accident}) => {
    const [startIndex, setStartIndex] = useState(isMobile() ? defineStepAccident(accident) - 1 : defineStepAccident(accident) > 1 ? defineStepAccident(accident) > 7 ? 6 : defineStepAccident(accident) - 2 : 0);
    const currentStep = defineStepAccident(accident)
    let stepLoad = isMobile() ? 3 : 5
    const nbShowStep = stepLoad

    const handlePrevClick = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (startIndex < events.length - nbShowStep) {
            setStartIndex(startIndex + 1);
        }
    };

    const events = [
        { description: 'Déclaration', img: Accident },
        { description: 'Déclaration', img: Accident },
        { description: 'Dossier ouvert', img: Document },
        { description: "Passage de l'expert", img: Search },
        { description: "Estimation de l'expert", img: Expert },
        { description: "Rapport de l'expert reçu", img: RapportExpert },
        { description: 'Analyse du dossier', img: OpenFolder },
        { description: 'Dossier complet', img: CompleteFolder },
        { description: 'Indemnisation', img: Indemnisation },
        { description: 'Clôture du dossier', img: CloseFolder },
        { description: 'Clôture du dossier', img: CloseFolder },
    ];

    return (
        <div className="timeline">
            <div className="timeline-events">
                <button className="btn btn-link" onClick={handlePrevClick} disabled={startIndex === 0}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="#2188e6" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                    </svg>
                </button>

                {events.slice(startIndex, startIndex + stepLoad).map((event, index) => {
                    const isActive = currentStep === startIndex + index
                    const isCompleted = currentStep > startIndex + index

                    return (
                        <div key={startIndex + index} className={`timeline-event ${index === 0 || index === (stepLoad - 1) ? 'opacity-0 w0' : ''}`}>
                            <div className={`timeline-event-content ${isActive ? 'active' : ''} ${isCompleted ? 'complete' : ''}`}>
                                <div className={`timeline-event-bubble ${isActive ? 'active' : ''} ${isCompleted ? 'complete' : ''}`}>
                                    { isCompleted && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="#FFF"
                                              d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                                    </svg> }
                                    { isActive && <svg style={{height: 30}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path fill="#FFF" d="M0 24C0 10.7 10.7 0 24 0H360c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V67c0 40.3-16 79-44.5 107.5L225.9 256l81.5 81.5C336 366 352 404.7 352 445v19h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h8V445c0-40.3 16-79 44.5-107.5L158.1 256 76.5 174.5C48 146 32 107.3 32 67V48H24C10.7 48 0 37.3 0 24zM110.5 371.5c-3.9 3.9-7.5 8.1-10.7 12.5H284.2c-3.2-4.4-6.8-8.6-10.7-12.5L192 289.9l-81.5 81.5zM284.2 128C297 110.4 304 89 304 67V48H80V67c0 22.1 7 43.4 19.8 61H284.2z"/>
                                    </svg> }
                                </div>
                                <div className="timeline-event-description my-2">{event.description}</div>
                                <img src={event.img} alt="Accident" width={40}/>
                            </div>
                        </div>
                    )
                })}

                <button className="btn btn-link" onClick={handleNextClick} disabled={startIndex === events.length - nbShowStep}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="#2188e6" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Timeline;
