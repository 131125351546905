export const validate = values => {
    const errors = {}

    const requiredFields = [
        'tel_mobile',
        'email',
        'email_confirmation'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })

    if (values['tel_mobile'] && !/^(?:(?:\+|00)33|0)\s*[6-7](?:[\s.-]*\d{2}){4}$/.test(values.tel_mobile)) {
        errors.tel_mobile = 'Le numéro de téléphone mobile saisi est invalide'
    }

    if (values['tel_fixe'] && !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(values.tel_fixe)) {
        errors.tel_fixe = 'Le numéro de téléphone fixe saisi est invalide'
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "l'adresse email saisi est invalide"
    }

    if (values.email !== values.email_confirmation){
        errors.email = "la confirmation d'email n'est pas valide"
        errors.email_confirmation = "la confirmation d'email n'est pas valide"
    }

    return errors

}
