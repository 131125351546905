import {connect} from 'react-redux'
import ReleveInformation from "./ReleveInformation";
import {
    getApporteur1Contract,
    getContractStatutCode, getCylindreeVehiculeContract, getDateFinSituationContract, getMotifSituationContract,
    getRiLinkContract, getRiLinkReasonContract, getSouscriptionLinkContract
} from "../../../../../../../../redux/selectors/contracts/contract.selector";
import {getEmailGestionWhitelabel} from "../../../../../../../../redux/selectors/whitelabel/whitelabel.selectors";
import {getFirstnameUser} from "../../../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        riLink: getRiLinkContract(state),
        statutCode: getContractStatutCode(state),
        riReason: getRiLinkReasonContract(state),
        emailGestion: getEmailGestionWhitelabel(state),
        nameUser: getFirstnameUser(state),
        apporteur1: getApporteur1Contract(state),
        cylindree: getCylindreeVehiculeContract(state),
        urlFormulaire: getSouscriptionLinkContract(state),
        motif: getMotifSituationContract(state),
        dateFin: getDateFinSituationContract(state)
    }
}

const mapDispatchToProps = {
}

const ReleveInformationContainer = connect(mapStateToProps, mapDispatchToProps)(ReleveInformation);

export default ReleveInformationContainer
