import {connect} from 'react-redux'
import Memo from "./Memo";
import {
    getContractSituation, getDataContract,
    getImmatVehiculeContract
} from "../../../../../../redux/selectors/contracts/contract.selector";
import {getContractReceiptsLink} from "../../../../../../redux/selectors/links/links.selectors.js";

const mapStateToProps = (state, ownProps) => {

    return {
        situation: getContractSituation(state),
        immatriculation: getImmatVehiculeContract(state),
        query: getContractReceiptsLink(state),
        contract: getDataContract(state)
    }
}

const MemoContainer = connect(mapStateToProps)(Memo)

export default MemoContainer
