import {connect} from 'react-redux'
import {compose} from 'redux'
import Connection from "./Connection";
import {LOGIN, postLogin} from "../../../../redux/actions/app/login/login.actions";
import {getErrorEntity, getLoadingEntity, getLogin} from "../../../../redux/selectors/ui/ui.selectors";
import {getNameWhitelabel} from "../../../../redux/selectors/whitelabel/whitelabel.selectors";

const mapStateToProps = state => {
    return {
        loading: getLoadingEntity(state, LOGIN),
        erreur: getErrorEntity(state, LOGIN),
        isLogin: getLogin(state),
        initialValues: { username: '', password: '' },
        name: getNameWhitelabel(state)
    }
}

const mapDispatchToProps = {
    postLogin
}

const ConnectionContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Connection)

export default ConnectionContainer
