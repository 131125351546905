import React from 'react';
import {Link} from "react-router-dom";
import ApplicationImg from '../../../../../../assets/images/avantages/application.png'
import AbonnementImg from '../../../../../../assets/images/avantages/abonnement-premium.png'


const LibertyRider = () => {
    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-avantages'} className={'text-decoration-none'}>Mes avantages</Link> - <Link to={'/mes-avantages/liberty-rider'} className={'text-decoration-none'}>Liberty Rider</Link></h6>
            </div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}><Link to={'/mes-avantages'}><i className="bi bi-arrow-left-circle me-2" /></Link>Liberty Rider</h1>
            </div>

            <div className={'row border border-color-primary p-5'}>
                <div className="col-md-4">
                    <img src={ApplicationImg} alt="Application" width={250} className='w-100'/>
                </div>
                <div className="col-md-8">
                    <img src={AbonnementImg} alt="Abonnement Premium Offert" />
                    <h4 className={'text-primary-light fw-bold mt-4'}>Abonnement premium à l'application inclus gratuitement avec votre contrat APRIL&nbsp;Moto</h4>
                    <p className={'mt-4 h5 fw-normal'}>L'application Liberty Rider détecte les accidents et contacte automatiquement les secours en cas de chute en moto.</p>
                    <p className={'mt-4 h5 fw-bold'}>Comment en bénéficier ?</p>
                    <p className={'h5 fw-normal'}>1. Téléchargez l'application</p>
                    <p className={'h5 fw-normal'}>2. Choisissez votre assurance APRIL Moto</p>
                    <p className={'h5 fw-normal'}>3. Renseignez votre plaque d'immatriculation</p>

                    <p className={'mt-4 h5 fw-normal'}>Bonne route avec APRIL&nbsp;Moto&nbsp;&&nbsp;Liberty&nbsp;Rider&nbsp;!</p>

                    <a className="btn btn-secondary mt-4 text-uppercase" target={'_blank'} href="https://liberty-rider.com/partners/april/">Profitez de l'offre</a>
                </div>
            </div>

        </div>
    );
};

export default LibertyRider;
