import {connect} from 'react-redux'
import MonProfil from "./MonProfil";
import {
    getBeneficiairesEffectifsUser,
    getEmailUser, getIbanBanqueUser, getIdUser, getPhoneUser, getStatutDemat, getTitreUser, getTitulaireBanqueUser
} from "../../../../../../redux/selectors/user/user.selectors";
import _ from "lodash";
import {bindActionCreators, compose} from "redux";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import {CONTRACTS, fetchContracts} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {
    getContractsLink, getStatutDematLink
} from "../../../../../../redux/selectors/links/links.selectors";
import {getDataContracts} from "../../../../../../redux/selectors/contracts/contracts.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {POST_DEMAT_STATUT, postDematStatut} from "../../../../../../redux/actions/app/parameters/parameters.actions.js";

const mapStateToProps = state => {
    const contracts = getDataContracts(state);

    return {
        query: getStatutDematLink(state),
        id: getIdUser(state),
        query_contract: getContractsLink(state),
        contracts: contracts,
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        loading: getLoadingEntity(state, POST_DEMAT_STATUT),
        user: {
            titre: getTitreUser(state),
            telephone: getPhoneUser(state),
            email: getEmailUser(state),
            iban: getIbanBanqueUser(state),
            titulaire: getTitulaireBanqueUser(state),
            paysNaissance: contracts ? contracts[0].souscripteur.pays_naissance : '',
            villeNaissance: contracts ? contracts[0].souscripteur.ville_naissance : '',
            nomNaissance: contracts ? contracts[0].souscripteur.nom_naissance : '',
            statut_juridique: contracts ? contracts[0].souscripteur.statut_juridique : '',
            num_siren_siret: contracts ? contracts[0].souscripteur.num_siren_siret : '',
            beneficiairesEffectifs: getBeneficiairesEffectifsUser(state),
            demat: getStatutDemat(state),
        },
        initialValues:{
            b_stopemail: getStatutDemat(state) || false,
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContracts,
    postDematStatut
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query_contract, contracts, query, id } = stateProps
    const { fetchContracts, postDematStatut } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps, postDematStatut,
        load: () => _.isEmpty(contracts) ? fetchContracts({query: query_contract}) : null,
    }
}

const MonProfilContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(MonProfil)


export default MonProfilContainer
