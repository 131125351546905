import React from 'react';
import {Formik} from "formik";
import Text from "../../../../../Commun/Inputs/Text/Text";
import SmallSpinner from "../../../../../Commun/Spinners/SmallSpinner";

const ChangeInformations = (props) => {
    const { initialValues, query, loading, validate, postAmendmentChangeContact } = props

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je modifie mes informations personnelles</h1>
            </div>

            <Formik validate={validate} initialValues={initialValues} onSubmit={values => postAmendmentChangeContact({query, form: values, successFunction: () => props.history.push('/mon-profil')})}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col col-md-6">
                                <div className={'mb-4 mt-4'}>
                                    <Text
                                        type={'text'}
                                        name="tel_mobile"
                                        label={'Numéro de téléphone'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.tel_mobile}
                                        error={errors.tel_mobile}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className={'mb-2 mt-4'}>
                                    <Text
                                        type={'text'}
                                        name="email"
                                        label={'Adresse e-mail de contact'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        error={errors.email}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className={'mb-2 mt-4'}>
                                    <Text
                                        type={'text'}
                                        name="email_confirmation"
                                        label={'Confirmez votre adresse e-mail'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email_confirmation}
                                        error={errors.email_confirmation}
                                    />
                                </div>
                            </div>

                            <small className={'text-secondary fst-italic'}>Cette adresse sera utilisée pour confirmer tous les actes effectués dans votre Espace Client.<br/> Il est donc indispensable qu'elle soit valide.</small>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className={'mb-4 mt-4'}>
                                    <Text
                                        type={'password'}
                                        name="password"
                                        label={'Votre mot de passe'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        error={errors.password}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-grid gap-2 mt-4">
                            {
                                loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                    Je valide mes informations personnelles
                                </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default ChangeInformations;
