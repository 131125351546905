import {createSelector} from 'reselect'
import _ from 'lodash'

const articlesSelector = state => state.articles

export const getAllArticles = createSelector(
    articlesSelector,
    articles => articles
)

export const getHomeArticles = createSelector(
    articlesSelector,
    articles => _.filter(articles, 'home')
)
