export const ARTICLES = '[Articles]'

export const FETCH_ARTICLES = `${ARTICLES} Fetch`
export const SET_ARTICLES = `${ARTICLES} Set`

export const fetchArticles = ({query}) => ({
    type: FETCH_ARTICLES,
    payload: {
        data: query
    }
})

export const setArticles = ({data}) => ({
    type: SET_ARTICLES,
    payload: {
        data
    }
})
