import {connect} from 'react-redux'
import InMotion from "./InMotion";
import {getCodePromoUser, getEligibleCodeUser} from "../../../../../../redux/selectors/user/user.selectors";
import {getCustomerRequestCodeLink} from "../../../../../../redux/selectors/links/links.selectors";
import {postRequestCode} from "../../../../../../redux/actions/app/customer/customer.actions";
import {getCodePromoUserUi} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        user: {
            code: getCodePromoUser(state),
            eligible: getEligibleCodeUser(state)
        },
        code_promo: getCodePromoUserUi(state),
        query: getCustomerRequestCodeLink(state)
    }
}

const mapDispatchToProps = {
    postRequestCode
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { postRequestCode } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postRequestCode: ({campaign}) => postRequestCode({campaign, query})
    }
}


const InMotionContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InMotion)

export default InMotionContainer
