import {connect} from 'react-redux'
import App from "./App";
import {getLoading, getLoadingEntity} from "../../redux/selectors/ui/ui.selectors";
import {fetchLinks, LINKS} from "../../redux/actions/app/links/links.actions";
import {PASS_URL} from "../../constants/constants";
import loader from "../Commun/Spinners/Loader/Loader";
import {compose} from "redux";
import LargeSpinnerContainer from "../Commun/Spinners/LargeSpinner/LargeSpinnerContainer";

const mapStateToProps = state => {
    return {
        loaded: getLoading(state) !== undefined && !getLoadingEntity(state, LINKS)
    }
}

const mapDispatchToProps = {
    load: () => fetchLinks({query: PASS_URL})
}

const AppContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader({LoadingIndicator: LargeSpinnerContainer})
)(App)

export default AppContainer
