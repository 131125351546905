import {connect} from 'react-redux'
import _ from 'lodash'
import {bindActionCreators, compose} from 'redux'
import TableauPrevelements from "./TableauPrevelements";
import {getContractPrelevements} from "../../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {fetchReceipt, RECEIPT} from "../../../../../../../redux/actions/app/payments/receipt/receipt.actions";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {getReceiptTableauPrelevement} from "../../../../../../../redux/selectors/payments/receipt/receipt.selectors";
import {getExportReceiptsLinkContract} from "../../../../../../../redux/selectors/contracts/contract.selector";

const mapStateToProps = (state, ownProps) => {
    return {
        prelevements : getReceiptTableauPrelevement(state), // On ne récupère plus les prélevements de la derniere quittance mais la somme des prels à venir du contrat
        query: _.replace(getContractPrelevements(state), '{id}', ownProps.contract_id),
        loaded: getLoadingEntity(state, RECEIPT) === false,
        error: getErrorEntity(state, RECEIPT),
        export_receipts_link: getExportReceiptsLinkContract(state)
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    fetchReceipt: (query) => fetchReceipt({query}),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const { fetchReceipt } = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchReceipt(query)
    }
}


const TableauPrevelementsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(TableauPrevelements)


export default TableauPrevelementsContainer