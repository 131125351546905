import React from 'react';
import {Formik} from "formik";
import Text from "../../../Commun/Inputs/Text/Text";
import {Link} from "react-router-dom";

const OubliMdp = (props) => {
    const { postForgotPassword, name } = props

    return (
        <Formik initialValues={{email: ''}} onSubmit={values => postForgotPassword(values)}>
            {({handleSubmit, errors, touched, handleChange, handleBlur, values}) => (
                <form onSubmit={handleSubmit}>

                    <p className={'h2 mb-4'}><Link to={'/login'}><i className="bi bi-arrow-left-circle me-2" /></Link>Je n'ai pas mon <span className={'text-primary-light'}>mot de passe</span> ou je l'ai <span className={'text-primary-light'}>oublié</span></p>

                    <p className={'fw-bold d-inline-block mb-3'}>C'est votre 1ère connexion ou vous avez oublié votre mot de passe ? On vous accompagne !</p>

                    <p className={'d-inline-block mt-2 mb-3'}>Saisissez <b>l'adresse email</b> associée à votre contrat {name} et cliquez sur "Envoyez-moi mon mot de passe".</p>
                    <p className={'d-inline-block mb-3'}>Vous allez recevoir un <b>email</b> avec un <b>nouveau mot de passe</b>, vous permettant de vous connecter à votre espace personnel.</p>

                    <div className={'mb-4 mt-2'}>
                        <Text
                            type={'text'}
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            required
                            label={'Votre adresse mail personnelle'}
                        />
                    </div>

                    <div className="d-grid gap-2">
                        <button type="submit" className={'btn btn-primary btn-block'}>
                            Envoyez-moi mon mot de passe
                        </button>
                    </div>

                </form>
            )}
        </Formik>
    );
};

export default OubliMdp;
