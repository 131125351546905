const ContactCourtier = (props) => {
    const { apporteur1 } = props;

    return (
        <div className={'col-12 bloc-client mt-5'}>
            <div className={'content'}>
                <h2 className={'title mb-4 fw-bold'}>Coordonnées de votre courtier</h2>
                <p>
                    <strong>Raison sociale : </strong>
                    {apporteur1.raison_sociale}
                </p>
                <p>
                    <strong>Adresse : </strong>
                    {apporteur1.adresse.street} {apporteur1.adresse.postal_code} {apporteur1.adresse.city}
                </p>
                <p>
                    <strong>Email : </strong>
                    {apporteur1.email}
                </p>
                <p>
                    <strong>Téléphone : </strong>
                    {apporteur1.phone}
                </p>
            </div>
        </div>
    );
};

export default ContactCourtier;
