import React from 'react';
import TableauPrevelementsContainer from "./TableauPrelevements/TableauPrevelementsContainer";

const Prelevements = (props) => {
    const {derniere_quittance, contract_id} = props

    return (
        <div>
            <TableauPrevelementsContainer quittance_id={derniere_quittance.id_belair} contract_id={contract_id}/>
        </div>
    );
};


export default Prelevements;