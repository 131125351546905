import moment from "moment";
import {getCitiesFromFrance} from "../../../../../../utils/functions";

export const validate = (values, changeTitulaire) => {
    const errors = {}

    let requiredFields = [
        'iban',
        'domiciliation',
        'bic'
    ]

    if(changeTitulaire){
        requiredFields = requiredFields.concat([
            'titulaire',
            'adresse',
            'cp',
            'ville',
        ]);
    }

    //tiers payeur
    if(values['contract']) {
        requiredFields.push('type');
    }

    if (values['type']) {
        if(values['type'] === 'P'){
            requiredFields = requiredFields.concat([
                'firstname',
                'lastname',
                'birthname',
                'birthdate',
                'birthcountry',
                'birthcity',
                'relationship',
            ]);

            if(values['relationship'] && values['relationship'] === 'A'){
                requiredFields.push('third_payeur_reason');
            }
            if (values.birthdate !== undefined && (!moment(values.birthdate).isValid() || moment(values.birthdate).add(14, 'years') >= moment())) {
                errors.birthdate = "Le tiers payeur doit avoir au moins 14 ans."
            }
            if (values.pays && values.pays === 'FRA' && values.ville) {
                getCitiesFromFrance(values.ville, 'ValidateFormBirth')
            }
        } else {
            requiredFields = requiredFields.concat([
                'lastname'
            ]);
        }
    }

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })

    return errors

}
