import { createSelector } from 'reselect'
import {getContractId} from "../../contracts/contract.selector";
import _ from "lodash";

const receiptsUnpaidSelector = state => state.receipts_unpaid

export const getDataReceiptsUnpaid = createSelector(
    receiptsUnpaidSelector,
    receiptsUnpaid => receiptsUnpaid
)

export const getDataReceiptsContractUnpaid = createSelector(
    getDataReceiptsUnpaid,
    getContractId,
    (receiptsUnpaid, contract_id) => {
        return (receiptsUnpaid && receiptsUnpaid.data) ? _.filter(receiptsUnpaid.data.data, ['id_contrat', contract_id.toString()]) : {};
    }
)
