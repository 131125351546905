import React from 'react';
import './contracts.scss'
import {Link} from "react-router-dom";
import Icons from "../../../../../Commun/Icon/Icon";
import ListeSouscriptionDevis from "./ListeSouscriptionDevis/ListeSouscriptionDevis";
import {HOST_MB} from "../../../../../../constants/constants";

const Contracts = (props) => {
    const {contracts,souscriptionGenerale, subscribeLinks} = props

    return (
        <div className={'bloc-white contracts col-xl-11 col-md-12'}>
            <h2 className={'title-bloc d-inline-block'}>Mes contrats</h2>
            <Link to={'/mes-contrats'} className={'link-underline d-inline-block float-right'}>Voir tout</Link>
            {contracts && contracts.map((c, i) => (
                <div className={'contract row'} key={i}>
                    <div className={'detail col-12 col-md-9'}>
                        <p>Contrat n°{c.numeroPolice}</p>
                        <small>{c.title} / {c.situation.statut}</small>
                    </div>
                    <div className={'col-12 col-md-3 align-right'}>
                        <Link to={`/mes-contrats/mon-contrat/${c.id}`} className={'link-detail'}>Voir le détail <Icons icon={'chevrondroite'} fontSize={12} /></Link>
                    </div>
                </div>
            ))}

            { souscriptionGenerale && HOST_MB === 43397 ?
                <ListeSouscriptionDevis subscribeLinks={subscribeLinks}/>
                : '' }
        </div>
    );
};

export default Contracts;
