import {connect} from 'react-redux'
import Sidebar from "./Sidebar";
import _ from "lodash";
import {getDataContracts} from "../../../../../redux/selectors/contracts/contracts.selectors.js";
import {getDataContract} from "../../../../../redux/selectors/contracts/contract.selector.js";
import {getApporteur1} from "../../../../../redux/selectors/user/user.selectors.js";

const mapStateToProps = (state, ownProps) => {
    const params =  _.split(ownProps.location.pathname, '/')

    return {
        first: params[1],
        second: params[2],
        third: params[3],
        contract: getDataContract(state),
        apporteur1: getApporteur1(state),
    }
}

const SidebarContainer = connect(mapStateToProps)(Sidebar)

export default SidebarContainer
