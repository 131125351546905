export const validate = values => {
    const errors = {}

    const requiredFields = [
        'email',
    ]

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "l'adresse email saisi est invalide"
    }

    return errors

}
