import {connect} from 'react-redux'
import _ from 'lodash'
import ChangeAbonnements from "./ChangeAbonnements";
import {
    getEmailUpdateCommunicationPreferencesLink,
    getUserLink
} from "../../../../../../redux/selectors/links/links.selectors";
import {getIdUser, getOptinPartenairesUser, getOptinUser} from "../../../../../../redux/selectors/user/user.selectors";
import {
    POST_COMMUNICATION_PREFERENCES,
    postCommunicationsPreferences
} from "../../../../../../redux/actions/app/parameters/parameters.actions";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {fetchUser} from "../../../../../../redux/actions/app/user/user.actions";
import {getNameWhitelabel} from "../../../../../../redux/selectors/whitelabel/whitelabel.selectors";

const mapStateToProps = state => {
    return {
        query: _.replace(getEmailUpdateCommunicationPreferencesLink(state), '{id}', getIdUser(state)),
        query_user: getUserLink(state),
        loading: getLoadingEntity(state, POST_COMMUNICATION_PREFERENCES),
        name: getNameWhitelabel(state),
        initialValues:{
            optin: getOptinUser(state) || false,
            optin_partenaires: getOptinPartenairesUser(state) || false
        }
    }
}

const mapDispatchToProps = {
    fetchUser: ({query}) => fetchUser({query: `${query}?refresh=true`}),
    postCommunicationsPreferences
}

const ChangeAbonnementsContainer = connect(mapStateToProps, mapDispatchToProps)(ChangeAbonnements)

export default ChangeAbonnementsContainer
