import React from 'react';
import {Formik} from "formik";
import Text from "../../../Commun/Inputs/Text/Text";
import {Link} from "react-router-dom";
import SmallSpinner from "../../../Commun/Spinners/SmallSpinner";

const NouveauMdp = (props) => {
    const { postResetPassword, loading, validate, login } = props

    if (login){
        window.location = '/'
        return
    }

    return (
        <Formik initialValues={{email: '', password: '', password_confirmation: ''}} onSubmit={values => postResetPassword(values)} validate={validate}>
            {({handleSubmit, errors, touched, handleChange, handleBlur, values}) => (
                <form onSubmit={handleSubmit}>

                    <p className={'h2 mb-4'}><Link to={'/login'}><i className="bi bi-arrow-left-circle me-2" /></Link>Je n'ai pas mon <span className={'text-primary-light'}>mot de passe</span> ou je l'ai <span className={'text-primary-light'}>oublié</span></p>

                    <p className={'d-inline-block mt-2 mb-3'}>Saisissez <b>l'adresse email</b> associée à votre contrat et cliquez sur "Je&nbsp;définis&nbsp;mon&nbsp;mot&nbsp;de&nbsp;passe".</p>

                    <div className={'mb-4 mt-2'}>
                        <Text
                            type={'text'}
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={errors.email}
                            required
                            label={'Votre adresse mail personnelle'}
                        />
                    </div>

                    <div className={'mb-4 mt-2'}>
                        <Text
                            type={'password'}
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={errors.password}
                            required
                            label={'Votre nouveau mot de passe ( 12 caractères minimum )'}
                        />
                    </div>

                    <div className={'mb-4 mt-2'}>
                        <Text
                            type={'password'}
                            name="password_confirmation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password_confirmation}
                            error={errors.password_confirmation}
                            required
                            label={'Confirmez votre nouveau mot de passe'}
                        />
                    </div>

                    <div className="d-grid gap-2">
                        {
                            loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                Je définis mon mot de passe
                            </button>
                        }
                    </div>

                </form>
            )}
        </Formik>
    );
};

export default NouveauMdp;
