import React from 'react';
import PropTypes from 'prop-types';

const Icons = (props) => {
    const { icon = 'moto', className, fontSize, ...other } = props

    return <span className={`icon-${icon} ${className}`} style={{fontSize}} {...other} />;
};

Icons.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    fontSize: PropTypes.number
}

export default Icons;
