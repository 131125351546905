import { createSelector } from 'reselect'
import _ from 'lodash'

const faqSelector = state => state.faq.data

export const getDataFaq = createSelector(
    faqSelector,
    faq => faq
)

/* Top Faq */
export const getTopFaq = createSelector(
    faqSelector,
    faq => _.filter(faq, 'home')
)

/* Nombre Top Faq */
export const getNumberTopFaq = createSelector(
    faqSelector,
    faq => _.size(_.filter(faq, 'home'))
)

/* Contract Faq  */
export const getFaqContract = createSelector(
    faqSelector,
    faq => _.filter(faq, (q) => {return q.category.id === 1})
)

/* Accident Faq  */
export const getFaqAccident = createSelector(
    faqSelector,
    faq => _.filter(faq, (q) => {return q.category.id === 2})
)

/* Other Faq  */
export const getFaqOther = createSelector(
    faqSelector,
    faq => _.filter(faq, (q) => {return q.category.id === 3})
)

/* Documents Faq  */
export const getFaqDocuments = createSelector(
    faqSelector,
    faq => _.filter(faq, (q) => {return q.category.id === 4})
)

/* Trottinette  Faq  */
export const getFaqTrottinette = createSelector(
    faqSelector,
    faq => _.filter(faq, (q) => {return q.category.id === 5})
)

/* VAE  Faq  */
export const getFaqVAE = createSelector(
    faqSelector,
    faq => _.filter(faq, (q) => {return q.category.id === 6})
)

/* Parrainages Faq  */
export const getFaqParrainages = createSelector(
    faqSelector,
    faq => _.filter(faq, (q) => {return q.category.id === 7})
)
