import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    FETCH_REQUEST_DOCUMENT,
    REQUEST_DOCUMENT,
    setRequestDocument
} from "../../../actions/app/requestDocument/requestDocument.actions";
export const RequestDocumentMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case FETCH_REQUEST_DOCUMENT:
            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: REQUEST_DOCUMENT, otherData: action.meta.libelle}),
                setLoader({state: true, entity: REQUEST_DOCUMENT})
            ])
            break

        case `${REQUEST_DOCUMENT} ${API_SUCCESS}`:
            next([
                setRequestDocument({state: true, entity: payload.meta.otherData}),
                setLoader({state: false, entity: REQUEST_DOCUMENT}),
                setError({state: false, entity: REQUEST_DOCUMENT}),
                setNotification({
                    entity: REQUEST_DOCUMENT,
                    html:  '<div><p>Votre demande a bien été prise en compte</p></div>',
                    icon: 'success',
                    title: 'Demande envoyée !',
                    confirmButtonText: 'J\'ai compris'
                }),
            ])
            break

        case `${REQUEST_DOCUMENT} ${API_ERROR}`:
            next([
                setError({state: true, entity: REQUEST_DOCUMENT}),
                setLoader({state: false, entity: REQUEST_DOCUMENT}),
                setNotification({
                    entity: REQUEST_DOCUMENT,
                    html: '<div><p>Votre demande n\'a pas pu être envoyée</p><p>'+ payload.data.response.data.reason +'</p></div>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break


        default:
            break
    }
    return null
}
