import {connect} from 'react-redux'
import {compose} from 'redux'
import OubliMdp from "./OubliMdp";
import {postForgotPassword} from "../../../../redux/actions/app/customer/customer.actions";
import {getCustomerCreateLink} from "../../../../redux/selectors/links/links.selectors";
import {getNameWhitelabel} from "../../../../redux/selectors/whitelabel/whitelabel.selectors";

const mapStateToProps = state => {
    return {
        query: getCustomerCreateLink(state),
        name: getNameWhitelabel(state)
    }
}

const mapDispatchToProps = {
    postForgotPassword
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { postForgotPassword } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postForgotPassword: (values) => postForgotPassword({...values, query})
    }
}

const OubliMdpContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(OubliMdp)

export default OubliMdpContainer
