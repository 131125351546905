import {connect} from 'react-redux'
import DecCirconstanciee from "./DecCirconstanciee.jsx";
import {getDecCirconstanciee} from "../../../../../../../redux/selectors/links/links.selectors.js";
import {getContractId} from "../../../../../../../redux/selectors/contracts/contract.selector.js";
import _ from "lodash";

const mapStateToProps = state => {
    return {
        getDecCirconstanciee: _.replace(getDecCirconstanciee(state), '{idContrat}', getContractId(state)),
    }
}

const DecCirconstancieeContainer = connect(mapStateToProps)(DecCirconstanciee)

export default DecCirconstancieeContainer