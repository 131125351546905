import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import Sinistres from "./Sinistres";
import {getAccidentsTiersLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {ACCIDENTS, fetchAccidents} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {getAccidentsTiersData} from "../../../../../redux/selectors/accidents/accidents.selectors";
import loader from "../../../../Commun/Spinners/Loader/Loader";
import {getIdUser} from "../../../../../redux/selectors/user/user.selectors";
import {getIsACLContract, getIsWakamContract} from "../../../../../redux/selectors/contracts/contract.selector.js";

const mapStateToProps = state => {
    return {
        query: getAccidentsTiersLink(state),
        loaded: getLoadingEntity(state, ACCIDENTS) === false,
        accidents : getAccidentsTiersData(state),
        userId: getIdUser(state),
        isACL: getIsACLContract(state),
        isWakam: getIsWakamContract(state)
    }
}

const mapDispatchToProps = {
    fetchAccidents
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchAccidents } = dispatchProps
    const { query, accidents, userId } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(accidents) ? fetchAccidents({query: _.replace(query, '{id}', userId)}) : null,
    }
}

const SinistresContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Sinistres)

export default SinistresContainer
