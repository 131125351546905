import {SET_RECEIPTS_UNPAID} from "../../../actions/app/payments/receiptsUnpaid/receiptsUnpaid.actions";

export const receiptsUnpaidReducer = (receipts_unpaid = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_RECEIPTS_UNPAID) {
        return payload
    } else{
        return receipts_unpaid
    }
}
