import React from 'react';
import {FieldArray} from "formik";
import Text from "../../../../../../Commun/Inputs/Text/Text";
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";

const Blesses = (props) => {
    const { values, errors, setFieldValue, handleChange } = props

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Bléssé(e)(s)</h4>
            </div>

            <div className="mb-5 col-xl-6">
                <RadioGroup
                    label={"Y-a-t-il d'autre(s) blessé(s) ?"}
                    name={`blesse`}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: "T", label:"Oui"},
                        {value: "F", label:"Non"}
                    ]}
                    value={values.blesse}
                />
            </div>

            {
                values.blesse === 'T' && <FieldArray
                    name={'victimes'}
                    render={arrayHelpers => (
                        <div>
                            {
                                values.victimes.map((victime, i) => (
                                    <div key={i} className={"row"}>
                                        <div>
                                            <p className="h4 mb-5 d-inline-block">
                                                Victime n°{i + 1}
                                            </p>
                                            {values.victimes.length === (i+1) && <span className={'ms-3 btn btn-secondary btn-sm py-2 px-3'}>
                                            <i
                                                className={'bi bi-trash h5'}
                                                onClick={() => arrayHelpers.remove(i)}
                                            />
                                        </span>}
                                        </div>

                                        <div className="mb-5 col-xl-6">
                                            <Text
                                                name={`victimes.${i}.nom`}
                                                onChange={handleChange}
                                                label="Nom"
                                                value={values.victimes[i].nom}
                                            />
                                        </div>

                                        <div className="mb-5 col-xl-6">
                                            <Text
                                                name={`victimes.${i}.prenom`}
                                                onChange={handleChange}
                                                label="Prénom"
                                                value={values.victimes[i].prenom}
                                            />
                                        </div>
                                        <div className="mb-5 col-xl-6">
                                            <RadioGroup
                                                label={"Situation"}
                                                name={`victimes.${i}.type_victime`}
                                                setFieldValue={setFieldValue}
                                                options={[
                                                    {value: "P", label:"Passager"},
                                                    {value: "N", label:"Passager adverse"},
                                                    {value: "W", label:"Piéton"},
                                                    {value: "V", label:"Cycliste"}
                                                ]}
                                                value={values.victimes[i].type_victime}
                                            />
                                        </div>
                                    </div>
                                ))
                            }

                            <span className={'btn btn-primary'} onClick={() => arrayHelpers.push({})}>Ajouter une victime</span>
                        </div>
                    )}
                />
            }
        </div>
    );
};

export default Blesses;
