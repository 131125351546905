import {connect} from 'react-redux'
import {
    getCategorieCodeAccident,
    getGarantiesOptionsAccident
} from "../../../../../../../../redux/selectors/accidents/accident.selectors";
import FormAjoutReparateur from "./FormAjoutReparateur";
import {getIsWakamContract} from "../../../../../../../../redux/selectors/contracts/contract.selector.js";

const mapStateToProps = state => {
    return {
        garanties: getGarantiesOptionsAccident(state),
        categorieCode: getCategorieCodeAccident(state),
        isWakam: getIsWakamContract(state)
    }
}

const FormAjoutReparateurContainer = connect(mapStateToProps)(FormAjoutReparateur)

export default FormAjoutReparateurContainer