import React, {useState} from 'react';
import {Formik} from "formik";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";
import Select from "../../../../../../Commun/Inputs/Select/Select";
import Textarea from "../../../../../../Commun/Inputs/Textarea/Textarea";
import Files from "../../../../../../Commun/Inputs/Files/Files";
import FormulaireContactResil from "./FormulaireContactResil/FormulaireContactResil";
import _ from "lodash";


const FormulaireContact = (props) => {
    const {query, query_resiliation, initialValues, postContact, loading, contracts} = props
    const [urlSubmit, setUrlSubmit] = useState(query)

    return (
        <div>
            <Formik initialValues={initialValues} onSubmit={(values, {resetForm})=> postContact({query: urlSubmit, form: values, successFunction: () => resetForm()})}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                    <form onSubmit={handleSubmit}>
                        <div className={'mt-4'}>
                            <p className={'fw-bold text-grey'}>Formulaire de contact</p>
                        </div>

                        <div className={'mb-4 mt-4'}>
                            {
                                contracts.length > 1 ?
                                    <Select
                                        required
                                        name={"contract_id"}
                                        label={'Contrat concerné par votre demande'}
                                        error={errors.contract_id}
                                        value={values.contract_id}
                                        onChange={e => {
                                            setFieldValue('contract_id', e.target.value);
                                            setFieldValue('police', _.find(contracts, ['id', Number(e.target.value)]).numeroPolice);
                                        }}
                                        >
                                        <option value={''}>Choisissez le contrat concerné</option>
                                        {
                                            contracts.map((c,i) => {
                                                return <option key={i} value={c.id}>{`Contrat n°${c.numeroPolice} - ${c.vehicule.marque} ${c.vehicule.immatriculation}`}</option>
                                            })

                                        }
                                    </Select>
                                    : <p className={"mt-4"}>
                                        Votre demande concerne votre contrat <span className={'fw-bold'}>{`Contrat n°${contracts[0].numeroPolice} - ${contracts[0].vehicule.marque} ${contracts[0].vehicule.immatriculation}`}</span>
                                    </p>
                            }
                        </div>

                        <div className={'mb-4 mt-4'}>
                            <Select
                                required
                                name={"reason"}
                                error={errors.reason}
                                label={'Quel est le motif de votre demande ?'}
                                value={values.reason}
                                onChange={e => {
                                    setFieldValue('reason', e.target.value);
                                    setUrlSubmit(e.target.value === 'resiliation' ? query_resiliation : query)
                                }}
                            >
                                <option value={''}>Sélectionnez votre motif</option>
                                <option value={'nouveau_contrat'}>Vous souhaitez souscrire un nouveau contrat ?</option>
                                <option value={'envoi_document'}>Vous avez une question sur l’envoi de vos documents ?</option>
                                <option value={'question_contrat'}>Vous avez une question ou une modification à faire sur votre contrat ?</option>
                                <option value={'paiements'}>Vous avez une question sur vos paiements ?</option>
                                <option value={'sinistre'}>Vous souhaitez déclarer ou suivre votre sinistre ?</option>
                                <option value={'resiliation'}>Vous souhaitez résilier votre contrat</option>
                            </Select>
                        </div>

                        {
                            (!values.reason || values.reason !== "resiliation") ?
                            <>
                                <div className={'mb-4 mt-4'}>
                                    <Textarea
                                        required
                                        label={'Descriptif de votre demande / champ libre'}
                                        name={"description"}
                                        error={errors.description}
                                        value={values.description}
                                        maxLength={400}
                                    />
                                </div>

                                <Files
                                    label={'Envoyer un fichier'}
                                    name={`files[X]`}
                                    arrayName={'files'}
                                    value={values.files}
                                    setFieldValue={setFieldValue}
                                    code={'X'}
                                />
                            </>
                            : <FormulaireContactResil
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />

                        }


                        <div className="d-grid gap-2 mt-4">
                            {
                                loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                    Envoyer
                                </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};


export default FormulaireContact;
