import React, {useState} from 'react';
import moment from "moment";
import motard from '../../../../../../../../assets/images/questions/motard.png'
import plusVert from '../../../../../../../../assets/images/questions/picto-plus-vert.png'
import {getReason} from "../../../../../../../../utils/getReason";
import FormReleveInformation from "./FormReleveInformation/FormReleveInformation";
import APILink from "../../../../../../../Commun/APILink/APILink";

const ReleveInformation = (props) => {
    const { riLink, statutCode, riReason, emailGestion, nameUser, apporteur1, cylindree, urlFormulaire, motif, dateFin } = props
    const [ disabled, setDisabled ] = useState(false)

    const handleClick = () => {
        // @todo : à mettre à jour avec fonction analyticsSetEvent ou supprimer si non utilisé suite refonte
        // setTrackAction({
        //     event: 'click',
        //     actionGoogle: 'clickButton',
        //     libelle: 'Télécharger mon ri',
        //     valeur: 'résiliation',
        // })
        setDisabled(true)
    }

    if (riLink !== null && statutCode === '6'){
        return (
            <div className={'text-center'}>
                <APILink href={riLink} target={'_blank'} onClick={() => handleClick()} className={`btn btn-primary ${disabled && 'disabled'}`} download>Télécharger mon RI</APILink>
                {
                    apporteur1 === '43397' &&
                    cylindree > 50 &&
                    motif === 'Résiliation suite à vente' &&
                    moment(dateFin).add(4, 'month') >= moment() &&
                    <div className={'mt-5 p-3 rounded text-start border bg-grey-light'}>
                        <img src={motard} alt="Image de motard" width={100} className={'mb-3 d-block mx-auto'} />
                        <p className="fw-bold">{nameUser}, vous nous avez récemment quitté et vous craquez pour un nouveau 2-roues.</p>
                        <p>N’attendez pas pour en profiter&nbsp;!</p>
                        <p>Comme vous faites toujours partie de la famille, on vous simplifie le retour.</p>
                        <p><b>Seule votre nouvelle carte grise<sup>*</sup></b>  nous suffit pour vous assurer. Pour le reste, on s’occupe de tout&nbsp;!</p>
                        <p><img src={plusVert} alt="+" width={30} className={'me-2'}/><b>En plus on conserve vos avantages clients.</b> Votre bonus et votre crédit fidélité franchise restent valables pendant 4 mois et vous bénéficiez d’offres privilèges<sup>**</sup> pour vous équiper chez nos partenaires (Cosmo, In&motion, Bering…)</p>
                        <p className="m-0 fw-bold">Comment en profiter&nbsp;?</p>
                        <p>Rien de plus simple&nbsp;! Rendez-vous sur le formulaire de souscription avec votre <b>nouvelle carte grise.</b></p>
                        <p className="mb-4 fw-bold">Ne partez pas sans faire un devis.</p>
                        <small className={'fst-italic d-block'}><sup>*</sup>Ou tout document justifiant de l’achat de votre véhicule (certificat de vente, carte grise barrée, facture d’achat, attestation d’enregistrement à la préfecture).</small>
                        <small className={'mb-4 fst-italic d-block'}><sup>**</sup>Pour toute nouvelle souscription dans les 4 mois suivant la résiliation pour vente de votre précédent contrat.</small>

                        <div className="text-center">
                            <a href={urlFormulaire} target={'_blank'} className={'btn btn-secondary'}>Testez nos <b>nouveaux tarifs !</b></a>
                        </div>
                    </div>
                }
            </div>
        );
    } else if (riLink !== null && statutCode !== '6'){
        return (
            <div className={'text-center'}>
                <p>Pour télécharger votre document, sélectionnez votre contrat, un motif puis cliquez sur le bouton ci-dessous :</p>
                <FormReleveInformation riLink={riLink}/>
            </div>
        )
    } else {
        return (
            <div className={'text-center'}>
                <p>Votre contrat ne permet pas l'édition d'un Relevé d'Information en ligne pour le motif suivant : <br/><strong>{getReason(riReason)}</strong></p>
                <p>Vous pouvez contacter le service client à <a href={`mailto:${emailGestion}`}>{emailGestion}</a></p>
            </div>
        )
    }
};

export default ReleveInformation;
