import React, {useState} from 'react';
import APILink from "../../../../../../../../Commun/APILink/APILink";

const FormReleveInformation = (props) => {
    const { riLink, title } = props
    const [ raison, setRaison ] = useState(null)

    const handleClick = (e) => {
        if (!raison){
            return e.preventDefault()
        }

        // @todo : à mettre à jour avec fonction analyticsSetEvent ou supprimer si non utilisé suite refonte
        // setTrackAction({
        //     event: 'click',
        //     actionGoogle: 'clickButton',
        //     libelle: 'Télécharger mon ri',
        //     valeur: raison,
        // })

        setRaison(null)

    }

    return (
        <div className={'mt-4'}>
            <select
                onChange={(e) => setRaison(e.target.value)}
                className={'p-3 rounded w-100'}
            >
                <option value={""}>Choisissez la raison concernée</option>
                <option value="Je change de moto" >Je change de moto</option>
                <option value="Je souhaite résilier mon contrat" >Je souhaite résilier mon contrat</option>
                <option value="C'est une demande de mon assureur auto" >C'est une demande de mon assureur auto</option>
                <option value="Je souhaite faire un devis à la concurrence" >Je souhaite faire un devis à la concurrence</option>
                <option value="Autre motif" >Autre motif</option>
            </select>

            {
                raison && <div className="gap-2 mt-4">
                    <APILink href={`${riLink}?reason=${raison}`} className={`btn btn-primary ${!raison && 'disabled'}`}
                             onClick={(e) => handleClick(e)} download>
                        {title || "Télécharger mon RI"}
                    </APILink>
                </div>
            }
        </div>
    );
};

export default FormReleveInformation;
