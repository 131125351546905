import React from 'react';
import './liste_questions.scss'
import FaqCard from "../../../../../Commun/FaqCard/FaqCard";

const ListeQuestions = (props) => {
    const {listQuestions: {contract, accident, other, documents, trottinette_faq, vae_faq, parrainages}, hasVAE, hasTrottinette, hasOtherContract} = props

    return (
        <div className={'bloc-white faq-page'}>

            <h2 className={'title fw-bold mb-5'}>Vous avez une question sur <strong>votre contrat d'assurance ?</strong></h2>

            {
                hasOtherContract && <>
                    <h2 className={'title mt-5'}><strong>Mes documents</strong></h2>
                    <div className={'content-faq '}>
                        {documents.map((d, index) => (
                            <FaqCard faq={d} index={index} key={index} />
                        ))}
                    </div>

                    <hr/>
                </>
            }

            {
                hasVAE && <>
                    <h2 className={'title mt-5'}><strong>Mon contrat VAE</strong></h2>
                    <div className={'content-faq '}>
                        {vae_faq.map((c, index) => (
                            <FaqCard faq={c} index={index} key={index}/>
                        ))}
                    </div>

                    <hr/>
                </>
            }

            {
                hasTrottinette && <>
                    <h2 className={'title mt-5'}><strong>Mon contrat Trottinette</strong></h2>
                    <div className={'content-faq '}>
                        {trottinette_faq.map((c, index) => (
                            <FaqCard faq={c} index={index} key={index}/>
                        ))}
                    </div>

                    <hr/>
                </>
            }

            {
                hasOtherContract && <>
                    <h2 className={'title mt-5'}><strong>Mon contrat</strong></h2>
                    <div className={'content-faq '}>
                        {contract.map((c, index) => (
                            <FaqCard faq={c} index={index} key={index}/>
                        ))}
                    </div>

                    <hr/>

                    <h2 className={'title mt-5'}><strong>Mes sinistres</strong></h2>
                    <div className={'content-faq '}>
                        {accident.map((a, index) => (
                            <FaqCard faq={a} index={index} key={index}/>
                        ))}
                    </div>

                    {
                        /*
                            <hr/>

                            <h2 className={'title mt-5'}><strong>Mes parrainages</strong></h2>
                            <div className={'content-faq '}>
                                {parrainages.map((o, index) => (
                                    <FaqCard faq={o} index={index} key={index}/>
                                ))}
                            </div>
                         */
                    }

                    <hr/>

                    <h2 className={'title mt-5'}><strong>Mes autres questions</strong></h2>
                    <div className={'content-faq '}>
                        {other.map((o, index) => (
                            <FaqCard faq={o} index={index} key={index}/>
                        ))}
                    </div>
                </>
            }

        </div>
    );
};

export default ListeQuestions;
