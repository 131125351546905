export const getMotif = (motif) => {
    switch(motif) {
        case 'Q':
            return 'Résiliation rétractation 14 jours';
        case 'vol':
            return 'Résiliation pour vol de véhicule';
        case 'X':
            return 'Résiliation pour non achat';
        case 'A':
            return 'Résiliation suite à vente';
        case 'L':
            return 'Résiliation Loi Chatel';
        case 'B':
            return 'Résiliation à échéance';
        case '0':
            return 'Résiliation Loi Hamon';
        case '3':
            return 'Résiliation suite à un refus d’avenant';
        case 'prime':
            return 'Résiliation pour augmentation de prime';
        case 'situation':
            return 'Résiliation pour changement de situation (adresse, profession...)';
        case 'echeance':
            return 'Résiliation à écheance / Loi Chatel';
        case 'autre':
            return 'Résiliation pour un autre motif'
        default:
            return motif;
    }
}
