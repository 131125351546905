import React, {useState} from 'react'
import './faqcard.scss'
import Icons from "../Icon/Icon";
import {getComponentByType} from "../../App/Layout/Page/Questions/ListeQuestions/Components/getComponentByType";

const FaqCard = (props) => {
    const { faq, index } = props
    const [show, setshow] = useState(false)

    return (
        <div className={'card-faq'}>
            <div className={`${show ? 'collapsed' : ''} card-header`} id={`heading${index}`} onClick={() => setshow(!show)}>
                <h3 className={`${show ? 'collapsed' : ''}`}
                    data-toggle="collapse"
                    data-target={`#collapse${index}`}
                    aria-controls={`collapse${index}`}
                    dangerouslySetInnerHTML={{__html: faq.question}}
                />
                <Icons icon={`${!show ? 'plus' : 'signe-moins'}`} fontSize={12} />
            </div>
            <div id={`collapse${index}`} className={`card-body ${show && 'show'}`}
                 aria-labelledby={`heading${index}`} data-parent="#faq">
                <div className={'content'} dangerouslySetInnerHTML={{__html: faq.content_html}}/>

                {getComponentByType(faq.type)}

            </div>
        </div>
    )
}

export default FaqCard;
