import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";

const Documents = ({ documents, uploadAppLink }) => {
    // Extraction des adhésions uniques des documents
    const uniqueAdhesions = _.uniqBy(documents, 'nb_piece');

    // État pour stocker l'adhésion sélectionnée
    const [selectedAdhesion, setSelectedAdhesion] = useState('');

    // Filtrer les documents par adhésion sélectionnée
    const filteredDocuments = selectedAdhesion ? documents.filter(doc => doc.nb_piece === selectedAdhesion) : [];

    // Déterminer la pièce la plus récente lors du chargement initial
    useEffect(() => {
        if (uniqueAdhesions.length > 0) {
            const recentPiece = Math.max(...uniqueAdhesions.map(adhesion => parseInt(adhesion.nb_piece))); // Convertir en nombre pour trouver le maximum
            setSelectedAdhesion(recentPiece.toString()); // Stocker en tant que chaîne de caractères
        }
    }, []);

    return (
        <div className="bloc-white">
            <div className={'mb-4'}>
                <h4 className={'fw-bold text-primary-light d-inline-block'}>Mes documents</h4>
            </div>

            <div className="form-group">
                <select
                    id="adhesion"
                    className="form-control"
                    onChange={(e) => setSelectedAdhesion(e.target.value)}
                    value={selectedAdhesion}
                >
                    {uniqueAdhesions.map((adhesion, index) => (
                        <option key={index} value={adhesion.nb_piece}>
                            Pièce n°{adhesion.nb_piece} - Motif {adhesion.motif_piece}
                        </option>
                    ))}
                </select>
            </div>
            <br/>
            <br/>
            {filteredDocuments.length > 0 ? (
                filteredDocuments.map((d, i) => (
                    <div key={d.code}>
                        {i > 0 && <hr/>}
                        <div className="row">
                            <div className="col">
                                <div className={'mb-1 d-inline-block'}>
                                    <p className={'m-0 fw-bold'}>{d.libelle}</p>
                                    {d.commentaire && (
                                        <p className={'m-0'}>
                                            <small className={'text-secondary'}>{d.commentaire}</small>
                                        </p>
                                    )}
                                </div>
                                <p className={'float-right m-0'}>
                                    {!d.recu ? (
                                        <span className={'badge bg-danger m-0 fw-bold'}>Non reçu</span>
                                    ) : d.traite ? (
                                        <span className={'badge bg-success m-0 fw-bold'}>
                                            Validé{' '}
                                            {d.dateTraitement && `le ${moment(d.dateTraitement).format('L')}`}
                                        </span>
                                    ) : (
                                        <span className={'badge bg-warning m-0 fw-bold'}>En cours de traitement</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>Pas de documents pour cette adhésion.</p>
            )}

            {/* Affichage du lien pour envoyer les documents si certains sont en attente */}
            {_.some(documents, ['recu', false]) && (
                <div className="text-center mt-4">
                    <a href={uploadAppLink} target={'_blank'}>
                        J'envoie mes documents
                    </a>
                </div>
            )}
        </div>
    );
};

export default Documents;
