import _ from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment'

const receiptsSelector = state => state.receipts

export const getDataReceipts = createSelector(
    receiptsSelector,
    receipts => receipts.data || {}
)

/* Quittances 12 derniers mois */
const lastYear = moment().subtract(1, 'year').format('YYYY-MM-DD')

export const getReceiptsLastYear = createSelector(
    getDataReceipts,
    data => _.filter(data, function(o) { return o.dateFin >= lastYear })
)

/* Dernière quittance */
export const getLastReceipt = createSelector(
    getDataReceipts,
    data => _.first(data)
)