import {SET_RECEIPTS} from "../../../actions/app/payments/receipts/receipts.actions";

export const receiptsReducer = (receipts = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_RECEIPTS) {
        return payload
    } else{
        return receipts
    }
}
