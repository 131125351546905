import React from 'react';
import { FileSelector } from 'react-rainbow-components';
import './files.scss'
import Icons from "../../Icon/Icon";

const Files = (props) => {
    const {label, value, setFieldValue, code, name, arrayName = 'files'} = props

    const handleChange = newValue => {
        console.log('newValue', newValue)
        let arrayValues = value;
        arrayValues[code] = newValue[0];
        setFieldValue(arrayName, arrayValues);
    }

    return (
        <FileSelector
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto d-inline-block me-4 files-input"
            style={{maxWidth: '100%'}}
            placeholder={label}
            variant="multiline"
            onChange={handleChange}
            value={value && value[code] ? value[code] : null}
            name={name}
            uploadIcon={<Icons icon={`camera`} fontSize={65} />}
        />
    );
};

export default Files;
