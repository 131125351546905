import React from 'react'
import ReleveInformationContainer from "./ReleveInformation/ReleveInformationContainer";
import SelectContractContainer from "../../../../../../Commun/SelectContract/SelectContractContainer";
import AvisEcheanceContainer from "./AvisEcheance/AvisEcheanceContainer";
import {Link} from "react-router-dom";
import RequestDocumentContainer from "./RequestDocument/RequestDocumentContainer";

export const getComponentByType = (type) => {
    switch(type) {
        case 'RI':
            return <div className={'my-3'}>
                <SelectContractContainer>
                    <ReleveInformationContainer />
                </SelectContractContainer>
            </div>
        case 'ECHEANCE':
            return <div>
                <SelectContractContainer>
                    <AvisEcheanceContainer />
                </SelectContractContainer>
            </div>
        case 'DP':
            return <RequestDocumentContainer title={'Je demande mon contrat'} type='dispositions_particulieres'/>
        case 'RIB':
            return <div className="text-center">
                <Link to={'/mon-profil/je-change-mes-informations-bancaires'} className={'btn btn-primary'}>Changer mon RIB</Link>
            </div>
        case 'CARTE_VERTE':
            return <div className="text-center">
                <Link to={'/mes-documents'} className={'btn btn-primary'}>Mes documents</Link>
            </div>
                case 'DECLA_SINISTRE':
            return <div className="text-center">
                <Link to={'/mes-sinistres/je-declare-un-sinistre'} className={'btn btn-primary'}>Je déclare un sinistre</Link>
            </div>
        default:
            return ''
    }
}
