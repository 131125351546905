import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_CONTACT} from "../../../actions/app/contact/contact.actions";

export const contactMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_CONTACT:
            let data = new FormData()
            let body = action.payload.body
            body.description = body.description.replace(/\n/g, ' ');

            Object.keys(body).forEach(key => {
                if (key === 'files') {
                    Object.keys(body[key]).forEach(doc => {
                        if (body[key][doc] && body[key][doc].name !== undefined && doc !== 'X') {
                            data.append(`file`, body[key][doc])
                        } else if (body[key][doc] && body[key][doc].name !== undefined) {
                            data.append(`files[${doc}]`, body[key][doc])
                        }
                    })
                } else {
                    data.append(key, body[key])
                }
            });

            if(body['reason']){
                data.append('motif_contact', body['reason'])
            }

            if(body['mois_echeance'] && body['annee_echeance']){
                data.append('date_echeance', `${('0' + body['mois_echeance']).slice(-2)}/${body['annee_echeance']}`)
            }

            next([
                apiRequest({body: data, method: 'POST', url: payload.data, entity: POST_CONTACT, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_CONTACT})
            ])
            break

        case `${POST_CONTACT} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_CONTACT}),
                setLoader({state: false, entity: POST_CONTACT}),
                setNotification({
                    entity: POST_CONTACT,
                    html: '<div><p>Votre demande de contact a bien été prise en compte</p></div>',
                    type: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])
            break

        case `${POST_CONTACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_CONTACT}),
                setLoader({state: false, entity: POST_CONTACT}),
                setNotification({
                    entity: POST_CONTACT,
                    html: '<p>' + payload.data.response.data.reason + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        default:
            break
    }
    return null
}
