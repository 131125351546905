export const ANALYTICS = '[Analytics]'
export const ANALYTICS_SET_EVENT = `${ANALYTICS} Set Event`;

export const SET_ANALYTICS = `${ANALYTICS} Set`

export const analyticsSetEvent = ({ event, datas = null }) => ({
    type: ANALYTICS_SET_EVENT,
    meta: {
        analytics: {
            event,
            datas
        },
    },
});

export const setAnalytics = ({entity}) => ({
    type: `${entity} ${SET_ANALYTICS}`,
    payload : {
        entity
    }
})
