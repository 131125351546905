import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import Contract from "./Contract";
import {getContractsGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import {CONTRACT, fetchContract} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getIsWakamContract} from "../../../../../../redux/selectors/contracts/contract.selector.js";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params

    return {
        query: _.replace(getContractsGetLink(state), '{id}', id),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        isWakam: getIsWakamContract(state)
    }
}

const mapDispatchToProps = {
    fetchContract
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchContract } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchContract({query})
    }
}

const ContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Contract)

export default ContractContainer
