import React from 'react';
import './radioGroupChoc.scss'

const RadioChoc = (props) => {
    const { options, name, value, setFieldValue, type } = props

    return (
        <div className={`form-group`}>
            {
                options.map(d => (
                    <label className={`amo-radio cursor-pointer d-block pl-4 ${d.label} ${type}`} key={d.value}>
                        <input type="radio" name={name} value={d.value} className={'position-absolute cursor-pointer'} checked={value === d.value} onChange={(e) => setFieldValue(name, e.target.value)} />
                        <span className="checkmark position-absolute rounded-circle"/>
                    </label>
                ))
            }
        </div>
    );
};

export default RadioChoc;
