import {API_ERROR, API_SUCCESS, apiRequest} from '../../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../../actions/app/ui/ui.actions'
import {FETCH_RECEIPTS_UNPAID, setReceiptsUnpaid} from "../../../../actions/app/payments/receiptsUnpaid/receiptsUnpaid.actions";
import {setNotification} from "../../../../actions/core/notifications/notifications.actions";

export const receiptsUnpaidMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_RECEIPTS_UNPAID:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: FETCH_RECEIPTS_UNPAID}),
                setLoader({state: true, entity: FETCH_RECEIPTS_UNPAID})
            ])
            break

        case `${FETCH_RECEIPTS_UNPAID} ${API_SUCCESS}`:
            next([
                setReceiptsUnpaid({data: action.payload.data}),
                setError({state: false, entity: FETCH_RECEIPTS_UNPAID}),
                setLoader({state: false, entity: FETCH_RECEIPTS_UNPAID})
            ])
            break

        case `${FETCH_RECEIPTS_UNPAID} ${API_ERROR}`:
            next([
                setNotification({
                    entity: FETCH_RECEIPTS_UNPAID,
                    html: '<p>Une erreur est survenue lors de la récupération des quittances impayés.</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: FETCH_RECEIPTS_UNPAID}),
                setLoader({state: false, entity: FETCH_RECEIPTS_UNPAID})
            ])
            break

        default:
            break
    }
    return null
}
