import React from 'react';

const ClausesHivernage = () => {
    return (
        <div>
            <p className={'fw-bold mb-0'}>Vous n’utilisez plus votre moto en hiver&nbsp;?</p>
            <p>Déclarez la période pendant laquelle votre moto est remisée au garage et bénéficiez d’une réduction sur votre cotisation d’assurance.</p>

            <p className={'fw-bold mb-0'}><span className={'text-primary-light'}>Ma période hivernale</span> est activable 1 seule fois</p>
            <p>Pour une durée maximale de 120 jours : du 1e octobre au 1e  mars de l’année suivante.</p>

            <p className={'fw-bold mb-0'}><span className={'text-primary-light'}>Le + APRIL Moto :</span> une baisse de prime sans baisse de garanties !</p>
            <p>Pendant la durée de Ma période hivernale, vous conservez les garanties de votre contrat en circulation et en stationnement.</p>

            <p className={'mb-0'}><span className={'fw-bold'}>Avantage :</span> Par une belle journée d’hiver ensoleillée, vous pouvez occasionnellement reprendre le guidon !</p>
        </div>
    );
};

export default ClausesHivernage;