import React from 'react';
import {Link, Route} from "react-router-dom";
import ParrainagesDetailContainer from "./ParrainagesDetail/ParrainagesDetailContainer";

const Parrainages = (props) => {
    const { isEligible } = props

    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-parrainages'} className={'text-decoration-none'}>Mes parrainages</Link></h6>
            </div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link>Mes parrainages</h1>
            </div>

            {isEligible
                ? <Route exact path={'/mes-parrainages'} component={ParrainagesDetailContainer}/>
                : <p className={'mt-5'}>Vous n'êtes pas éligible à l’offre de parrainage.</p>
            }
        </div>
    );
};

export default Parrainages;
