export const UI = '[Ui]'

export const SET_LOADER = `${UI} SET_LOADER`
export const RESET_LOADER = `${UI} RESET_LOADER`
export const SET_IS_LOGIN = `${UI} SET_IS_LOGIN`
export const SET_IS_LOGOUT = `${UI} SET_IS_LOGOUT`
export const SET_MAINTENANCE = `${UI} SET_MAINTENANCE`
export const SET_ERROR = `${UI} SET_ERROR`
export const SET_RESPONSE_ERROR = `${UI} SET_RESPONSE_ERROR`
export const SET_FILTER = `${UI} SET_FILTER`
export const SET_ACCIDENT_REPORTED = `${UI} SET_ACCIDENT_REPORTED`
export const SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT = `${UI} SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT`
export const SET_CODE_PROMO_USER = `${UI} SET_CODE_PROMO_USER`
export const SET_RESPONSE_POST_ACCIDENT = `${UI} SET_RESPONSE_POST_ACCIDENT`
export const SET_HIVERNAGE_REDUCTION = `${UI} SET_HIVERNAGE_REDUCTION`


export const setLoader = ({state, entity}) => ({
    type: `${entity} ${SET_LOADER}`,
    payload: state,
    meta: entity
})

export const resetLoader = ({entity}) => ({
    type: `${entity} ${RESET_LOADER}`,
    payload: true
})

export const setError = ({state, entity}) => ({
    type: `${entity} ${SET_ERROR}`,
    payload: state,
    meta: entity
})

export const setErrorResponse = ({entity, data}) => ({
    type: `${entity} ${SET_RESPONSE_ERROR}`,
    payload: {
        data
    }
})

export const setIsLogin = ({state, entity}) => ({
    type: `${entity} ${SET_IS_LOGIN}`,
    payload: state
})

export const setIsLogout = ({state, entity}) => ({
    type: `${entity} ${SET_IS_LOGOUT}`,
    payload: state
})

export const setMaintenance = ({state, entity}) => ({
    type: `${entity} ${SET_MAINTENANCE}`,
    payload: state
})

export const setFilters = ({entity, filters}) => ({
    type: `${entity} ${SET_FILTER}`,
    payload: {
        ...filters
    },
    meta: entity
})

export const setAccidentReported = ({data}) => ({
    type: SET_ACCIDENT_REPORTED,
    payload: {
        data
    }
})

export const setDocumentsNeededForAccident = ({documents}) => ({
    type: SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT,
    payload:{
        data: documents
    }
})

export const setCodePromoUser = ({data}) => ({
    type: SET_CODE_PROMO_USER,
    payload: {
        data
    }
})

export const setPostAccidentResponse = ({data}) => ({
    type: SET_RESPONSE_POST_ACCIDENT,
    payload: {
        data
    }
})

export const setHivernageReduction = ({state}) => ({
    type: SET_HIVERNAGE_REDUCTION,
    payload: state
})
