import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import woman from '../../../../../../../assets/images/icons/businesswoman.png';
import identityCard from '../../../../../../../assets/images/icons/id-card.png';
import { tiersPayeurRelation } from '../../../../../../../utils/optionsSelect';
import APILink from '../../../../../../Commun/APILink/APILink';
import FlotteContainer from './Flotte/FlotteContainer';
import Documents from "./Documents/Documents.jsx";
import React from "react";
import Swal from "sweetalert2";
import {postConfirmResilHamon} from "../../../../../../../redux/actions/app/contracts/contracts.actions.js";

const DetailContract = (props) => {
    const {
        contract,
        fractionnement,
        prelevement,
        has_waiting_doc,
        uploadAppLink,
        police,
        souscripteur,
        vehicule,
        isVAE,
        garanties,
        documents,
        receipts_unpaid,
        tiersPayeur,
        options,
        match: {
            params: { id },
        },
        statutCode,
        isAIMCO,
        submitDeleteTiersPayeur,
        isWakam,
        hamon,
        postConfirmResilHamon,
        query_resil_hamon
    } = props;
    const documentsSigned = _.filter(documents, (document) => {
        return document.download_link !== null;
    }).valueOf();

    const dateAffaireNouvelle = moment(contract.situation.dateAffaireNouvelle);
    const sixMonthsAgo = moment().subtract(6, 'months');

    const handleClickInfo = () => {
        Swal.fire({
            icon: 'question',
            title: 'Le saviez-vous ?',
            text: 'Lors de la résiliation, votre ancien assureur doit vous faire parvenir un courrier de confirmation (courrier libre / email / relevé d\'informaation) justifiant l\'arrêt de votre contrat',
            showConfirmButton: true,
            confirmButtonText: 'Terminer',
            showCloseButton: true
        })
    }

    const handleConfirmHamonChange = (value) => {
        postConfirmResilHamon({
            query: _.replace(query_resil_hamon, '{id}', id),
            form: { confirm_resil: value },
        });
    };

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>
                    <Link to={'/mes-contrats'}>
                        <i className="bi bi-arrow-left-circle me-2" />
                    </Link>{' '}
                    Mon contrat N°{police}
                </h1>
            </div>

            {has_waiting_doc || receipts_unpaid.length > 0 ? (
                <div className={'col bg-grey-light todo-block pb-0 my-4'}>
                    <h2 className="title-bloc d-inline-block">À faire</h2>
                    <div className="row">
                        {receipts_unpaid &&
                            receipts_unpaid.map((r, i) => (
                                <div
                                    className="bloc-white col-lg-5 col-md-6 col-xs-12 me-4 position-relative text-center"
                                    key={i}
                                >
                                    <div className={'position-absolute info-block p-3'}>
                                        <i className="bi bi-info-circle text-light fs-4" />
                                    </div>
                                    <div>
                                        <img src={woman} alt="Quittances" className={'my-2'} width={50} />
                                        <p className={'fw-bold text-danger fs-6'}>
                                            Vous avez une cotisation à régler&nbsp;!
                                        </p>
                                        <p className={'fst-italic text-grey fs-6'}>
                                            Contrat&nbsp;:&nbsp;{r.numeroPolice} - {r.solde}€ restant
                                        </p>
                                        {r.pay_link ? (
                                            <a
                                                target="_blank"
                                                href={`${r.pay_link}?url_return=${window.location.href}`}
                                                className={'fs-6'}
                                            >
                                                Payer ma cotisation
                                            </a>
                                        ) : (
                                            <span className={`badge bg-info`}>
                                                {_.replace(r.pay_link_reason[0], '_', ' ')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}

                        {has_waiting_doc && (
                            <div className="bloc-white col-lg-5 col-md-6 col-xs-12 me-4 position-relative text-center">
                                <div className={'position-absolute info-block p-3'}>
                                    <i className="bi bi-info-circle text-light fs-4" />
                                </div>
                                <div>
                                    <img src={identityCard} alt="Documents" className={'my-2'} width={50} />
                                    <p className={'fw-bold text-danger fs-6'}>
                                        Vous avez des documents à envoyer&nbsp;!
                                    </p>
                                    <a target="_blank" href={uploadAppLink} className={'fs-6'}>
                                        Envoyer mes documents
                                    </a>
                                </div>
                            </div>
                        )}
                        {(hamon?.id && hamon?.confirm_resil !== 'T' && dateAffaireNouvelle.isAfter(sixMonthsAgo)) ? (
                            <div className="bloc-white col-lg-5 col-md-6 col-xs-12 me-4 position-relative text-center">
                                <div className={'position-absolute info-block p-3'}>
                                    <i className="bi bi-info-circle text-light fs-4"/>
                                </div>
                                <div>
                                    <p className={'fw-bold text-danger fs-6 ms-4'}>
                                        Vous avez souscrit par le biais de la loi Hamon&nbsp;!
                                    </p>
                                    <p className={'fs-9'}>
                                        Avez-vous reçu la confirmation de la résiliation de votre ancienne assurance ?
                                        <span
                                            onClick={handleClickInfo}
                                            className="d-inline-block text-center bg-primary text-white rounded-circle"
                                            style={{
                                                cursor: 'pointer',
                                                width: '20px',
                                                height: '20px',
                                                lineHeight: '20px',
                                                marginLeft: '10px'
                                            }}
                                        >
                                            ?
                                        </span>
                                    </p>
                                    <a onClick={() => handleConfirmHamonChange('T')} className={'fs-6 cursor-pointer'}
                                       style={{cursor: 'pointer'}}>
                                        Oui
                                    </a>

                                    <a onClick={() => handleConfirmHamonChange('F')}
                                       className={'fs-6 mx-4 cursor-pointer'}>
                                        Non
                                    </a>

                                    <a onClick={() => handleConfirmHamonChange('help')}
                                       className={'fs-6 cursor-pointer'}>
                                        J'ai besoin d'aide
                                    </a>
                                </div>
                            </div>
                        ) : hamon?.confirm_resil === 'T' ? (
                            <div className="bloc-white col-lg-5 col-md-6 col-xs-12 me-4 position-relative text-center">
                                <div className={'position-absolute info-block p-3'}>
                                    <i className="bi bi-info-circle text-light fs-4"/>
                                </div>
                                <div>
                                    <p className={'fw-bold text-success fs-6 ms-4'}>
                                        Loi Hamon : Confirmation de résiliation reçue !
                                    </p>
                                    <p className={'fs-9'}>
                                        Merci d'avoir confirmé la réception de la résiliation de votre ancienne assurance.
                                    </p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}

            {(
                moment().diff(moment(contract?.situation.dateAffaireNouvelle, 'YYYY-MM-DD'), 'years') >= 1 &&
                contract?.avenant_link != '' &&
                contract?.apporteur1 == '43397' &&
                (contract?.vehicule.codeProduit !== '7311_VAE' && contract?.vehicule.codeProduit !== '7311_NVEI')
            ) && <div className={'col bg-grey-light todo-block pb-0 my-4'}>
                <h2 className="title-bloc d-inline-block">Vous avez une modification à apporter à votre contrat&nbsp;?</h2>
                <div className="row">
                    <p className={'fw-bold'}>Changement de véhicule, de garanties... vous pouvez modifier votre
                        contrat en autonomie !</p>

                    <div className={'mt-2 pb-4'}>
                        <a href={contract.avenant_link} className={'btn btn-primary full'} target={'_blank'}>Je modifie mon contrat</a>
                    </div>
                </div>
            </div> }

            <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Souscripteur / Assuré</h4>
                </div>

                <div className={'mt-4'}>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12">
                            <p className={'mb-1 fw-bold'}>Assuré</p>
                            <p className={'m-0 text-secondary'}>{souscripteur.nom}</p>
                        </div>
                        {
                            !isWakam && <div className="col-lg-6 col-md-6 col-xs-12">
                                <p className={'mb-1 fw-bold'}>Date de naissance</p>
                                <p className={'m-0 text-secondary'}>{moment(souscripteur.naissance).format('L')}</p>
                            </div>
                        }
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12">
                            <p className={'mb-1 fw-bold'}>Adresse postale</p>
                            <p className={'m-0 text-secondary'}>
                                {souscripteur.adresse.rue1} {souscripteur.adresse.rue2} {souscripteur.adresse.rue3}
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12">
                            {statutCode === '1' && !isAIMCO && !isWakam && (
                                <Link to={`/mes-contrats/mon-contrat/${id}/je-demenage`} className={'float-right'}>
                                    Modifier
                                </Link>
                            )}
                            <p className={'mb-1 fw-bold'}>Code postal - Ville</p>
                            <p className={'m-0 text-secondary'}>
                                {souscripteur.adresse.codePostal} {souscripteur.adresse.ville}
                            </p>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">
                            <Link to={`/mon-profil/je-change-mes-informations`} className={'float-right'}>
                                Modifier
                            </Link>
                            <p className={'mb-1 fw-bold'}>Numéro de téléphone</p>
                            <p className={'m-0 text-secondary'}>{souscripteur.phone}</p>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">
                            <Link to={`/mon-profil/je-change-mes-informations`} className={'float-right'}>
                                Modifier
                            </Link>
                            <p className={'mb-1 fw-bold'}>Adresse email</p>
                            <p className={'m-0 text-secondary'}>{souscripteur.email}</p>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">
                            {!_.isEmpty(tiersPayeur) ? (
                                <>
                                    <Link
                                        to={`/mes-contrats/mon-contrat/${id}/mon-tiers-payeur`}
                                        className={'float-right'}
                                    >
                                        Modifier
                                    </Link>
                                    <button
                                        type={'button'}
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    'Êtes-vous sûr(e) de vouloir supprimer le tiers payeur de votre contrat ?',
                                                )
                                            )
                                                submitDeleteTiersPayeur();
                                        }}
                                        className={'float-right btn-link me-3'}
                                    >
                                        Supprimer
                                    </button>
                                </>
                            ) : (
                                <Link to={`/mes-contrats/mon-contrat/${id}/mon-tiers-payeur`} className={'float-right'}>
                                    Ajouter
                                </Link>
                            )}

                            <p className={'mb-1 fw-bold'}>Tiers payeur</p>
                            {!_.isEmpty(tiersPayeur) ? (
                                <>
                                    <p className={'m-0 text-secondary'}>
                                        {tiersPayeur.nom}
                                        {` ${tiersPayeur.prenom || ''}`}{' '}
                                        {tiersPayeur.relation && (
                                            <>
                                                - {_.find(tiersPayeurRelation, ['code', tiersPayeur.relation]).libelle}
                                                {tiersPayeur.relation === 'A' &&
                                                    tiersPayeur.raison_autre &&
                                                    ` : ${tiersPayeur.raison_autre}`}
                                            </>
                                        )}
                                    </p>
                                </>
                            ) : (
                                <p className={'m-0'}>Pas de tiers payeur</p>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">
                            {!prelevement && statutCode === '1' && (
                                <Link
                                    to={`/mes-contrats/mon-contrat/${id}/je-passe-en-prelevement`}
                                    className={'float-right'}
                                >
                                    Modifier
                                </Link>
                            )}
                            <p className={'mb-1 fw-bold'}>Information de Prélèvement</p>
                            <p className={'m-0 text-secondary'}>
                                Prélèvement : {prelevement ? `Oui (${fractionnement})` : 'Non'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {contract.flotte ? (
                <FlotteContainer className={'loader'} />
            ) : (
                <>
                    <div className={'bloc-white'}>
                        <div>
                            <h4 className={'fw-bold text-primary-light d-inline-block'}>Véhicule assuré</h4>
                        </div>

                        <div className={'mt-4'}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <p className={'mb-1 fw-bold'}>Véhicule assuré</p>
                                    <p className={'m-0 text-secondary'}>
                                        {vehicule.marque} {vehicule.modele} {vehicule.cylindree}
                                    </p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <p className={'mb-1 fw-bold'}>{isVAE ? 'Marquage' : 'Immatriculation'}</p>
                                    <p className={'m-0 text-secondary'}>
                                        {isVAE ? vehicule.VIN : vehicule.immatriculation}
                                    </p>
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <p className={'mb-1 fw-bold'}>Usage déclaré</p>
                                    <p className={'m-0 text-secondary'}>{vehicule.usage}</p>
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <p className={'mb-1 fw-bold'}>Zone de stationnement</p>
                                    <p className={'m-0 text-secondary'}>{vehicule.lieuStationnement}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bloc-white">
                        <div className={'mb-4'}>
                            <h4 className={'fw-bold text-primary-light d-inline-block'}>Mes garanties et options</h4>
                        </div>
                        {_.orderBy(garanties, ['libelle'], ['asc']).map((g, i) => {
                            if (!_.includes(['FRAIS_GESTION', 'FRAIS_FRAC', 'FRAIS_VARIATION'], g.code_famille)){
                                return <div key={g.code}>
                                    {i > 0 && <hr/>}
                                    <div className="row">
                                        <div className="col">
                                            <p className={'mb-1 fw-bold d-inline-block'}>{g.libelle}</p>
                                            {vehicule.codeProduit === '20MOT_GAN' ||
                                            vehicule.libelleProduit === 'AUTO' ? null : (
                                                <p className={'float-right m-0 text-secondary'}>{g.franchise}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                </>
            )}

            <Documents documents={documents} uploadAppLink={uploadAppLink} />

            <div className="bloc-white">
                <div className={'mb-4'}>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>
                        Mes documents signés électroniquement
                    </h4>
                </div>
                {documentsSigned.map((d, i) => (
                    <div key={d.code}>
                        {i > 0 && <hr />}
                        <div className="row">
                            <div className="col">
                                <div className={'mb-1 d-inline-block'}>
                                    <p className={'m-0 fw-bold'}>{d.libelle}</p>
                                    <p className={'m-0 text-secondary'}>
                                        Signé le : <strong>{moment(d.dateReception).format('L')}</strong>
                                    </p>
                                </div>
                                <p className={'float-right m-0'}>
                                    <APILink
                                        href={d.download_link}
                                        target={'_blank'}
                                        className={`btn btn-primary`}
                                        download
                                    >
                                        Télécharger
                                    </APILink>
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
                {_.isEmpty(documentsSigned) && <p>Pas de documents signés électroniquement.</p>}
            </div>
        </div>
    );
};

export default DetailContract;
