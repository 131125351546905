import React from 'react';

const GetContracts = (props) => {
    const { children } = props

    return (
        <div>
            { children }
        </div>
    );
};

export default GetContracts;
