import _ from 'lodash'

export const validate = values => {
    const errors = {}

    if (values['codePostalNew'] && (_.startsWith(values.codePostalNew, '97') || _.startsWith(values.codePostalNew, '98'))) {
        errors.codePostalNew = 'Nous ne sommes pas en mesure d\'assurer les résidents à Monaco ou dans les DOM/TOM'
    }

    return errors
}
