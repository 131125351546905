import React, {useEffect} from 'react';
import voiture from "../../../../../../../assets/images/accidents/voiture_topview.png";
import moto from "../../../../../../../assets/images/accidents/moto_topview.png";
import quad from "../../../../../../../assets/images/accidents/quad_topview.png";
import RadioChoc from "../../../../../../Commun/Inputs/RadioChoc/RadioChoc";

const Chocs = (props) => {
    const { errors, values, setFieldValue, produit, handleChange} = props

    useEffect(() => {
        let choc = 'Point de choc : '

        switch(values.point_choc) {
            case 'bottom':
                choc = 'Arrière - '
                break
            case 'top':
                choc = 'Avant - '
                break
            case 'left':
                choc = 'Coté gauche - '
                break
            case 'right':
                choc = 'Coté droit - '
                break
            case 'top_right':
                choc = 'Avant droit - '
                break
            case 'top_left':
                choc = 'Avant gauche - '
                break
            case 'bottom_left':
                choc = 'Arrière gauche - '
                break
            case 'bottom_right':
                choc = 'Arrière droit - '
                break
            case 'middle_right':
                choc = 'Coté droit - '
                break
            case 'middle_left':
                choc = 'Coté gauche - '
                break
            default:
                choc = 'Non défini - '
        }

        setFieldValue('dommagesDeclares', `Point de choc : ${choc}`)

    }, [values.point_choc])

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Cocher le point de choc initial de l'accident sur votre véhicule</h4>
            </div>

            {(produit === 'AUTOF3' || produit === '7311_AUT1') &&
                <div className="mb-5 mt-5 position-relative text-center">
                    <RadioChoc
                        label={"Selectionnez le point de choc initial :"}
                        name={'point_choc'}
                        type={'voiture'}
                        setFieldValue={setFieldValue}
                        options={[
                            {value: 'top_right', label:'top_right'},
                            {value: 'top_left', label:'top_left'},
                            {value: 'bottom_left', label:'bottom_left'},
                            {value: 'bottom_right', label:'bottom_right'},
                            {value: 'left', label:'left'},
                            {value: 'right', label:'right'},
                        ]}
                        value={values.point_choc}
                        error={errors.point_choc}
                        onChange={handleChange}
                        required
                    />
                    <img src={voiture} alt={'Point de choc voiture'} height={200}/>
                </div>
            }

            {(produit === '7311_PVK') &&
                <div className="mb-4 position-relative text-center">
                    <RadioChoc
                        label={"Selectionnez le point de choc initial :"}
                        name={'point_choc'}
                        type={'quad'}
                        setFieldValue={setFieldValue}
                        options={[
                            {value: 'top_right', label:'top_right'},
                            {value: 'top_left', label:'top_left'},
                            {value: 'bottom_left', label:'bottom_left'},
                            {value: 'bottom_right', label:'bottom_right'},
                            {value: 'left', label:'left'},
                            {value: 'right', label:'right'},
                        ]}
                        value={values.point_choc}
                        error={errors.point_choc}
                        onChange={handleChange}
                        required
                    />
                    <img src={quad} alt={'Point de choc quad'} height={250}/>
                </div>
            }

            {(produit !== '7311_PVK' && produit !== 'AUTOF3' && produit !== '7311_AUT1') &&
                <div className="mb-4 position-relative text-center">
                    <RadioChoc
                        label={"Selectionnez le point de choc initial :"}
                        name={'point_choc'}
                        type={'moto'}
                        setFieldValue={setFieldValue}
                        options={[
                            {value: 'top', label:'top'},
                            {value: 'top_left', label:'top_left'},
                            {value: 'middle_left', label:'middle_left'},
                            {value: 'bottom_left', label:'bottom_left'},
                            {value: 'bottom', label:'bottom'},
                            {value: 'top_right', label:'top_right'},
                            {value: 'middle_right', label:'middle_right'},
                            {value: 'bottom_right', label:'bottom_right'},
                        ]}
                        value={values.point_choc}
                        error={errors.point_choc}
                        onChange={handleChange}
                        required
                    />
                    <img src={moto} alt={'Point de choc moto'} height={300}/>
                </div>
            }
        </div>
    );
};

export default Chocs;
