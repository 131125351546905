import {SET_RECEIPT} from "../../../actions/app/payments/receipt/receipt.actions";

export const receiptReducer = (receipt = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_RECEIPT) {
        return payload
    } else{
        return receipt
    }
}
