import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import loader from "../../../../Commun/Spinners/Loader/Loader";
import {localStorageAMO} from "../../../../../redux/selectors/localStorage/localStorage";
import Home from "./Home";
import {getFirstnameUser, getIdUser} from "../../../../../redux/selectors/user/user.selectors";
import {
    getApporteurNomContract, getSouscripteurNomNaissance,
    getSouscripteurPaysNaissance, getSouscripteurVilleNaissance
} from "../../../../../redux/selectors/contracts/contract.selector";
import {CONTRACTS, fetchContracts} from "../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import {getContractsLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getDataContracts, getDateCV} from "../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = state => {
    return {
        messageWarning: localStorageAMO.welcome.message_warning,
        userId: getIdUser(state),
        nameApporteur: getApporteurNomContract(state),
        paysNaissance: getSouscripteurPaysNaissance(state),
        villeNaissance: getSouscripteurVilleNaissance(state),
        firstName: getFirstnameUser(state),
        nomNaissance: getSouscripteurNomNaissance(state),
        query: getContractsLink(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        contracts : getDataContracts(state),
        derniereCv: getDateCV(state)[0] ? getDateCV(state)[0].dernierCourrierCV : null
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContracts: ({query}) => fetchContracts({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchContracts } = dispatchProps
    const { query, contracts } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query}) : null,
    }
}


const HomeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Home)

export default HomeContainer
