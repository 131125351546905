import React, {useEffect, useState} from 'react';
import Select from "../../../../../../../Commun/Inputs/Select/Select";
import moment from "moment";
import Files from "../../../../../../../Commun/Inputs/Files/Files";
import Checkbox from "../../../../../../../Commun/Inputs/Checkbox/Checkbox";

const FormulaireContactResil = (props) => {
    const {values, errors, setFieldValue, handleChange, handleBlur} = props
    const [months, setMonths] = useState([])

    useEffect(() => {
        let monthN = [];
        for (let i = 0; i < 12; i++) {
            const m =  moment().set('month', i);
            monthN[m.format("M")] = m.format("MMMM")
        }
        setMonths(monthN);
    }, []);

    const onChangeMotifResil = (value) => {
        setFieldValue('motif_resil', value);
        clearResilValues();

        if(value === 'echeance'){
            setFieldValue('mois_echeance', moment().format("M"));
            setFieldValue('annee_echeance', moment().format("YYYY"));
        }
    }

    const clearResilValues = () => {
        setFieldValue('motif_retractation', null);
        setFieldValue('annee_echeance', null);
        setFieldValue('mois_echeance', null);
    }

    return (
        <div>
            <div className={'mb-4'}>
                <Select
                    required
                    name={"motif_resil"}
                    error={errors.motif_resil}
                    label={'Quel est le motif de votre résiliation ?'}
                    value={values.motif_resil}
                    onChange={e => onChangeMotifResil(e.target.value)}>
                    <option value={''}>Sélectionnez votre motif</option>
                    <option value={'vente'}>Suite à la vente de mon véhicule</option>
                    <option value={'echeance'}>A échéance (ou Loi Chatel) de mon contrat</option>
                    <option value={'retractation'}>Rétractation (dans les 14 jours)</option>
                    <option value={'evolution_tarif'}>Evolution de mon tarif</option>
                    <option value={'loi_hamon'}>Loi Hamon</option>
                </Select>
            </div>

            {
                values.motif_resil && values.motif_resil === 'vente' &&
                    <>
                        <div className={'mb-4'}>
                            <Checkbox
                                name="suspension_contrat_vente"
                                label={'Souhaitez vous suspendre votre contrat ?'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.suspension_contrat_vente}
                                error={errors.suspension_contrat_vente}
                            />
                        </div>

                        <div className={'mb-4'}>
                            <label>Afin d’arrêter les garanties merci de nous joindre un certificat de cession.<sup className={'text-danger'}>*</sup></label>
                            <Files
                                name={`files[cession]`}
                                arrayName={'files'}
                                label={'Certificat de cession'}
                                value={values.files}
                                setFieldValue={setFieldValue}
                                code={'cession'}
                            />
                        </div>
                    </>
            }
            {
                values.motif_resil && values.motif_resil === 'echeance' &&

                <div className={'mb-4'}>
                    <label>Quelle est votre date d'échéance ?<sup className={'text-danger'}>*</sup></label>

                    <div className={'row'}>
                        <div className={'col-12 col-md-6'}>
                            <Select
                                required
                                name={"mois_echeance"}
                                error={errors.mois_echeance}
                                label={false}
                                value={values.mois_echeance}>
                                {
                                    months.map((m,i) => (
                                        <option key={i} value={i}>{m}</option>
                                    ))
                                }
                            </Select>
                        </div>

                        <div className={'col-12 col-md-6'}>
                            <Select
                                required
                                name={"annee_echeance"}
                                error={errors.annee_echeance}
                                label={false}
                                value={values.annee_echeance}>
                                <option value={moment().format('YYYY')}>{moment().format('YYYY')}</option>
                                <option value={moment().add(1, 'year').format('YYYY')}>{moment().add(1, 'year').format('YYYY')}</option>
                            </Select>
                        </div>
                    </div>
                </div>
            }
            {
                values.motif_resil && values.motif_resil === 'retractation' &&
                <Select
                    required
                    name={"motif_retractation"}
                    error={errors.motif_retractation}
                    label={'Quel est le motif de votre rétractation ?'}
                    value={values.motif_retractation}>
                    <option value={''}>Sélectionnez votre motif</option>
                    <option value={'non_achat'}>Non achat</option>
                    <option value={'tarif'}>Tarif</option>
                    <option value={'garanties'}>Garanties non adaptées</option>
                </Select>
            }
        </div>
    );
};
export default FormulaireContactResil;