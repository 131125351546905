import React from 'react'
import _ from 'lodash'
import Swal from "sweetalert2";

const RequestDocument = (props) => {
    const { title, type, className, id_contrat, contracts, query, fetchRequestDocument } = props

    const chooseContract = (e) => {
        e.preventDefault()
        let optionsFilted = []
        let listeContrat = contracts;

        if (type === 'memo') {
            listeContrat = _.filter(contracts, c => {return c.vehicule.codeProduit !== '7311_NVEI' && c.vehicule.codeProduit !== '7311_VAE'});
        }

        listeContrat.map(c => {
            return optionsFilted[c.id] = `Contrat n°${c.numeroPolice} - ${c.vehicule.marque} ${c.vehicule.immatriculation}`
        })

        Swal.fire({
            title: 'Choisissez votre contrat',
            input: 'select',
            inputOptions: optionsFilted && optionsFilted.length > 0 ? optionsFilted : [],
            confirmButtonText: "Choisir ce contrat",
            allowOutsideClick: true,
            showCancelButton: true,
            cancelButtonText: 'Annuler',
        }).then(result => {
            if (result.isConfirmed) {
                fetchRequestDocument({
                    contract: id_contrat,
                    query: _.replace(query, '{type}', type),
                    libelle: title
                })
            }
        })
    }

    return (
        <div className={'text-center m-auto'}>
            <button type={'button'}
                    onClick={(e) => chooseContract(e)}
                    className={className ? className : 'btn btn-primary'}
            >
                {title}
            </button>
        </div>
    )
}


export default RequestDocument;
