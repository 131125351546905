import React from 'react';
import './todobloc.scss'
import identityCard from '../../../../../../assets/images/icons/id-card.png'
import woman from '../../../../../../assets/images/icons/businesswoman.png'
import _ from "lodash";

const TodoBloc = (props) => {
    const { receipts, documentsWaiting } = props

    return (
        <>
            {
                (receipts?.data.length > 1 || (documentsWaiting.length > 1 && _.find(documentsWaiting, 'upload_app_link'))) && <div className={'col-xl-11 col-md-12 bg-grey-light todo-block pb-0 my-4'}>
                    <h2 className="title-bloc d-inline-block">À faire</h2>
                    <div className="row gx-5">
                        {
                            receipts && receipts.data.data.map((r, i) => (
                                <div className="col-md-6 col-12 my-4" key={i}>
                                    <div className="bloc-white position-relative text-center bg-white h-100 m-0">
                                        <div className={'position-absolute info-block p-3'}><i className="bi bi-info-circle text-light fs-4" /></div>
                                        <div>
                                            <img src={woman} alt="Quittances" className={'my-2'} width={50}/>
                                            <p className={'fw-bold text-danger fs-6'}>Vous avez une cotisation à régler&nbsp;!</p>
                                            <p className={'fst-italic text-grey fs-6'}>Contrat&nbsp;:&nbsp;{r.numeroPolice} - {r.solde}€ restant</p>
                                            {r.pay_link
                                                ? <a target='_blank' href={`${r.pay_link}?url_return=${window.location.href}`} className={'fs-6'}>Payer ma cotisation</a>
                                                : <span className={`badge bg-info`}>{_.replace(r.pay_link_reason[0], '_', ' ')}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        {
                            documentsWaiting && documentsWaiting.map((o,i) => (
                                <div className="col-md-6 col-12 my-4" key={i}>
                                    <div className="bloc-white position-relative text-center bg-white h-100 m-0">
                                        <div className={'position-absolute info-block p-3'}><i className="bi bi-info-circle text-light fs-4" /></div>
                                        <div>
                                            <img src={identityCard} alt="Documents" className={'my-2'} width={50} />
                                            <p className={'fw-bold text-danger fs-6'}>Vous avez des documents à envoyer&nbsp;!</p>
                                            <p className={'fst-italic text-grey fs-6'}>Contrat&nbsp;:&nbsp;{o.numeroPolice}</p>
                                            <a target='_blank' href={o.upload_app_link} className={'fs-6'}>Envoyer mes documents</a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default TodoBloc;
