import React from 'react';
import {Formik} from "formik";
import Swal from 'sweetalert2'
import Text from "../../../Commun/Inputs/Text/Text";
import SimpleDatePicker from "../../../Commun/Inputs/DatePicker/SimpleDatePicker";
import {Link} from "react-router-dom";

const OubliEmail = (props) => {
    const { postForgotEmail, validate, host } = props

    const showToolTip = () => {
        Swal.fire({
            imageUrl: '/images/login/avi-echeance.png',
            confirmButtonText: 'J\'ai compris'
        })
    }

    return (
        <Formik initialValues={{}} onSubmit={values => postForgotEmail(values)} validate={validate}>
            {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                <form onSubmit={handleSubmit}>
                    <p className={'h2 mb-4'}><Link to={'/login'}><i className="bi bi-arrow-left-circle me-2" /></Link>Je ne me souviens plus de mon <span className={'text-primary-light'}>email de connexion</span></p>

                    <div className={'mb-4'}>
                        <Text
                            type={'text'}
                            name="nom"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nom}
                            required
                            label={'Votre nom de famille'}
                        />
                    </div>

                    <div className={'mb-2'}>
                        <Text
                            type={'number'}
                            name="belair_id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.belair_id}
                            error={errors.belair_id}
                            required
                            label={'Votre numéro client'}
                        />

                        { host === 43397 && <p className={'fw-bold d-inline-block mt-2 mb-3 text-primary cursor-pointer'} onClick={() => showToolTip()}>Où trouver mon numéro client ?</p> }
                    </div>

                    <div className={'mb-4'}>
                        <SimpleDatePicker
                            label={'Votre date de naissance'}
                            name={'date_naissance'}
                            setFieldValue={setFieldValue}
                            value={values.date_naissance}
                            error={errors.date_naissance}
                            required
                        />
                    </div>

                    <div className={'mb-4'}>
                        <Text
                            type={'text'}
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            required
                            label={'Votre adresse mail personnelle'}
                        />
                    </div>

                    <div className="d-grid gap-2">
                        <button type="submit" className={'btn btn-primary btn-block'}>
                            Je valide mon adresse email
                        </button>
                    </div>

                </form>
            )}
        </Formik>
    );
};

export default OubliEmail;
