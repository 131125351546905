import { createSelector } from 'reselect'

const userSelector = state => state.user

export const getWhitelabelUser = createSelector(
    userSelector,
    user => user.whitelabel
)

export const getApporteur1 = createSelector(
    userSelector,
    user => user.apporteur1
)

export const getIdUser = createSelector(
    userSelector,
    user => user.belair_id
)

export const getAddressUser = createSelector(
    userSelector,
    user => user.address_street_1
)

export const getCPUser = createSelector(
    userSelector,
    user => user.address_postalcode
)

export const getCityUser = createSelector(
    userSelector,
    user => user.address_city
)

export const getEmailUser = createSelector(
    userSelector,
    user => user.email
)

export const getTitreUser = createSelector(
    userSelector,
    user => user.titre
)

export const getPhoneUser = createSelector(
    userSelector,
    user => user.address_phone
)

export const getFirstnameUser = createSelector(
    userSelector,
    user => user.firstname
)

export const getLastnameUser = createSelector(
    userSelector,
    user => user.lastname
)

export const getBeneficiairesEffectifsUser = createSelector(
    userSelector,
    user => user.beneficiaires_effectifs
)

export const getPrefilledSubscribeLinksUser = createSelector(
    userSelector,
    user => user.prefilled_subscribe_links || {}
)

export const getGeneralPrefilledSubscribeLinksUser = createSelector(
    getPrefilledSubscribeLinksUser,
    links => links.general
)

export const getBanqueUser = createSelector(
    userSelector,
    user => user.banque || {}
)

export const getTitulaireBanqueUser = createSelector(
    getBanqueUser,
    banque => banque.titulaire
)

export const getIbanBanqueUser = createSelector(
    getBanqueUser,
    banque => banque.iban
)

export const getStatutDemat = createSelector(
    userSelector,
    user => user.statut_demat
)

export const getOptinUser = createSelector(
    userSelector,
    user => user.optin
)

export const getOptinPartenairesUser = createSelector(
    userSelector,
    user => user.optin_partenaires
)


export const getEligibleCodeUser = createSelector(
    userSelector,
    user => user.eligible_code
)

export const getEligibleParrainageUser = createSelector(
    userSelector,
    user => user.eligible_parrainage
)

export const getMarketingInfoUser = createSelector(
    userSelector,
    user => user.marketing_code || {}
)

export const getCodePromoUser = createSelector(
    getMarketingInfoUser,
    marketing => marketing.code
)

export const getNbParrainageUser = createSelector(
    userSelector,
    user => user.nb_parrainage
)
