export const validate = values => {
    const errors = {}

    const requiredFields = [
        "date_naissance",
        "nom",
        "belair_id",
        "email"
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })

    return errors
}
