import {
    CONTRACT,
    CONTRACTS,
    FETCH_CONTRACT,
    FETCH_CONTRACTS, FETCH_WAITING_DOC_CONTRACTS, POST_CONFIRM_RESIL_HAMON, setContract,
    setContracts, setWaitingDocumentsContracts, WAITING_DOC_CONTRACTS
} from '../../../actions/app/contracts/contracts.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const contractsMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_CONTRACTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: CONTRACTS}),
                setLoader({state: true, entity: CONTRACTS})
            ])
            break

        case `${CONTRACTS} ${API_SUCCESS}`:
            const { data, meta, links } = action.payload.data
            next([
                setContracts({data, meta, links}),
                setError({state: false, entity: CONTRACTS}),
                setLoader({state: false, entity: CONTRACTS})
            ])

            break

        case `${CONTRACTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: CONTRACTS}),
                setLoader({state: false, entity: CONTRACTS})
            ])
            break

        case FETCH_CONTRACT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: CONTRACT}),
                setLoader({state: true, entity: CONTRACT})
            ])
            break

        case `${CONTRACT} ${API_SUCCESS}`:

            next([
                setContract({data: action.payload.data}),
                setError({state: false, entity: CONTRACT}),
                setLoader({state: false, entity: CONTRACT})
            ])

            break

        case `${CONTRACT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: FETCH_CONTRACT,
                    html: <p>Une erreur est survenue lors de la récupération du contrat.</p>,
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: CONTRACT}),
                setLoader({state: false, entity: CONTRACT})
            ])
            break

        case FETCH_WAITING_DOC_CONTRACTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: WAITING_DOC_CONTRACTS}),
                setLoader({state: true, entity: WAITING_DOC_CONTRACTS})
            ])
            break

        case `${WAITING_DOC_CONTRACTS} ${API_SUCCESS}`:
            next([
                setWaitingDocumentsContracts({data: action.payload.data.data, meta: action.payload.data.meta, links: action.payload.data.links}),
                setError({state: false, entity: WAITING_DOC_CONTRACTS}),
                setLoader({state: false, entity: WAITING_DOC_CONTRACTS})
            ])

            break

        case `${WAITING_DOC_CONTRACTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: WAITING_DOC_CONTRACTS}),
                setLoader({state: false, entity: WAITING_DOC_CONTRACTS})
            ])
            break


        case POST_CONFIRM_RESIL_HAMON:
            console.log(action);
            const confirmResil = action.payload.body.confirm_resil;
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CONFIRM_RESIL_HAMON,
                    otherData: {
                        ...action.payload.otherData,
                        confirmResil
                    }
                }),
                setLoader({ state: true, entity: POST_CONFIRM_RESIL_HAMON })
            ]);
            break;

        case `${POST_CONFIRM_RESIL_HAMON} ${API_SUCCESS}`:
            const notificationMessage = action.payload.meta.otherData.confirmResil === 'T'
                ? 'Vous nous avez confirmé avoir recu la notificiation de la résiliation de votre ancien contrat'
                : action.payload.meta.otherData.confirmResil === 'F'
                    ? 'Vérifiez auprès de votre ancienne assurance que votre contrat est bien résilié ? Partagez nous l\'info ici dès que vous l\'obtenez !'
                    : 'Merci pour votre retour, un conseiller vous recontacte dans les meilleurs délais'

            next([
                setError({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setLoader({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setNotification({
                    entity: POST_CONFIRM_RESIL_HAMON,
                    html: `<div><p>${notificationMessage}</p></div>`,
                    type: 'success',
                    title: 'Loi Hamon',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: action.payload.meta.otherData.successFunction
                })
            ]);
            break;

        case `${POST_CONFIRM_RESIL_HAMON} ${API_ERROR}`:
            next([
                setError({ state: true, entity: POST_CONFIRM_RESIL_HAMON }),
                setLoader({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setNotification({
                    entity: POST_CONFIRM_RESIL_HAMON,
                    html: `<p>${action.payload.data.response.data.reason}</p>`,
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ]);
            break;

        default:
            break;
    }
    return null
}
