import React from 'react';
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";

const DeclarationVol = (props) => {
    const { errors, values, setFieldValue} = props

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-4'}>Dites-nous en plus...</h4>
            </div>

            <div className="mb-4">
                <RadioGroup
                    label={"Votre véhicule était-il dans un garage ?"}
                    name={`vehiculeIsInGarage`}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: "T", label:"Oui"},
                        {value: "F", label:"Non"}
                    ]}
                    value={values.vehiculeIsInGarage}
                />
            </div>
            <div className="mb-4">
                <RadioGroup
                    label={"Votre véhicule était-il gravé ?"}
                    name={`vehiculeIsMark`}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: "T", label:"Oui"},
                        {value: "F", label:"Non"}
                    ]}
                    value={values.vehiculeIsMark}
                />
            </div>
            <div className="mb-4">
                <RadioGroup
                    label={"Votre véhicule était-il attaché avec un antivol au moment du vol ?"}
                    name={`vehiculeIsAttached`}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: "T", label:"Oui"},
                        {value: "F", label:"Non"}
                    ]}
                    value={values.vehiculeIsAttached}
                />
            </div>
        </div>
    );
};

export default DeclarationVol;
