import React from 'react';
import './welcome.scss'

const Welcome = (props) => {
    const {firstName, userId} = props

    return (
        <div className={'bloc-white welcome col-xl-11 col-md-12'}>
            <small>Assuré n°{userId}</small>
            <p>Bonjour {firstName}, bienvenue sur votre <strong>espace assuré</strong></p>
        </div>
    );
};

export default Welcome;
