import React, {useEffect, useState} from 'react';
import Text from "../../../../../../Commun/Inputs/Text/Text";
import PostalCodeCity from "../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";
import _ from "lodash";
import {getCountries} from "../../../../../../../utils/functions";
import moment from "moment/moment";
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import Autocomplete from "../../../../../../Commun/Inputs/Autocomplete/Autocomplete";
import Select from "../../../../../../Commun/Inputs/Select/Select";
import {tiersPayeurRelation} from "../../../../../../../utils/optionsSelect";
import SimpleDatePicker from "../../../../../../Commun/Inputs/DatePicker/SimpleDatePicker";
import AutoCompleteBirth from "../../../../../../Commun/Inputs/AutoCompleteBirth/AutoCompleteBirth.jsx";

const ChangeTitulaire = (props) => {
    const {values, errors, handleChange, handleBlur, titulaire, changeTitulaire, setChangeTitulaire, setFieldValue, contrat} = props
    const [countries, setCountries] = useState()
    const souscripteur = contrat ? contrat.souscripteur.nom : null

    const handleChangeTitulaire = () => {
        setChangeTitulaire(false)
        setFieldValue('type', '');
        setFieldValue('adresse', '');
        setFieldValue('cp', '');
        setFieldValue('ville', '');
        setFieldValue('relationship', '');
        setFieldValue('titulaire', '');
        setFieldValue('firstname', '');
        setFieldValue('lastname', '');
        setFieldValue('birthname', '');
        setFieldValue('birthdate', '');
        setFieldValue('birthcity', '');
        setFieldValue('birthcountry', '');
        setFieldValue('relationship', '');
        setFieldValue('third_payeur_reason', '');
    }

    useEffect(() => {
        (async function fetchCountries(){
            let countries = await getCountries()

            const listcountries = countries.map(c => ({
                value: c.id,
                label: c.name
            }))

            setCountries(listcountries)
        })()
    }, [])

    const handleChangeTitulaireField = (e) => {
        if(contrat.id){
            if(!e || _.toUpper(_.trim(souscripteur)) === _.toUpper(_.trim(e))){
                handleChangeType(null);
                clearFieldsThirdPartyPayer();
            } else {
                setFieldValue('contract', contrat.id);
            }
        }
    }

    const clearFieldsThirdPartyPayer = (type = 'all') =>{
        if(type === "M"|| type === 'all'){
            setFieldValue('firstname', '');
            setFieldValue('birthname', '');
            setFieldValue('birthdate', '');
            setFieldValue('birthcountry', '');
            setFieldValue('birthcity', '');
            setFieldValue('relationship', '');
            setFieldValue('third_payeur_reason', '');
        }

        if(type === 'all'){
            setFieldValue('type', '');
            setFieldValue('lastname', '');
            setFieldValue('contract', '');
        }
    }

    const handleChangeType = (e) => {
        setFieldValue('type', e);
        clearFieldsThirdPartyPayer(e);
    }
	
    return (
        <div>
            {
                changeTitulaire ?
                    <div>
                        <button type="button" className={'btn btn-link'} onClick={handleChangeTitulaire}>
                            Annuler
                        </button>
                        <div className={'mb-4 mt-4'}>
                            <Text
                                type={'text'}
                                name="titulaire"
                                label={'Nom du titulaire du compte'}
                                onBlur={handleBlur}
                                value={values.titulaire}
                                error={errors.titulaire}
                                onChange={(e) => {
                                    handleChange(e)
                                    handleChangeTitulaireField(e.target.value)
                                }}
                                required
                            />
                        </div>
                        { (contrat.id && values.titulaire && _.toUpper(_.trim(souscripteur)) !== _.toUpper(_.trim(values.titulaire))) &&
                            <>
                                <div className={'mb-4 mt-4'}>
                                    <RadioGroup
                                        label={"Type de tiers payeur"}
                                        name={'type'}
                                        setFieldValue={setFieldValue}
                                        options={[
                                            {value: "P", label:"Personne physique"},
                                            {value: "M", label:"Personne morale"}
                                        ]}
                                        value={values.type}
                                        onChange={(e) => handleChangeType(e.target.value)}
                                        required
                                    />
                                </div>

                                {values.type === 'P' &&
                                    <>
                                        <div className={'mb-4 mt-4'}>
                                            <Select
                                                name={"relationship"}
                                                label={'Votre lien avec le tiers payeur'}
                                                value={values.relationship}
                                                error={errors.relationship}
                                                onChange={e => {
                                                    setFieldValue('relationship', e.target.value)
                                                    if(e.target.value !== 'A'){
                                                        setFieldValue('third_payeur_reason', '')
                                                    }
                                                }}
                                                required
                                            >
                                                <option value={""}>Sélectionnez une relation</option>
                                                {tiersPayeurRelation.map((relation, index) => (
                                                    <option key={index} value={relation.code}>{relation.libelle}</option>
                                                ))}
                                            </Select>
                                        </div>

                                        { values.relationship === 'A' &&
                                            <div className={'mb-4 mt-4'}>
                                                <Text
                                                    type={'text'}
                                                    name="third_payeur_reason"
                                                    label={'Motif d\'intervention du payeur'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.third_payeur_reason}
                                                    error={errors.third_payeur_reason}
                                                    maxLength={250}
                                                    required
                                                />
                                            </div>
                                        }
                                    </>
                                }

                                <div className={'mb-4 mt-4'}>
                                    <Text
                                        type={'text'}
                                        name="lastname"
                                        label={values.type === 'P' ? 'Nom' : 'Nom de la société'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastname}
                                        error={errors.lastname}
                                        normalize={v => v.toUpperCase()}
                                        maxLength={50}
                                        required
                                    />
                                </div>

                                {values.type === 'P' &&
                                    <>
                                        <div className={'mb-4 mt-4'}>
                                            <Text
                                                type={'text'}
                                                name="birthname"
                                                label={'Nom de naissance'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.birthname}
                                                error={errors.birthname}
                                                normalize={v => v.toUpperCase()}
                                                maxLength={20}
                                                required
                                            />
                                        </div>

                                        <div className={'mb-4 mt-4'}>
                                            <Text
                                                type={'text'}
                                                name="firstname"
                                                label={'Prénom'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.firstname}
                                                error={errors.firstname}
                                                normalize={v => v.toUpperCase()}
                                                maxLength={20}
                                                required
                                            />
                                        </div>

                                        <div className={'mb-4 mt-4'}>
                                            <SimpleDatePicker
                                                label={'Date de naissance'}
                                                name={'birthdate'}
                                                setFieldValue={setFieldValue}
                                                value={values.birthdate}
                                                error={errors.birthdate}
                                                maxDate={new Date(moment().subtract(14, 'Y').format('YYYY-MM-DD'))}
                                                required
                                            />
                                        </div>

                                        <div className={'mb-4 mt-4'}>
                                            {
                                                countries && <Autocomplete
                                                    name={`birthcountry`}
                                                    label="Pays de naissance"
                                                    data={countries}
                                                    onChange={handleChange}
                                                    value={values.birthcountry}
                                                    defaultValue={_.find(countries, ['value', values.birthcountry])?.label}
                                                    setFieldValue={setFieldValue}
                                                    error={errors.birthcountry}
                                                    required
                                                />
                                            }
                                        </div>

                                        <div className={'mb-4 mt-4'}>
                                            { values.birthcountry === 'FRA' ?
                                                <AutoCompleteBirth
                                                    name="birthcity"
                                                    label='Ville de naissance'
                                                    onChange={handleChange}
                                                    value={values.birthcity}
                                                    country={values.birthcountry}
                                                    defaultValue={values.birthcity}
                                                    setFieldValue={setFieldValue}
                                                    required
                                                />
                                                : <Text
                                                type='text'
                                                name="birthcity"
                                                label='Ville de naissance'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.birthcity}
                                                error={errors.birthcity}
                                                required
                                            /> }
                                        </div>
                                    </>
                                }
                            </>
                        }

                        <div className={'mb-4 mt-4'}>
                            <Text
                                type={'text'}
                                name="adresse"
                                label={'Adresse du titulaire du compte'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.adresse}
                                error={errors.adresse}
                                required
                            />
                        </div>
                        
                        <div className={'mb-4 mt-4'}>
                            <PostalCodeCity
                                label={'Code postal et ville'}
                                cp='cp'
                                ville='ville'
                                value={values.ville}
                                error={errors.ville}
                                required
                            />
                        </div>
                    </div>
                : <div>
                    <p style={{margin: 0}}>Titulaire du compte : {titulaire}</p>
                    <button type="button" className={'btn btn-link'} onClick={() => setChangeTitulaire(true)}>
                        Changer le titulaire du compte
                    </button>
                </div>
            }
        </div>
    );
};

export default ChangeTitulaire;
