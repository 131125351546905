import React, {useState} from 'react';
import _ from 'lodash'
import { useFormikContext } from 'formik'
import {getCity, getCityBySearch} from "../../../../utils/functions";
import Text from "../Text/Text";

const PostalCodeCity = (props) => {
    const { cp, ville, required, label, error, defaultValue, disabled } = props
    const { setFieldValue, values }= useFormikContext()
    const [city, setCity] = useState([])
    const [selected, setSelected] = useState(false)
    const [textValue, setTextValue] = useState(values[cp] && values[ville] ? `${values[cp]} ${values[ville]}` : '')

    /* Format la réponse par code postal */
    const formatObj = ({codePostal, villes}) => {
        let data = []
        _.forEach(villes, ville => {
            data.push({ville, codePostal})
        })
        return data
    }

    /* fonction asynchrone pour recherche ville par code postal */
    async function fetchCityByPostalCode(value){
        let cities = await getCity(value)
        let arrayCities = formatObj({codePostal: value, villes: cities})
        setCity(arrayCities)
    }

    /* fonction asynchrone pour recherche ville par nom de ville */
    async function fetchCityBySearch(value){
        let cities = await getCityBySearch(value)
        setCity(cities)
    }

    /* lance la recherche au onChange du champ */
    const searchCity = value =>{
        setSelected(false)
        if (_.parseInt(value)){
            value.length === 5 && fetchCityByPostalCode(value)
        } else {
            return value.length >= 3 && fetchCityBySearch(value)
        }
    }

    /* set les valeur pour affichage */
    const setCityAndPostalCode = cityClicked => {
        setSelected(true)
        setFieldValue(cp, cityClicked.codePostal)
        setFieldValue(ville, cityClicked.ville)
        setTextValue(`${cityClicked.codePostal} ${cityClicked.ville}`)
    }

    return (
        <>
            <Text
                type={'text'}
                required={required}
                label={label}
                onChange={(e) => {
                    setTextValue(e.target.value)
                    searchCity(e.target.value)
                }}
                onBlur={(e) => e.target.value === "" && setSelected(true)}
                value={textValue || defaultValue}
                error={error}
                disabled={disabled}
            />
            {
                !selected && <div className="row">
                    <div className="col">
                        <div className="list-group">
                            {
                                city.map((city, index) => (
                                    <li className={'cursor-pointer list-group-item list-group-item-action'} key={index} value={city.ville} onClick={() => setCityAndPostalCode(city)}>
                                        {city.ville} {city.codePostal}
                                    </li>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};


export default PostalCodeCity;
