import {connect} from 'react-redux'
import FormulaireResil from "./FormulaireResil";
import {
    getAdhesionPrincipaleContract,
    getNumeroPoliceContract,
    getSouscripteurLastNameContract,
    getSouscripteurFirstNameContract,
    getSouscripteurEmailContract,
    getContractStatutCode,
    getContractDateAffaireNouvelle,
    getSouscripteurTitreContract,
    getUsageVehiculeContract,
    getDateAvtContract,
    getContractGarantiesOptions,
    getContractId,
    getContractDateEcheance,
    getContractEcheance,
    getIsVAEContract,
    getIsNVEIContract
} from "../../../../../../redux/selectors/contracts/contract.selector";
import {fetchAccidents} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {postTerminate} from "../../../../../../redux/actions/app/terminate/terminate.actions";
import _ from "lodash";
import {getAccidentsTiersData} from "../../../../../../redux/selectors/accidents/accidents.selectors";
import {getAccidentsByContratLink, terminateGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {compose} from "redux";
import loader from "../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    return {
        isVAE: getIsVAEContract(state),
        isNVEI: getIsNVEIContract(state),
        contrat_id: getContractId(state),
        query: _.replace(getAccidentsByContratLink(state), '{id}', getContractId(state)),
        queryTerminate: _.replace(terminateGetLink(state), '{id}', getContractId(state)),
        situation: getContractStatutCode(state),
        echeance: getContractEcheance(state),
        dateEcheance: getContractDateEcheance(state),
        dateAfn: getContractDateAffaireNouvelle(state),
        titre: getSouscripteurTitreContract(state),
        usage: getUsageVehiculeContract(state),
        dateAvt: getDateAvtContract(state),
        garanties: getContractGarantiesOptions(state),
        accidents : getAccidentsTiersData(state),
        initialValues: {
            police: getNumeroPoliceContract(state),
            adhesion: getAdhesionPrincipaleContract(state),
            prenom: getSouscripteurFirstNameContract(state),
            nom: getSouscripteurLastNameContract(state),
            email: getSouscripteurEmailContract(state),
            files: []
        }
    }
}

const mapDispatchToProps = {
    fetchAccidents,
    postTerminate
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchAccidents } = dispatchProps
    const { query, accidents } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(accidents) ? fetchAccidents(query) : null,
    }
}

const FormulaireResilContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(FormulaireResil)

export default FormulaireResilContainer
