import React from 'react';
import {Link} from "react-router-dom";

const ConfirmationSinistre = (props) => {
    const {accidentResonse} = props

    return (
        <div className={'my-3'}>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}><Link to={'/mes-sinistres'}><i className="bi bi-arrow-left-circle me-2" /></Link> Mes sinistres</h1>
            </div>
            <div className="alert alert-success" role="alert">
                {accidentResonse ? accidentResonse : 'Votre déclaration de sinistre a bien été enregistrée ! Un(e) gestionnaire reprendra contact avec vous sous 48h.'}
            </div>

           <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block mb-4'}>Vous souhaitez être recontacté(e) à une heure précise, prenez rendez-vous !</h4>
                </div>

                <iframe
                    src='https://outlook.office365.com/owa/calendar/RDVsuitevotredclarationdesinistre@groupeapril.onmicrosoft.com/bookings/'
                    width='100%'
                    height='1730px'
                    scrolling='yes'
                />
            </div>
        </div>
    )
}

export default ConfirmationSinistre;
