import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import AppProvider from './AppProvider';
import './assets/scss/global.scss';
import { APP_ENV } from './constants/constants';
import * as Sentry from "@sentry/react";

window.dataLayer = window.dataLayer || [];

window.dataLayer.push({
    environnement: APP_ENV,
});

/*Sentry.init({
    dsn: "https://4fb76c2547e34b27aa6e5f98aa40bdec@sentry.april-moto.com/7",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: APP_ENV
});*/

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    tracesSampleRate: 0.01,
    environment: APP_ENV,
    enabled: true
});

const root = createRoot(document.getElementById('root'));

if (APP_ENV) {
    root.render(<AppProvider />);
} else {
    root.render(<h1>.env requis à la racine du projet, un npm start est nécessaire.</h1>);
}
