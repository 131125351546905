import React from 'react';
import {Field} from "formik";
import PropTypes from 'prop-types'

const Select = (props) => {
    const { value, error, children, label, required } = props

    return (
        <div>
            {label &&
                <label>{label}{required ? <sup className={'text-danger'}>*</sup> : ''}</label>
            }
            <Field as="select" {...props} className={`w-100 p-3 rounded ${value ? 'valid' : ''}`}>
                {children}
            </Field>
            {error && <small className={'text-danger fst-italic'}>{error}</small>}
        </div>
    );
};

Select.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    children: PropTypes.node
};


export default Select;
