import React from 'react';
import {Link} from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Badge from "../../../../../Commun/Badge/Badge";

const ListeSinistres = (props) => {
    const { accidents, isACL, isWakam } = props

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link> Mes sinistres</h1>
            </div>
            {accidents.length >= 1 ?
                accidents.map(c => (
                    <div className={'bloc-white'} key={c.id}>
                        <div>
                            <h4 className={'fw-bold text-primary-light d-inline-block'}>Sinistre N°{c.numCbt}</h4> <Link to={`/mes-sinistres/contrat/${c.id_contrat}/sinistre/${c.id}`} className={'float-right'}>Voir le détail</Link>
                        </div>

                        <div className={'mt-4'}>
                            <div className="row">
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Date de survenance</p>
                                    <p className={'m-0 text-secondary'}>{moment(c.dateSurv).format('L')}</p>
                                </div>
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Situation</p>
                                    <Badge status={_.capitalize(c.situation)}/>
                                </div>
                            </div>

                            <hr/>

                            <div className="row">
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Véhicule</p>
                                    <p className={'m-0 text-secondary'}>{c.libelle}</p>
                                </div>
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Catégorie</p>
                                    <p className={'m-0 text-secondary'}>{c.categorie}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                : <p className={'mt-4 text-grey'}>Vous n'avez actuellement aucun sinistre déclaré.</p>
            }
            { !isACL && !isWakam && <div className="mt-5">
                <Link to={'/mes-sinistres/je-declare-un-sinistre'} className={'btn btn-primary full'}>Déclarer un sinistre</Link>
            </div> }
        </div>
    );
};

export default ListeSinistres;
