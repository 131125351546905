import {connect} from 'react-redux'
import DetailContract from "./DetailContract";
import {
    getIsVAEContract,
    getNumeroPoliceContract,
    getContractGarantiesOptions,
    getSouscripteurContract,
    getUploadAppLinkContract,
    getUploadLinkContract,
    getVehiculeContract,
    getContractStatutCode,
    getIsAIMCOContract,
    getTiersPayeurContract,
    getContractId,
    getPrelevementContract, getContractFractionnementLabel, getDataContract, getIsWakamContract, getContractHamon
} from "../../../../../../../redux/selectors/contracts/contract.selector";
import {DOCUMENTS, fetchDocuments} from "../../../../../../../redux/actions/app/documents/documents.actions";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {compose} from "redux";
import {
    getDataDocuments,
    getIfWaitingDocuments
} from "../../../../../../../redux/selectors/documents/documents.selector";
import {
    FETCH_RECEIPTS_UNPAID,
    fetchReceiptsUnpaid
} from "../../../../../../../redux/actions/app/payments/receiptsUnpaid/receiptsUnpaid.actions";
import {
    confirmationResilHamonGetLink,
    deleteThirdPartyPayerLink,
    receiptsLink
} from "../../../../../../../redux/selectors/links/links.selectors";
import {getDataReceiptsContractUnpaid} from "../../../../../../../redux/selectors/payments/receiptsUnpaid/receiptsUnpaid.selectors";
import {postDeleteTiersPayeur} from "../../../../../../../redux/actions/app/tiers_payeur/tiers_payeur.actions";
import _ from "lodash";
import {postConfirmResilHamon} from "../../../../../../../redux/actions/app/contracts/contracts.actions.js";

const mapStateToProps = (state,ownProps) => {
    const { id } = ownProps.match.params

    return {
        id_contrat: getContractId(state),
        isWakam: getIsWakamContract(state),
        loaded: getLoadingEntity(state, DOCUMENTS) === false && getLoadingEntity(state, FETCH_RECEIPTS_UNPAID) === false,
        query_documents: getUploadLinkContract(state),
        query_receipts_unpaid: `${receiptsLink(state)}?status=all`,
        query_delete_third_party_payer: deleteThirdPartyPayerLink(state),
        query_resil_hamon: confirmationResilHamonGetLink(state),
        documents: getDataDocuments(state),
        receipts_unpaid: getDataReceiptsContractUnpaid(state),
        souscripteur: getSouscripteurContract(state),
        tiersPayeur: getTiersPayeurContract(state),
        vehicule: getVehiculeContract(state),
        isVAE: getIsVAEContract(state),
        garanties: getContractGarantiesOptions(state),
        hamon: getContractHamon(state),
        police: getNumeroPoliceContract(state),
        has_waiting_doc: getIfWaitingDocuments(state),
        uploadAppLink: getUploadAppLinkContract(state),
        statutCode: getContractStatutCode(state),
        isAIMCO: getIsAIMCOContract(state),
        prelevement: getPrelevementContract(state),
        fractionnement: getContractFractionnementLabel(state),
        contract: getDataContract(state)
    }
}

const mapDispatchToProps = {
    fetchDocuments,
    fetchReceiptsUnpaid,
    postDeleteTiersPayeur,
    postConfirmResilHamon
}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchDocuments, fetchReceiptsUnpaid, postDeleteTiersPayeur } = dispatchProps
    const { query_documents, query_receipts_unpaid, query_delete_third_party_payer, id_contrat } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitDeleteTiersPayeur: () => postDeleteTiersPayeur({query: _.replace(query_delete_third_party_payer, '{id}', id_contrat)}),
        load: () => {
            fetchDocuments({query: query_documents})
            fetchReceiptsUnpaid({query: query_receipts_unpaid})
        }
    }
}

const DetailContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DetailContract)

export default DetailContractContainer
