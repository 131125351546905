export const DOCUMENTS = '[Documents]'

export const FETCH_DOCUMENTS = `${DOCUMENTS} Fetch`
export const SET_DOCUMENTS = `${DOCUMENTS} Set`

export const fetchDocuments = ({query}) => ({
    type: FETCH_DOCUMENTS,
    payload: {
        data: query
    }
})

export const setDocuments = ({data}) => ({
    type: SET_DOCUMENTS,
    payload: { data },
    meta:{
        entity: DOCUMENTS
    }
})
