import {connect} from 'react-redux'
import Hivernage from "./Hivernage.jsx";
import {validate} from "./validate";
import {
    getCanActivateHivernageContract, getContractDateEcheance,
    getContractId, getDernierHivernageContract,
    getIsEligibleHivernageContract
} from "../../../../../../../redux/selectors/contracts/contract.selector.js";
import moment from "moment";
import {
    getHivernageReduc,
    POST_AMENDMENT_FIN_HIVERNAGE, POST_AMENDMENT_GET_HIVERNAGE_REDUC,
    POST_AMENDMENT_HIVERNAGE,
    postFinHivernage,
    postHivernage
} from "../../../../../../../redux/actions/app/amendment/amendment.actions.js";
import _ from "lodash";
import {
    amendmentFinHivernageGetLink,
    amendmentHivernageGetLink
} from "../../../../../../../redux/selectors/links/links.selectors.js";
import {getHivernageReduction, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors.js";

const mapStateToProps = state => {
    const dernier_hivernage = getDernierHivernageContract(state);
    const echeance = getContractDateEcheance(state);
    let maxDate = moment().add(120, 'day');
    let maxDateEcheance = false;
    let finHivernagePeriode = moment('01/03', 'DD/MM');

    if(moment().startOf('day').isAfter(finHivernagePeriode)){
        finHivernagePeriode = finHivernagePeriode.add(1, 'year');
    }

    if(dernier_hivernage && dernier_hivernage?.periodeCourante && dernier_hivernage.statutCode !== '1'){
        // si on a déjà eu une période hivernale terminée
        maxDate = moment().add((120 - dernier_hivernage.duree), 'day');
    }
    if(maxDate.isAfter(finHivernagePeriode)){
        maxDate = finHivernagePeriode;
    }
    if(!moment(echeance, 'YYYY/MM/DD').isSame(moment().startOf('day')) && maxDate.isAfter(moment(echeance, 'YYYY/MM/DD').subtract(1, 'day'))){
        //La date max ne peut pas dépasser le terme sauf si le terme est aujourd'hui
        maxDate = moment(echeance, 'YYYY/MM/DD').subtract(1, 'day')
        maxDateEcheance = true;
    }

    return {
        validate,
        query: _.replace(amendmentHivernageGetLink(state), '{id}', getContractId(state)),
        query_fin: _.replace(amendmentFinHivernageGetLink(state), '{id}', getContractId(state)),
        id_contrat: getContractId(state),
        is_eligible_hivernage : getIsEligibleHivernageContract(state),
        can_activate_hivernage : getCanActivateHivernageContract(state),
        dernier_hivernage: dernier_hivernage,
        maxDate: maxDate,
        initialValues: {
            dateFin: maxDate.format('YYYY-MM-DD'),
        },
        loading: getLoadingEntity(state, POST_AMENDMENT_HIVERNAGE),
        loading_get_reduc: getLoadingEntity(state, POST_AMENDMENT_GET_HIVERNAGE_REDUC),
        loading_fin: getLoadingEntity(state, POST_AMENDMENT_FIN_HIVERNAGE),
        maxDateEcheance: maxDateEcheance,
        hivernage_reduction: getHivernageReduction(state)
    }
}

const mapDispatchToProps = {
    postHivernage,
    postFinHivernage,
    getHivernageReduc
}

const HivernageContainer = connect(mapStateToProps, mapDispatchToProps)(Hivernage)

export default HivernageContainer