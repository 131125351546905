import React, {useState} from 'react';
import Autocomplete from "../Inputs/Autocomplete/Autocomplete.jsx";
import Select from "../Inputs/Select/Select.jsx";

const FilteredAutocomplete = (props) => {
    const {labelothervalue = 'Autre', datafiltered, data, setFieldValue, name, onChange = () => {}} = props;
    const [showAll, setShowAll] = useState(false);

    return (
        !showAll ?
            <Select
                {...props}
                name={`filtered${name}`}
                onChange={(e) => {
                    setFieldValue(name, e.target.value === 'other' ? null : e.target.value);

                    if(e.target.value === 'other') {
                        setShowAll(true)
                    } else {
                        onChange(e.target.value);
                    }
                }}
            >
                <option value={null}>Choisissez une valeur</option>
                {datafiltered.map((c, index) => <option key={index} value={c.value}>{c.label}</option>)}
                <option value={'other'}>{labelothervalue}</option>
            </Select>
        : <Autocomplete {...props} data={data} setFieldValue={setFieldValue} />
    );
};

export default FilteredAutocomplete;
