import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_TERMINATE} from "../../../actions/app/terminate/terminate.actions";

export const terminateMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){
        case POST_TERMINATE:
            let data = new FormData()
            let body = action.payload.body

            Object.keys(body).forEach(key => {
                if (key === 'files') {
                    Object.keys(body[key]).forEach(doc => {
                        data.append(`files[${doc}]`, body[key][doc])
                    })
                } else if (key === 'prenom' || key === 'nom') {
                    if (body[key] !== '') {
                        data.append(key, body[key])
                    }
                } else {
                    data.append(key, body[key])
                }
            });

            next([
                apiRequest({body: data, method: 'POST', url: payload.data, entity: POST_TERMINATE, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_TERMINATE})
            ])
            break

        case `${POST_TERMINATE} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_TERMINATE}),
                setLoader({state: false, entity: POST_TERMINATE}),
                setNotification({
                    entity: POST_TERMINATE,
                    html: '<div><p>Un accusé de réception a été envoyé par email</p></div>',
                    type: 'success',
                    title: 'Demande envoyée',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])
            break

        case `${POST_TERMINATE} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_TERMINATE}),
                setLoader({state: false, entity: POST_TERMINATE}),
                setNotification({
                    entity: POST_TERMINATE,
                    html: '<p>' + payload.data.response.data.reason + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        default:
            break
    }
    return null
}
