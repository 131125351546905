import {Link, Route} from "react-router-dom";
import React from "react";
import ListeArticlesContainer from "./ListeArticles/ListeArticlesContainer";
import DetailArticleContainer from "./DetailArticle/DetailArticleContainer";

const Articles = () => {
    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-actualites'} className={'text-decoration-none'}>Mes actualités</Link></h6>
            </div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}>Mes actualités</h1>
            </div>

            <Route exact path={'/mes-actualites'} component={ListeArticlesContainer} />
            <Route exact path={'/mes-actualites/:id'} component={DetailArticleContainer} />
        </div>
    );
};

export default Articles;
