import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    FLOTTE, FLOTTE_ACCIDENTS,
    FLOTTE_DETAIL,
    GET_FLOTTE, GET_FLOTTE_ACCIDENTS,
    POST_DELETE_VEHICLES,
    GET_FLOTTE_DETAIL,
    setFlotte,
    setFlotteDetail
} from "../../../actions/app/flotte/flotte.actions";
import _ from 'lodash'
import {setAccidents} from "../../../actions/app/accidents/accidents.actions";
import moment from "moment";
import {setNotification} from "../../../actions/core/notifications/notifications.actions.js";
import {POST_UPDATE_BIRTH_INFOS_TIERS} from "../../../actions/app/customer/customer.actions.js";

export const flotteMiddleware = () => next => action => {
    next(action)

    let clone = null;
    let url = '';

    switch (action.type) {

        case GET_FLOTTE:
            url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters) {
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: FLOTTE}),
                setLoader({state: true, entity: FLOTTE})
            ])
            break

        case `${FLOTTE} ${API_SUCCESS}`:
            let {data, meta, links} = action.payload.data
            next([
                setFlotte({data, meta, links}),
                setError({state: false, entity: FLOTTE}),
                setLoader({state: false, entity: FLOTTE})
            ])
            break

        case `${FLOTTE} ${API_ERROR}`:
            next([
                setError({state: true, entity: FLOTTE}),
                setLoader({state: false, entity: FLOTTE})
            ])
            break

        case GET_FLOTTE_DETAIL:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: FLOTTE_DETAIL}),
                setLoader({state: true, entity: FLOTTE_DETAIL}),
            ])
            break

        case `${FLOTTE_DETAIL} ${API_SUCCESS}`:
            next([
                setFlotteDetail({data: action.payload.data}),
                setLoader({state: false, entity: FLOTTE_DETAIL}),
                setError({state: false, entity: FLOTTE_DETAIL})
            ])
            break

        case `${FLOTTE_DETAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: FLOTTE_DETAIL}),
                setLoader({state: false, entity: FLOTTE_DETAIL})
            ])
            break

        case GET_FLOTTE_ACCIDENTS:
            url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters) {
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url: url, entity: FLOTTE_ACCIDENTS}),
                setLoader({state: true, entity: FLOTTE_ACCIDENTS})
            ])
            break

        case `${FLOTTE_ACCIDENTS} ${API_SUCCESS}`:
            next([
                setAccidents({...action.payload}),
                setError({state: false, entity: FLOTTE_ACCIDENTS}),
                setLoader({state: false, entity: FLOTTE_ACCIDENTS})
            ])
            break

        case `${FLOTTE_ACCIDENTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: FLOTTE_ACCIDENTS}),
                setLoader({state: false, entity: FLOTTE_ACCIDENTS})
            ])
            break

        case POST_DELETE_VEHICLES:
            clone = _.cloneDeep(action.payload.body)

            delete clone.vehicules
            clone.date_sortie =  moment(clone.date_sortie).format('YYYY-MM-DD')

            next([
                apiRequest({
                    body: clone,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DELETE_VEHICLES
                }),
                setLoader({state: true, entity: POST_DELETE_VEHICLES})
            ])
            break

        case `${POST_DELETE_VEHICLES} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DELETE_VEHICLES,
                    html: '<p>La sortie des véhicules de la flotte a été effectuée</p>',
                    icon: 'success',
                    title: 'Suppression effectuée !',
                    confirmButtonText: 'J\'ai compris',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    successFunction: action.payload.meta.otherData.successFunction
                }),
                setLoader({state: false, entity: POST_DELETE_VEHICLES}),
                setError({state: false, entity: POST_DELETE_VEHICLES}),
            ])
            break

        case `${POST_DELETE_VEHICLES} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DELETE_VEHICLES,
                    html: `<p>Votre demande n'a pas pu être envoyée. ${action.payload.data.response.data.reason}</p>`,
                    icon: 'error',
                    title: 'Erreur lors de la suppression !',
                }),
                setError({state: true, entity: POST_DELETE_VEHICLES}),
                setLoader({state: false, entity: POST_DELETE_VEHICLES}),
            ])
            break
        default:
            break
    }
    return null
}
