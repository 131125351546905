import {connect} from 'react-redux'
import Infobulle from "./Infobulle";
import {getDataContracts} from "../../../../redux/selectors/contracts/contracts.selectors";
import {getDataContract, getIsWakamContract} from "../../../../redux/selectors/contracts/contract.selector.js";

const mapStateToProps = state => {
    return {
        contracts: getDataContracts(state),
        isWakam: getIsWakamContract(state),
        contract: getDataContract(state)
    }
}

const InfobulleContainer = connect(mapStateToProps)(Infobulle)

export default InfobulleContainer
