import React, {useState} from 'react';
import _ from "lodash";
import {getCity, getReparateurByCodePostal, removeJoursFeries} from "../../../../../../../../utils/functions";
import Text from "../../../../../../../Commun/Inputs/Text/Text";
import Select from "../../../../../../../Commun/Inputs/Select/Select";
import moment from "moment";

const FormAjoutReparateur = (props) => {
    const {garanties, categorieCode, handleChange, errors, values, setFieldValue, isWakam} = props
    const [city, setCity] = useState([])
    const [reparateurs, setReparateurs] = useState([])
    const [missions, setMissions] = useState([])
    const [addressReparateur, setAddressReparateurs] = useState(null)

    /* Recherche du reparateur par code postal */
    const setPostalCode = (postalCode) => {
        fetchCityByPostalCode(postalCode)
        fetchReparateurs(postalCode)
    }

    /* ajout l'id du reparateur dans le form */
    const changeValueReparateurVisites = (reparateur, missionsReparateur) => {
        setFieldValue('reparateur', reparateur)
        if (!(!_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], categorieCode))){
            if(missionsReparateur[0]){
                setFieldValue('visit', moment(missionsReparateur[0]).format('L'))
            } else {
                setFieldValue('visit', '')
            }
        }
    }

    /* Function async qui va chercher les villes via le code postal */
    async function fetchCityByPostalCode(value){
        let cities = await getCity(value)
        setCity(cities)
    }

    /* Function async qui va chercher les reparateur via le code postal */
    async function fetchReparateurs(postalCode){
        let arrayReparateurs = await getReparateurByCodePostal(postalCode)
        let reparateurs = _.orderBy(arrayReparateurs, ['raisonSociale'], ['asc'])

        reparateurs[0].datesVisite= removeJoursFeries(reparateurs[0].datesVisite)
        setReparateurs(reparateurs)
        setMissions(reparateurs[0].datesVisite)
        setAddressReparateurs(reparateurs[0].rue1)

        changeValueReparateurVisites(reparateurs[0].id, reparateurs[0].datesVisite)
    }

    /* Ajout l'addresse du réparateur et les missions disponible apres la selection du reparateur */
    const getAdresseReparateur = (id) => {
        const item = _.find(reparateurs, ['id', id])

        item.datesVisite = removeJoursFeries(item.datesVisite)

        setAddressReparateurs(item.rue1)
        setMissions(item.datesVisite)

        changeValueReparateurVisites(item.id, item.datesVisite)
    }

    return (
        <div>
            <div className="mb-4">
                <Text
                    name="cpreparateur"
                    required
                    onChange={(e) => {
                        handleChange(e)
                        e.target.value.length === 5 && setPostalCode(e.target.value)
                    }}
                    label={'Code postal du garage / réparateur'}
                    value={values.cpreparateur}
                    error={errors.cpreparateur}
                    maxLength={5}
                />
            </div>

            <div className="mb-4">
                <Select
                    required
                    name={"reparateur"}
                    error={errors.reparateur}
                    label={'Garage / Réparateur'}
                    value={values.reparateur}
                    disabled={city.length === 0}
                    onChange={(e) => getAdresseReparateur(e.target.value)}
                >
                    {
                        reparateurs.map((r, i) => (
                            <option key={i} value={r.id}>{r.raisonSociale}</option>
                        ))
                    }
                </Select>
            </div>

            {
                values.reparateur && <div className="alert alert-warning mb-4" role="alert">
                    <p className={'m-0'}>Adresse : {addressReparateur}</p>
                </div>
            }

            {
               !_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], categorieCode) ? null : isWakam ? null : <div className="mb-4">
                    <Select
                        name={"visit"}
                        error={errors.visit}
                        label={'Date de passage de l\'expert'}
                        value={values.visit}
                        disabled={city.length === 0}
                    >
                        {
                            missions.map((m, i) => (
                                <option key={i} value={moment(m).format('L')}>{moment(m).format('L')}</option>
                            ))
                        }
                    </Select>
                    <p className={'text-danger mt-2 fw-bold'}>Cette date est susceptible d'être modifiée, un mail de confirmation vous sera envoyé lorsque l'expertise sera validée.</p>
                </div>
            }
        </div>
    );
};

export default FormAjoutReparateur;