import { connect } from 'react-redux';
import { compose } from 'redux';
import { FLOTTE, getFlotte } from '../../../../../../../../redux/actions/app/flotte/flotte.actions';
import {
    getContractId,
    getDataContract,
    getIsWakamContract
} from '../../../../../../../../redux/selectors/contracts/contract.selector';
import { getFlotteSelector } from '../../../../../../../../redux/selectors/flotte/flotte.selectors';
import { getFleetLink } from '../../../../../../../../redux/selectors/links/links.selectors';
import { getLoadingEntity } from '../../../../../../../../redux/selectors/ui/ui.selectors';
import Flotte from './Flotte';

const mapStateToProps = (state, ownProps) => {
    return {
        uri: getFleetLink(state),
        loading: getLoadingEntity(state, FLOTTE),
        flotte: getFlotteSelector(state),
        contractId: getContractId(state),
        isWakam: getIsWakamContract(state),
        contract: getDataContract(state)
    };
};

const mapDispatchToProps = {
    getFlotte,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
    };
};

const FlotteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(Flotte);

export default FlotteContainer;
