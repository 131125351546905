import {POST_CUSTOMER_FORGOT_EMAIL} from '../../../../actions/app/customer/customer.actions'
import {
    API_ERROR,
    API_SUCCESS,
    apiRequest
} from '../../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../../actions/app/ui/ui.actions'
import {setNotification} from '../../../../actions/core/notifications/notifications.actions'

export const customerForgotemailMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let body = null

    switch (action.type) {

        case POST_CUSTOMER_FORGOT_EMAIL:
            next([
                apiRequest({
                    body: payload.body,
                    method: 'POST',
                    url: payload.data,
                    entity: POST_CUSTOMER_FORGOT_EMAIL
                }),
                setLoader({state: true, entity: POST_CUSTOMER_FORGOT_EMAIL})
            ])
            break

        case `${POST_CUSTOMER_FORGOT_EMAIL} ${API_SUCCESS}`:
            body = `
                <p>Vous avez reçu un email pour définir un nouveau mot de passe.</p>
                <p>Si vous n'avez rien reçu, consultez votre boîte de spams ou vos courriers indésirables.</p>
            `
            next([
                setNotification({entity: POST_CUSTOMER_FORGOT_EMAIL,
                    title: 'Félicitations !',
                    icon: 'success',
                    html: body,
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: false, entity: POST_CUSTOMER_FORGOT_EMAIL}),
                setLoader({state: false, entity: POST_CUSTOMER_FORGOT_EMAIL})
            ])
            break

        case `${POST_CUSTOMER_FORGOT_EMAIL} ${API_ERROR}`:
            body = `<p>Nous ne retrouvons pas votre compte avec les informations saisies.</p>`

            next([
                setNotification({
                    entity: POST_CUSTOMER_FORGOT_EMAIL,
                    title: 'Attention !',
                    icon: 'error',
                    html: body,
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: POST_CUSTOMER_FORGOT_EMAIL}),
                setLoader({state: false, entity: POST_CUSTOMER_FORGOT_EMAIL})
            ])
            break

        default:
            break
    }
    return null
}
