import React from 'react';
import {formatEuro} from "../../../../../../../utils/functions";
import {Link} from "react-router-dom";
import moment from "moment";


const TableauPrevelements = (props) => {
    const {prelevements} = props

    return (
        <div>

            <div className={'text-center mt-5 pb-2'}>
                <h2 className={'fw-bold text-black d-block'}>
                    <Link to={'/mes-paiements'}><i className="bi bi-chevron-left me-2 float-left text-black" /></Link>
                    Tableau de prélèvement
                </h2>
            </div>

            <div className={'bloc-white py-0'}>
                <div>
                    <div className={'table-responsive'}>
                        <table className="table custom-table">
                            <thead>
                                <th>Date</th>
                                <th>Montant à prélever</th>
                                <th>Effectué le</th>
                                <th>Motif du rejet</th>
                                <th>Date du rejet</th>
                            </thead>
                            <tbody>
                                {prelevements.length >= 1 ?
                                    prelevements.map((prelevement, i) => (
                                        <tr key={i} className={'fw-bold'}>
                                            <td>{moment(prelevement.date).format('L')}</td>
                                            <td>{formatEuro(prelevement.montant)}</td>
                                            <td>
                                                {prelevement.datePrelevement ? prelevement.datePrelevement : 'A venir'}
                                                {!prelevement.datePrelevement ?
                                                    <>
                                                        <br/>
                                                        <small
                                                            className={'fw-normal text-grey'}>Le {moment(prelevement.date).format('L')}</small>
                                                    </>
                                                    : ''
                                                }
                                            </td>
                                            <td>{prelevement.motifRejet ? prelevement.motifRejet : '-'}</td>
                                            <td>{prelevement.dateRejet ? moment(prelevement.dateRejet).format('L') : '-'}</td>
                                        </tr>
                                    ))
                                    : <tr><td colSpan={5} className={'text-center'}>Aucun prélèvement n'a été trouvé.</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableauPrevelements;
