import React from 'react'
import FaqCard from "../../../../../Commun/FaqCard/FaqCard";
import './faq.scss';
import {Link} from "react-router-dom";
import GetContractsContainer from "../../../../../Commun/GetContracts/GetContractsContainer";

const ParrainagesFAQ = (props) => {
    const { faqs } = props

    return (
        <GetContractsContainer>
            <div className={'bloc-nobg col-12 top-questions'}>
                <h2 className={'title mb-4'}>Tout savoir sur <strong>le parrainage</strong></h2>
                {faqs.length > 0 &&
                    <div className={'content-faq'}>
                        {faqs.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                }
                <Link className={'btn btn-secondary full mt-4'} to={'/mes-questions'}>Voir toutes les questions</Link>
            </div>
        </GetContractsContainer>
    )
}

export default ParrainagesFAQ
