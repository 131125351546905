import {connect} from 'react-redux'
import {compose} from 'redux'
import CotisationSelectedContract from "./CotisationSelectedContract";
import _ from "lodash";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getContractId,
    getExportReceiptsLinkContract
} from "../../../../../../../redux/selectors/contracts/contract.selector";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {getContractReceiptsLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    FETCH_RECEIPTS,
    fetchReceipts,
    getReceiptsDownload
} from "../../../../../../../redux/actions/app/payments/receipts/receipts.actions";
import {
    getLastReceipt,
    getReceiptsLastYear
} from "../../../../../../../redux/selectors/payments/receipts/receipts.selectors";

const mapStateToProps = state => {
    const id_contrat = getContractId(state);
    return {
        query: _.replace(getContractReceiptsLink(state), '{id}', id_contrat),
        loaded: getLoadingEntity(state, FETCH_RECEIPTS) === false,
        receipts: getReceiptsLastYear(state),
        id_contrat: getContractId(state),
        export_receipts_link : getExportReceiptsLinkContract(state),
        derniere_quittance: getLastReceipt(state)
    }
}

const mapDispatchToProps = {
    fetchReceipts,
    getReceiptsDownload
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchReceipts } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchReceipts({query})
    }
}

const CotisationSelectedContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(CotisationSelectedContract)


export default CotisationSelectedContractContainer