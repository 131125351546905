import React from 'react';
import {Link, Route} from "react-router-dom";
import FormulaireResilContainer from "./FormulaireResil/FormulaireResilContainer";


const Resilier = () => {
    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className="text-primary-light"><Link to="/" className="text-decoration-none">Mon espace</Link> - <Link to="/resilier" className="text-decoration-none">Résilier</Link></h6>
            </div>
            <Route exact path={'/resilier'} component={FormulaireResilContainer} />
        </div>
    );
};

export default Resilier;
