export const CONTACT = '[Contact]'

export const POST_CONTACT = `${CONTACT} Post`

export const postContact = ({query, form, successFunction}) => ({
    type: POST_CONTACT,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})