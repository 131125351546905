import React, {useState} from 'react';
import {getReason} from "../../../../../../../../utils/getReason";
import APILink from "../../../../../../../Commun/APILink/APILink";

const AvisEcheance = (props) => {
    const { avisEcheanceLink, avisEcheanceReason, emailGestion } = props
    const [ disabled, setDisabled ] = useState(false)

    const handleClick = (e) => {
        if (disabled){
            return e.preventDefault()
        }

        // @todo : à mettre à jour avec fonction analyticsSetEvent ou supprimer si non utilisé suite refonte
        // setTrackAction({
        //     event: 'click',
        //     actionGoogle: 'clickButton',
        //     libelle: 'Télécharger mon avis d\'échéance'
        // })
        setDisabled(true)
    }

    if(avisEcheanceLink !== null) {
        return(
            <div className="text-center">
                <p>Pour télécharger votre document cliquez sur le bouton ci-dessous :</p>
                <APILink href={avisEcheanceLink} target={'_blank'} onClick={(e) => handleClick(e)} className={`btn btn-primary ${disabled && 'disabled'}`} download>Télécharger mon avis d'échéance</APILink>
            </div>
        )
    } else {
        return (
            <div>
                <p>Votre contrat ne permet pas l'édition d'un avis d'échéance en ligne pour le motif suivant : <strong>{getReason(avisEcheanceReason)}</strong></p>
                <p>Vous pouvez contacter le service client à <a href={`mailto:${emailGestion}`}>{emailGestion}</a></p>
            </div>
        )
    }
};

export default AvisEcheance;
