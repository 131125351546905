import React from 'react';
import FooterContainer from "../Footer/FooterContainer";
import HeaderContainer from "../Header/HeaderContainer";
import {Route} from "react-router-dom";
import HomeContainer from "./Home/HomeContainer";
import ContractsContainer from "./Contracts/ContractsContainer";
import QuestionsContainer from "./Questions/QuestionsContainer";
import Avantages from "./Avantages/Avantages";
import SinistresContainer from "./Sinistres/SinistresContainer";
import Profile from "./Profile/Profile";
import ArticlesContainer from "./Articles/ArticlesContainer";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import SidebarContainer from "./Sidebar/SidebarContainer";
import Mentions from "./Mentions/Mentions";
import Cookies from "./Cookies/Cookies";
import PaiementsContainer from "./Paiements/PaiementsContainer";
import InfobulleContainer from "../Infobulle/InfobulleContainer";
import Contact from "./Contact/Contact";
import HeaderMobileContainer from "../Header/HeaderMobileContainer";
import {HOST_MB} from "../../../../constants/constants";
import ParrainagesContainer from "./Parrainages/ParrainagesContainer"
import InMotionContainer from "./Avantages/InMotion/InMotionContainer";
import LibertyRider from "./Avantages/LibertyRider/LibertyRider";
import Resilier from "./Resilier/Resilier.jsx";
import Documents from "./Documents/Documents.jsx";
import Sentidrive from "./Avantages/Sentidrive/Sentidrive.jsx";

const Page = (props) => {
    const isDec = window.location.href.indexOf("je-declare-un-sinistre") <= -1
    return (
        <div>
            <HeaderContainer />
            <HeaderMobileContainer />
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={isDec ? `left-content col-xl-8 col-lg-12 col-md-12 col-xs-12` : `col-xl-12 col-lg-12 col-md-12 col-xs-12`}>
                        <SwitchTransition>
                            <CSSTransition
                                timeout={500}
                                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                classNames={`fade-left`}
                                key={window.location}
                            >
                                <div className={'row'}>
                                    <Route exact path={'/'} component={HomeContainer} />
                                    <Route path={'/mes-contrats'} component={ContractsContainer} />
                                    { HOST_MB === 43397 && <Route path={'/mes-questions'} component={QuestionsContainer} /> }
                                    { HOST_MB === 43397 && <Route exact path={'/mes-avantages'} component={Avantages} /> }
                                    { HOST_MB === 43397 && <Route path={'/mes-avantages/in&motion'} component={InMotionContainer} /> }
                                    { HOST_MB === 43397 && <Route path={'/mes-avantages/liberty-rider'} component={LibertyRider} /> }
                                    { HOST_MB === 43397 && <Route path={'/mes-avantages/sentidrive'} component={Sentidrive} /> }
                                    { HOST_MB === 43397 && <Route path={'/mes-parrainages'} component={ParrainagesContainer} /> }
                                    <Route path={'/mes-sinistres'} component={SinistresContainer} />
                                    <Route path={'/mes-actualites'} component={ArticlesContainer} />
                                    <Route path={'/mentions-legales'} component={Mentions} />
                                    <Route path={'/gestion-des-cookies'} component={Cookies} />
                                    <Route path={'/mon-profil'} component={Profile} />
                                    <Route path={'/mes-paiements'} component={PaiementsContainer} />
                                    { (HOST_MB === 43397 || HOST_MB === 111619 || HOST_MB === 479835 || HOST_MB === 479846) && <Route path={'/resilier'} component={Resilier} /> }
                                    { HOST_MB === 43397 && <Route path={'/contact'} component={Contact} /> }
                                    <Route path={'/mes-documents'} component={Documents} />
                                </div>
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                    { isDec && <div className={'right-content col-xl-4 col-lg-12 col-md-12 col-xs-12'}>
                        <div className={'row'}>
                            <SidebarContainer {...props} />
                        </div>
                    </div>}
                </div>
            </div>

            <FooterContainer/>
            <InfobulleContainer history={props.history} />
        </div>
    );
};

export default Page;
