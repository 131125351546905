import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import Faq from './Faq'
import { FAQ, fetchFaq } from '../../../../../../redux/actions/app/faq/faq.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import { getFaqLink } from '../../../../../../redux/selectors/links/links.selectors'
import {
    getTopFaq
} from '../../../../../../redux/selectors/faq/faq.selectors'
import {
    getHasContractTrottinette,
    getHasContractVAE, getHasOtherContract
} from "../../../../../../redux/selectors/contracts/contracts.selectors";

const mapStateToProps = state => {
    let topFaq = getTopFaq(state)

    if (!getHasContractVAE(state)) {
        topFaq = _.reject(topFaq, ['faqs_category_id', 6])
    }

    if (!getHasContractTrottinette(state)){
        topFaq = _.reject(topFaq, ['faqs_category_id', 5])
    }

    if (!getHasOtherContract(state)){
        topFaq = _.reject(topFaq, q => {
            return _.includes([1, 2, 3, 4], q.faqs_category_id)
        })
    }

    return {
        topFaq,
        uri: getFaqLink(state),
        loaded: getLoadingEntity(state, FAQ) === false,
        error:  getErrorEntity(state, FAQ)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getFaq: (query) => fetchFaq({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { getFaq } = dispatchProps
    const { uri } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getFaq(uri)
    }
}

const FaqContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Faq)

export default FaqContainer
