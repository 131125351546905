import React from 'react';
import './checkbox.scss'

const Checkbox = (props) => {
    const { label, error } = props

    return (
        <>
            <label className="checkboxField d-flex align-self-center position-relative cursor-pointer f-14">
                {label}

                <input className={'position-absolute cursor-pointer'} {...props} checked={props.value} type={'checkbox'} />
                <span className={'checkmark bg-white border position-absolute'} />
            </label>
            {error && <small className={'text-danger fst-italic'}>{error}</small>}
        </>
    );
};

export default Checkbox;
