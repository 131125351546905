import React, {useState} from 'react';
import {Formik} from "formik";
import _ from 'lodash'
import Text from "../../../../../Commun/Inputs/Text/Text";
import {checkIban, normalizeIban} from "../../../../../../utils/functions";
import SmallSpinner from "../../../../../Commun/Spinners/SmallSpinner";
import ChangeTitulaireContainer from "./ChangeTitulaire/ChangeTitulaireContainer";


const ChangeRib = (props) => {
    const {initialValues, query, loading, validate, postAvenantChangeIban, iban} = props
    const [ errorIban, setErrorIban ] = useState(null)
    const [ loadingIban, setLoadingIban ] = useState(false)
    const [changeTitulaire, setChangeTitulaire] = useState(false)

    const fetchCheckIban = async (value, setFieldValue) => {
        setLoadingIban(true)
        let iban = await checkIban(value)
        setLoadingIban(false)
        setFieldValue('domiciliation', iban.bank_data.bank)
        setFieldValue('bic', iban.bank_data.bic)

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null

        _.forEach(fieldValidations, field => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)){
                error = iban.validations[field].message
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === "NO" || iban.sepa_data.SCT === "NO"){
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires")
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null)
    }

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je modifie mes coordonnées bancaires</h1>
            </div>

            <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} validate={values => validate(values, changeTitulaire)} onSubmit={values => postAvenantChangeIban({query, form: values, changementTitulaire: changeTitulaire, successFunction: () => props.history.push('/mon-profil')})}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                    <form onSubmit={handleSubmit}>
                        <div className={'mt-4'}>
                            <div className="row">
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Mon IBAN actuel</p>
                                    <p className={'m-0 text-secondary'}>{iban ? normalizeIban(iban) : 'Non renseigné'}</p>
                                </div>
                            </div>
                        </div>

                        <div className={'mt-4'}>
                            <p className={'fw-bold'}>Mes nouvelles coordonnées</p>
                        </div>

                        <ChangeTitulaireContainer
                            changeTitulaire={changeTitulaire}
                            setChangeTitulaire={setChangeTitulaire}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}/>

                        <div className={'mb-4 mt-4'}>
                            <Text
                                type={'text'}
                                name="iban"
                                label={'IBAN'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.iban?.toUpperCase()}
                                error={errors.iban}
                                required
                            />

                            <p className={'mb-0 mt-2'}>Attention, merci de bien saisir un IBAN d'un compte courant et non lié à un livret A ou MyPocket car les prélèvements ne seront pas possibles.</p>

                            {
                                errorIban && <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{__html: errorIban}} />
                            }

                            {
                                values.domiciliation && values.bic && !errorIban && <div className={'alert-success alert mb-0'}>
                                    L'IBAN est valide
                                </div>
                            }

                            <span className="btn btn-secondary mt-4" onClick={() => fetchCheckIban(values.iban, setFieldValue)}>
                                {
                                    loadingIban ? <div className="spinner-border" role="status" style={{width: 19, height: 19}}>
                                        <span className="sr-only" />
                                    </div> : "Vérifier mon IBAN"
                                }
                            </span>

                        </div>

                        {
                            values.domiciliation && values.bic && <>
                                <div className={'mb-4 mt-4'}>
                                    <Text
                                        type={'text'}
                                        name="domiciliation"
                                        label="Domiciliation de la banque"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.domiciliation}
                                        error={errors.domiciliation}
                                        required
                                        disabled
                                    />
                                </div>

                                <div className={'mb-4 mt-4'}>
                                    <Text
                                        type={'text'}
                                        name="bic"
                                        label="Code BIC"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bic}
                                        error={errors.bic}
                                        required
                                        disabled
                                    />
                                </div>
                            </>
                        }

                        <p><i className="bi bi-exclamation-triangle me-2" />Attention :</p>
                        <p>- Une mensualité / annuité peut encore être prélevée sur vos anciennes coordonnées bancaires.</p>
                        <p>- La modification de l'IBAN concerne tous vos contrats.</p>

                        <div className="d-grid gap-2 mt-4">
                            {
                                loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                    Je valide mes nouvelles coordonnées bancaires
                                </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};


export default ChangeRib;
