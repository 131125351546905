import {
    POST_CUSTOMER_FORGOT_PASSWORD
} from '../../../../actions/app/customer/customer.actions'
import {
    API_ERROR,
    API_SUCCESS,
    apiRequest
} from '../../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../../actions/app/ui/ui.actions'
import {setNotification} from '../../../../actions/core/notifications/notifications.actions'

export const customerForgotpasswordMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let body = null

    switch (action.type) {

        case POST_CUSTOMER_FORGOT_PASSWORD:
            next([
                apiRequest({
                    body: payload.body,
                    method: 'POST',
                    url: payload.data,
                    entity: POST_CUSTOMER_FORGOT_PASSWORD
                }),
                setLoader({state: true, entity: POST_CUSTOMER_FORGOT_PASSWORD})
            ])
            break

        case `${POST_CUSTOMER_FORGOT_PASSWORD} ${API_SUCCESS}`:
            body = `
                <p>Vous avez reçu un email pour définir un nouveau mot de passe.</p>
                <p>Si vous n'avez rien reçu, consultez votre boîte de spams ou vos courriers indésirables.</p>`

            next([
                setNotification({
                    entity: POST_CUSTOMER_FORGOT_PASSWORD,
                    html: body,
                    icon: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: false, entity: POST_CUSTOMER_FORGOT_PASSWORD}),
                setLoader({state: false, entity: POST_CUSTOMER_FORGOT_PASSWORD})
            ])
            break

        case `${POST_CUSTOMER_FORGOT_PASSWORD} ${API_ERROR}`:

            if (payload?.data?.response?.data?.code === 406) {
                body = `
                    <p>Le contrat d'assurance rattaché a été souscrit auprès d'un intermédiaire.</p>
                    <p>Merci de vous rapprocher de cet intermédiaire pour toute information liée à votre contrat d'assurance.</p>`
            } else {
                body = `
                    <p>Votre adresse email n'est pas reconnue mais pas de panique&nbsp;!</p>
                    <p>Vous pouvez vérifier la saisie ou essayer de vous <a href='/login/oubli-email'><u>connecter avec votre numéro client.</u></a></p>
                `
            }

            next([
                setNotification({
                    entity: POST_CUSTOMER_FORGOT_PASSWORD,
                    html: body,
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: POST_CUSTOMER_FORGOT_PASSWORD}),
                setLoader({state: false, entity: POST_CUSTOMER_FORGOT_PASSWORD})
            ])
            break

        default:
            break
    }
    return null
}
