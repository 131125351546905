import React, {useState, useEffect} from 'react';
import {Formik, useFormikContext} from "formik";
import _ from 'lodash'
import Text from "../../../../../../Commun/Inputs/Text/Text";
import PostalCodeCity from "../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";
import Checkbox from "../../../../../../Commun/Inputs/Checkbox/Checkbox";
import {getAddress} from "../../../../../../../utils/functions";

const CheckVilleCP = props => {
    const { values } = useFormikContext();
    const { setFieldValue, fetchAddress } = props

    useEffect(() => {
        console.log(`${values.villeNew} ${values.codePostalNew}`)
        if(values.villeNew && values.codePostalNew){
            fetchAddress(`${values.villeNew} ${values.codePostalNew}`, setFieldValue, 'city')
        }
    }, [values.villeNew, values.codePostalNew]);
}

const Demenagement = (props) => {
    const { initialValues, query, postAmendmentChangeAddress, loading, validate } = props
    const [ listAddresses, setListAddresses ] = useState([])
    const [ addressSelected, setAddressSelected ] = useState(null)
    const [ adresseNewIsTooLong, setAdresseNewIsTooLong ] = useState(null)
    const [hasSearchAddress, setHasSearchAddress] = useState(false)
    const [localityId, setLocalityId] = useState(null);

    const fetchAddress = async (value, setFieldValue, where, values) => {
        setAddressSelected(null)
        let addressParams;

        if (where === 'city') {
            addressParams = [value, 'Undefined'];
        } else {
            addressParams = [
                value,
                'Undefined',
                localityId,
                values.codePostalNew,
                values.villeNew
            ];
        }

        let address = await getAddress(...addressParams)

        if (where === 'city') {
            setLocalityId(address[0].localityId);
            setListAddresses([])
            const adresseFields = ['adresseNew', 'adresse2New', 'adresse3New'];

            adresseFields.forEach(field => {
                setFieldValue(field, null);
            });
        } else {
            setListAddresses(address?.slice(0, 15));
        }
        setHasSearchAddress(true)

        if (value.length > 31){
            setFieldValue('addressNotFound', true)
            setAdresseNewIsTooLong(true)
        } else {
            setAdresseNewIsTooLong(false)
        }

    }

    const selectAddr = (address, setFieldValue) => {
        setAddressSelected(address)
        setFieldValue('adresseNew', address.additionalInfo_1)
        setFieldValue('adresse3New', address.buildingName || '')
    }

    const myOnSubmit = (values) => {
        postAmendmentChangeAddress({query, form: values, successFunction: () => props.history.push(`/mes-contrats/mon-contrat/${values.contrat}`)})
        if(hasSearchAddress){
            getAddress(_.get(values, 'adresseNew'), "ValidateForm")
        }
    }

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je déménage</h1>
                <p><i className="bi bi-info-circle me-2" />Votre nouvelle adresse sera utilisée pour l'envoi de l'ensemble des documents liés à votre contrat d'assurance.</p>
            </div>

            <Formik initialValues={initialValues} onSubmit={values => myOnSubmit(values)} validate={validate}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                    <form onSubmit={handleSubmit}>
                        <div className={'mb-4'}>
                            <PostalCodeCity
                                label={'Votre code postal, ville: (Ex: 75011 PARIS)'}
                                cp='codePostalNew'
                                ville='villeNew'
                                required
                                error={errors.codePostalNew}
                                defaultValue={`${values.codePostalNew && values.villeNew ? `${values.codePostalNew} ${values.villeNew}` : ''}`}
                            />
                        </div>
                        <div className={'mb-4 mt-4'}>
                            <Text
                                type={'text'}
                                name="adresseNew"
                                label={'Votre nouvelle adresse: (Ex: ENTREE F BATIMENT 5 37 BIS RUE DE LA GARE 75011 PARIS)'}
                                onChange={handleChange}
                                functionOnChange={(e) => fetchAddress(e, setFieldValue, 'address', values)}
                                onBlur={handleBlur}
                                value={values.adresseNew}
                                setFieldValue={setFieldValue}
                                required
                            />
                        </div>

                        <div className={'mb-2'}>
                            <Checkbox
                                name="addressNotFound"
                                label={'Je ne trouve pas mon adresse'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.addressNotFound}
                            />
                        </div>

                        {
                            adresseNewIsTooLong && <div className="alert alert-warning mt-3" role="alert">
                                <p>Attention, l'adresse dépasse le nombre de caractère autorisé. (31) <br/> Merci de rentrer
                                    manuellement l'adresse et d'utiliser le complément d'adresse.</p>
                            </div>
                        }

                        {
                            !adresseNewIsTooLong && !values.addressNotFound && !addressSelected && _.map(listAddresses, (adresse, i) => (
                                <div key={i} onClick={() => selectAddr(adresse, setFieldValue)} className={"list-group"}>
                                    <p className={'cursor-pointer m-0 list-group-item list-group-item-action'}>{adresse.inputOutput}</p>
                                </div>
                            ))
                        }

                        {
                            (addressSelected || values.addressNotFound) && <>
                                <div className={'my-4'}>
                                    <Text
                                        type={'text'}
                                        name="adresse2New"
                                        label={'Complément de votre nouvelle adresse: (Ex: Appartement 25)'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.adresse2New}
                                    />
                                </div>

                                <div className={'mb-4'}>
                                    <Text
                                        type={'text'}
                                        name="adresse3New"
                                        label={'Bâtiment, escalier, étages: (Ex: ENTREE F BATIMENT 5)'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.adresse3New}
                                        disabled={addressSelected && !values.addressNotFound}
                                    />
                                </div>


                            </>
                        }

                        {
                            !values.sansStationnement &&
                            <>
                                <div className={'mb-4 mt-4'}>
                                    <Checkbox
                                        name="new_adress_is_new_lieuStationnement"
                                        label={'Cette adresse est également l\'adresse de stationnement.'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.new_adress_is_new_lieuStationnement}
                                    />
                                </div>

                                {
                                    !values.new_adress_is_new_lieuStationnement && <div className={'mb-4'}>
                                        <PostalCodeCity
                                            label={'Votre code postal, ville de stationnement'}
                                            cp='cp_new_lieuStationnement'
                                            ville='ville_new_lieuStationnement'
                                        />
                                    </div>
                                }
                            </>

                        }

                        <div className="d-grid gap-2">
                            {
                                loading ? <SmallSpinner/> :
                                    <button
                                        type="submit"
                                        className={`btn btn-primary btn-block ${adresseNewIsTooLong && 'disabled'}`}
                                        onClick={(e) => adresseNewIsTooLong ? e.preventDefault() : null}
                                    >
                                        Je valide ma nouvelle adresse
                                    </button>
                            }
                        </div>
                        <CheckVilleCP fetchAddress={fetchAddress} setFieldValue={setFieldValue} />
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default Demenagement;
