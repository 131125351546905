import {TERMINATE} from "../terminate/terminate.actions.js";

export const CONTRACTS = '[Contracts]'
export const WAITING_DOC_CONTRACTS = '[Waiting Doc Contracts]'
export const CONTRACT = '[Contract]'
export const CONTRACT_THIRD_PARTY_PAYER = '[Contract Tiers Payeur]'

export const FETCH_CONTRACTS = `${CONTRACTS} Fetch`
export const SET_CONTRACTS = `${CONTRACTS} Set`
export const CLEAR_CONTRACTS = `${CONTRACTS} Clear`

export const FETCH_CONTRACT = `${CONTRACT} Fetch`
export const SET_CONTRACT = `${CONTRACT} Set`

export const FETCH_WAITING_DOC_CONTRACTS = `${WAITING_DOC_CONTRACTS} Fetch`
export const SET_WAITING_DOC_CONTRACTS = `${WAITING_DOC_CONTRACTS} Set`

export const SET_CONTRACT_THIRD_PARTY_PAYER = `${CONTRACT_THIRD_PARTY_PAYER} Set `
export const POST_CONFIRM_RESIL_HAMON = `${CONTRACT} Post Confirm Resil Hamon`

export const fetchContracts = ({query}) => ({
    type: FETCH_CONTRACTS,
    payload: {data: query}
})

export const setContracts = ({data, meta, links}) => ({
    type: SET_CONTRACTS,
    payload: {data, meta, links}
})

export const clearContracts = () => ({
    type: CLEAR_CONTRACTS,
})

export const fetchContract = ({query}) => ({
    type: FETCH_CONTRACT,
    payload: {
        data: query,
    }
})

export const setContract = ({data}) => ({
    type: SET_CONTRACT,
    payload: {
        data
    }
})

export const setContractThirdPartyPayer = ({tpp}) => ({
    type: SET_CONTRACT_THIRD_PARTY_PAYER,
    payload: {
        data: tpp
    }
})

export const fetchWaitingDocContracts = ({query}) => ({
    type: FETCH_WAITING_DOC_CONTRACTS,
    payload: {
        data: query,
    }
})

export const setWaitingDocumentsContracts = ({data}) => ({
    type: SET_WAITING_DOC_CONTRACTS,
    payload: {
        data
    }
})

export const postConfirmResilHamon = ({query, form}) => ({
    type: POST_CONFIRM_RESIL_HAMON,
    payload: {
        data: query,
        body: form,
    }
})