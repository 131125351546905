import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import moment from 'moment'
import {
    getCityBySearch,
    getReparateurByCodePostal,
    JoursFeries,
    normalizeIban
} from "../../../../../../../utils/functions";
import Text from "../../../../../../Commun/Inputs/Text/Text";
import Select from "../../../../../../Commun/Inputs/Select/Select";
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import PostalCodeCity from "../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";

const Reparation = (props) => {
    const { garanties, vehicule: { marque, modele, immatriculation }, errors, values, setFieldValue, handleChange, prelevement, isWakam } = props
    const [reparateurs, setReparateurs] = useState([])
    const [addressReparateur, setAddressReparateurs] = useState(null)
    const [missions, setMissions] = useState([])
    const [city, setCity] = useState([])

    useEffect(() => {
        if(values.cpreparateur !== undefined){
            fetchCityByPostalCode(values.cpreparateur)
            fetchReparateurs(values.cpreparateur)
        }
    }, [values.cpreparateur])

    /* ajout l'id du reparateur dans le form */
    const changeValueReparateurVisites = (reparateur) => {
        setFieldValue('reparateur', reparateur)
        if (!(!_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], values.catsinorigin))){
            reparateur !== 'NONE' ? setFieldValue('visit', missions[0]) : setFieldValue('visit', '')
        }
    }

    /* Retire les jours feries d'un tableau de date */
    const removeJoursFeries = (array) => {
        const joursFeries = JoursFeries(moment().weekYear())

        _.remove(array, n => {
            return _.includes(joursFeries, n)
        })
    }

    /* Function async qui va chercher les villes via le code postal */
    async function fetchCityByPostalCode(value){
        let cities = await getCityBySearch(value)
        setCity(cities)
    }

    /* Function async qui va chercher les reparateur via le code postal */
    async function fetchReparateurs(postalCode){
        let arrayReparateurs = await getReparateurByCodePostal(postalCode)
        let reparateurs = _.orderBy(arrayReparateurs, ['raisonSociale'], ['asc'])
        reparateurs.push({id: 'NONE', raisonSociale: 'Autre garage', datesVisite: []})

        removeJoursFeries(reparateurs[0].datesVisite)
        setReparateurs(reparateurs)
        setMissions(reparateurs[0].datesVisite)
        setAddressReparateurs(reparateurs[0].rue1)

        changeValueReparateurVisites(reparateurs[0].id)
    }

    /* Ajout l'addresse du réparateur et les missions disponible apres la selection du reparateur */
    const getAdresseReparateur = (id) => {
        const item = _.find(reparateurs, ['id', id])

        removeJoursFeries(item.datesVisite)

        setAddressReparateurs(item.rue1)
        setMissions(item.datesVisite)

        changeValueReparateurVisites(item.id)
    }

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Votre Réparation</h4>
            </div>

            <div className="alert alert-warning mb-5" role="alert">
                <p>Garage où se trouve le véhicule
                    : <b>{marque} {modele}</b> -
                    Immatriculé : <b>{immatriculation}</b>
                </p>
                <p className={'m-0'}>En cas de dommages garantie par votre contrat, vous avez la faculté de choisir le réparateur professionnel auquel vous souhaitez recourir</p>
            </div>

            <div className="mb-5">
                <PostalCodeCity
                    label={'Code postal / Ville du garage ou réparateur'}
                    cp='cpreparateur'
                    ville='reparateurAutreVille'
                    name={'cpreparateur'}
                    onChange={(e) =>/* setPostalCode(e.target.value)*/ console.log(e)}
                    required
                    error={errors.cpreparateur}
                />
                {/*<Text
                    name="cpreparateur"
                    required
                    onChange={(e) => {
                        handleChange(e)
                        e.target.value.length === 5 && setPostalCode(e.target.value)
                    }}
                    label={'Code postal du garage / réparateur'}
                    value={values.cpreparateur}
                    error={errors.cpreparateur}
                    maxlength={5}
                />*/}
            </div>

            <div className="mb-5">
                <Select
                    required
                    name={"reparateur"}
                    error={errors.reparateur}
                    label={'Garage / Réparateur'}
                    value={values.reparateur}
                    onChange={(e) => getAdresseReparateur(e.target.value)}
                >
                    {
                        reparateurs.map((r, i) => (
                            <option key={i} value={r.id}>{r.raisonSociale}</option>
                        ))
                    }
                </Select>
            </div>

            {
                values.reparateur && values.reparateur !== 'NONE' && <div className="alert alert-warning mb-5" role="alert">
                    <p className={'m-0'}>Adresse : {addressReparateur}</p>
                </div>
            }

            {
                values.reparateur && values.reparateur === 'NONE' && <div>
                    <div className="mb-5">
                        <Text
                            name="reparateurAutreNom"
                            label={'Nom du garage'}
                            value={values.reparateurAutreNom}
                            error={errors.reparateurAutreNom}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-5">
                        <Text
                            name="reparateurAutreAdresse"
                            label={'Adresse du garage'}
                            value={values.reparateurAutreAdresse}
                            error={errors.reparateurAutreAdresse}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            }

            {
                (values.reparateur && values.reparateur === 'NONE') || (!_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], values.catsinorigin))
                    ? null
                    : isWakam ? null : <div className="mb-5">
                        <Select
                            name={"visit"}
                            error={errors.visit}
                            label={'Date de passage de l\'expert'}
                            value={values.visit}
                        >
                            {
                                missions.map((m, i) => (
                                    <option key={i} value={m}>{moment(m).format('dddd DD MMMM YYYY')}</option>
                                ))
                            }
                        </Select>
                        <p className={'text-danger mt-2 fw-bold'}>Cette date est susceptible d'être modifiée, un mail de confirmation vous sera envoyé lorsque l'expertise sera validée.</p>
                    </div>
            }

            <div className="mb-5">
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Dans le cas du remboursement du sinistre, vous préférez</h4>
                <RadioGroup
                    label={'Ne pas avoir à avancer des frais auprès du garagiste'}
                    name={'remb_garage'}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: 'T', label: 'Oui'}
                    ]}
                    value={values.remb_garage}
                    error={errors.remb_garage}
                    required
                />
            </div>
            <div className="mb-5">
                <RadioGroup
                    label={'Régler le garagiste vous-même puis être remboursé par APRIL Moto ultérieurement'}
                    name={'remb_garage'}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: 'F', label: 'Oui'}
                    ]}
                    value={values.remb_garage}
                    error={errors.remb_garage}
                    required
                />
            </div>

            {(values.remb_garage === 'F' && !prelevement) && <>
                <div className={'mb-5 mt-4'}>
                    <Text
                        type={'text'}
                        name="iban"
                        label={'IBAN'}
                        onChange={handleChange}
                        value={values.iban}
                        error={errors.iban}
                        normalize={normalizeIban}
                        required
                    />
                </div>

                <div className={'mb-5 mt-4'}>
                    <Text
                        type={'text'}
                        name="domiciliation"
                        label="Domiciliation de la banque"
                        onChange={handleChange}
                        value={values.domiciliation}
                        error={errors.domiciliation}
                        required
                    />
                </div>

                <div className={'mb-5 mt-4'}>
                    <Text
                        type={'text'}
                        name="bic"
                        label="Code BIC"
                        onChange={handleChange}
                        value={values.bic}
                        error={errors.bic}
                        required
                    />
                </div>

                <div className="alert alert-info mt-5" role="alert">
                    Vos informations bancaires serviront uniquement pour le remboursement de ce sinistre.
                </div>
            </>}
        </div>
    );
};

export default Reparation;
