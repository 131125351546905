import React from 'react';
import _ from 'lodash'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import PrivateRouteContainer from "./PrivateRoute/PrivateRouteContainer";
import LoginContainer from "./Login/LoginContainer";
import WhitelabelContainer from "./Whitelabel/WhitelabelContainer";

const App = () => {

    if (_.startsWith(window.location.pathname, '/nouveau-mot-de-passe')){
        window.location = `/login${window.location.pathname}`
        return null
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={'/maintenance'} component={() => <p>maintenance</p>} />
                <Route exact path={'/indisponible'} component={() => <p>indisponible</p>} />
                <Route path={'/login'} component={LoginContainer} />
                <PrivateRouteContainer path={'/'} component={WhitelabelContainer} />
            </Switch>
        </BrowserRouter>
    );
};

export default App;
