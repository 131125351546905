import {connect} from 'react-redux'
import Demenagement from "./Demenagement";
import {amendmentChangeAddressLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    POST_AMENDMENT_CHANGE_ADDRESS,
    postAmendmentChangeAddress
} from "../../../../../../../redux/actions/app/amendment/amendment.actions";
import {
    getCodeProduitContract,
    getContractId, getIsVAEContract, getLieuStationnementCodePostalContract,
    getLieuStationnementVilleContract
} from "../../../../../../../redux/selectors/contracts/contract.selector";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import { validate } from './validate'

const mapStateToProps = state => {
    return {
        validate,
        query: amendmentChangeAddressLink(state),
        loading: getLoadingEntity(state, POST_AMENDMENT_CHANGE_ADDRESS),
        initialValues: {
            codePostalA: getLieuStationnementCodePostalContract(state),
            villeA: getLieuStationnementVilleContract(state),
            adresseNew: '',
            adresse2New: '',
            adresse3New: '',
            codePostalNew: '',
            villeNew: '',
            new_adress_is_new_lieuStationnement: true,
            cp_new_lieuStationnement: '',
            ville_new_lieuStationnement: '',
            contrat: getContractId(state),
            codeProduit: getCodeProduitContract(state),
            sansStationnement: getIsVAEContract(state)
        }
    }
}

const mapDispatchToProps = {
    postAmendmentChangeAddress
}

const DemenagementContainer = connect(mapStateToProps, mapDispatchToProps)(Demenagement)

export default DemenagementContainer
