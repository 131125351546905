import {connect} from 'react-redux'
import ChangeInformations from "./ChangeInformations";
import {validate} from './validate'
import {getEmailUser, getIdUser, getPhoneUser} from "../../../../../../redux/selectors/user/user.selectors";
import {amendmentChangeContactLink} from "../../../../../../redux/selectors/links/links.selectors";
import {
    POST_AMENDMENT_CHANGE_CONTACT,
    postAmendmentChangeContact
} from "../../../../../../redux/actions/app/amendment/amendment.actions";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        query: amendmentChangeContactLink(state),
        loading: getLoadingEntity(state, POST_AMENDMENT_CHANGE_CONTACT),
        validate,
        initialValues: {
            tiers: getIdUser(state),
            tel_mobile: getPhoneUser(state),
            email: getEmailUser(state),
            email_confirmation: '',
        }
    }
}

const mapDispatchToProps ={
    postAmendmentChangeContact
}

const ChangeInformationsContainer = connect(mapStateToProps, mapDispatchToProps)(ChangeInformations)

export default ChangeInformationsContainer
