import React from 'react';
import './contactez_nous.scss';
import affluence from '../../../../../../assets/images/contact/horaires_affluence.png'
import FormulaireContactContainer from "./FormulaireContact/FormulaireContactContainer";

const ContactezNous = (props) => {

    return (
        <div className={'contact'}>
            <div className={'row mt-5 liste-contacts'}>
                <div className={'col'}>
                    <div className={'row bloc-white p-0'}>
                        <div className={'col-3 contact-illustration'}></div>
                        <div className={'col-9 p-4 pb-5'}>
                            <h4 className={'fw-bold fs-3'}>Vous avez une question sur votre contrat ?</h4>
                            <h5 className={'text-primary-light fw-bold'}>Un numéro unique pour nous joindre : 02 47 51 07 07</h5>

                            <p className={'text-grey fs-6'}>Du lundi au vendredi de 9h00 à 18h00</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'mt-5 text-center container-image-affluence'}>
                <img src={affluence} alt={'Avantage'}/>
            </div>

            <div className={'mt-5 text-black text-center fw-bold'}>
                <p className={'fs-5'}>Si vous n'arrivez pas à nous joindre, privilégiez l'envoi d'un email à l'adresse :</p>
                <a href="mailto:moncontrat@april-moto.com" className={'fs-4 text-primary-light text-decoration-none'}>moncontrat@april-moto.com</a>
            </div>

            <FormulaireContactContainer {...props}/>
        </div>
    );
};


export default ContactezNous;
