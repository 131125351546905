import {connect} from 'react-redux'
import Footer from "./Footer";
import {getLogoDarkUrlWhitelabel} from "../../../../redux/selectors/whitelabel/whitelabel.selectors";

const mapStateToProps = state => {
    return {
        logo: getLogoDarkUrlWhitelabel(state)
    }
}

const FooterContainer = connect(mapStateToProps)(Footer)

export default FooterContainer
