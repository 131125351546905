import {connect} from 'react-redux'
import ListeSinistres from "./ListeSinistres";
import {getAccidentsTiersData} from "../../../../../../redux/selectors/accidents/accidents.selectors";
import {getIsACLContract, getIsWakamContract} from "../../../../../../redux/selectors/contracts/contract.selector.js";

const mapStateToProps = state => {
    return {
        accidents : getAccidentsTiersData(state),
        isWakam: getIsWakamContract(state),
        isACL: getIsACLContract(state)
    }
}

const ListeSinistresContainer = connect(mapStateToProps)(ListeSinistres)

export default ListeSinistresContainer
