import {connect} from 'react-redux'
import NouveauMdp from "./NouveauMdp";
import {POST_CUSTOMER_RESET_PASSWORD, postResetPassword} from "../../../../redux/actions/app/customer/customer.actions";
import {getLoadingEntity, getLogin} from "../../../../redux/selectors/ui/ui.selectors";
import {getCustomerResetPasswordLink} from "../../../../redux/selectors/links/links.selectors";
import {HOST_MB} from "../../../../constants/constants";
import {validate} from "./validate";
import {LOGIN} from "../../../../redux/actions/app/login/login.actions";

const mapStateToProps = state => {
    return {
        loading: getLoadingEntity(state, POST_CUSTOMER_RESET_PASSWORD) || getLoadingEntity(state, LOGIN),
        query: getCustomerResetPasswordLink(state),
        login: getLogin(state),
        validate
    }
}

const mapDispatchToProps = {
    postResetPassword
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { postResetPassword } = dispatchProps
    const { query } = stateProps
    const { token } = ownProps.match.params

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postResetPassword: (form) => postResetPassword({form: {...form, token, owner_belair_id: HOST_MB}, query})
    }
}

const NouveauMdpContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(NouveauMdp)

export default NouveauMdpContainer
