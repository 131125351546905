import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import {getCountries} from "../../../../../../../utils/functions";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import moment from "moment/moment";
import Text from "../../../../../../Commun/Inputs/Text/Text";
import SimpleDatePicker from "../../../../../../Commun/Inputs/DatePicker/SimpleDatePicker";
import Autocomplete from "../../../../../../Commun/Inputs/Autocomplete/Autocomplete";
import _ from "lodash";
import AutoCompleteBirth from "../../../../../../Commun/Inputs/AutoCompleteBirth/AutoCompleteBirth";
import Select from "../../../../../../Commun/Inputs/Select/Select";
import {tiersPayeurRelation} from "../../../../../../../utils/optionsSelect";

const ChangeTiersPayeur = (props) => {
    const { initialValues, loading, postUpdateTiersPayeur, query, validate, id_contrat } = props
    const [countries, setCountries] = useState()

    useEffect(() => {
        (async function fetchCountries(){
            let countries = await getCountries()

            const listcountries = countries.map(c => ({
                value: c.id,
                label: c.name
            }))

            setCountries(listcountries)
        })()
    }, [])

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je renseigne mon tiers payeur</h1>
                <p><i className="bi bi-info-circle me-2" />Le tiers payeur est la personne qui va payer les cotisations tout en n'étant pas présente sur le contrat, nous avons besoin d'avoir ses informations personnelles.</p>
            </div>

            <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} onSubmit={values => {postUpdateTiersPayeur({query, form: values, successFunction: () => props.history.push(`/mes-contrats/mon-contrat/${id_contrat}`)})}} validate={validate}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                    <form onSubmit={handleSubmit}>
                        <div className={'mb-4 mt-4'}>
                            <RadioGroup
                                label={"Type de tiers payeur"}
                                name={'type'}
                                setFieldValue={setFieldValue}
                                options={[
                                    {value: "P", label:"Personne physique"},
                                    {value: "M", label:"Personne morale"}
                                ]}
                                value={values.type}
                                onChange={(e) => {
                                    if(e.target.value === "M"){
                                        setFieldValue('firstname', '')
                                        setFieldValue('birthname', '')
                                        setFieldValue('birthdate', '')
                                        setFieldValue('birthcountry', '')
                                        setFieldValue('birthcity', '')
                                        setFieldValue('relationship', '')
                                        setFieldValue('third_payeur_reason', '')
                                    }
                                }}
                            />
                        </div>
                        { values.type === 'P' &&
                            <>
                                <div className="row">
                                    <div className="col col-md-6 my-4">
                                        <Select
                                            name={"relationship"}
                                            label={'Votre lien avec le tiers payeur'}
                                            value={values.relationship}
                                            error={errors.relationship}
                                            onChange={e => {
                                                setFieldValue('relationship', e.target.value)
                                                if(e.target.value !== 'A'){
                                                    setFieldValue('third_payeur_reason', '')
                                                }
                                            }}

                                        >
                                            <option value={""}>Sélectionnez une relation</option>
                                            {tiersPayeurRelation.map((relation, index) => (
                                                <option key={index} value={relation.code}>{relation.libelle}</option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                { values.relationship === 'A' &&
                                    <div className="row">
                                        <div className="col col-md-6 my-4">
                                            <Text
                                                type={'text'}
                                                name="third_payeur_reason"
                                                label={'Motif d\'intervention du payeur'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.third_payeur_reason}
                                                error={errors.third_payeur_reason}
                                                maxLength={250}

                                            />
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        <div className="row">
                            <div className="col col-md-6 my-4">
                                <Text
                                    type={'text'}
                                    name="lastname"
                                    label={values.type === 'P' ? 'Nom' : 'Nom de la société'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastname}
                                    error={errors.lastname}
                                    normalize={v => v.toUpperCase()}
                                    maxLength={50}

                                />
                            </div>

                            { values.type === 'P' &&
                                <div className="col col-md-6 my-4">
                                    <Text
                                        type={'text'}
                                        name="firstname"
                                        label={'Prénom'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstname}
                                        error={errors.firstname}
                                        normalize={v => v.toUpperCase()}
                                        maxLength={20}

                                    />
                                </div>
                            }
                        </div>

                        { values.type === 'P' &&
                            <>
                                <div className="row">
                                    <div className="col col-md-6 my-4">
                                        <Text
                                            type={'text'}
                                            name="birthname"
                                            label={'Nom de naissance'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.birthname}
                                            error={errors.birthname}
                                            normalize={v => v.toUpperCase()}
                                            maxLength={20}

                                        />
                                    </div>

                                    <div className="col col-md-6 my-4">
                                        <SimpleDatePicker
                                            label={'Date de naissance'}
                                            name={'birthdate'}
                                            setFieldValue={setFieldValue}
                                            value={values.birthdate}
                                            error={errors.birthdate}
                                            maxDate={new Date(moment().subtract(14, 'Y').format('YYYY-MM-DD'))}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-md-6 my-4">
                                        {
                                            countries && <Autocomplete
                                                name={`birthcountry`}
                                                label="Pays de naissance"
                                                data={countries}
                                                onChange={handleChange}
                                                value={values.birthcountry}
                                                defaultValue={_.find(countries, ['value', initialValues.birthcountry])?.label}
                                                setFieldValue={setFieldValue}
                                                error={errors.birthcountry}

                                            />
                                        }
                                    </div>
                                    <div className="col col-md-6 my-4">
                                        { values.birthcountry === 'FRA' ?
                                            <AutoCompleteBirth
                                                name="birthcity"
                                                label='Ville de naissance'
                                                onChange={handleChange}
                                                value={values.birthcity}
                                                country={values.birthcountry}
                                                defaultValue={values.birthcity}
                                                setFieldValue={setFieldValue}
                                            />
                                            : <Text
                                            type='text'
                                            name="birthcity"
                                            label='Ville de naissance'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.birthcity}
                                            error={errors.birthcity}
                                        /> }
                                    </div>
                                </div>
                            </>
                        }

                        <div className="d-grid gap-2">
                            {
                                loading ? <SmallSpinner /> :
                                    <button
                                        type="submit"
                                        className={`btn btn-primary btn-block`}
                                    >
                                        Je valide mon tiers payeurs
                                    </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};
export default ChangeTiersPayeur;
