import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import FormulaireContact from "./FormulaireContact";
import {getDataContracts} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import {CONTRACTS, fetchContracts} from "../../../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getRequestRecontactLink,
    getContractsLink,
    getContactLink
} from "../../../../../../../redux/selectors/links/links.selectors";
import {POST_CONTACT, postContact} from "../../../../../../../redux/actions/app/contact/contact.actions";
import {getEmailUser, getFirstnameUser, getLastnameUser} from "../../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    const contracts = getDataContracts(state);

    return {
        query_contract: getContractsLink(state),
        query: getRequestRecontactLink(state),
        query_resiliation: getContactLink(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        loading: getLoadingEntity(state, POST_CONTACT),
        contracts: contracts,
        initialValues: {
            contract_id: contracts && contracts.length === 1 ? contracts[0].id : '',
            police: contracts && contracts.length === 1 ? contracts[0].numeroPolice : '',
            reason: '',
            description: '',
            suspension_contrat_vente: false,
            nom: getLastnameUser(state),
            prenom: getFirstnameUser(state),
            email: getEmailUser(state),
            files: []
        }
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContracts,
    postContact,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query_contract, contracts } = stateProps
    const { fetchContracts } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query: query_contract}) : null,
    }
}


const FormulaireContactContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(FormulaireContact)


export default FormulaireContactContainer