import {createSelector} from 'reselect'

/* UI */
const uiSelector = state => state.ui

/* Maintenance */
export const getMaintenance = createSelector(
    uiSelector,
    ui => ui.maintenance
)

/* Login */
export const getLogin = createSelector(
    uiSelector,
    ui => ui.login
)

/* Loading */
const loadingEntitySelector = (state, entity) => state.ui.loading[entity]

export const getLoading = createSelector(
    uiSelector,
    ui => ui.loading
)

export const getLoadingEntity = createSelector(
    loadingEntitySelector,
    loading => loading
)

/* Error */
const errorEntitySelector = (state, entity) => state.ui.error[entity]

export const getErrorEntity = createSelector(
    errorEntitySelector,
    error => error
)

export const getErrorResponse = createSelector(
    uiSelector,
    ui => ui.errorResponse
)

/* Filters */
const filtersEntitySelector = (state, entity) => state.ui.filters[entity]

export const getFiltersEntity = createSelector(
    filtersEntitySelector,
    filters => filters || {}
)

/* Request Document */
const requestDocumentSelector = (state, entity) => state.ui.requestDocument[entity]

export const getRequestDocument = createSelector(
    requestDocumentSelector,
    document => document
)

/* Documents For Accident */
export const getDocumentsForAccident = createSelector(
    uiSelector,
    ui => ui.documentsForAccident || []
)


/* Documents For Accident */
export const getCodePromoUserUi = createSelector(
    uiSelector,
    ui => ui.code_promo_user || null
)

/* Message retour décla accident */
export const getAccidentResponse = createSelector(
    uiSelector,
    ui => ui.postAccidentResponse
)

export const getHivernageReduction = createSelector(
    uiSelector,
    ui => ui.hivernageReduction
)
