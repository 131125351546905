import React from 'react';
import {Link, Route} from "react-router-dom";
import ChangeInformationsContainer from "./ChangeInformations/ChangeInformationsContainer";
import MonProfilContainer from "./MonProfil/MonProfilContainer";
import ChangeRibContainer from "./ChangeRib/ChangeRibContainer";
import ChangeAbonnementsContainer from "./ChangeAbonnements/ChangeAbonnementsContainer";
import ChangeMotDePasseContainer from "./ChangeMotDePasse/ChangeMotDePasseContainer";
import changeInformationsNaissanceContainer from "./ChangeInformationsNaissance/ChangeInformationsNaissanceContainer";

const Profile = (props) => {
    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light fw-bold'}>
                    <Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={`/mon-profil`} className={'text-decoration-none'}>Mon compte</Link>
                </h6>
            </div>

            <Route exact path={'/mon-profil/je-change-mes-informations-bancaires'} component={ChangeRibContainer} />
            <Route exact path={'/mon-profil/je-change-mes-informations'} component={ChangeInformationsContainer} />
            <Route exact path={'/mon-profil/je-change-mes-informations-de-naissance'} component={changeInformationsNaissanceContainer} />
            <Route exact path={'/mon-profil/je-change-mes-abonnements'} component={ChangeAbonnementsContainer} />
            <Route exact path={'/mon-profil/je-change-mon-mot-de-passe'} component={ChangeMotDePasseContainer} />
            <Route exact path={'/mon-profil'} component={MonProfilContainer} />

        </div>
    );
};


export default Profile;
