export const AVANTAGES = '[Avantages]'

export const FETCH_AVANTAGES = `${AVANTAGES} Fetch`
export const SET_AVANTAGES = `${AVANTAGES} Set`

export const fetchAvantages = ({query}) => ({
    type: FETCH_AVANTAGES,
    payload: {
        data: query
    }
})

export const setAvantages = ({data}) => ({
    type: SET_AVANTAGES,
    payload: {
        data
    }
})
