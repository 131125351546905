import React, {useEffect} from 'react';
import _ from 'lodash'
import moment from 'moment'
import Textarea from "../../../../../../Commun/Inputs/Textarea/Textarea";
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import SimpleDatePicker from "../../../../../../Commun/Inputs/DatePicker/SimpleDatePicker";
import PostalCodeCity from "../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";
import Text from "../../../../../../Commun/Inputs/Text/Text";
import {getCategoryByTypeAccident} from "./getCategoryByTypeAccident";
import Select from "../../../../../../Commun/Inputs/Select/Select";

const Declaration = (props) => {
    const { isWakam, errors, values, setFieldValue, contract, sinistres, risque, conduct, handleChange, garanties } = props

    useEffect(() => {
        setFieldValue('risque', String(risque))
        setFieldValue('conduct', conduct)
    }, [])

    useEffect(() => {
        let desc = ''
        if(values.equipementsDommageGants === 'T'){
            desc += 'Gants - '
        }
        if(values.equipementsDommageBottes === 'T'){
            desc += 'Bottes - '
        }
        if(values.equipementsDommageBlouson === 'T'){
            desc += 'Blouson - '
        }
        if(values.equipementsDommageCasque === 'T'){
            desc += 'Casque - '
        }
        if(values.equipementsDommageAirbag === 'T'){
            desc += 'Airbag - '
        }
        if(values.equipementsDommageAutre === 'T'){
            desc += 'Autres equipements ('+values.equipementsDommageAutreDesc+')'
        }

        setFieldValue('dommagesDeclares', desc)
    }, [values.equipementsDommageGants, values.equipementsDommageBottes, values.equipementsDommageBlouson, values.equipementsDommageCasque, values.equipementsDommageAirbag, values.equipementsDommageAutre])


    let sinistreDejaDeclare = _.find(sinistres, s => {
        return moment(s.dateSurv).format('L') === moment(values.datesurvorigin).format('L')
    })

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Votre déclaration</h4>
            </div>

            <div className="mb-5">
                <Select
                    className={'categorieSin'}
                    label={"Sélectionnez la catégorie du sinistre"}
                    name={`catsinorigin`}
                    error={errors.catsinorigin}
                    value={values.catsinorigin}
                    onChange={e => {
                        setFieldValue(`catsinorigin`, e.target.value)
                    }}
                >
                    <option value={''}>Choisissez la catégorie du sinistre</option>
                    {
                        getCategoryByTypeAccident(values.type_sinistre).map((cat, index) =>
                            <option key={index} value={cat.value}>{cat.label}</option>
                        )
                    }
                </Select>
            </div>

            {
                !_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5'], values.catsinorigin) &&
                <div className="alert alert-danger" role="alert">
                    <p className={'m-0'}><b>ATTENTION</b> : Vous avez sélectionné une catégorie Dommage tous accidents pour ce sinistre alors que votre contrat ne comprend pas la prise en charge de cette garantie. Vous ne pourrez donc pas poursuivre la déclaration.</p>
                </div>
            }
            {
                !_.find(garanties, ['code_famille', 'VI']) && _.includes(['10.0', '105.1', '9.0', '105.2', '18.0', '19.0'], values.catsinorigin) &&
                <div className="alert alert-danger" role="alert">
                    <p className="mb-0">
                        <b>ATTENTION</b> : Vous avez sélectionné une catégorie "Vol" ou "Incendie" pour ce sinistre alors que votre contrat ne comprend pas la prise en charge de cette garantie. Vous ne pourrez donc pas poursuivre la déclaration.
                    </p>
                </div>
            }
            {['103.1', '103.2', '103.3', '104.1', '104.2', '104.3', '104.4'].includes(values.catsinorigin) &&
                <div className="mb-5">
                    <Textarea
                        label={'Quel était le motif du déplacement au moment du sinistre ?'}
                        name={"motif_deplacement"}
                        value={values.motif_deplacement}
                        error={errors.motif_deplacement}
                        maxLength={120}
                    />
                </div>
            }
            <div className="mb-5">
                <Textarea
                    label={'Précisez les circonstances du sinistre'}
                    name={"commentaire"}
                    value={values.commentaire}
                    error={errors.commentaire}
                    maxLength={120}
                />
            </div>

            {
                values.catsinorigin === "104.1" && <div className="mb-5">
                    <RadioGroup
                        label={"Connaissez-vous l'immatriculation du véhicule en fuite ?"}
                        name={'indentify'}
                        setFieldValue={setFieldValue}
                        options={[
                            {value: 'T', label: 'Oui'},
                            {value: 'F', label: 'Non'}
                        ]}
                        value={values.indentify}
                        error={errors.indentify}
                        required
                    />
                </div>
            }

            {
                values.catsinorigin === "104.4" && <div className="mb-5">
                    <RadioGroup
                        label={"le propriétaire de l'animal est-il identifié ?"}
                        name={'indentify'}
                        setFieldValue={setFieldValue}
                        options={[
                            {value: 'T', label: 'Oui'},
                            {value: 'F', label: 'Non'}
                        ]}
                        value={values.indentify}
                        error={errors.indentify}
                        required
                    />
                </div>
            }

            {
                values.catsinorigin === "103.3" && <div className="mb-5">
                    <RadioGroup
                        label={"Le piéton/cycliste est-il identifié ?"}
                        name={'indentify'}
                        setFieldValue={setFieldValue}
                        options={[
                            {value: 'T', label: 'Oui'},
                            {value: 'F', label: 'Non'}
                        ]}
                        value={values.indentify}
                        error={errors.indentify}
                        required
                    />
                </div>
            }

            <div className="mb-5">
                <SimpleDatePicker
                    label={'Date du sinistre'}
                    name={'datesurvorigin'}
                    setFieldValue={setFieldValue}
                    value={values.datesurvorigin}
                    error={errors.datesurvorigin}
                    required
                    maxDate={new Date()}
                />
                {
                    values.type_sinistre === 'vol' && <small className={'mt-2 fw-bold text-grey'}>Indiquez le jour de la disparition de votre véhicule. Si vous ne connaissez pas le jour exact, indiquez la date la plus ancienne inscrite sur le procès verbal.</small>
                }
            </div>

            <div className="mb-5">
                <PostalCodeCity
                    label={'Code postal et ville du sinistre'}
                    cp='cplieu'
                    ville='lieu'
                    value={values.lieu}
                    error={errors.lieu}
                    required
                />
            </div>

            <div className="mb-5">
                <RadioGroup
                    label={"Le conducteur est-il blessé ?"}
                    name={'corpoconduct'}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: 'T', label: 'Oui'},
                        {value: 'F', label: 'Non'}
                    ]}
                    value={values.corpoconduct}
                    error={errors.corpoconduct}
                    required
                />
            </div>

            {
                isWakam && values.catsinorigin !== "10.0" && <div className="mb-5">
                    <SimpleDatePicker
                        label={'Date de naissance du conducteur'}
                        name={'birthdate_conduct'}
                        setFieldValue={setFieldValue}
                        value={values.birthdate_conduct}
                        error={errors.birthdate_conduct}
                        required
                    />
                </div>
            }

            {
                ['103.1', '103.2', '103.3', '104.1', '104.2', '104.3', '104.4'].includes(values.catsinorigin) &&
                <div className="mb-5">
                    <Text
                        name={`conduct`}
                        label="Nom et prénom du conducteur lors de l'accident"
                        onChange={handleChange}
                        value={values.conduct}
                    />
                </div>
            }

            {
                (values.catsinorigin === '103.1' ||
                    values.catsinorigin === '103.2' ||
                    values.catsinorigin === '104.1' ||
                    values.catsinorigin === '104.2' ||
                    values.catsinorigin === '103.3' ||
                    values.catsinorigin === '104.4') &&
                <div className="mb-5">
                    <RadioGroup
                        label={"Y-a-t-il des témoins ?"}
                        name={'temoin'}
                        setFieldValue={setFieldValue}
                        options={[
                            {value: 'T', label: 'Oui'},
                            {value: 'F', label: 'Non'}
                        ]}
                        value={values.temoin}
                        error={errors.temoin}
                        required
                    />
                </div>
            }

            { isWakam && values.catsinorigin === '103.2' &&
                <div className="mb-5">
                    <Select
                        className={'categorieSin'}
                        label={"Combien de véhicules sont impliqués sur ce sinistre ?"}
                        name={`nb_vehicules`}
                        error={errors.nb_vehicules}
                        value={values.nb_vehicules}
                        onChange={e => {
                            setFieldValue(`nb_vehicules`, e.target.value)
                        }}
                    >
                        <option value={null}>Sélectionnez le nombre</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </Select>
                </div>
}

{
    values.catsinorigin !== "10.0" && <div className="mb-5">
        <RadioGroup
            label={"Le véhicule a-t-il subi des dommages ?"}
            name={'vehiculeDommage'}
            setFieldValue={setFieldValue}
                        options={[
                            {value: 'T', label: 'Oui'},
                            {value: 'F', label: 'Non'}
                        ]}
                        value={values.vehiculeDommage}
                        error={errors.vehiculeDommage}
                        required
                    />
                </div>
            }

            {
                ['103.1', '103.2', '103.3', '104.1', '104.2', '104.3', '104.4'].includes(values.catsinorigin) &&
                <div className="mb-5">
                    <RadioGroup
                        label={"Il y a-t-il des dégâts sur vos équipements et accessoires ?"}
                        name={'equipementsDommage'}
                        setFieldValue={setFieldValue}
                        options={[
                            {value: 'T', label: 'Oui'},
                            {value: 'F', label: 'Non'}
                        ]}
                        value={values.equipementsDommage}
                        error={errors.equipementsDommage}
                        required
                    />
                </div>
            }
            {
                values.equipementsDommage === "T" && <div className={'row'}>
                    <div className="mb-5 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                        <RadioGroup
                            label={"Votre casque"}
                            name={'equipementsDommageCasque'}
                            setFieldValue={setFieldValue}
                            options={[
                                {value: 'T', label: 'Oui'},
                                {value: 'F', label: 'Non'}
                            ]}
                            value={values.equipementsDommageCasque}
                            error={errors.equipementsDommageCasque}
                            required
                        />
                    </div>
                    <div className="mb-5 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                        <RadioGroup
                            label={"Vos gants"}
                            name={'equipementsDommageGants'}
                            setFieldValue={setFieldValue}
                            options={[
                                {value: 'T', label: 'Oui'},
                                {value: 'F', label: 'Non'}
                            ]}
                            value={values.equipementsDommageGants}
                            error={errors.equipementsDommageGants}
                            required
                        />
                    </div>
                    <div className="mb-5 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                        <RadioGroup
                            label={"Votre blouson"}
                            name={'equipementsDommageBlouson'}
                            setFieldValue={setFieldValue}
                            options={[
                                {value: 'T', label: 'Oui'},
                                {value: 'F', label: 'Non'}
                            ]}
                            value={values.equipementsDommageBlouson}
                            error={errors.equipementsDommageBlouson}
                            required
                        />
                    </div>
                    <div className="mb-5 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                        <RadioGroup
                            label={"Vos bottes"}
                            name={'equipementsDommageBottes'}
                            setFieldValue={setFieldValue}
                            options={[
                                {value: 'T', label: 'Oui'},
                                {value: 'F', label: 'Non'}
                            ]}
                            value={values.equipementsDommageBottes}
                            error={errors.equipementsDommageBottes}
                            required
                        />
                    </div>
                    <div className="mb-5 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                        <RadioGroup
                            label={"Votre gilet Airbag"}
                            name={'equipementsDommageAirbag'}
                            setFieldValue={setFieldValue}
                            options={[
                                {value: 'T', label: 'Oui'},
                                {value: 'F', label: 'Non'}
                            ]}
                            value={values.equipementsDommageAirbag}
                            error={errors.equipementsDommageAirbag}
                            required
                        />
                    </div>
                    <div className="mb-5 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                        <RadioGroup
                            label={"Un autre équipement ?"}
                            name={'equipementsDommageAutre'}
                            setFieldValue={setFieldValue}
                            options={[
                                {value: 'T', label: 'Oui'},
                                {value: 'F', label: 'Non'}
                            ]}
                            value={values.equipementsDommageAutre}
                            error={errors.equipementsDommageAutre}
                            required
                        />
                    </div>
                    {
                        values.equipementsDommageAutre === "T" &&
                        <div className="mb-5 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <Text
                                name={`equipementsDommageAutreDesc`}
                                label="Préciser l'equipement endommagé"
                                value={values.equipementsDommageAutreDesc}
                                onChange={handleChange}
                            />
                        </div>
                    }
                </div>
            }
            {
                sinistreDejaDeclare && <div className="alert alert-danger" role="alert">
                    <p className={'m-0'}>
                        <b>ATTENTION</b> : un sinistre a déjà été déclaré à cette date, vous ne pouvez pas poursuivre la déclaration.
                    </p>
                </div>
            }
        </div>
    );
};

export default Declaration;
