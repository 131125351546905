import {connect} from 'react-redux'
import ChangeRib from "./ChangeRib";
import {amendmentChangeIbanGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {validate} from "./validate";
import {
    POST_AMENDMENT_CHANGE_IBAN,
    postAvenantChangeIban
} from "../../../../../../redux/actions/app/amendment/amendment.actions";
import {
    getIbanBanqueUser,
    getIdUser,
    getTitulaireBanqueUser
} from "../../../../../../redux/selectors/user/user.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {

    return {
        query: amendmentChangeIbanGetLink(state),
        loading: getLoadingEntity(state, POST_AMENDMENT_CHANGE_IBAN),
        validate,
        iban: getIbanBanqueUser(state),
        initialValues: {
            tiers: getIdUser(state),
            adresse: '',
            cp: '',
            ville: '',
            titulaire: '',
            type: '',
            firstname: '',
            lastname: '',
            birthname: '',
            birthdate: '',
            birthcity: '',
            birthcountry: '',
            relationship: '',
            third_payeur_reason: '',
        }
    }
}


const mapDispatchToProps ={
    postAvenantChangeIban
}

const ChangeRibContainer = connect(mapStateToProps, mapDispatchToProps)(ChangeRib)

export default ChangeRibContainer
