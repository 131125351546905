import {SET_WAITING_DOC_CONTRACTS} from '../../actions/app/contracts/contracts.actions'

export const waitingDocContractsReducer = (contracts = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_WAITING_DOC_CONTRACTS) {
        return payload
    } else {
        return contracts
    }
}
