import {createSelector} from 'reselect'

const flotteSelector = state => state.flotte
const flotteDetailSelector = state => state.flotte_detail

export const getFlotteSelector = createSelector(
    flotteSelector,
    flotte => flotte
)


export const getFlotteDetailData = createSelector(
    flotteDetailSelector,
    flotte_detail => flotte_detail.data
)

export const getGarantiesFlotteDetailData = createSelector(
    getFlotteDetailData,
    data => data.garanties
)
