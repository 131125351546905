import React from 'react';
import './textarea.scss'
import {Field} from "formik";

const Textarea = (props) => {
    const { label, value, error, required, maxLength } = props

    return (
        <div className={'input-textarea w-100 position-relative'}>
            <Field as="textarea" {...props} className={`w-100 p-3 rounded ${value ? 'valid' : ''}`} maxLength={maxLength} />

            <label className={'position-absolute'}>{label}{required ? <sup className={'text-danger'}>*</sup> : ''}</label>
            { maxLength && value && <small className={'float-end'}>{`${maxLength - value.length} / ${maxLength}`}</small>}

            {error && <small className={'text-danger fst-italic'}>{error}</small>}
        </div>
    );
};

export default Textarea;
