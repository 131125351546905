import React from 'react';
import header from '../../../../../../assets/images/parrainages.png'
import './parrainage.scss'
import pdf from '../../../../../../assets/pdf/Reglement_Offre-Parrainage_APRIL-Moto_2024-VF.pdf'
import AddUser from '../../../../../../assets/images/icons/add-user.svg'
import AvionEnPapier from '../../../../../../assets/images/icons/avion-en-papier.svg'
import Cadeau from '../../../../../../assets/images/icons/cadeau.svg'
import SendParrainageContainer from "./SendParrainage/SendParrainageContainer.js";

const ParrainagesDetail = (props) => {
    const {parrainages} = props
    const parrainages_restants = 5 - parrainages.length

    const etapeParrainage = (parrainage) => {
        if(parrainage.date_en_cours !== null) {
            return <p className={'float-right m-0 text-success'}>Parrainage validé</p>
        } else if(parrainage.date_en_cours === null && parrainage.date_souscription !== null) {
            return <p className={'float-right m-0 text-secondary'}>Contrat en cours de validation</p>
        } else {
            if (/^\+?[1-10]\d{1,14}$/.test(parrainage.email)){
                return <p className={'float-right m-0 text-secondary'}>SMS de parrainage envoyé</p>
            } else {
                return <p className={'float-right m-0 text-secondary'}>Email de parrainage envoyé</p>
            }
        }
    }

    return (
        <>
            <div className={'col-12 mt-5'}>
                <img src={header} alt={'Parrainages APRIL Moto'} className={'img-fluid'} style={{marginBottom: 350}}/>
                <div className={'blocs-parrainages row'} style={{position: "absolute", top: 390}}>
                    <div className={'blocs-send-parrainages text-center bg-white col-12 col-sm-6'}>
                        <p className={'mb-4 h5'}>Parrainez vos proches et <strong className={'text-primary-light'}>obtenez
                            jusqu'à 150€*</strong></p>
                        <small className={'mt-4'}>Invitez vos proches à souscrire une assurance chez APRIL Moto et
                            recevez
                            30€ en carte cadeau lorsque le contrat de votre filleul sera validé par APRIL Moto.</small>
                        <div className={'mt-4'}>

                            {
                                parrainages.length >= 5 ?
                                    <p className={'h5'}>Félicitation vous avez le maximum de parrainages&nbsp;!</p> :
                                    <SendParrainageContainer/>
                            }

                        </div>
                        <small className={'fst-italic d-block text-start mt-4'}>*Dans la limite de 5 filleuls</small>
                    </div>
                </div>

                <h2 className={'title fw-bold mt-5 mb-5'}><strong>Comment ça fonctionne ?</strong></h2>
                <div className={'blocs-parrainages row mb-5'}>
                    <div className={'bloc'}>
                        <div className={'mb-3 text-center'}>
                            <img src={AvionEnPapier} alt="Envoyer une invitation" width={50}
                                 style={{filter: "invert(40%) sepia(91%) saturate(1598%) hue-rotate(189deg) brightness(95%) contrast(89%)"}}/>
                        </div>
                        <p><strong>Envoyez une invitation</strong><br/>
                            de parrainage à vos amis pour qu'ils fassent un devis</p>
                    </div>
                    <div className={'bloc'}>
                        <div className={'mb-3 text-center'}>
                            <img src={AddUser} alt="Souscription du filleul" width={50}
                                 style={{filter: "invert(40%) sepia(91%) saturate(1598%) hue-rotate(189deg) brightness(95%) contrast(89%)"}}/>
                        </div>
                        <p><strong>Vos amis souscrivent</strong><br/>
                            Un contrat d'assurance via le lien</p>
                    </div>
                    <div className={'bloc'}>
                        <div className={'mb-3 text-center'}>
                            <img src={Cadeau} alt="Gagnez 30€" width={50}
                                 style={{filter: "invert(40%) sepia(91%) saturate(1598%) hue-rotate(189deg) brightness(95%) contrast(89%)"}}/>
                        </div>
                        <p><strong>Vous gagnez 30€ chacun</strong><br/>
                            Vous recevez chacun une carte cadeau de 30€ dès validation du contrat de votre filleul par
                            APRIL Moto</p>
                    </div>
                </div>
                <div className={'parrainages-form bloc-white row'}>
                    <p>Vous pouvez parrainer jusqu'à 5 filleuls et obtenir 150€ de carte cadeau :</p>
                    {
                        parrainages.map((p, i) => (
                            <div key={i}>
                                <div className="row">
                                    <div className="col">
                                        <p className={'mb-1 fw-bold d-inline-block'}>{p.email}</p>
                                    </div>
                                    <div className="col">{etapeParrainage(p)}</div>
                                </div>
                                <hr/>
                            </div>
                        ))
                    }
                </div>
                <h2 className={'title fw-bold mt-5 mb-5'}>Conditions <strong>de l'offre</strong></h2>
                <div className={'bloc-white row'}>
                    <ul>
                        <li>Le Parrain et le Filleul ne peuvent être une seule et même personne</li>
                        <li>L’attribution des récompenses est effective une fois la souscription du contrat du filleul
                            validée par APRIL Moto après réception des pièces justificatives
                        </li>
                        <li>Le contrat du filleul doit être un contrat Moto ou Scooter (supérieur à 50 cm3)</li>
                    </ul>
                    <a href={pdf} target={'_blank'}>Téléchargez les conditions de l'offre</a>
                </div>
            </div>

            <style jsx>{`
                @media (max-width: 767px) {
                    img.img-fluid {
                        margin-bottom: 0 !important;
                    }

                    .blocs-parrainages {
                        position: static !important;
                        top: auto !important;
                    }
                }
            `}</style>
        </>
    );
};

export default ParrainagesDetail;
