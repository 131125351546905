import React, {useEffect, useState} from 'react';
import Icons from "../../../../../../Commun/Icon/Icon";
import './liste_souscription_devis.scss'

const ListeSouscriptionDevis = (props) => {
    const {subscribeLinks} = props
    const [open, setOpen] = useState(false)

    const handleClick = (event) => {
        if(!event.target.classList.contains('btn-realiser-devis') && !Boolean(event.target.closest('.btn-realiser-devis')) ){
            setOpen(false)
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const produits = [
        {'label': 'Ma <strong>Moto</strong>', 'url': subscribeLinks['moto']},
        {'label': 'Mon <strong>Scooter</strong>', 'url': subscribeLinks['scooter']},
        {'label': 'Mon <strong>Cyclo 50cm3</strong>', 'url': subscribeLinks['50']},
        {'label': 'Ma <strong>Moto verte</strong>', 'url': subscribeLinks['verte']},
        {'label': 'Mon <strong>Quad</strong>', 'url': subscribeLinks['quad']},
        {'label': 'Ma <strong>Trottinette</strong>', 'url': subscribeLinks['trottinette']},
        {'label': 'Mon <strong>Vélo</strong>', 'url': subscribeLinks['velo']}
    ];

    return (
        <div className={'liste-devis'}>
            <button type={"button"} className={`btn-realiser-devis btn btn-primary mt-4 ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
                Réaliser un nouveau devis <i className={'b-left bi bi-chevron-down ms-2 d-inline-block'} />
            </button>

            <div className={`position-absolute bg-white dropdown-menu bg-grey-light ${open ? 'show' : ''}`}>
                <div className="list-group list-unstyled ">
                    {
                        produits.map((p,i) => {
                            return p.url ?
                            <a key={i} className="list-group-item border-0 d-flex justify-content-between align-items-center" href={p.url} target={'_blank'}>
                                <span className={'pe-4'} dangerouslySetInnerHTML={{__html: p.label}}/><Icons icon={`chevrondroite`} fontSize={10} />
                            </a> : ''
                        })
                    }
                </div>
            </div>
        </div>
    );
};


export default ListeSouscriptionDevis;