import moment from 'moment/moment';
import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { formatImmatriculation } from '../../../../../../../../utils/functions';
import Badge from '../../../../../../../Commun/Badge/Badge.jsx';

export default function Flotte({ contract, flotte, getFlotte, contractId, uri, loading, isWakam }) {
    const { data = [], meta = {}, links = {} } = flotte;

    const textInputRef = React.useRef(null);
    const [state, setState] = React.useState({
        query: '',
        sort: '',
        active: '',
        immat: '',
    });

    useEffect(() => {
        getFlotte({ query: uri, contrat: contractId, sort: '', active: '', immat: '' });
    }, []);

    const handleChangeSort = (value, field) => {
        let newValue = formatImmatriculation(value.replaceAll(' ', ''));

        const newState = {
            ...state,
            [field]: newValue,
        };

        setState(newState);
        getFlotte({
            query: uri,
            sort: newState.sort,
            immat: newState.immat,
            active: newState.active,
            contrat: contractId,
        });
    };

    const handleChangePage = (query) => {
        getFlotte({
            query: query,
            sort: state.sort,
            immat: state.immat,
            active: state.active,
            contrat: contractId,
        });
    };


    return (
        <div className={'bloc-white'}>
            <div className={'row'}>
                <div className="col-4">
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Vos véhicules assurés</h4>
                    <p className={'text-secondary small'}>{loading ? 'Chargement...' : `${meta.total} véhicules`}</p>
                </div>
                <div className="col text-end">
                    { isWakam && contract.avenant_link && <a href={contract.avenant_link} target={"_blank"} className="btn btn-small btn-primary d-inline-flex">
                        Ajout de véhicule/Changement de garanties
                    </a> }

                    <Link to={`/mes-contrats/mon-contrat/${contractId}/sortie-vehicules`} className="ms-2 btn btn-small btn-danger d-inline-flex">
                        Sortir des véhicules
                    </Link>
                </div>
            </div>
            <div className={'row'}>
                <div className={'d-flex'}>
                    <select
                        name="sort"
                        onChange={(e) => handleChangeSort(e.target.value, 'sort')}
                        className="px-2 rounded border w-100 small"
                        value={state.sort}
                    >
                        <option value={''}>Adhesion</option>
                        <option value={'date'}>Mise en circulation croissant</option>
                        <option value={'-date'}>Mise en circulation décroissant</option>
                    </select>
                    <input
                        type="text"
                        className="px-3 rounded mx-2 border w-100 small"
                        placeholder="Immatriculation"
                        onKeyDown={(e) =>
                            e.key === 'Enter' && handleChangeSort(textInputRef.current.value.toUpperCase(), 'immat')
                        }
                        onChange={(e) => e.target.value.length === 0 && handleChangeSort('', 'immat')}
                        ref={textInputRef}
                    />
                    <button
                        type="button"
                        className="btn btn-small btn-primary d-inline-flex"
                        onClick={(e) => {
                            e.preventDefault();
                            handleChangeSort(textInputRef.current.value.toUpperCase(), 'immat')
                        }}
                    >
                        Rechercher
                    </button>
                </div>

                {loading ? (
                    <div className={'d-flex justify-content-center align-items-center my-4'}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                <div className={'table-responsive mt-4'}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Numéro d'adhésion</th>
                                <th scope="col">Marque, Modèle</th>
                                <th scope="col">Immatriculation</th>
                                <th scope="col">Date de mise en circulation</th>
                                <th scope="col">Status</th>
                                <th scope="col">
                                    <span className={'d-none'}>Détails</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((v, index) => (
                                <tr key={index}>
                                    <td>{v.adhesion}</td>
                                    <td>
                                        {v.marque} {v.modele}
                                    </td>
                                    <td>{v.immatriculation ? v.immatriculation : '-'}</td>
                                    <td>{v.dateMEC ? moment(v.dateMEC).format('L') : '-'}</td>
                                    <td>
                                        <Badge status={v.inactif ? 'Inactif' : 'Actif'} />
                                    </td>
                                    <td>
                                        <Link to={`${contractId}/vehicule/${v.adhesion}`} className={'link'}>
                                            Voir
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                )}
                {!loading && meta?.total > meta?.per_page && (
                    <nav className="w-100 d-flex align-items-center justify-content-center">
                        <div className="pagination">
                            {meta.current_page > 2 && (
                                <button className="page-item" onClick={() => handleChangePage(links.first)}>
                                    {`<<`}
                                </button>
                            )}
                            {meta.current_page > 1 && (
                                <button className="page-item" onClick={() => handleChangePage(links.prev)}>
                                    {'<'}
                                </button>
                            )}
                            <button className="page-item current">{meta.current_page}</button>
                            {meta.last_page > meta.current_page && (
                                <button className="page-item" onClick={() => handleChangePage(links.next)}>
                                    {`>`}
                                </button>
                            )}
                            {meta.last_page >= meta.current_page + 2 && (
                                <button className="page-item" onClick={() => handleChangePage(links.last)}>
                                    {`>>`}
                                </button>
                            )}
                        </div>
                    </nav>
                )}
            </div>
        </div>
    );
}
