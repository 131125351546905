export const RECEIPTS_UNPAID = '[Receipts Unpaid]'

export const FETCH_RECEIPTS_UNPAID = `${RECEIPTS_UNPAID} Fetch`
export const SET_RECEIPTS_UNPAID = `${RECEIPTS_UNPAID} Set`


export const fetchReceiptsUnpaid = ({query}) => ({
    type: FETCH_RECEIPTS_UNPAID,
    payload: {
        data: query
    }
})

export const setReceiptsUnpaid = ({data}) => ({
    type: SET_RECEIPTS_UNPAID,
    payload: {
        data
    }
})
