import {connect} from 'react-redux'
import Reparation from "./Reparation";
import {
    getContractGarantiesOptions, getIsWakamContract, getPrelevementContract,
    getVehiculeContract
} from "../../../../../../../redux/selectors/contracts/contract.selector";

const mapStateToProps = state => {
    return {
        garanties: getContractGarantiesOptions(state),
        isWakam: getIsWakamContract(state),
        vehicule: getVehiculeContract(state),
        prelevement: getPrelevementContract(state)
    }
}

const ReparationContainer = connect(mapStateToProps)(Reparation)

export default ReparationContainer
