import {SET_AVANTAGES} from '../../actions/app/avantages/avantages.actions'

export const avantagesReducer = (avantages = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_AVANTAGES) {
        return payload
    } else{
        return avantages
    }
}
