import {connect} from 'react-redux'
import AvisEcheance from "./AvisEcheance";
import {getEmailGestionWhitelabel} from "../../../../../../../../redux/selectors/whitelabel/whitelabel.selectors";
import {
    getAvisEcheanceLinkContract,
    getAvisEcheanceLinkReasonContract
} from "../../../../../../../../redux/selectors/contracts/contract.selector";

const mapStateToProps = state => {
    return {
        avisEcheanceLink: getAvisEcheanceLinkContract(state),
        avisEcheanceReason: getAvisEcheanceLinkReasonContract(state),
        emailGestion: getEmailGestionWhitelabel(state)
    }
}


const mapDispatchToProps = {
}

const AvisEcheanceContainer = connect(mapStateToProps, mapDispatchToProps)(AvisEcheance)

export default AvisEcheanceContainer
