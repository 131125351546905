import {connect} from 'react-redux'
import DeclarationSinistre from "./DeclarationSinistre";
import { validate } from "./validate";
import {POST_ACCIDENT, postAccident} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {
    getAccidentClaimLinkContract,
    getContractGarantiesOptions,
    getDataContract,
    getIsFleetContract,
    getIsVAEContract
} from "../../../../../../redux/selectors/contracts/contract.selector";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getAccidentsTiersData} from "../../../../../../redux/selectors/accidents/accidents.selectors";
import {getFlotteDetailData} from "../../../../../../redux/selectors/flotte/flotte.selectors.js";

const mapStateToProps = state => {
    return {
        validate,
        loading: getLoadingEntity(state, POST_ACCIDENT),
        query: getAccidentClaimLinkContract(state),
        isVAE: getIsVAEContract(state),
        contract: getDataContract(state),
        sinistres: getAccidentsTiersData(state),
        garanties: getIsFleetContract(state) ? getFlotteDetailData(state)?.garanties : getContractGarantiesOptions(state),
        initialValues: {
            catsinorigin: '',
            adversaires: [{}],
            victimes: [{}],
            temoins: [{}],
            circonstances: [],
            files: []
        }
    }
}

const mapDispatchToProps = {
    postAccident
}

const DeclarationSinistreContainer = connect(mapStateToProps, mapDispatchToProps)(DeclarationSinistre)

export default DeclarationSinistreContainer
