import {connect} from 'react-redux'
import Parrainages from "./Parrainages";
import {compose} from "redux";
import loader from "../../../../Commun/Spinners/Loader/Loader";
import {getEligibleParrainageUser} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        isEligible: getEligibleParrainageUser(state)
    }
}

const ParrainagesContainer = compose(
    connect(mapStateToProps),
    loader()
)(Parrainages)

export default ParrainagesContainer
