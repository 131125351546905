import {connect} from 'react-redux'
import Chocs from "./Chocs";
import {getContractCodeProduit} from "../../../../../../../redux/selectors/contracts/contract.selector";

const mapStateToProps = state => {
    return {
        produit: getContractCodeProduit(state),
    }
}

const ChocsContainer = connect(mapStateToProps)(Chocs)

export default ChocsContainer
