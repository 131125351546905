import { createSelector } from 'reselect'
import _ from 'lodash'

const accidentSelector = state => state.accident.data

export const getAccidentData = createSelector(
    accidentSelector,
    data => data
)

/* Documents à upload */
/* Retrait des documents en attendant que ces pièces soit marqué dans BELAIR pour ne pas être affichés:
 * Elements divers CIE : J
 * Rapport expertise : 6
 * Complément rapport : 0
 * Témoignage : E
 * Lettre mise en cause : 4
 * Réclamation Cie Adverse : D
 */
export const getDocumentsAccident = createSelector(
    accidentSelector,
    data => {
        const documents = _.reject(data.documents, (doc) => {
            return _.includes(['J', '6', '0', 'E', '4', 'D'], doc.code)
        })
        return documents
    }
)


/* ID du sinistre */
export const getAccidentId = createSelector(
    accidentSelector,
    data => data.id
)

/* Lien d'upload des documents */
export const getUploadLinkAccident = createSelector(
    accidentSelector,
    data => data.upload_link
)

export const getCategorieCodeAccident = createSelector(
    accidentSelector,
    data => data.categorieCode
)

export const getGarantiesOptionsAccident = createSelector(
    accidentSelector,
    data => data.garanties
)
