import { connect } from 'react-redux';
import { getApporteur1 } from '../../../../../../redux/selectors/user/user.selectors';
import ContactCourtier from './ContactCourtier.jsx';

const mapStateToProps = (state) => {
    return {
        apporteur1: getApporteur1(state),
    };
};

const ContactCourtierContainer = connect(mapStateToProps)(ContactCourtier);

export default ContactCourtierContainer;
