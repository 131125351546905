import { createSelector } from 'reselect'
import _ from "lodash";

const documentsSelector = state => state.documents

export const getDataDocuments = createSelector(
    documentsSelector,
    documents => documents.data?.filter(document => document.code && document.code.trim() !== '')
)

export const getIfWaitingDocuments = createSelector(
    documentsSelector,
    documents => _.some(documents.data, ['recu', false])
)