import React from 'react';
import {Link} from "react-router-dom";
import './liste_articles.scss'

const ListeArticles = (props) => {
    const { articles } = props

    return (
        <div className={'row listeActu mt-5'}>
            {articles.map((a, index) => (
                <div className={'col-6 actualites'} key={index}>
                    <div className={'content-img'}>
                        <img src={a.thumbnail} alt={a.title}/>
                    </div>
                    <div className={'content'}>
                        <h2 className={'title'}>{a.title}</h2>
                        <p>{a.lead}</p>
                        <div className={'mt-4 pb-3'}>
                            <Link className={'btn btn-primary full'} to={`/mes-actualites/${a.id}`}>Lire l'article...</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ListeArticles;
