import {LOGIN, LOGOUT, POST_LOGIN, POST_LOGOUT} from '../../../actions/app/login/login.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIsLogin, setLoader} from '../../../actions/app/ui/ui.actions'
import {clearLocalStorage, setLocalStorage} from '../../../actions/core/localStorage/localStorage.actions'

export const loginMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_LOGIN:
            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: LOGIN}),
                setLoader({state: true, entity: LOGIN})
            ])
            break

        case `${LOGIN} ${API_SUCCESS}`:
            const { message_footer, img_footer, message_warning, token_type, access_token } = payload.data

            const storage = {
                token: `${token_type} ${access_token}`,
                welcome: {message_footer, message_warning, img_footer},
                id_whitelabel: payload.data.user.whitelabel
            }

            next([
                setLocalStorage({entity: LOGIN, storage}),
                setIsLogin({state: true, entity: LOGIN}),
                setError({state: false, entity: LOGIN}),
                setLoader({state: false, entity: LOGIN})
            ])
            break

        case `${LOGIN} ${API_ERROR}`:
            next([
                setError({state: true, entity: LOGIN}),
                setLoader({state: false, entity: LOGIN})
            ])
            break

        case POST_LOGOUT:
            next([
                apiRequest({method: 'POST', url: payload.data, entity: LOGOUT}),
                clearLocalStorage({entity: LOGOUT})
            ])
            break

        default:
            break
    }
    return null
}
