import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './login.scss'
import ConnectionContainer from "./Connection/ConnectionContainer";
import OubliEmailContainer from "./OubliEmail/OubliEmailContainer";
import OubliMdpContainer from "./OubliMdp/OubliMdpContainer";
import NouveauMdpContainer from "./NouveauMdp/NouveauMdpContainer";

const Login = (props) => {
    const { name, logo } = props

    document.title = `Espace client | ${name}`

    return (
        <div id={'login'}>
            <div className="row position-fixed top-0 start-0 w-100 m-0 login-header-container" style={{zIndex: 10}}>
                <div className="col shadow-lg px-5 py-2 rounded-end rounded-top rounded-pill bg-white">
                    <img src={logo} alt="Logo" height={60} className={'ps-3'} />
                </div>
            </div>
            <div className="row m-0">
                <div className="p-0 rounded-start rounded-top rounded-medium login-picture-container d-none d-lg-block">
                    <img src="/images/login/image-login.jpg" alt="Image Login" className={'rounded-start rounded-top rounded-medium'} />
                </div>
                <div className="m-auto login-form-container" style={{width: '46vw'}}>
                    <div className={'m-auto login-formulaire'} style={{width: '40vw', maxWidth: 500}}>
                        <BrowserRouter>
                            <Switch>
                                <Route exact path={'/login'} component={ConnectionContainer} />
                                <Route exact path={'/login/oubli-mot-de-passe'} component={OubliMdpContainer} />
                                <Route exact path={'/login/oubli-email'} component={OubliEmailContainer} />
                                <Route exact path={'/login/nouveau-mot-de-passe/:token'} component={NouveauMdpContainer} />
                            </Switch>
                        </BrowserRouter>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
