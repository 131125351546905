import React from 'react';
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import Files from "../../../../../../Commun/Inputs/Files/Files";

const DeclarationReparateur = (props) => {
    const { errors, values, setFieldValue} = props

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Connaissez-vous le garage qui fera les réparations de votre véhicule ?</h4>
            </div>

            <div className="mb-5">
                <RadioGroup
                    label={"Connaissez-vous le garage qui fera les réparations de votre véhicule ?"}
                    name={'vehicule'}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: 'T', label: 'Oui'},
                        {value: 'F', label: 'Non'}
                    ]}
                    value={values.vehicule}
                    error={errors.vehicule}
                    required
                />
            </div>
            {
                values.vehicule === "F" && values.catsinorigin !== "10.0" && <div className="alert alert-danger" role="alert">
                    <p className={'m-0'}>
                        <b>ATTENTION</b> : Si votre véhicule ne se trouve
                        pas chez un garagiste, le passage de l'expert ne
                        pourra être planifié
                    </p>
                </div>
            }
        </div>
    );
};

export default DeclarationReparateur;
