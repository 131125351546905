import {FAQ, FETCH_FAQ, setFaq} from '../../../actions/app/faq/faq.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const faqMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_FAQ:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: FAQ}),
                setLoader({state: true, entity: FAQ})
            ])
            break

        case `${FAQ} ${API_SUCCESS}`:
            const { data } = action.payload.data

            next([
                setFaq({data}),
                setError({state: false, entity: FAQ}),
                setLoader({state: false, entity: FAQ})
            ])
            break

        case `${FAQ} ${API_ERROR}`:
            next([
                setError({state: true, entity: FAQ}),
                setLoader({state: false, entity: FAQ})
            ])
            break

        default:
            break
    }
    return null
}
