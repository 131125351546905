export const AMENDMENT = '[Amendment]'

export const POST_AMENDMENT_CHANGE_ADDRESS = `${AMENDMENT} Post Change Address`
export const POST_AMENDMENT_CHANGE_CONTACT = `${AMENDMENT} Post Change Contact`
export const POST_AMENDMENT_CHANGE_IBAN = `${AMENDMENT} Post Change Iban`
export const POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT = `${AMENDMENT} Post Change Fractionnement Prelevement`

export const POST_AMENDMENT_HIVERNAGE = `${AMENDMENT} Post Hivernage`
export const POST_AMENDMENT_GET_HIVERNAGE_REDUC = `${AMENDMENT} Get Hivernage Reduc`

export const POST_AMENDMENT_FIN_HIVERNAGE = `${AMENDMENT} Post Fin Hivernage`

export const postAmendmentChangeAddress = ({query, form, successFunction}) => ({
    type: POST_AMENDMENT_CHANGE_ADDRESS,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})

export const postAmendmentChangeContact = ({query, form, successFunction}) => ({
    type: POST_AMENDMENT_CHANGE_CONTACT,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})

export const postAvenantChangeIban = ({query, form, changementTitulaire = false, successFunction}) => ({
    type: POST_AMENDMENT_CHANGE_IBAN,
    payload: {
        data: query,
        body: form,
        otherData: {
            changementTitulaire,
            successFunction
        }
    }
})

export const postChangeFractionnement = ({createChrono, query, form, successFunction}) => ({
    type: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction,
            createChrono
        }
    }
})

export const postHivernage = ({query, form, successFunction}) => ({
    type: POST_AMENDMENT_HIVERNAGE,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})

export const postFinHivernage = ({query}) => ({
    type: POST_AMENDMENT_FIN_HIVERNAGE,
    payload: {
        data: query
    }
})

export const getHivernageReduc = ({query, form}) => ({
    type: POST_AMENDMENT_GET_HIVERNAGE_REDUC,
    payload: {
        data: query,
        body: form
    }
})