import {API_ERROR, API_SUCCESS, apiRequest} from '../../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../../actions/core/notifications/notifications.actions";
import {FETCH_RECEIPT, RECEIPT, setReceipt} from "../../../../actions/app/payments/receipt/receipt.actions";

export const receiptMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_RECEIPT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: RECEIPT}),
                setLoader({state: true, entity: RECEIPT})
            ])
            break

        case `${RECEIPT} ${API_SUCCESS}`:
            next([
                setReceipt({data: action.payload.data}),
                setError({state: false, entity: RECEIPT}),
                setLoader({state: false, entity: RECEIPT})
            ])
            break

        case `${FETCH_RECEIPT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: FETCH_RECEIPT,
                    html: '<p>Une erreur est survenue lors de la récupération de la quittance.</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: RECEIPT}),
                setLoader({state: false, entity: RECEIPT})
            ])
            break

        default:
            break
    }
    return null
}
