import _ from 'lodash';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import imgContracts from '../../../assets/images/icons/contracts.png';
import SmallSpinner from '../Spinners/SmallSpinner';

const SelectContract = (props) => {
    const { children, contracts, fetchContract, queryContract, contractLoaded, contract } = props;
    const [
        currentContract = {
            vehicule: {
                marque: '',
            },
        },
        setCurrentContract,
    ] = useState(contracts[0]);

    useEffect(() => {
        fetchContract({ query: _.replace(queryContract, '{id}', contracts[0].id) });
    }, [contracts, fetchContract, queryContract]);

    useEffect(() => {
        if (contract.id && currentContract.id !== contract.id) {
            setCurrentContract(contract);
        }
    }, [contract]);

    const selectContract = (contract) => {
        setCurrentContract(contract);
        fetchContract({ query: _.replace(queryContract, '{id}', contract.id) });
    };

    const showToolTip = () => {
        let options = [];

        contracts.map(c => {
            options[c.id] = `Contrat n°${c.numeroPolice} ${!_.isEmpty(c.vehicule.marque) ? `- ${c.vehicule.marque} ${c.vehicule.immatriculation}` : ''}`
        })

        Swal.fire({
            title: 'Changer de contrat',
            input: 'select',
            inputOptions: options,
            inputValue: currentContract.id,
            confirmButtonText: 'Charger ce contrat',
            allowOutsideClick: true,
            showCancelButton: true,
            cancelButtonText: 'Annuler',
        }).then((result) => {
            result.isConfirmed && selectContract(_.find(contracts, ['id', Number(result.value)]));
        });
    };

    return (
        <div className={'select-contract'}>
            <div className="row">
                <div className="col text-center my-3">
                    <div className={'border-bottom border-top border-color-grey py-4 align-items-center'}>
                        <div className={'row'}>
                            <div className="col text-start d-flex align-items-center">
                                <div>
                                    <img src={imgContracts} alt="Icon contrats" width={40} className="me-3" />
                                </div>
                                <h5 className={'m-0 d-inline-block fw-bold align-middle'}>Contrat n°{currentContract.numeroPolice} {currentContract.vehicule.marque && <>- <span className={'fw-normal'}>{currentContract.vehicule.marque} {currentContract.vehicule.immatriculation || currentContract.vehicule.VIN}</span></>}</h5>
                            </div>
                            <div className="col-12 col-sm-3 text-sm-end mt-3 mt-sm-0">
                                <h5
                                    className={'m-0 cursor-pointer text-primary-light fw-bold d-inline-block'}
                                    onClick={() => showToolTip()}
                                >
                                    Changer de contrat <i className="bi bi-chevron-down text-black ms-2" />
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>{contractLoaded ? children : <SmallSpinner />}</div>
        </div>
    );
};

export default SelectContract;
