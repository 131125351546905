export const tiersPayeurRelation = [
    {code: 'C', libelle: 'Conjoint'},
    {code: 'P', libelle: 'Parent'},
    {code: 'FS', libelle: 'Frère/Sœur'},
    {code: 'GP', libelle: 'Grand-parent'},
    {code: 'E', libelle: 'Enfant'},
    {code: 'A', libelle: 'Autres'},
]

export const listFormeJuridique = [
    {'id': "EARL", 'name': 'EARL'},
    {'id': "EURL", 'name': 'EURL'},
    {'id': "GIE", 'name': 'GIE'},
    {'id': "SA", 'name': 'SA'},
    {'id': "SARL", 'name': 'SARL'},
    {'id': "SAS", 'name': 'SAS'},
    {'id': "SASU", 'name': 'SASU'},
    {'id': "SC", 'name': 'SC'},
    {'id': "SCA", 'name': 'SCA'},
    {'id': "SCI", 'name': 'SCI'},
    {'id': "SCM", 'name': 'SCM'},
    {'id': "SCOP", 'name': 'SCOP'},
    {'id': "SCP", 'name': 'SCP'},
    {'id': "SCS", 'name': 'SCS'},
    {'id': "SELRL", 'name': 'SELRL'},
    {'id': "SNC", 'name': 'SNC'},
    {'id': "SAEM", 'name': 'SAEM'},
    {'id': "ETS", 'name': 'ETS'},
    {'id': "EIRL", 'name': 'EIRL'},
]

export const codeCompagnys = ['390001', '390282', '164180', '390007', '390006', '390321', '390134', '390008', '390009', '390010', '390012', '390219', '390014', '390017', '390018', '390019', '390020', '390021', '390022', '390023', '390024', '390025', '33276', '390071', '390334', '390027', '390028', '390029', '390030', '390031', '390004', '390032', '390033', '390034', '390036', '390037', '390038', '390039', '390040', '390041', '390280', '390043', '390044', '390045', '390046', '390273', '390047', '390048', '390049', '390050', '390058', '390051', '390052', '390053', '390054', '390055', '390056', '390057', '390059', '225173', '136746', '222340', '164180', '185466', '390060', '390061', '390062', '390063', '390064', '390065', '390066', '390067', '390069', '390070', '124246', '390071', '390072', '390073', '390074', '390075', '390076', '390078', '390079', '390080', '390081', '390082', '390083', '390084', '390085', '390086', '390087', '113179', '176971', '390089', '390090', '390103', '390091', '390092', '390093', '390094', '390096', '390097', '390007', '390100'];
