import React, {useEffect} from 'react';
import _ from "lodash";
import CirconstanceCard from "../../../../../../Commun/CirconstanceCard/CirconstanceCard";

const Circonstances = (props) => {
    const {circonstances, values, setFieldValue, query, postCasIda, cas_ida, rc} = props

    let totalA = 0
    let totalB = 0

    let formCirc = _.get(values, ['circonstances']) || {}
    _.map(formCirc, function(o, n) {
        if (_.startsWith(n, 'coche_a') && o === true){
            totalA++
        }
        if (_.startsWith(n, 'coche_b') && o === true){
            totalB++
        }
    });

    useEffect(() => {
        let arrayA = []
        let arrayB = []
        let obj = []
        let formCirconstances = _.get(values, ['circonstances']) || {}
        let responsabilite = rc
        let casIda = cas_ida

        _.map(formCirconstances, function(o, n) {
            if (_.startsWith(n, 'coche_a') && o === true) {
                arrayA.push(_.split(n, 'coche_a')[1]);
            }
        });
        arrayA.sort()
        _.map(arrayA, function(a, i) {
            if (i === 0) {
                obj.vehicule_a = a
            } else if (i === 1) {
                obj.vehicule_a_bis = a
            }
        })
        _.map(formCirconstances, function(o, n) {
            if (_.startsWith(n, 'coche_b') && o === true) {
                arrayB.push(_.split(n, 'coche_b')[1]);

            }
        });
        arrayB.sort()
        _.map(arrayB, function(a, i) {
            if (i === 0) {
                obj.vehicule_b = a
            } else if (i === 1) {
                obj.vehicule_b_bis = a
            }
        })

        postCasIda({
            query: query,
            vehicule_a: obj.vehicule_a,
            vehicule_b: obj.vehicule_b,
            vehicule_a_bis: obj.vehicule_a_bis,
            vehicule_b_bis: obj.vehicule_b_bis
        })

        if(values.codegta === undefined){
            if(rc === 100){
                casIda = 'HIZ'
            } else if(rc === 75) {
                casIda = 'HIW'
            } else if(rc === 50) {
                casIda = 'HIY'
            } else if(rc === 25) {
                casIda = 'HIV'
            } else if(rc === 0) {
                casIda = 'HIX'
            }
        }

        setFieldValue('manuel_resp', responsabilite)
        setFieldValue('manuel_ida', casIda)

    }, [values, postCasIda, query, rc, cas_ida])


    return (
        <div className={'bloc-white'}>
            <div className="col-md-12 col-sm-12">
                <h4 className={'fw-bold text-primary-light d-inline-block mb-2'}>Circonstances</h4>
                <p className={'mb-5'}>Attention de bien mettre les cases bien identiques à celles cochées sur le constat amiable.</p>

                <span className={'align-left d-inline-block w-50 fw-bold h5'}>Vous<br/>{values.conduct}</span>
                <span className={'align-right d-inline-block w-50 fw-bold h5'}>L'adversaire<br/>{values.adversaires[0].nom} {values.adversaires[0].prenom}</span>
            </div>

            <div className="col-md-12 col-sm-12 mb-3">
                {
                    circonstances.map((c, i) =>
                        <CirconstanceCard key={i}>
                            <label className={'float-left position-relative'}>
                                <input
                                    type="checkbox"
                                    name={`circonstancesvalues`}
                                    value={`coche_a${c.number}`}
                                    onChange={(e) => setFieldValue(`circonstances`, {...values.circonstances, ['coche_a'+c.number]: !values.circonstances[`coche_a${c.number}`] ?? false }) }
                                />
                            </label>
                            <p>
                                <b className={'h5 fw-bold'} style={{float: 'left'}}>{c.number}</b>
                                <span>{c.text}</span>
                                <b className={'h5 fw-bold'} style={{float: 'right'}}>{c.number}</b>
                            </p>
                            <label className={'float-right position-relative'}>
                                <input
                                    type="checkbox"
                                    name={`circonstancesvalues`}
                                    value={`coche_b${c.number}`}
                                    onChange={(e) => setFieldValue(`circonstances`, {...values.circonstances, ['coche_b'+c.number]: !values.circonstances[`coche_b${c.number}`] ?? false }) }
                                />
                            </label>
                        </CirconstanceCard>
                    )
                }
                <p className={'text-center font-weight-bold mt-4'} style={{fontSize: 20}}>
                    <span className={'float-left ms-3'}>{totalA}</span>
                    Total
                    <span className={'float-right me-3'}>{totalB}</span>
                </p>
            </div>
        </div>
    );
};

export default Circonstances;
