import {ACCIDENTS, FETCH_ACCIDENTS, setAccidents} from '../../../../actions/app/accidents/accidents.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../../actions/app/ui/ui.actions'

export const accidentsMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case FETCH_ACCIDENTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: ACCIDENTS}),
                setLoader({state: true, entity: ACCIDENTS})
            ])
            break

        case `${ACCIDENTS} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setAccidents({data}),
                setError({state: false, entity: ACCIDENTS}),
                setLoader({state: false, entity: ACCIDENTS})
            ])
            break

        case `${ACCIDENTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACCIDENTS}),
                setLoader({state: false, entity: ACCIDENTS})
            ])
            break

        default:
            break;
    }
    return null
}
