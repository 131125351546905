import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import Articles from "./Articles";
import {getArticlesLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {ARTICLES, fetchArticles} from "../../../../../redux/actions/app/articles/articles.actions";
import {getAllArticles} from "../../../../../redux/selectors/articles/articles.selectors";
import loader from "../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    return {
        query: getArticlesLink(state),
        loaded: getLoadingEntity(state, ARTICLES) === false,
        articles : getAllArticles(state)
    }
}

const mapDispatchToProps = {
    fetchArticles
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchArticles } = dispatchProps
    const { query, articles } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(articles) ? fetchArticles({query}) : null,
    }
}

const ArticlesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Articles)

export default ArticlesContainer
