import { createSelector } from 'reselect'
import _ from 'lodash'

const contractsSelector = state => state.contracts

export const getDataContracts = createSelector(
    contractsSelector,
    contracts => contracts.data
)

export const getDataFirstContractInProgress = createSelector(
    contractsSelector,
    contracts => { return _.find(contracts.data, (c) => { return c.situation.statutCode === "1" }) }
)

export const getDataFirstContract = createSelector(
    contractsSelector,
    contracts => contracts[0]?.data
)

export const getPrelevementContracts = createSelector(
    contractsSelector,
    contracts => {return _.find(contracts.data, 'prelevement')}
)

export const getHasContractTrottinette = createSelector(
    contractsSelector,
    contracts => _.find(contracts.data, (o) => {
        return o.vehicule.codeProduit === "7311_NVEI"
    })
)

export const getHasContractVAE = createSelector(
    contractsSelector,
    contracts => _.find(contracts.data, (o) => {
        return o.vehicule.codeProduit === "7311_VAE"
    })
)

export const getCheckIfOnlyTrottinetteContract = createSelector(
    contractsSelector,
    contracts => _.find(contracts.data, o => {
        return o.vehicule.codeProduit !== "7311_NVEI"
    })
)

export const getHasOtherContract = createSelector(
    contractsSelector,
    contracts => _.find(contracts.data, o => {
        return o.vehicule.codeProduit !== "7311_VAE" && o.vehicule.codeProduit !== "7311_NVEI"
    })
)

export const getDateCV = createSelector(
    contractsSelector,
    contracts => _.reject(contracts.data, ['dernierCourrierCV', null])
)
