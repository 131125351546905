import React from 'react';
import './contact.scss'
import {Link} from "react-router-dom";

const Contact = () => {
    return (
        <div className={'col-xl-5 col-md-6 col-xs-12 pe-0 offset-xl-1'}>
            <div className={'bloc-white contact'}>
                <h2 className={'title-bloc'}>Contact</h2>
                <p>Une question ? Besoin d'un renseignement ? Nos équipes sont là pour vous aider.</p>
                <p>Contactez nous par téléphone au <strong>02 47 51 07 07 puis tapez "1"</strong>, du lundi au vendredi, de 9h00 à 18h00.</p>
                <p>Vous préferez nous contacter par mail ? Envoyez nous votre demande à l'adresse suivante :</p>
                <a href="mailto:moncontrat@april-moto.com" className={'mail'}>moncontrat@april-moto.com</a>
                <div className={'mt-4 pb-5'}>
                    <Link className={'btn btn-primary full'} to={'/contact'}>Contactez-nous</Link>
                </div>
            </div>
        </div>
    );
};

export default Contact;
