import {connect} from 'react-redux'
import AjoutReparateur from "./AjoutReparateur";
import {
    POST_ACCIDENT_REPAIRER,
    postAccidentRepairer
} from "../../../../../../../redux/actions/app/accidents/accidents.actions";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import _ from "lodash";
import {
    getAccidentRepairerUpdateLink,
} from "../../../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = (state, ownProps) => {
    const { id, ids } = ownProps.match.params

    return {
        query: _.replace(_.replace(getAccidentRepairerUpdateLink(state), '{id}', id), '{ids}', ids),
        redirect_url: _.replace(_.replace('/mes-sinistres/contrat/:id/sinistre/:ids', ':id', id), ':ids', ids),
        loading: getLoadingEntity(state, POST_ACCIDENT_REPAIRER),
        initialValues: {
            reparateur: '',
            cpreparateur: '',
            visit: ''
        }
    }
}

const mapDispatchToProps = {
    postAccidentRepairer
}

const AjoutReparateurContainer = connect(mapStateToProps, mapDispatchToProps)(AjoutReparateur)

export default AjoutReparateurContainer