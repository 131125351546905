import React from 'react';
import {Link, Route} from "react-router-dom";
import _ from 'lodash'
import ListeContractsContainer from "./ListeContracts/ListeContractsContainer";
import ContractContainer from "./Contract/ContractContainer";

const Contracts = (props) => {
    const { police, id } = props
    const params =  _.split(window.location.pathname, '/')

    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}>
                    <Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-contrats'} className={'text-decoration-none'}>Mes contrats</Link>{params.length > 4 && police && <Link to={`/mes-contrats/mon-contrat/${id}`} className={'text-decoration-none'}> - Mon contrat {police}</Link>}
                </h6>
            </div>
            <Route path={'/mes-contrats/mon-contrat/:id'} component={ContractContainer} />
            <Route exact path={'/mes-contrats'} component={ListeContractsContainer} />
        </div>
    );
};

export default Contracts;
