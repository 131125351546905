import {SET_CONTRACT, SET_CONTRACT_THIRD_PARTY_PAYER} from '../../actions/app/contracts/contracts.actions'

export const contractReducer = (contract = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_CONTRACT) {
        return payload
    } else if (action.type === SET_CONTRACT_THIRD_PARTY_PAYER) {
        return {data: {...contract?.data, 'tiers_payeur': payload.data}}
    }else {
        return contract
    }
}
