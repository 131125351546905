export const PARRAINAGES = '[Parrainages]'

export const FETCH_PARRAINAGES = `${PARRAINAGES} Get`
export const SET_PARRAINAGES = `${PARRAINAGES} Set`
export const POST_PARRAINAGES = `${PARRAINAGES} Post`

export const fetchParrainages = ({query}) => ({
    type: FETCH_PARRAINAGES,
    payload: {
        data: query
    }
})

export const setParrainages = ({data}) => ({
    type: SET_PARRAINAGES,
    payload: {
        data
    }
})

export const postParrainages = ({query, form, successFunction}) => ({
    type: POST_PARRAINAGES,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})
