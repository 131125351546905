import React from 'react';
import Text from "../../../../../Commun/Inputs/Text/Text";
import {Formik} from "formik";
import SmallSpinner from "../../../../../Commun/Spinners/SmallSpinner";

const ChangeMotDePasse = (props) => {
    const {initialValues, postUpdatePassword, query, loading, validate} = props

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je modifie mon mot de passe</h1>
            </div>

            <Formik validate={validate} initialValues={initialValues} onSubmit={values => postUpdatePassword({query, form: values, successFunction: () => props.history.push('/mon-profil')})}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col col-md-6">
                                <div className={'mb-4 mt-4'}>
                                    <Text
                                        type={'password'}
                                        name="old_password"
                                        label={'Ancien mot de passe'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.old_password}
                                        error={errors.old_password}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6">
                                <div className={'mb-4 mt-4'}>
                                    <Text
                                        type={'password'}
                                        name="password"
                                        label={'Nouveau mot de passe'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        error={errors.password}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className={'mb-4 mt-4'}>
                                    <Text
                                        type={'password'}
                                        name="password_confirmation"
                                        label={'Confirmez votre mot de passe'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password_confirmation}
                                        error={errors.password_confirmation}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-grid gap-2 mt-4">
                            {
                                loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                    Je valide ma modification de mot de passe
                                </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default ChangeMotDePasse;
