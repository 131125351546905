import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import Contracts from "./Contracts";
import {getContractsLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACTS, fetchContracts} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getDataContracts} from "../../../../../redux/selectors/contracts/contracts.selectors";
import loader from "../../../../Commun/Spinners/Loader/Loader";
import {getContractId, getNumeroPoliceContract} from "../../../../../redux/selectors/contracts/contract.selector";

const mapStateToProps = state => {
    return {
        query: getContractsLink(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        contracts : getDataContracts(state),
        police: getNumeroPoliceContract(state),
        id: getContractId(state)
    }
}

const mapDispatchToProps = {
    fetchContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchContracts } = dispatchProps
    const { query, contracts } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query}) : null,
    }
}

const ContractsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Contracts)

export default ContractsContainer
