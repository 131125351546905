export const THIRD_PARTY_PAYER = '[Tiers Payeur]';
export const POST_UPDATE_THIRD_PARTY_PAYER = `${THIRD_PARTY_PAYER} Update`
export const POST_DELETE_THIRD_PARTY_PAYER = `${THIRD_PARTY_PAYER} Delete`

export const postUpdateTiersPayeur = ({query, form, successFunction}) => ({
    type: POST_UPDATE_THIRD_PARTY_PAYER,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})

export const postDeleteTiersPayeur = ({query}) => ({
    type: POST_DELETE_THIRD_PARTY_PAYER,
    payload: {
        data: query
    }
})