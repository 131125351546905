export const ACCIDENTS = '[Accidents]'
export const ACCIDENT = '[Accident]'
export const ACCIDENT_FORM = '[Accident Formulaire]'
export const DOCUMENT_NEEDED = '[Documents Needed Accident]'
export const ACCIDENT_REPAIRER = '[Accident reparateur]'

export const FETCH_ACCIDENTS = `${ACCIDENTS} Fetch`
export const SET_ACCIDENTS = `${ACCIDENTS} Set`

export const FETCH_ACCIDENT = `${ACCIDENT} Fetch`
export const SET_ACCIDENT = `${ACCIDENT} Set`

export const POST_ACCIDENT = `${ACCIDENT_FORM} Post`
export const GET_DOCUMENT_NEEDED = `${DOCUMENT_NEEDED} Get`

export const POST_ACCIDENT_REPAIRER = `${ACCIDENT_REPAIRER} Post`

export const fetchAccidents = ({query}) => ({
    type: FETCH_ACCIDENTS,
    payload: {
        data: query
    }
})

export const setAccidents = ({data}) => ({
    type: SET_ACCIDENTS,
    payload: {data},
    meta: {
        entity: ACCIDENTS
    }
})

export const fetchAccident = ({query}) => ({
    type: FETCH_ACCIDENT,
    payload: {data: query}
})

export const setAccident = ({data}) => ({
    type: SET_ACCIDENT,
    payload: {data}
})

export const postAccident = ({form, query, successFunction}) => ({
    type: POST_ACCIDENT,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    },
    meta: {
        'analytics': {
            'event': 'click',
            'actionGoogle': 'clickButton',
            'libelle': 'Déclaration de Sinistre',
        },
        entity: POST_ACCIDENT
    }
})

export const getDocumentsAccident = ({query}) => ({
    type: GET_DOCUMENT_NEEDED,
    payload:{
        data: query
    }
})

export const postAccidentRepairer = ({form, query, successFunction}) => ({
    type: POST_ACCIDENT_REPAIRER,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})