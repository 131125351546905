import {connect} from 'react-redux'
import {compose} from 'redux'
import Paiements from "./Paiements";
import loader from "../../../../Commun/Spinners/Loader/Loader";
import {
    FETCH_RECEIPTS_UNPAID,
    fetchReceiptsUnpaid
} from "../../../../../redux/actions/app/payments/receiptsUnpaid/receiptsUnpaid.actions";
import {receiptsLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getDataReceiptsUnpaid} from "../../../../../redux/selectors/payments/receiptsUnpaid/receiptsUnpaid.selectors";

const mapStateToProps = state => {
    return {
        query: `${receiptsLink(state)}?status=all`,
        loaded: getLoadingEntity(state, FETCH_RECEIPTS_UNPAID) === false,
        receipts: getDataReceiptsUnpaid(state),
    }
}

const mapDispatchToProps = {
    fetchReceiptsUnpaid
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query } = stateProps
    const { fetchReceiptsUnpaid } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchReceiptsUnpaid({query: query})
    }
}

const PaiementsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Paiements)

export default PaiementsContainer
