import React from 'react';
import {Route} from "react-router-dom";
import DemenagementContainer from "./Demenagement/DemenagementContainer";
import DetailContractContainer from './DetailContract/DetailContractContainer'
import ChangeTiersPayeurContainer from "./ChangeTiersPayeur/ChangeTiersPayeurContainer";
import FractionnementContainer from "./Fractionnement/FractionnementContainer";
import HivernageContainer from "./Hivernage/HivernageContainer.js";
import FlotteDetailContainer from "./DetailContract/FlotteDetail/FlotteDetailContainer.js";
import SortieVehiculesContainer from "./DetailContract/Flotte/SortieVehicules/SortieVehiculesContainer.js";

const Contract = ({isWakam}) => {
    return (
        <div>
            <Route exact path={'/mes-contrats/mon-contrat/:id/mon-tiers-payeur'} component={ChangeTiersPayeurContainer} />
            {!isWakam && <Route exact path={'/mes-contrats/mon-contrat/:id/je-demenage'} component={DemenagementContainer} /> }
            <Route exact path={'/mes-contrats/mon-contrat/:id/je-passe-en-prelevement'} component={FractionnementContainer} />
            {!isWakam && <Route exact path={'/mes-contrats/mon-contrat/:id/hivernage'} component={HivernageContainer} /> }
            <Route exact path={'/mes-contrats/mon-contrat/:id/'} component={DetailContractContainer} />
            <Route exact path={'/mes-contrats/mon-contrat/:id/sortie-vehicules'} component={SortieVehiculesContainer} />
            <Route exact path={'/mes-contrats/mon-contrat/:id/vehicule/:vehiculeId'} component={FlotteDetailContainer} />
        </div>
    );
};

export default Contract;
