import {POST_REQUEST_CODE} from '../../../../actions/app/customer/customer.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../../actions/core/api/api.actions'
import {setCodePromoUser, setError, setLoader} from '../../../../actions/app/ui/ui.actions'
import {setNotification} from '../../../../actions/core/notifications/notifications.actions'


export const customerRequestCodeMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_REQUEST_CODE:
            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: POST_REQUEST_CODE}),
                setLoader({state: true, entity: POST_REQUEST_CODE})
            ])
            break

        case `${POST_REQUEST_CODE} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_REQUEST_CODE}),
                setLoader({state: false, entity: POST_REQUEST_CODE}),
                setCodePromoUser({data: payload.data.reason})
            ])
            break

        case `${POST_REQUEST_CODE} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_REQUEST_CODE}),
                setLoader({state: false, entity: POST_REQUEST_CODE}),
                setNotification({
                    entity: POST_REQUEST_CODE,
                    html: '<p>Une erreur est survenue : ' + payload.data.response.data.reason + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        default:
            break
    }
    return null
}
