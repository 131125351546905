import {connect} from 'react-redux'
import SituationContrat from "./SituationContrat";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACT} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {
    getAvantageContract,
    getContractCRM,
    getContractDateAffaireNouvelle,
    getContractEcheance,
    getContractFractionnementLabel, getContractId,
    getContractStatut,
    getContractStatutCode,
    getIsNVEIContract,
    getIsVAEContract, getIsWakamContract,
    getNumeroPoliceContract, getPrelevementContract,
    getSouscripteurNClientContract,
    getSouscripteurNomContract
} from "../../../../../../redux/selectors/contracts/contract.selector";
import {compose} from "redux";
import loader from "../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    return {
        loaded: getLoadingEntity(state, CONTRACT) === false,
        isWakam: getIsWakamContract(state),
        souscripteur: getSouscripteurNomContract(state),
        num_client: getSouscripteurNClientContract(state),
        num_police: getNumeroPoliceContract(state),
        crm: getContractCRM(state),
        isVAE: getIsVAEContract(state),
        isNVEI: getIsNVEIContract(state),
        date_souscription: getContractDateAffaireNouvelle(state),
        echeance: getContractEcheance(state),
        fractionnement: getContractFractionnementLabel(state),
        statut_code_contrat: getContractStatutCode(state),
        statut_contrat: getContractStatut(state),
        id_contrat: getContractId(state),
        isPrelevement: getPrelevementContract(state),
        avantage: getAvantageContract(state)
    }
}
const SituationContratContainer = compose(
    connect(mapStateToProps),
    loader()
)(SituationContrat)


export default SituationContratContainer