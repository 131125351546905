import {CLEAR_CONTRACTS, SET_CONTRACTS} from '../../actions/app/contracts/contracts.actions'

export const contractsReducer = (contracts = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_CONTRACTS) {
        return payload
    } else if (action.type === CLEAR_CONTRACTS) {
        return {}
    } else {
        return contracts
    }
}
