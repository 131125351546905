import {connect} from 'react-redux'
import {
    getFaqAccident,
    getFaqContract,
    getFaqDocuments,
    getFaqOther, getFaqParrainages, getFaqTrottinette, getFaqVAE
} from '../../../../../../redux/selectors/faq/faq.selectors'
import ListeQuestions from "./ListeQuestions";
import {
    getDataContracts,
    getHasContractTrottinette,
    getHasContractVAE, getHasOtherContract
} from "../../../../../../redux/selectors/contracts/contracts.selectors";
import {getContractsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACTS, fetchContracts} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import {compose} from "redux";
import loader from "../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    return {
        query: getContractsLink(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        contracts : getDataContracts(state),
        listQuestions: {
            contract: getFaqContract(state),
            accident: getFaqAccident(state),
            other: getFaqOther(state),
            documents: getFaqDocuments(state),
            trottinette_faq: getFaqTrottinette(state),
            vae_faq: getFaqVAE(state),
            parrainages: getFaqParrainages(state)
        },
        hasTrottinette: getHasContractTrottinette(state),
        hasVAE: getHasContractVAE(state),
        hasOtherContract: getHasOtherContract(state)
    }
}

const mapDispatchToProps = {
    fetchContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchContracts } = dispatchProps
    const { query, contracts } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query}) : null,
    }
}

const ListeQuestionsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ListeQuestions)

export default ListeQuestionsContainer
