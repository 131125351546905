import {FETCH_WHITELABEL, setWhitelabel, WHITELABEL} from '../../../actions/app/whitelabel/whitelabel.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorResponse, setLoader} from '../../../actions/app/ui/ui.actions'
import {LightenDarkenColor} from "../../../../utils/functions";

export const whitelabelMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case FETCH_WHITELABEL:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: WHITELABEL}),
                setLoader({state: true, entity: WHITELABEL})
            ])
            break

        case `${WHITELABEL} ${API_SUCCESS}`:
            const {style_color1, style_color2} = action.payload.data

            const whitelabel = {
                'primary': style_color1,
                'secondary': style_color2,

                get 'primary-dark'() {
                    return LightenDarkenColor(this.primary, -0.2);
                },
                get 'primary-light'() {
                    return LightenDarkenColor(this.primary, 0.2);
                },
                get 'secondary-dark'() {
                    return LightenDarkenColor(this.secondary, -0.2);
                },
                get 'secondary-light'() {
                    return LightenDarkenColor(this.secondary, 0.2);
                }
            }

            for (let [key, value] of Object.entries(whitelabel)) {
                document.documentElement.style.setProperty(`--${key}`, value)
            }

            next([
                setWhitelabel({whitelabel: action.payload.data}),
                setError({state: false, entity: WHITELABEL}),
                setLoader({state: false, entity: WHITELABEL})
            ])
            break

        case `${WHITELABEL} ${API_ERROR}`:
            next([
                setError({state: true, entity: WHITELABEL}),
                setErrorResponse({entity: WHITELABEL, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: WHITELABEL})
            ])
            break

        default:
            break
    }
    return null
}
