import {connect} from 'react-redux'
import PrivateRoute from './PrivateRoute'
import {getUserLink} from '../../../redux/selectors/links/links.selectors'
import {bindActionCreators, compose} from 'redux'
import loader from '../../Commun/Spinners/Loader/Loader'
import {fetchUser, USER} from '../../../redux/actions/app/user/user.actions'
import {localStorageAMO} from '../../../redux/selectors/localStorage/localStorage'
import {getLogin, getErrorEntity, getLoading, getLoadingEntity} from '../../../redux/selectors/ui/ui.selectors'
import LargeSpinnerContainer from "../../Commun/Spinners/LargeSpinner/LargeSpinnerContainer";

const mapStateToProps = state => {
    return {
        login: getLogin(state),
        fail: getErrorEntity(state, USER),
        query: getUserLink(state),
        token: localStorageAMO && localStorageAMO.token ? localStorageAMO.token : null,
        loaded: getLoading(state) !== undefined && !getLoadingEntity(state, USER)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchUser: ({query}) => fetchUser({query: `${query}?refresh=true`}),
}, dispatch)

const PrivateRouteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader({LoadingIndicator: LargeSpinnerContainer})
)(PrivateRoute)

export default PrivateRouteContainer
