export const WHITELABEL = '[Whitelabel]'

export const FETCH_WHITELABEL = `${WHITELABEL} Fetch`
export const SET_WHITELABEL = `${WHITELABEL} Set`

export const fetchWhitelabel = ({query}) => ({
    type: FETCH_WHITELABEL,
    payload: {
        data: query
    }
})

export const setWhitelabel = ({whitelabel}) => ({
    type: SET_WHITELABEL,
    payload: {
        data: whitelabel
    }
})
