import Autocomplete from "../Autocomplete/Autocomplete";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {getCitiesFromFrance} from "../../../../utils/functions";

export default function AutoCompleteBirth({ country, ...props}) {
    const [listVilles, setListVilles] = useState([])
    const [queries, setQueries] = useState([]);
    const [query, setQuery] = useState('');

    async function fetchCites(value) {
        let cities = await getCitiesFromFrance(value)
        if (!Array.isArray(cities)) {
            return
        }
        let list = _.orderBy(cities, ['sInsee'], ['asc'])

        list = cities.map(c => ({
            value: c.sBirthplace,
            label: c.sBirthplace
        }))

        const newVilles = _.uniqBy([...listVilles, ...list], 'label')
        setListVilles(newVilles)
    }

    useEffect(() => {
        if (query && query.length >= 2 && country === 'FRA' && !queries.includes(query)) {
            fetchCites(query)
            setQueries([...queries, query])
        }
    }, [query, country]);

    return (
        <Autocomplete
            data={listVilles}
            setQuery={setQuery}
            {...props}
        />
    )
}

