import {connect} from 'react-redux'
import Cotisations from "./Cotisations";
import {getDataReceiptsUnpaid} from "../../../../../../redux/selectors/payments/receiptsUnpaid/receiptsUnpaid.selectors";


const mapStateToProps = state => {
    return {
        receipts: getDataReceiptsUnpaid(state)
    }
}

const CotisationsContainer = connect(mapStateToProps)(Cotisations)

export default CotisationsContainer
