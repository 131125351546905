import React, {useState, useEffect} from 'react';
import { FieldArray } from 'formik'
import {getCompagnys} from "../../../../../../../utils/functions";
import Text from "../../../../../../Commun/Inputs/Text/Text";
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import PostalCodeCity from "../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity";
import Autocomplete from "../../../../../../Commun/Inputs/Autocomplete/Autocomplete";
import _ from 'lodash'
import FilteredAutocomplete from "../../../../../../Commun/FilteredAutocomplete/FilteredAutocomplete.jsx";
import {codeCompagnys} from "../../../../../../../utils/optionsSelect.js";

const Adversaires = (props) => {
    const { values, errors, setFieldValue, handleChange } = props
    const [compagnys, setCompagnys] = useState([])

    useEffect(() => {
        (async function fetchCompagnys(){
            let compagnys = await getCompagnys()

            const listcompanys = compagnys.map(company => ({
                value: company.id,
                codegta: company.codegta,
                label: company.name + ' - ' + company.adresse + ' - ' + company.codePostal + ' ' + company.ville
            }))

            setCompagnys(listcompanys)
        })()
    }, [])

    useEffect(() => {
        if (Number(values.nb_vehicules) > 1){
            values.adversaires.push({})
        }
    }, [values.nb_vehicules])

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Adversaire(s)</h4>
            </div>

            <FieldArray
                name={'adversaires'}
                render={arrayHelpers => (
                    <div>
                        {
                            values.adversaires.map((adversaire, i) => (
                                <div key={i} className={'row'}>
                                    <div>
                                        <p className="h4 mb-5 d-inline-block">
                                            Partie adverse n°{i + 1}
                                        </p>
                                        {/*{values.adversaires.length === (i+1) && <span className={'ms-3 btn btn-secondary btn-sm py-2 px-3'}>
                                            <i
                                                className={'bi bi-trash h5'}
                                                onClick={() => arrayHelpers.remove(i)}
                                            />
                                        </span>}*/}
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <Text
                                            name={`adversaires.${i}.immat`}
                                            onChange={handleChange}
                                            label='Immatriculation'
                                            value={values.adversaires[i].immat}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <Text
                                            name={`adversaires.${i}.police`}
                                            onChange={handleChange}
                                            label="Numéro de contrat d'assurance"
                                            value={values.adversaires[i].police}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <FilteredAutocomplete
                                            name={`adversaires.${i}.cie`}
                                            label="Compagnie d'assurance"
                                            labelothervalue="Autre compagnie"
                                            data={compagnys}
                                            datafiltered={_.filter(compagnys, c => _.includes(codeCompagnys, c.value))}
                                            setFieldValue={setFieldValue}
                                            onChange={(value) => handleChange('codegta', _.find(compagnys, ['value', value]).codegta)}
                                        />

                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <RadioGroup
                                            label={"Civilité"}
                                            name={`adversaires.${i}.titre`}
                                            setFieldValue={setFieldValue}
                                            options={[
                                                {value: "M", label:"Monsieur"},
                                                {value: "Mme", label:"Madame"},
                                                {value: "Societe", label:"Société"}
                                            ]}
                                            value={values.adversaires[i].titre}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <Text
                                            name={`adversaires.${i}.nom`}
                                            onChange={handleChange}
                                            label="Nom"
                                            value={values.adversaires[i].nom}
                                        />
                                    </div>

                                    {
                                        values.adversaires[i].titre !== 'Societe' && <div className="mb-5 col-xl-6">
                                            <Text
                                                name={`adversaires.${i}.prenom`}
                                                onChange={handleChange}
                                                label="Prénom"
                                                value={values.adversaires[i].prenom}
                                            />
                                        </div>
                                    }

                                    <div className="mb-5 col-xl-6">
                                        <RadioGroup
                                            label={"Y-a-t-il des blessés dans cette partie adverse ?"}
                                            name={`adversaires.${i}.blesse`}
                                            setFieldValue={setFieldValue}
                                            options={[
                                                {value: "T", label:"Oui"},
                                                {value: "F", label:"Non"}
                                            ]}
                                            value={values.adversaires[i].blesse}
                                        />
                                    </div>

                                    <div className="mb-5 col-xl-6">
                                        <RadioGroup
                                            label={"Avez-vous son adresse ?"}
                                            name={`adversaires.${i}.adresse`}
                                            setFieldValue={setFieldValue}
                                            options={[
                                                {value: "T", label:"Oui"},
                                                {value: "F", label:"Non"}
                                            ]}
                                            value={values.adversaires[i].adresse}
                                        />
                                    </div>

                                    {
                                        values.adversaires[i].adresse === 'T' && <div className={"row"}>
                                            <div className="mb-5 col-xl-6">
                                                <Text
                                                    name={`adversaires.${i}.adresse1`}
                                                    onChange={handleChange}
                                                    label="Adresse"
                                                    value={values.adversaires[i].adresse1}
                                                />
                                            </div>

                                            <div className="mb-5 col-xl-6">
                                                <PostalCodeCity
                                                    label={'Ville de résidence'}
                                                    cp={`adversaires.${i}.cp`}
                                                    ville={`adversaires.${i}.ville`}
                                                    value={values.adversaires[i].ville}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                        }
{/*

                        <span className={'btn btn-primary'} onClick={() => arrayHelpers.push({})}>Ajouter une partie adverse</span>
*/}

                    </div>
                )}
            />

        </div>
    );
};

export default Adversaires;
