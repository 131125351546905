import React, {useEffect, useState} from 'react'
import './memo.scss';
import Icons from "../../../../../Commun/Icon/Icon";
import RequestDocumentContainer from "../../Questions/ListeQuestions/Components/RequestDocument/RequestDocumentContainer";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import {PASS_URL} from "../../../../../../constants/constants.js";
import * as Sentry from "@sentry/react";
import {localStorageAMO} from "../../../../../../redux/selectors/localStorage/localStorage.js";
import APILink from "../../../../../Commun/APILink/APILink.jsx";

const Memo = (props) => {
    const {situation, immatriculation, query, contract} = props
    const [quittances, setQuittances] = useState([])

    useEffect(() => {
        const queryReceipts = _.replace(query, '{id}', contract.id)

        axios({
            url: queryReceipts,
            method: 'get',
            headers: {
                authorization: localStorageAMO.token
            }
        }).then(response => {
            setQuittances(response.data.data)
            }).catch(error => {
            setQuittances([])
        })
    }, [contract])

    return (
        <div className={'col-12 demande-cv'}>
            <div className={'content'}>
                <div className={'title-icon mb-5'}>
                    <Icons icon={'documents'} fontSize={65} />
                    <h2 className={'title mb-4 fw-bold'}>1er Avril 2024 : fin de la carte verte&nbsp;!</h2>
                </div>
                <p>Dorénavant, les forces de l’ordre s'appuieront uniquement sur le Fichier des Véhicules Assurés pour vérifier la couverture d'assurance.</p>
                <p>Pour retrouver les informations essentielles de votre contrat, vous disposez d'un document appelé "Mémo Véhicule Assuré".</p>
                {!_.isEmpty(immatriculation) && quittances.length > 0 && !_.isEmpty(quittances[0].memo_link) && <div className="text-center">
                    <APILink className={"btn btn-primary max-rounded d-block d-lg-inline-block m-3"} href={quittances[0].memo_link}>
                        Télécharger mon Mémo Véhicule Assuré
                    </APILink>
                </div>}

                { quittances.length > 0 && !_.isEmpty(quittances[0].attestation_assurance_link) && <div className="text-center mt-3">
                    <APILink className={"btn btn-primary max-rounded d-block d-lg-inline-block m-3"} href={quittances[0].attestation_assurance_link}>
                        Télécharger mon Attestation assurance
                    </APILink>
                </div>}
            </div>
        </div>
    )
}

export default Memo
