export const FAQ = '[Faq]'

export const FETCH_FAQ = `${FAQ} Fetch`
export const SET_FAQ = `${FAQ} Set`
export const EXPAND_FAQ = `${FAQ} Expand`

export const fetchFaq = ({query}) => ({
    type: FETCH_FAQ,
    payload: {
        data: query
    }
})

export const setFaq = ({data}) => ({
    type: SET_FAQ,
    payload: {
        data,
    },
    meta:{
        entity: FAQ
    }
})

export const expandQuestion = ({question, category}) => ({
    type: EXPAND_FAQ,
    meta:{
        'analytics': {
            'event': 'click',
            'actionGoogle': 'openQuestion',
            'libelle': question.question,
            'categorie': category ? category : question.category.title,
            'unique': true,
        },
        entity: FAQ
    }
})
