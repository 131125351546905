import React, {useEffect, useState} from 'react';
import './header.scss'
import {NavLink, Link, useLocation} from "react-router-dom";
import {HOST_MB} from "../../../../constants/constants";

const HeaderMobile = (props) => {
    const { postLogout, logo, isWakam, contracts } = props
    const [active, setActive] = useState(false)
    const location = useLocation();

    useEffect(() => {
        setActive(false)
    }, [location]);

    return (
        <div className={'header mobile d-md-block d-lg-block d-xl-none d-xs-block d-sm-block'}>
            <nav className="navbar navbar-expand-xl navbar-light">
                <Link to={'/'} className={"navbar-brand"}><img src={logo} alt={'Logo'} width={170} style={{marginTop: '5px'}} /></Link>
                <button className="navbar-toggler" type="button">
                    <span className={`${active ? 'icon-close ' : 'navbar-toggler-icon'}`} onClick={() => setActive(!active)}>&nbsp;</span>
                </button>
                <div className={`${active && 'show'} collapse navbar-collapse`} id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item"><NavLink to={'/mes-contrats'}>Mes contrats</NavLink></li>
                        <li className="nav-item"><NavLink to={'/mes-paiements'}>Mes paiements</NavLink></li>
                        <li className="nav-item"><NavLink to={'/mes-sinistres'}>Mes sinistres</NavLink></li>
                        { HOST_MB === 43397 && <li className="nav-item"><NavLink to={'/mes-questions'}>Mes questions</NavLink></li> }
                        { HOST_MB === 43397 && !isWakam && <li className="nav-item"><NavLink to={'/mes-avantages'}>Mes avantages</NavLink></li> }
                        {(contracts && contracts[0].vehicule.codeProduit && contracts[0].vehicule.codeProduit !== 'WAKAM_LIV') &&
                            <li className="nav-item"><NavLink to={'/mes-documents'}>Mes documents</NavLink></li>
                        }
                        { HOST_MB === 43397 && <li className="nav-item"><NavLink to={'/mes-parrainages'}>Mes parrainages</NavLink></li>}
                        { HOST_MB === 43397 && <li className="nav-item"><NavLink to={'/resilier'}>Résilier</NavLink></li> }
                        <li className="nav-item"><NavLink to={'/mon-profil'}>Mon profil</NavLink></li>
                        <li className="nav-item"><button onClick={() => postLogout()} className={'logout btn btn-link'}>Se déconnecter</button></li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default HeaderMobile;
