import React from 'react';
import _ from "lodash";
import moment from "moment";

const DetailSinistreDocuments = (props) => {
    const {documents, situationCode, upload_app_link} = props

    return (
        <div className="bloc-white">
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block'}>Mes documents</h4>
            </div>

            {
                _.orderBy(documents, ['recu', 'traite']).map((d,i) => (
                    <div key={d.code}>
                        {i > 0 && <hr/>}
                        <div className="row">
                            <div className="col">
                                <div className={'mb-1 d-inline-block'}>
                                    <p className={'m-0 fw-bold'}>{d.libelle}</p>
                                    {d.commentaire ? <p className={'m-0'}><small className={'text-secondary'}>{d.commentaire}</small></p> : ''}
                                </div>
                                <p className={'float-right m-0'}>
                                    {
                                        !d.recu ?
                                            <span className={'badge bg-danger m-0 fw-bold'}>Non reçu</span>
                                            : d.traite ?
                                            <span className={'badge bg-success m-0 fw-bold'}>Validé {d.dateTraitement ? `le ${moment(d.dateTraitement).format('L')}` : ''}</span>
                                            : <span className={'badge bg-warning m-0 fw-bold'}>En cours de traitement</span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            }
            {_.isEmpty(documents) && <p>Pas de documents.</p>}

            { situationCode !== "2" && situationCode !== "8" && _.find(documents, ['recu', false]) && upload_app_link ?
                <div className="text-center mt-4">
                    <a href={upload_app_link} target={'_blank'}>J'envoie mes documents</a>
                </div> : ''
            }

        </div>
    );
};

export default DetailSinistreDocuments;