import {SET_DOCUMENTS} from '../../actions/app/documents/documents.actions'

export const documentsReducer = (documents = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_DOCUMENTS) {
        return payload
    } else{
        return documents
    }
}
