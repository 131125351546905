import React from 'react';
import './small-spinner.scss'

const SmallSpinner = (props) => {
    return (
        <div id={'spinner'} {...props}>
            <div className={'left'}>
                <i className={'icon'} />
            </div>
            <div className={'right'}>
                <i className={'icon'} />
            </div>
        </div>
    );
};

export default SmallSpinner;
