import React from 'react'
import './client.scss';
import {Link} from "react-router-dom";

const InfoClient = (props) => {
    const {user: {nom, prenom, adresse, cp, ville, telephone, email}} = props
    return (
        <div className={'col-12 mt-5 mb-5'}>
            <div className={'content'}>
                <h2 className={'title mb-4 fw-bold'}>Mes informations <strong>client</strong></h2>
                <p><strong>Nom : </strong>{nom}</p>
                { prenom && <p><strong>Prénom : </strong>{prenom}</p> }
                <p><strong>Adresse : </strong>{adresse}</p>
                <p><strong>Code postal - Ville : </strong>{cp} {ville}</p>
                <p><strong>Téléphone : </strong>{telephone}</p>
                <p><strong>Email : </strong>{email}</p>

                <Link className={'btn btn-secondary full mt-4'} to={'/mon-profil'}>Voir mon profil</Link>
            </div>
        </div>
    )
}

export default InfoClient
