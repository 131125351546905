import React, {Fragment, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Formik} from "formik";
import DeclarationContainer from "./Declaration/DeclarationContainer";
import SelectContractContainer from "../../../../../Commun/SelectContract/SelectContractContainer";
import ReparationContainer from "./Reparation/ReparationContainer";
import Adversaires from "./Adversaires/Adversaires";
import Blesses from "./Blesses/Blesses";
import Temoins from "./Temoins/Temoins";
import SmallSpinner from "../../../../../Commun/Spinners/SmallSpinner";
import _ from "lodash";
import moment from "moment";
import Constat from "./Constat/Constat";
import Police from "./Police/Police";
import ChocsContainer from "./Chocs/ChocsContainer";
import CirconstancesContainer from "./Circonstances/CirconstancesContainer";
import SelectionSinistre from "./SelectionSinistre/SelectionSinistre";
import DeclarationReparateur from "./DeclarationReparateur/DeclarationReparateur";
import DeclarationVol from "./DeclarationVol/DeclarationVol";
import DecCirconstancieeContainer from "./DecCirconstanciee/DecCirconstancieeContainer.js";

const DeclarationSinistre = (props) => {
    const { initialValues, validate, postAccident, query, loading, isVAE, contract, sinistres, garanties } = props
    const [firstCheck, setFirstCheck] = useState(false)
    const [alert, setAlert] = useState(false)

    const checkFirstStep = (e, values) => {
        if(contract.situation.dateFin !== "" && moment(contract.situation.dateFin) < values.datesurvorigin){
            e.preventDefault()
            return setAlert("La date de survenance ne peut pas être antérieure à la date de fin de contrat.")
        } else {
            return setFirstCheck(true)
        }
    }

    if (contract.flotte && _.isEmpty(garanties)){
        props.history.push(`/mes-contrats/mon-contrat/${contract.id}`)
    }

    return (
        <div className={'my-3'}>
            <SelectContractContainer>
                <div className={'mt-5'}>
                    <h1 className={'fw-bold'}><Link to={'/mes-sinistres'}><i className="bi bi-arrow-left-circle me-2" /></Link> Je déclare un sinistre</h1>
                </div>
                {(isVAE || query === null)
                    ? <div className={'mt-5 text-grey'}>Attention la déclaration de sinistre pour ce contrat n'est pas disponible.</div>
                    : <Formik
                        initialValues={initialValues}
                        onSubmit={values => postAccident({form: values, query, successFunction: () => values.type_sinistre === 'accident' ? props.history.push('/mes-sinistres/je-declare-un-sinistre/rdv') : props.history.push('/')})}
                        validate={validate}
                    >
                            {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                                <form onSubmit={handleSubmit}>
                                    {
                                        !values.type_sinistre && <SelectionSinistre
                                            setFieldValue={setFieldValue}
                                        />
                                    }

                                    {
                                        values.type_sinistre && <>
                                        <DeclarationContainer
                                            errors={errors}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            handleChange={handleChange}
                                            setFirstCheck={setFirstCheck}
                                        />
                                        {
                                            (values.catsinorigin === '10.0' ||
                                            values.catsinorigin === '105.1' ||
                                            values.catsinorigin === '9.0' ||
                                            values.catsinorigin === '105.2') &&
                                                <DeclarationVol
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    handleChange={handleChange}
                                                />
                                        }
                                        { firstCheck && <Fragment>
                                            {
                                                ['103.1', '103.2', '103.3', '104.1', '104.3', '104.4'].includes(values.catsinorigin) &&
                                                <>
                                                    <Constat
                                                        errors={errors}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        handleChange={handleChange}
                                                    />
                                                    {
                                                        (values.constat_accident === "F" || (values.constat_accident === "T" && values.constat_sign_tiers === "F")) &&
                                                        <DecCirconstancieeContainer/>
                                                    }
                                                </>
                                            }
                                            {
                                                (['103.1', '103.2', '103.3', '104.1', '104.2', '104.3', '104.4'].includes(values.catsinorigin) && values.constat_sign_tiers === 'F' || values.constat_sign_assure === 'F') &&
                                                <Police
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    handleChange={handleChange}
                                                />
                                            }
                                            {
                                                (values.indentify === 'T' ||
                                                    (values.catsinorigin === '103.1' || values.catsinorigin === '103.2')) &&
                                                <>
                                                    <Adversaires
                                                        errors={errors}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        handleChange={handleChange}
                                                    />
                                                    <Blesses
                                                        errors={errors}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        handleChange={handleChange}
                                                    />
                                                </>
                                            }

                                            {
                                                values.temoin === 'T' &&
                                                <Temoins
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    handleChange={handleChange}
                                                />
                                            }

                                            {
                                                values.constat_accident === 'T' &&
                                                <CirconstancesContainer
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    handleChange={handleChange}
                                                />
                                            }
                                            {
                                                ['103.1', '103.2', '103.3', '104.1', '104.2', '104.3', '104.4'].includes(values.catsinorigin) &&
                                                <ChocsContainer
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    handleChange={handleChange}
                                                />
                                            }
                                            {
                                                values.vehiculeDommage === "T" &&
                                                <DeclarationReparateur
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    handleChange={handleChange}
                                                />
                                            }
                                            {
                                                values.vehicule === "T" &&
                                                values.vehiculeDommage === "T" &&
                                                values.catsinorigin !== "10.0" &&
                                                <ReparationContainer
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    handleChange={handleChange}
                                                />
                                            }
                                        </Fragment>}

                                            {
                                                alert && <div className={'alert-danger alert mb-0'}>
                                                    <p className={'mb-0'}>{alert}</p>
                                                </div>
                                            }

                                        {firstCheck ?
                                            <>
                                                {
                                                    loading ? <SmallSpinner /> : <button
                                                        className={`btn btn-primary full mt-5
                                            ${(_.find(sinistres, s => {return moment(s.dateSurv).format('L') === moment(values.datesurvorigin).format('L')}) ||
                                                            !_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5'], values.catsinorigin) ||
                                                            !_.find(garanties, ['code_famille', 'VI']) && _.includes(['10.0', '105.1', '9.0', '105.2', '18.0', '19.0'], values.catsinorigin)) &&
                                                        'disabled'}`}

                                                        disabled={_.find(sinistres, s => {return moment(s.dateSurv).format('L') === moment(values.datesurvorigin).format('L')}) ||
                                                        !_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5'], values.catsinorigin) ||
                                                        !_.find(garanties, ['code_famille', 'VI']) && _.includes(['10.0', '105.1', '9.0', '105.2', '18.0', '19.0'], values.catsinorigin)}
                                                    >
                                                        Envoyer ma déclaration de sinistre
                                                    </button>
                                                }
                                            </> :
                                            <button className={`btn btn-primary full mt-5`} onClick={(e) => checkFirstStep(e, values)}>Poursuivre la déclaration</button>
                                        }
                                    </>
                                }
                                </form>
                            )}
                        </Formik>
                }
            </SelectContractContainer>
        </div>
    );
};

export default DeclarationSinistre;
