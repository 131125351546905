import React, {useEffect} from 'react';
import Text from "../../../../../../Commun/Inputs/Text/Text";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner";
import { Formik } from "formik";
import _ from "lodash";

const validateEmailOrPhone = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[\d\s\-]{10}$/;

    if (!value) {
        return 'Ce champ est obligatoire';
    } else if (!emailPattern.test(value) && !phonePattern.test(value)) {
        return 'Veuillez entrer un email ou un numéro de téléphone valide';
    }
};

const SendParrainage = (props) => {
    const { initialValues, query, postParrainages, loading, userId } = props;

    useEffect(() => {

    }, []);

    return (
        <div className={'mt-2 mb-2'}>
            <Formik
                initialValues={initialValues}
                onSubmit={values => postParrainages({
                    query: _.replace(query, '{id}', userId),
                    form: values,
                    successFunction: () => props.history.push(`/mes-parrainages`)
                })}
                validate={values => {
                    const errors = {};
                    const emailOrPhoneError = validateEmailOrPhone(values.text);
                    if (emailOrPhoneError) {
                        errors.text = emailOrPhoneError;
                    }
                    return errors;
                }}
            >
                {({ handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className={'row'}>
                        <div className={'col-12'}>
                            <Text
                                type={'text'}
                                name="text"
                                label={'e-mail ou téléphone du filleul'}
                                onChange={e => {

                                    const value = e.target.value;
                                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    const phonePattern = /^\+?[\d\s\-]{10}$/;

                                    if (emailPattern.test(value)) {
                                        setFieldValue('email', value);
                                        setFieldValue('phone', '');
                                    } else if (phonePattern.test(value)) {
                                        setFieldValue('phone', value);
                                        setFieldValue('email', '');
                                    }

                                    handleChange(e);

                                }}
                                onBlur={handleBlur}
                                value={values.text}
                                error={errors.text && touched.text ? errors.text : ''}
                            />
                        </div>
                        <div className={'col-12 mt-4'}>
                            {
                                loading
                                    ? <SmallSpinner />
                                    : <button type="submit" className={'btn btn-primary w-100'}>
                                        Envoyer
                                    </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default SendParrainage;
