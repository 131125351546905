import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import moment from "moment/moment.js";
import SimpleDatePicker from "../../../../../../Commun/Inputs/DatePicker/SimpleDatePicker.jsx";
import {Formik} from "formik";
import SmallSpinner from "../../../../../../Commun/Spinners/SmallSpinner.jsx";
import ClausesHivernage from "./ClausesHivernage/ClausesHivernage.jsx";
import hivernage from "../../../../../../../assets/images/hivernage.png";
import ContrepartiesHivernage from "./ContrepartiesHivernage/ContrepartiesHivernage.jsx";

const Hivernage = (props) => {
    const {id_contrat, is_eligible_hivernage, can_activate_hivernage, dernier_hivernage, initialValues, maxDate, loading, loading_fin, query_fin, query, validate, postHivernage, postFinHivernage, maxDateEcheance, getHivernageReduc, hivernage_reduction, loading_get_reduc } = props
    const overlay = dernier_hivernage?.statutCode === '1' || (dernier_hivernage?.periodeCourante && !can_activate_hivernage);
    const [tarifSimule, setTarifSimule] = useState(false)

    useEffect(() => {
        if (hivernage_reduction){
            setTarifSimule(true)
        }
    }, [hivernage_reduction])

    if (!is_eligible_hivernage){
        return <Redirect to={`/mes-contrats/mon-contrat/${id_contrat}`} />
    }
	
    return (
        <div className={'col-12 mt-5 mb-5'}>
            <div className={'bloc-white'}>
                <div className={'d-flex'}>
                    <div className={'flex-shrink-0 pe-5'}>
                        <img src={hivernage} alt="hivernage" width={200}/>
                    </div>
                    <div>
                        <h4 className={'text-primary-light fw-bold mb-4'}>Ma période hivernale</h4>

                        {
                            (dernier_hivernage?.periodeCourante && dernier_hivernage.statutCode === '1') &&
                            <>
                                <p className={'fw-bold'}><span className={'text-primary-light'}>Ma période hivernale</span> est activée jusqu’au {moment(dernier_hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>

                                <div className="d-grid gap-2">
                                    {
                                        loading_fin ? <SmallSpinner /> :
                                            <button type={'button'}
                                                    onClick={() => {
                                                        if (window.confirm('En désactivant de manière anticipée Ma période hivernale, il ne sera plus possible de la réactiver')) postFinHivernage({query: query_fin})
                                                    }} className={'btn btn-primary btn-block'}>
                                                Je désactive  Ma période hivernale de manière anticipée
                                            </button>
                                    }
                                </div>
                            </>
                        }
                        {
                            (dernier_hivernage?.periodeCourante && dernier_hivernage.statutCode !== '1') &&
                            <>
                                <p className={'fw-bold'}>Vous avez bénéficié de <span className={'text-primary-light'}>Ma période hivernale </span> entre le {moment(dernier_hivernage.dateDebut, 'YYYY-MM-DD').format('DD/MM/YYYY')} et le {moment(dernier_hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>
                                { !can_activate_hivernage && <p className={'fw-bold'}>Il n’est plus possible de l’activer.</p> }
                            </>
                        }

                        {!overlay && <ClausesHivernage/>}
                    </div>
                </div>

                <div className={`${overlay ? 'overlay mt-5' : ''}`}>
                    {overlay && <ClausesHivernage/>}
                    <ContrepartiesHivernage />

                    {
                        can_activate_hivernage &&
                        <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} validate={validate} onSubmit={values => {postHivernage({query, form: values, successFunction: () => props.history.push(`/mes-contrats/mon-contrat/${id_contrat}`)})}} >
                            {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                                <form onSubmit={handleSubmit}>
                                    <h5 className={'text-center fw-bold'}>Pour activer Ma période hivernale, <span className={'text-primary-light'}>choisissez uniquement la date de fin</span></h5>

                                    <div className="row my-4">
                                        <div className="col-12 order-md-1 col-md-6 mb-4">
                                            <p>La date de début est <span className={'fw-bold'}>obligatoirement</span> celle d’aujourd’hui</p>
                                        </div>
                                        <div className="col-12 order-md-3 col-md-6 mb-4">
                                            <p>Date de début :<br/><span className={'fw-bold'}>{moment().format('DD/MM/YYYY')}</span></p>
                                        </div>

                                        <div className="col-12 col-md-6 order-md-2">
                                            {
                                                maxDateEcheance
                                                    ? <p>La date de fin de Ma période hivernale ne peut pas dépasser la date d’échéance <span className={'fw-bold'}>{moment(maxDate).format('DD/MM/YYYY')}</span></p>
                                                    : <p>La date de fin de Ma période hivernale ne peut dépasser le <span className={'fw-bold'}>{moment(maxDate).format('DD/MM/YYYY')}</span></p>
                                            }
                                        </div>

                                        <div className="col-12 col-md-6 order-md-4">
                                            <div className={'mb-2'}>
                                                <SimpleDatePicker
                                                    label={'Date de fin'}
                                                    name={'dateFin'}
                                                    setFieldValue={setFieldValue}
                                                    value={values.dateFin}
                                                    error={errors.dateFin}
                                                    minDate={new Date(moment().add(1, 'day').format('YYYY-MM-DD'))}
                                                    maxDate={new Date(maxDate.format('YYYY-MM-DD'))}
                                                    onChange={() => setTarifSimule(false)}
                                                />
                                            </div>
                                            <div>
                                                {
                                                    loading_get_reduc ? <SmallSpinner /> :
                                                        <button
                                                            className={'btn btn-primary btn-block'}
                                                            type={'button'}
                                                            onClick={() => getHivernageReduc({query: query, form: values})}>
                                                            Estimer ma réduction
                                                        </button>
                                                }
                                            </div>

                                            {
                                                (tarifSimule && hivernage_reduction) &&
                                                <p className="mb-0 mt-3">
                                                    Pour Ma période hivernale du {moment().format('DD/MM/YYYY')} au {moment(values.dateFin).format('DD/MM/YYYY')}, <span className={'fw-bold'}>le montant estimé de votre réduction est de {hivernage_reduction} euros. </span>
                                                    Le montant exact de la réduction sera affiché sur la lettre&#8209;avenant transmise après activation.
                                                </p>
                                            }
                                        </div>
                                    </div>

                                    <div className="d-grid gap-2">
                                        {
                                            loading ? <SmallSpinner /> :
                                                <button
                                                    type="submit"
                                                    className={`btn btn-primary btn-block`}
                                                    disabled={!tarifSimule}
                                                >
                                                    {
                                                        (dernier_hivernage && dernier_hivernage?.periodeCourante)
                                                        ? 'Je réactive Ma période hivernale aux dates choisies'
                                                        : 'J\'active Ma période hivernale aux dates choisies'
                                                    }
                                                </button>
                                        }
                                    </div>
                                </form>
                            )}
                        </Formik>
                    }
                </div>
            </div>
        </div>
    );
};

export default Hivernage;