
import {HOST_MB} from "../../../../constants/constants";
import moment from "moment";

export const CUSTOMER = '[Customer]'

export const POST_CUSTOMER_FORGOT_EMAIL = `${CUSTOMER} Post forgot email`
export const POST_CUSTOMER_FORGOT_PASSWORD = `${CUSTOMER} Post forgot password`
export const POST_CUSTOMER_UPDATE_PASSWORD = `${CUSTOMER} Post update password`
export const POST_CUSTOMER_RESET_PASSWORD = `${CUSTOMER} Post reset password`
export const POST_REQUEST_CODE = `${CUSTOMER} Post request code promo`
export const POST_UPDATE_BIRTH_INFOS_TIERS = `${CUSTOMER} Post update birth infos`

export const postForgotEmail = ({nom, belair_id, date_naissance, email, query}) => ({
    type: POST_CUSTOMER_FORGOT_EMAIL,
    payload: {
        data: query,
        body: ({nom, belair_id, date_naissance: moment(date_naissance).format('YYYY-MM-DD'), email, owner_belair_id: HOST_MB})
    }
})

export const postForgotPassword = ({email, query}) => ({
    type: POST_CUSTOMER_FORGOT_PASSWORD,
    payload: {
        data: query,
        body: ({email, owner_belair_id: HOST_MB})
    }
})

export const postUpdatePassword = ({form, query, successFunction}) => ({
    type: POST_CUSTOMER_UPDATE_PASSWORD,
    payload: {
        data: query,
        body: (form),
        otherData: {
            successFunction
        }
    }
})

export const postResetPassword = ({form, query}) => ({
    type: POST_CUSTOMER_RESET_PASSWORD,
    payload: {
        data: query,
        body: form
    }
})


export const postRequestCode = ({campaign, query}) => ({
    type: POST_REQUEST_CODE,
    payload: {
        data: query,
        body: ({campaign})
    }
})

export const postUpdateBirthInfosTiers = ({query, form, successFunction}) => ({
    type: POST_UPDATE_BIRTH_INFOS_TIERS,
    payload: {
        data: query,
        body: (form),
        otherData: {
            successFunction
        }
    }
})
