import {connect} from 'react-redux'
import {compose} from "redux";
import ChangeTitulaire from "./ChangeTitulaire";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import _ from "lodash";
import {CONTRACTS, fetchContracts} from "../../../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractsLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {
    getDataContracts, getDataFirstContract,
    getDataFirstContractInProgress
} from "../../../../../../../redux/selectors/contracts/contracts.selectors";
import {getTitulaireBanqueUser} from "../../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    const contracts = getDataContracts(state);

    return {
        query: getContractsLink(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        contracts,
        titulaire: getTitulaireBanqueUser(state),
        contrat: !_.isEmpty(contracts) ? (getDataFirstContractInProgress(state) || contracts[0]) : {},
    }
}

const mapDispatchToProps = {
    fetchContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchContracts } = dispatchProps
    const { query, contracts } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query}) : null,
    }
}

const ChangeTitulaireContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ChangeTitulaire)

export default ChangeTitulaireContainer