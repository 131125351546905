import React from 'react';
import './footer.scss'
import {NavLink} from "react-router-dom";
import Icons from "../../../Commun/Icon/Icon";
import {HOST_MB} from "../../../../constants/constants";

const Footer = ({logo}) => {

    return (
        <div className={'footer'}>
            <div className={'container'}>
                <div className="row">
                    {
                        HOST_MB === 43397 && <div className={'col-12'}>
                            <div className={'top'}>
                                <div className={'row'}>
                                    <div className={"col-xl-6 col-md-12 socials"}>
                                        <p className={'d-inline-block'}>Suivez nous</p>
                                        <ul className={'d-inline-block'}>
                                            <li><a href={'https://www.facebook.com/APRIL.Assurances.Moto/'} rel={'noreferrer'} target={'_blank'}>
                                                <Icons icon={`facebook2`} fontSize={15} />
                                            </a></li>
                                            <li><a href={'https://www.instagram.com/aprilmoto/?hl=fr'} rel={'noreferrer'} target={'_blank'}>
                                                <Icons icon={`instagram`} fontSize={15} />
                                            </a></li>
                                        </ul>
                                    </div>
                                    <div className={"col-xl-6 col-md-12 text-right"}>
                                        <ul className={'nav-footer'}>
                                            <li><NavLink to={'/mes-questions'}>FAQ</NavLink></li>
                                            <li><NavLink to={'/mentions-legales'}>Mentions légales</NavLink></li>
                                            <li><NavLink to={'/gestion-des-cookies'}>Gestion des cookies</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={'col-12'}>
                        <div className={'bottom'}>
                            <img src={logo} alt={'Logo'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
