import React from 'react';
import './text.scss'

const Text = (props) => {
    const { label, value, error, required, normalize = v => v, onChange, functionOnChange, setFieldValue, ...other } = props

    return (
        <div className={'input-text'}>
            <input
                required={required}
                value={value}
                onChange={(e) => {
                    e.target.value = normalize(e.target.value)
                    functionOnChange && functionOnChange(e.target.value, setFieldValue)
                    onChange(e)
                }}
                className={`p-3 rounded ${value && 'valid'}`}
                onWheel={(e) => e.target.blur()}
                {...other}
            />
            <label>{label}{required ? <sup className={'text-danger'}>*</sup> : ''}</label>
            {error && <small className={'text-danger fst-italic'}>{error}</small>}
        </div>
    );
};

export default Text;
