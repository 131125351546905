import React from 'react';
import './warning.scss'

const Warning = (props) => {
    const {messageWarning} = props

    return (
        <div className={'bloc-white warning col-xl-11 col-md-12'}>
            <strong dangerouslySetInnerHTML={{__html: messageWarning}} />
        </div>
    );
};

export default Warning;
