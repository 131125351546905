import React from 'react';
import moment from "moment";
import {formatEuro} from "../../../../../../../utils/functions";
import {Link} from "react-router-dom";
import APILink from "../../../../../../Commun/APILink/APILink";


const CotisationSelectedContract = (props) => {
    const {receipts, export_receipts_link, getReceiptsDownload, id_contrat, derniere_quittance} = props

    const ReceiptsDownload = (e) => {
        getReceiptsDownload();
        //window.open(export_receipts_link, '_blank');
    }

    return (
        <div>
            {
                derniere_quittance && derniere_quittance.has_tableau_prelevement ?
                <div className="text-center">
                    <Link className={'btn btn-secondary'} to={`/mes-paiements/mon-contrat/${id_contrat}/mes-prelevements`}>Accéder à mon tableau de prélèvement</Link>
                </div> : ''
            }
            <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Mes quittances sur les 12 derniers mois</h4>
                </div>

                <div className={'mt-4'}>
                    {receipts && receipts.length >= 1
                        ?
                        <>
                            <div className={'table-responsive'}>
                                <table className="table custom-table">
                                    <tbody>
                                    {receipts.map((receipt, i) => (
                                        <tr key={i}>
                                            <td>
                                                <strong>Du {moment(receipt.dateDebut).format('L')} au {moment(receipt.dateFin).format('L')}</strong>
                                            </td>
                                            <td>Montant : <strong>{formatEuro(receipt.montant)}</strong></td>
                                            <td>Motif : <strong>{receipt.type}</strong></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="text-center">
                                <APILink className={'btn btn-link'} onClick={ReceiptsDownload} download href={export_receipts_link}>
                                    Télécharger mon historique des quittances
                                </APILink>
                            </div>
                        </>
                        : <p>Aucune quittance n'ont été trouvée pour les 12 derniers mois.</p>
                    }
                </div>
            </div>
        </div>
    );
};


export default CotisationSelectedContract;