import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {maskIban} from "../../../../../../utils/functions";
import _ from "lodash";
import moment from "moment/moment";
import {postDematStatut} from "../../../../../../redux/actions/app/parameters/parameters.actions.js";
import {Formik} from "formik";
import RadioGroup from "../../../../../Commun/Inputs/RadioGroup/RadioGroup.jsx";

const MonProfil = (props) => {
    const {
        user: {
            demat,
            titre,
            telephone,
            email,
            titulaire,
            iban,
            paysNaissance,
            villeNaissance, nomNaissance, beneficiairesEffectifs, statut_juridique, num_siren_siret
        },
        contracts,
        postDematStatut,
        query,
        id
    } = props

    const canModifyRib = contracts.some(contract => contract.situation.statutCode === '1');

    const [dematStatus, setDematStatus] = useState(demat);
    const handleDematChange = () => {
        const newDematStatus = dematStatus === 'T' ? 'F' : 'T';

        postDematStatut({
            query: _.replace(query, '{id}', id),
            form: { b_stopemail: newDematStatus },
            successFunction: () => {
                setDematStatus(newDematStatus);
            }
        });
    };

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Mon compte</h1>
            </div>

            <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Informations de contact</h4>
                    <Link to={`/mon-profil/je-change-mes-informations`} className={'float-right'}>Modifier</Link>
                </div>

                <div className={'mt-4'}>
                    <div className="row">
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Numéro de téléphone</p>
                            <p className={'m-0 text-secondary'}>{telephone}</p>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Adresse email</p>
                            <p className={'m-0 text-secondary'}>{email}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Informations personnelles</h4>
                    <Link to={`/mon-profil/je-change-mes-informations-de-naissance`}
                          className={'float-right'}>Modifier</Link>
                </div>

                <div className={'mt-4'}>
                    {
                        titre === 'SOC' ?
                            <>
                                <div className="row">
                                    <div className="col">
                                        <p className={'mb-1 fw-bold'}>Statut juridique</p>
                                        <p className={'m-0 text-secondary'}>{statut_juridique ?? 'Non renseigné'}</p>
                                    </div>
                                </div>

                                <hr/>

                                <div className="row">
                                    <div className="col">
                                        <p className={'mb-1 fw-bold'}>Numéro SIREN/SIRET</p>
                                        <p className={'m-0 text-secondary'}>{num_siren_siret ?? 'Non renseigné'}</p>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="row">
                                    <div className="col">
                                        <p className={'mb-1 fw-bold'}>Lieu de naissance</p>
                                        <p className={'m-0 text-secondary'}>{villeNaissance ?? 'Non renseigné'} - {paysNaissance ?? 'Non renseigné'}</p>
                                    </div>
                                </div>

                                <hr/>

                                <div className="row">
                                    <div className="col">
                                        <p className={'mb-1 fw-bold'}>Nom de naissance</p>
                                        <p className={'m-0 text-secondary'}>{nomNaissance ?? 'Non renseigné'}</p>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>

            {
                titre === 'SOC' &&
                <div className={'bloc-white'}>
                    <div>
                        <h4 className={'fw-bold text-primary-light d-inline-block'}>Bénéficiaires effectifs</h4>
                    </div>

                    <div className={'mt-4'}>
                        {!_.isEmpty(beneficiairesEffectifs) ?
                            <>
                                {beneficiairesEffectifs.map((beneficiaire, index) =>
                                    <div key={index}>
                                        {index > 0 && <hr/>}
                                        <div className="row">
                                            <div className="col">
                                                <p className={'mb-1 fw-bold'}>{beneficiaire.nom} {beneficiaire.prenom}{beneficiaire.nom_naissance ? `, né(e) ${beneficiaire.nom_naissance}` : ''}</p>
                                                <p className={'m-0 text-secondary'}>Né(e)
                                                    le {moment(beneficiaire.naissance).format('L')} à {beneficiaire.ville_naissance}, {beneficiaire.pays_naissance}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                            : <p>Vous n'avez pas de bénéficiaire effectif de renseigné.</p>
                        }
                    </div>
                </div>
            }

            <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Informations bancaires</h4>
                    {canModifyRib && <Link to={`/mon-profil/je-change-mes-informations-bancaires`}
                          className={'float-right'}>Modifier</Link> }
                </div>

                {
                    iban ?
                        <div className={'mt-4'}>
                            <div className="row">
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Titulaire du compte</p>
                                    <p className={'m-0 text-secondary'}>{titulaire}</p>
                                </div>
                            </div>

                            <hr/>

                            <div className="row">
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>IBAN</p>
                                    <p className={'m-0 text-secondary'}>{iban ? maskIban(iban) : 'Non renseigné'}</p>
                                </div>
                            </div>
                        </div>
                        : <small>Vous n'avez aucun IBAN d'enregistré.</small>
                }
            </div>

            <div className="bloc-white">
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Paramètres</h4>
                </div>

                <div className="mt-4">
                    <div className="row">
                        <div className="col">
                            <Link to={`/mon-profil/je-change-mon-mot-de-passe`}
                                  className={'float-right'}>Modifier</Link>
                            <p className={'mb-1 fw-bold'}>Modifier mon mot de passe</p>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col-12">
                            <Link to={`/mon-profil/je-change-mes-abonnements`} className={'float-right'}>Modifier</Link>
                            <p className={'mb-1 fw-bold'}>Préférences d'abonnement</p>
                        </div>
                    </div>


                </div>
            </div>

            <div className="bloc-white">
                <div>
                    <h4 className="fw-bold text-primary-light d-inline-block">Gestion de la dématérialisation</h4>
                    <p>Pour faciliter les échanges, votre contrat est soumis à la dématérialisation de l’avis
                        d'échéance. Vous pouvez choisir de refuser la dématérialisation ci-dessous :</p>
                </div>

                <div className="mt-4">
                    <div className="row">
                        <div className="col">
                            <a onClick={() => handleDematChange()} className={'cursor-pointer float-right'}>
                                {dematStatus === 'T' ? 'Désactiver la dématérialisation ?' : 'Activer la dématérialisation'}
                            </a>
                            <p className={'mb-1 fw-bold'}>Statut de la dématérialisation</p>
                            <p className={'m-0 text-secondary'}>
                                {dematStatus === 'T'
                                    ? <span className={`badge bg-success`}>Activé</span>
                                    : <span className={`badge bg-danger`}>Désactivé</span>
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default MonProfil;
