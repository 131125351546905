import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

import _ from "lodash";
import {
    POST_DELETE_THIRD_PARTY_PAYER,
    POST_UPDATE_THIRD_PARTY_PAYER,
} from "../../../actions/app/tiers_payeur/tiers_payeur.actions";
import moment from "moment";
import {setContractThirdPartyPayer} from "../../../actions/app/contracts/contracts.actions";

export const tiersPayeurMiddleware = () => next => action => {
    next(action)
    let clone = null;

    switch (action.type) {
        case POST_UPDATE_THIRD_PARTY_PAYER:
            clone = _.cloneDeep(action.payload.body)

            if(clone.type === 'P' && clone.birthdate && moment(clone.birthdate).isValid()){
                clone.birthdate = moment(clone.birthdate).format('YYYY-MM-DD')
            }

            next([
                apiRequest({
                    body: (_.pickBy(clone, function(value) {return  !_.isEmpty(value)})),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_THIRD_PARTY_PAYER,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_UPDATE_THIRD_PARTY_PAYER})
            ])
            break

        case `${POST_UPDATE_THIRD_PARTY_PAYER} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_UPDATE_THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: POST_UPDATE_THIRD_PARTY_PAYER}),
                setNotification({
                    entity: POST_UPDATE_THIRD_PARTY_PAYER,
                    html: '<div><p>Votre demande de modification a bien été prise en compte</p></div>',
                    icon: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: action.payload.meta.otherData.successFunction
                })
            ])

            break

        case `${POST_UPDATE_THIRD_PARTY_PAYER} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_UPDATE_THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: POST_UPDATE_THIRD_PARTY_PAYER}),
                setNotification({
                    entity: POST_UPDATE_THIRD_PARTY_PAYER,
                    html: '<p>Une erreur est survenue : ' + action.payload.data.response.data.reason + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case POST_DELETE_THIRD_PARTY_PAYER:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DELETE_THIRD_PARTY_PAYER
                }),
                setLoader({state: true, entity: POST_DELETE_THIRD_PARTY_PAYER})
            ])
            break

        case `${POST_DELETE_THIRD_PARTY_PAYER} ${API_SUCCESS}`:
            next([
                setContractThirdPartyPayer({tpp: []}),
                setLoader({state: false, entity: POST_DELETE_THIRD_PARTY_PAYER}),
                setError({state: false, entity: POST_DELETE_THIRD_PARTY_PAYER}),
                setNotification({
                    entity: POST_DELETE_THIRD_PARTY_PAYER,
                    html: '<div><p>Votre demande de suppression a bien été prise en compte</p></div>',
                    icon: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case `${POST_DELETE_THIRD_PARTY_PAYER} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_DELETE_THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: POST_DELETE_THIRD_PARTY_PAYER}),
                setNotification({
                    entity: POST_DELETE_THIRD_PARTY_PAYER,
                    html: '<p>Une erreur est survenue : ' + action.payload.data.response.data.reason + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        default:
            break
    }
    return null
}
