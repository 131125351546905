import {connect} from 'react-redux'
import Declaration from "./Declaration";
import {
    getAdhesionPrincipaleContract, getContractGarantiesOptions,
    getDataContract, getIsFleetContract, getIsWakamContract, getSouscripteurNomContract
} from "../../../../../../../redux/selectors/contracts/contract.selector";
import {getAccidentsTiersData} from "../../../../../../../redux/selectors/accidents/accidents.selectors";
import {getFlotteDetailData} from "../../../../../../../redux/selectors/flotte/flotte.selectors.js";

const mapStateToProps = state => {
    return {
        contract: getDataContract(state),
        sinistres: getAccidentsTiersData(state),
        risque: getAdhesionPrincipaleContract(state),
        conduct: getIsFleetContract(state) ? "" : getSouscripteurNomContract(state),
        isWakam: getIsWakamContract(state),
        garanties: getIsFleetContract(state) ? getFlotteDetailData(state)?.garanties : getContractGarantiesOptions(state),
    }
}

const DeclarationContainer = connect(mapStateToProps)(Declaration)

export default DeclarationContainer
