export const RECEIPT= '[Receipt]'

export const FETCH_RECEIPT = `${RECEIPT} Fetch`
export const SET_RECEIPT = `${RECEIPT} Set`


export const fetchReceipt = ({query}) => ({
    type: FETCH_RECEIPT,
    payload: {
        data: query
    }
})

export const setReceipt = ({data}) => ({
    type: SET_RECEIPT,
    payload: {
        data
    }
})
