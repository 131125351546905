import React from 'react';
import {Route} from "react-router-dom";
import DetailSinistreContainer from "./DetailSinistre/DetailSinistreContainer";
import AjoutReparateurContainer from "./AjoutReparateur/AjoutReparateurContainer";

const Sinistre = (props) => {

    return (
        <div>
            <Route exact path={'/mes-sinistres/contrat/:id/sinistre/:ids/ajouter-reparateur'} component={AjoutReparateurContainer} />
            <Route exact path={'/mes-sinistres/contrat/:id/sinistre/:ids'} component={DetailSinistreContainer} />
        </div>
    );
};

export default Sinistre;