import React from 'react'
import _ from "lodash";

export const Badge = ({status, classBadge=false}) => {
    switch(_.toLower(status)) {
        case 'en cours':
        case 'actif':
        case 'sinistre ouvert':
            return <span className={`badge bg-success ${classBadge ? classBadge : ''}`}>{status}</span>
        case 'sans effet':
            return <span className={`badge bg-secondary ${classBadge ? classBadge : ''}`}>{status}</span>
        case 'sinistre clos':
        case 'sinistre terminé':
        case 'sinistre pré-déclaré':
        case 'résiliée':
        case 'résiliation':
        case 'résil/impayé':
        case 'fin de pièce':
        case 'inactif':
            return <span className={`badge bg-danger ${classBadge ? classBadge : ''}`}>{status}</span>
        case 'En établissement':
        case 'Suspendue':
        case 'A régulariser':
        case 'sinistre en attente':
            return <span className={`badge bg-warning ${classBadge ? classBadge : ''}`}>{status}</span>
        default:
            return <span className={`badge bg-secondary ${classBadge ? classBadge : ''}`}>{status}</span>
    }
}

export default Badge
