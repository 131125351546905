import {connect} from 'react-redux'
import SortieVehicules from "./SortieVehicules.jsx";
import { validate } from './validate'
import {
    FLOTTE,
    getFlotte,
    POST_DELETE_VEHICLES,
    postDeleteVehicles
} from "../../../../../../../../../redux/actions/app/flotte/flotte.actions.js";
import {getLoadingEntity} from "../../../../../../../../../redux/selectors/ui/ui.selectors.js";
import {
    getFleetLink,
    getFleetVehiclesDeleteLink
} from "../../../../../../../../../redux/selectors/links/links.selectors.js";
import {getFlotteSelector} from "../../../../../../../../../redux/selectors/flotte/flotte.selectors.js";
import {getContractId} from "../../../../../../../../../redux/selectors/contracts/contract.selector.js";
import moment from "moment";
import {compose} from "redux";
import loader from "../../../../../../../../Commun/Spinners/Loader/Loader.jsx";

const mapStateToProps = state => {
    return {
        validate,
        query: getFleetVehiclesDeleteLink(state),
        loadingDelete: getLoadingEntity(state, POST_DELETE_VEHICLES),
        uri: getFleetLink(state),
        loading: getLoadingEntity(state, FLOTTE),
        loaded: getLoadingEntity(state, FLOTTE) === false,
        flotte: getFlotteSelector(state),
        contractId: getContractId(state),
        initialValues: {
            selected_vehicules: [],
            date_sortie: moment()
        }
    }
}

const mapDispatchToProps = {
    postDeleteVehicles, getFlotte
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, contractId } = stateProps
    const { getFlotte } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getFlotte({ query: uri, contrat: contractId, sort: '', active: '', immat: '' })
    }
}


const SortieVehiculesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(SortieVehicules)

export default SortieVehiculesContainer
