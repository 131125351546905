import {linksMiddleware} from "./links/links.middleware";
import {userMiddleware} from "./user/user.middleware";
import {whitelabelMiddleware} from "./whitelabel/whitelabel.middleware";
import {loginMiddleware} from "./login/login.middleware";
import {customerForgotemailMiddleware} from "./customer/forgotEmail/customer.forgotemail.middleware";
import {customerForgotpasswordMiddleware} from "./customer/forgotPassword/customer.forgotpassword.middleware";
import {contractsMiddleware} from "./contracts/contracts.middleware";
import {accidentSelectedMiddleware} from "./accidents/accident/accident.middleware";
import {accidentsMiddleware} from "./accidents/accidents/accidents.middleware";
import {faqMiddleware} from "./faq/faq.middleware";
import {avantagesMiddleware} from "./avantages/avantages.middleware";
import {articlesMiddleware} from "./articles/articles.middleware";
import {amendmentMiddleware} from "./amendment/amendment.middleware";
import {receiptMiddleware} from "./payments/receipt/receipt.middleware";
import {receiptsUnpaidMiddleware} from "./payments/receiptsUnpaid/receiptsUnpaid.middleware";
import {receiptsMiddleware} from "./payments/receipts/receipts.middleware";
import {contactMiddleware} from "./contact/contact.middleware";
import {parametersMiddleware} from "./parameters/parameters.middleware";
import {customerUpdatepasswordMiddleware} from "./customer/updatePassword/customer.updatepassword.middleware";
import {documentsMiddleware} from "./documents/documents.middleware";
import {RequestDocumentMiddleware} from "./requestDocument/requestDocument.middleware";
import {customerResetpasswordMiddleware} from "./customer/resetPassword/customer.resetpassword.middleware";
import {customerRequestCodeMiddleware} from "./customer/requestCodePromo/customer.requestcode.middleware";
import {casIdaMiddleware} from "./cas_ida/cas_ida.middleware";
import {parrainagesMiddleware} from "./parrainages/parrainages.middleware";
import {customerUpdatebirthinfosMiddleware} from "./customer/updateBirthInfos/customer.updatebirthinfos.middleware";
import {tiersPayeurMiddleware} from "./tiers_payeur/tiers_payeur.middleware";
import {terminateMiddleware} from "./terminate/terminate.middleware";
import {flotteMiddleware} from "./flotte/flotte.middleware";

export const appMiddleware = [
    linksMiddleware,
    userMiddleware,
    whitelabelMiddleware,
    loginMiddleware,
    customerForgotemailMiddleware,
    customerForgotpasswordMiddleware,
    customerUpdatepasswordMiddleware,
    contractsMiddleware,
    accidentSelectedMiddleware,
    accidentsMiddleware,
    faqMiddleware,
    RequestDocumentMiddleware,
    avantagesMiddleware,
    receiptMiddleware,
    receiptsUnpaidMiddleware,
    receiptsMiddleware,
    articlesMiddleware,
    amendmentMiddleware,
    contactMiddleware,
    parametersMiddleware,
    documentsMiddleware,
    customerResetpasswordMiddleware,
    customerRequestCodeMiddleware,
    casIdaMiddleware,
    parrainagesMiddleware,
    customerUpdatebirthinfosMiddleware,
    tiersPayeurMiddleware,
    terminateMiddleware,
    flotteMiddleware
]
