import {connect} from 'react-redux'
import {compose} from 'redux'
import SendParrainage from "./SendParrainage";
import loader from "../../../../../../Commun/Spinners/Loader/Loader";
import {
    postParrainagesLink
} from "../../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {POST_PARRAINAGES, postParrainages} from "../../../../../../../redux/actions/app/parrainages/parrainages.actions";
import { validate } from './validate'
import {getIdUser} from "../../../../../../../redux/selectors/user/user.selectors";


const mapStateToProps = (state, ownProps) => {
    return {
        validate,
        query: postParrainagesLink(state),
        loading: getLoadingEntity(state, `${POST_PARRAINAGES}-${ownProps.index}`),
        userId: getIdUser(state),
        initialValues: {
            'email': '',
            'phone': '',
            'index': ownProps.index,
        }
    }
}

const mapDispatchToProps = {
    postParrainages
}

const SendParrainageContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(SendParrainage)


export default SendParrainageContainer
