import {DOCUMENTS, FETCH_DOCUMENTS, setDocuments} from '../../../actions/app/documents/documents.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const documentsMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_DOCUMENTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DOCUMENTS}),
                setLoader({state: true, entity: DOCUMENTS})
            ])
            break

        case `${DOCUMENTS} ${API_SUCCESS}`:
            const { data } = action.payload.data

            next([
                setDocuments({data}),
                setError({state: false, entity: DOCUMENTS}),
                setLoader({state: false, entity: DOCUMENTS})
            ])
            break

        case `${DOCUMENTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: DOCUMENTS}),
                setLoader({state: false, entity: DOCUMENTS})
            ])
            break

        default:
            break
    }
    return null
}
