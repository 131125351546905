import React from 'react';

const ContrepartiesHivernage = () => {
    return (
        <div>
            <div className={'alert alert-info my-5 text-black'}>
                <p>En contrepartie de la réduction de cotisation applicable pendant la période stipulée, au cours de laquelle les garanties du contrat seront maintenues, vous :</p>
                <ul>
                    <li><span className={'fw-bold'}>acceptez de supporter une franchise additionnelle de 450€ pour tout sinistre survenu pendant cette période</span> (hors sinistre survenu sur le lieu de stationnement du véhicule dans le garage clos et couvert individuel ou collectif pendant la période hivernale,  et sauf en cas de sinistre catastrophes naturelles)</li>
                </ul>

                <p className={'mb-0'}>
                    A l’issue de la Période Hivernale stipulée, le contrat s’appliquera de nouveau dans les conditions de garanties et de prix antérieures à la signature de la lettre avenant.<br/>
                    Toute modification ultérieure sur le contrat qui interviendrait après la signature de la lettre avenant pendant la période hivernale, mettra automatiquement fin à la présente clause et à la réduction liée, au prorata du temps écoulé.
                </p>
            </div>
        </div>
    );
};

export default ContrepartiesHivernage;