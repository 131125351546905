import {connect} from 'react-redux'
import {compose} from 'redux'
import TodoBloc from "./TodoBloc";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import {
    FETCH_RECEIPTS_UNPAID,
    fetchReceiptsUnpaid
} from "../../../../../../redux/actions/app/payments/receiptsUnpaid/receiptsUnpaid.actions";
import {getContractsLink, receiptsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    CONTRACTS,
    fetchWaitingDocContracts, WAITING_DOC_CONTRACTS
} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {getDataReceiptsUnpaid} from "../../../../../../redux/selectors/payments/receiptsUnpaid/receiptsUnpaid.selectors";
import {getWaitingDocContractsData} from "../../../../../../redux/selectors/contracts/waitingDocContracts.selectors";

const mapStateToProps = state => {
    return {
        query: `${receiptsLink(state)}?status=all`,
        query_contrat: `${getContractsLink(state)}?waiting_document=true`,
        loaded: getLoadingEntity(state, FETCH_RECEIPTS_UNPAID) === false && getLoadingEntity(state, CONTRACTS) === false && getLoadingEntity(state, WAITING_DOC_CONTRACTS) === false,
        receipts: getDataReceiptsUnpaid(state),
        documentsWaiting: getWaitingDocContractsData(state)
    }
}

const mapDispatchToProps = {
    fetchReceiptsUnpaid,
    fetchWaitingDocContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, query_contrat } = stateProps
    const { fetchReceiptsUnpaid, fetchWaitingDocContracts } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            fetchReceiptsUnpaid({query})
            fetchWaitingDocContracts({query: query_contrat})
        }
    }
}

const TodoBlocContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(TodoBloc)

export default TodoBlocContainer
