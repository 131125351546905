import React from 'react';
import './selection_sinistre.scss';

const SelectionSinistre = (props) => {
    const {setFieldValue} = props

    return (
        <div className={'navigation-decla-accident container'}>
            <div className="row">
                <div className={'bloc-icon mt-5 mb-5'} onClick={(e) => setFieldValue(`type_sinistre`, 'accident')}>
                    <div className={'icone icone-accident'}/>
                    <div className={'d-block mt-4'}>
                        <p className={'m-0'}>Déclarer un sinistre</p>
                        <span className={'font-weight-bold'}>Accident</span>
                    </div>
                </div>
                <div className={'bloc-icon mt-5 mb-5'} onClick={(e) => setFieldValue(`type_sinistre`, 'vol')}>
                    <div className={'icone icone-vol'}/>
                    <div className={'d-block mt-4'}>
                        <p className={'m-0'}>Déclarer un sinistre</p>
                        <span className={'font-weight-bold'}>Vol - Tentative de Vol</span>
                    </div>
                </div>
                <div className={'bloc-icon mt-5 mb-5'} onClick={(e) => setFieldValue(`type_sinistre`, 'autres')}>
                    <div className={'icone icone-autres'}/>
                    <div className={'d-block mt-4'}>
                        <p className={'m-0'}>Déclarer un sinistre</p>
                        <span className={'font-weight-bold'}>Autres</span> (incendie, vandalisme, événement climatique…)
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectionSinistre;
