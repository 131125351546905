import {SET_ARTICLES} from "../../actions/app/articles/articles.actions";

export const articlesReducer = (articles = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ARTICLES) {
        return payload.data
    } else {
        return articles
    }
}
