import {connect} from 'react-redux'
import LargeSpinner from "./LargeSpinner";
import {getLogoLightUrlWhitelabel} from "../../../../redux/selectors/whitelabel/whitelabel.selectors";

const mapStateToProps = state => {
    return {
        logo: getLogoLightUrlWhitelabel(state)
    }
}

const LargeSpinnerContainer = connect(mapStateToProps)(LargeSpinner)

export default LargeSpinnerContainer
