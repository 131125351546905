import {connect} from 'react-redux'
import _ from 'lodash'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import {getContractsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACTS, fetchContracts} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import Contracts from "./Contracts";
import {getDataContracts} from "../../../../../../redux/selectors/contracts/contracts.selectors";
import {
    getGeneralPrefilledSubscribeLinksUser,
    getPrefilledSubscribeLinksUser
} from "../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        query: getContractsLink(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        contracts : getDataContracts(state),
        souscriptionGenerale: getGeneralPrefilledSubscribeLinksUser(state),
        subscribeLinks: getPrefilledSubscribeLinksUser(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContracts: ({query}) => fetchContracts({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchContracts } = dispatchProps
    const { query, contracts } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query}) : null,
    }
}

const ContractsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Contracts)

export default ContractsContainer
