import React from 'react';
import PropTypes from 'prop-types'
import './simpleDatePicker.scss'
import { DatePicker } from 'react-rainbow-components';

const SimpleDatePicker = (props) => {
    const { value, setFieldValue, name, label, format = 'small', required, error, maxDate, onChange, ...other } = props

    return (
        <div>
            <DatePicker
                value={value}
                onChange={value => {
                    setFieldValue(name, value)
                    onChange ? onChange(value) : null
                }}
                label={label}
                formatStyle={format}
                locale={'fr-Fr'}
                className={`simpleDatePicker ${value && 'active'}`}
                required={required}
                maxDate={maxDate}
                {...other}
            />
            {error && <small className={'text-danger fst-italic'}>{error}</small>}
        </div>

    );
};

SimpleDatePicker.propsType = {
    label: PropTypes.string,
    name: PropTypes.string.required,
    setFieldValue: PropTypes.func.required,
    value: PropTypes.string,
    format: PropTypes.oneOf(['small', 'medium', 'large'])
}

export default SimpleDatePicker;

