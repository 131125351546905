import React from 'react';
import _ from 'lodash'
import { RadioButtonGroup } from 'react-rainbow-components';
import './radioGroup.scss'

const RadioGroup = (props) => {
    const { options, setFieldValue, name, label, value, required, error, className, onChange } = props

    return (
        <div>
            <label className={`d-block`}><span dangerouslySetInnerHTML={{__html: label}} />{required ? <sup className={'text-danger'}>*</sup> : ''}</label>
            <RadioButtonGroup
                options={options}
                variant={'brand'}
                value={value}
                onChange={e => {
                    onChange && onChange(e)
                    setFieldValue(name, e.target.value)
                }}
                className={`radioGroup ${className ? className : ''} ${_.includes(['F', "false"], value) && 'radio-error'}`}
            />
            {error && <small className={'text-danger fst-italic d-block'}>{error}</small>}
        </div>
    );
};

export default RadioGroup;
