import {connect} from 'react-redux'
import ChangeInformationsNaissance from "./ChangeInformationsNaissance";
import {validate} from './validate'
import {
    changeBirthInfosTiersLink, getContractsLink
} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    POST_UPDATE_BIRTH_INFOS_TIERS,
    postUpdateBirthInfosTiers
} from "../../../../../../redux/actions/app/customer/customer.actions";
import {getIdUser} from "../../../../../../redux/selectors/user/user.selectors";
import {
    CONTRACTS,
    fetchContracts
} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {getDataContracts} from "../../../../../../redux/selectors/contracts/contracts.selectors";
import {bindActionCreators, compose} from "redux";
import _ from "lodash";
import loader from "../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = state => {
    const contracts = getDataContracts(state);

    return {
        query_contract: getContractsLink(state),
        contracts: contracts,
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        query: changeBirthInfosTiersLink(state),
        loading: getLoadingEntity(state, POST_UPDATE_BIRTH_INFOS_TIERS),
        validate,
        societe: contracts ? contracts[0].souscripteur.titre === 'SOC' : false,
        initialValues: {
            pays: contracts ? contracts[0].souscripteur.code_pays_naissance : '',
            ville: contracts ? contracts[0].souscripteur.ville_naissance : '',
            nom: contracts ? contracts[0].souscripteur.nom_naissance : '',
            statut_juridique: contracts ? contracts[0].souscripteur.statut_juridique : '',
            siren_siret:  contracts ? contracts[0].souscripteur.num_siren_siret : '',
        },
        id: getIdUser(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postUpdateBirthInfosTiers,
    fetchContracts,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query_contract, contracts } = stateProps
    const { fetchContracts } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query: query_contract}) : null,
    }
}

const ChangeInformationsNaissanceContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ChangeInformationsNaissance)

export default ChangeInformationsNaissanceContainer
