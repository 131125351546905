import {
    SET_IS_LOGIN,
    SET_LOADER,
    SET_MAINTENANCE,
    SET_ERROR,
    SET_IS_LOGOUT,
    SET_FILTER,
    SET_RESPONSE_ERROR,
    SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT,
    SET_CODE_PROMO_USER,
    SET_RESPONSE_POST_ACCIDENT,
    SET_HIVERNAGE_REDUCTION
} from '../../actions/app/ui/ui.actions'
import {SET_REQUEST_DOCUMENT} from "../../actions/app/requestDocument/requestDocument.actions";

export const initStateUi = {
    login: false,
    logout: false,
    maintenance: false,
    filters: {},
    error: {},
    requestDocument: {}
}

export const uiReducer = (ui = initStateUi, action = {}) => {
    const { payload, meta } = action

    switch (true){
        case action.type.includes(SET_LOADER):
            return {...ui, loading: {...ui.loading, [meta]: payload}}
        case action.type.includes(SET_ERROR):
            return {...ui, error: {...ui.error, [meta]: payload}}
        case action.type.includes(SET_RESPONSE_ERROR):
            return {...ui, errorResponse: payload.data}
        case action.type.includes(SET_IS_LOGIN):
            return {...ui, login: payload}
        case action.type.includes(SET_IS_LOGOUT):
            return {...ui, logout: payload}
        case action.type.includes(SET_MAINTENANCE):
            return {...ui, maintenance : payload}
        case action.type.includes(SET_FILTER):
            return {...ui, filters: {...ui.filters, [meta]: payload}}
        case action.type.includes(SET_REQUEST_DOCUMENT):
            return {...ui, requestDocument: {...ui.requestDocument, [meta]: payload}}
        case action.type.includes(SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT):
            return {...ui, documentsForAccident: payload.data}
        case action.type.includes(SET_CODE_PROMO_USER):
            return {...ui, code_promo_user: payload.data}
        case action.type.includes(SET_RESPONSE_POST_ACCIDENT):
            return {...ui, postAccidentResponse: payload.data}
        case action.type.includes(SET_HIVERNAGE_REDUCTION):
            return {...ui, hivernageReduction: payload}


        default:
            return ui
    }
}
