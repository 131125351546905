import {combineReducers} from 'redux'
import {linksReducer} from "./links/links.reducer";
import {uiReducer} from "./ui/ui.reducer";
import {whitelabelReducer} from "./whitelabel/whitelabel.reducer";
import {contractsReducer} from "./contracts/contracts.reducer";
import {accidentSelectedReducer} from "./accidents/accident.selected.reducer";
import {accidentsReducer} from "./accidents/accidents.reducer";
import {userReducer} from "./user/user.reducer";
import {faqReducer} from "./faq/faq.reducer";
import {contractReducer} from "./contracts/contract.reducer";
import {avantagesReducer} from "./avantages/avantages.reducer";
import {articlesReducer} from "./articles/articles.reducer";
import {receiptReducer} from "./payments/receipt/receipt.reducer";
import {receiptsUnpaidReducer} from "./payments/receiptsUnpaid/receiptsUnpaid.reducer";
import {receiptsReducer} from "./payments/receipts/receipts.reducer";
import {documentsReducer} from "./documents/documents.reducer";
import {waitingDocContractsReducer} from "./contracts/waitingDocContracts.reducer";
import {casIdaReducer} from "./cas_ida/cas_ida.reducer";
import {parrainagesReducer} from "./parrainages/parrainages.reducer";
import {flotteReducer} from "./flotte/flotte.reducer";
import {flotteDetailReducer} from "./flotte/flotte_detail.reducer";

export const AppReducers = combineReducers({
    links: linksReducer,
    ui: uiReducer,
    whitelabel: whitelabelReducer,
    contracts: contractsReducer,
    contract: contractReducer,
    accident: accidentSelectedReducer,
    accidents: accidentsReducer,
    user: userReducer,
    faq: faqReducer,
    avantages: avantagesReducer,
    receipt: receiptReducer,
    receipts_unpaid: receiptsUnpaidReducer,
    receipts: receiptsReducer,
    articles: articlesReducer,
    documents: documentsReducer,
    waiting_doc_contracts: waitingDocContractsReducer,
    cas_ida: casIdaReducer,
    parrainages: parrainagesReducer,
    flotte: flotteReducer,
    flotte_detail: flotteDetailReducer,
})
