import React from 'react';
import label_excellence from '../../../../../../assets/images/label_excellence.png'
import './label_excellence.scss'


const LabelExcellence = () => {

    return (
        <div className={'col-12 mt-5 LabelExcellence text-center'}>
            <img src={label_excellence} className={'img-fluid'}/>
        </div>
    );
};


export default LabelExcellence;
