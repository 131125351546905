import {connect} from 'react-redux'
import DetailSinistre from "./DetailSinistre";
import {getAccidentData} from "../../../../../../../redux/selectors/accidents/accident.selectors";
import {getIsWakamContract} from "../../../../../../../redux/selectors/contracts/contract.selector.js";

const mapStateToProps = state => {
    return {
        accident: getAccidentData(state),
        isWakam: getIsWakamContract(state)
    }
}

const DetailSinistreContainer = connect(mapStateToProps)(DetailSinistre)

export default DetailSinistreContainer
