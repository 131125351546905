import {connect} from 'react-redux'
import {getAccidentResponse} from "../../../../../../../redux/selectors/ui/ui.selectors";
import ConfirmationSinistre from "./ConfirmationSinistre";

const mapStateToProps = state => {
    return {
        accidentResonse: getAccidentResponse(state),
    }
}

const ConfirmationSinistreContainer = connect(mapStateToProps)(ConfirmationSinistre)

export default ConfirmationSinistreContainer