import React from 'react';
import {Link} from "react-router-dom";

const Cookies = () => {

    const showPopup = () => {
        const consent_cta = document.querySelectorAll(".consent-modal");
        if(consent_cta[0]){
            consent_cta[0].style.display = 'block';
        }
    }

    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mentions-legales'} className={'text-decoration-none'}>Gestion des cookies</Link></h6>
            </div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link> Gestion des cookies</h1>
            </div>
            <div className={'col-10 mt-5'}>
                <h2 className={'title'}><strong>Préambule</strong></h2>
                <p>La présente politique, qui complète les Conditions Générales d’Utilisation (CGU) et la Politique données personnelles du Site, précise quels sont les cookies et traceurs utilisés sur le Site ainsi que les moyens dont Vous disposez pour accepter ou refuser leur dépôt.<br/>Les définitions figurant dans les Conditions Générales d’Utilisation du Site s’appliquent également à la présente Politique.</p>

                <button type={'button'} className={'btn btn-link'} onClick={showPopup}>Gérer mes préférences</button>

                <h2 className={'title mt-5'}><strong>Politique en matière de cookies</strong></h2>
                <h4>Qu’est-ce qu’un cookie ?</h4>
                <p>Un cookie est une suite d’informations, généralement de petite taille et identifié par un nom, qui peut être placé sur le terminal de l’internaute à l’occasion de la consultation d’un site internet ou d’une application et ce, grâce à son logiciel de navigation.</p>
                <p>Un cookie permet à son émetteur d’identifier le terminal dans lequel il est enregistré, pendant la durée de validité ou d’enregistrement dudit cookie. Les cookies ont de multiples usages, certains sont indispensables à l’utilisation du site, alors que d’autres permettent par exemple d’optimiser et de personnaliser les contenus affichés ou de réaliser des statistiques.</p>
                <p><strong>Le terme cookie dans la présente Politique est entendu au sens large et couvre l’ensemble des traceurs déposés et/ou lus lors de la consultation du Site</strong>, quel que soit le type de terminal que Vous utilisez (ordinateur, smartphone, tablette numériques, etc.).</p>


                <h2 className={'title mt-5'}><strong>Cookies utilisés sur le Site</strong></h2>
                <h4>Les cookies nécessaires au fonctionnement du Site</h4>
                <p>Il s’agit des cookies permettant au Site de fonctionner de manière optimale. Vous pouvez <strong>Vous opposer au dépôt de ces cookies et les supprimer en utilisant les paramètres de votre navigateur. Cependant, sans ces cookies Vous ne pourrez pas utiliser normalement le Site et votre expérience risque d’être dégradée.</strong></p>
                <p>Nous déclinons toute responsabilité pour les conséquences liées au fonctionnement dégradé des services du Site résultant de l’impossibilité d’enregistrer ou de consulter les cookies nécessaires à son fonctionnement que Vous auriez refusés ou supprimés.</p>
                <div className={'table-responsive'}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Editeurs</th>
                            <th scope="col">Nom du cookie</th>
                            <th scope="col">Finalité</th>
                            <th scope="col">Durée de vie</th>
                            <th scope="col">Type de cookie</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Google</td>
                            <td>APISID</td>
                            <td>Cookies publicitaires</td>
                            <td>2 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>HSID</td>
                            <td>Cookies publicitaires</td>
                            <td>2 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>IDE</td>
                            <td>Cookies publicitaires</td>
                            <td>13 mois</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>NID</td>
                            <td>Cookies publicitaires</td>
                            <td>6 mois</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>OTZ</td>
                            <td>Cookies publicitaires</td>
                            <td>1 mois</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>SAPISID</td>
                            <td>Cookies publicitaires</td>
                            <td>2 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>SID</td>
                            <td>Cookies publicitaires</td>
                            <td>2 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>SIDCC</td>
                            <td>Cookies publicitaires</td>
                            <td>1 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>SSID</td>
                            <td>Cookies publicitaires</td>
                            <td>2 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>__Secure-3PAPISID</td>
                            <td>Cookies publicitaires</td>
                            <td>2 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>__Secure-3PSID</td>
                            <td>Cookies publicitaires</td>
                            <td>2 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>__Secure-3PSIDCC</td>
                            <td>Cookies publicitaires</td>
                            <td>1 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Facebook</td>
                            <td>_fbp</td>
                            <td>Cookies publicitaires</td>
                            <td>3 mois</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>_ga</td>
                            <td>Cookies de mesure d’audience et de personnalisation du contenu</td>
                            <td>2 ans</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>_gcl_au</td>
                            <td>Cookies publicitaires</td>
                            <td>3 mois</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>April Moto</td>
                            <td>_gid</td>
                            <td>Cookies de mesure d’audience et de personnalisation du contenu</td>
                            <td>1 journée</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>Matomo</td>
                            <td>_pk_id*</td>
                            <td>Cookies de mesure d’audience</td>
                            <td>13 mois</td>
                            <td>Tiers</td>
                        </tr>
                        <tr>
                            <td>April Moto</td>
                            <td>browser_uuid</td>
                            <td>Cookies de mesure d’audience et de personnalisation du contenu</td>
                            <td>12 mois</td>
                            <td>Interne</td>
                        </tr>
                        <tr>
                            <td>Facebook</td>
                            <td>fr</td>
                            <td>Cookies publicitaires</td>
                            <td>3 mois</td>
                            <td>Tiers</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <h4 className={'mt-5'}>Cookie exempt de consentement</h4>
                <p>Le cookie analytique Realytics est un cookie de mesure d’audience et statistiques, de ce fait exempt de consentement selon la CNIL, utilisé pour mesurer la performance des campagnes TV des annonceurs sur le digital. En plus de la mesure, Realytics offre des solutions d’optimisation ou de mesure d’audience avancée qui peuvent nécessiter de recueillir un consentement libre, explicite et non équivoque de l’utilisateur.</p>
                <p>Realytics devient alors un fournisseur de données pour les partenaires de l’annonceur, qui doit s’assurer de la conformité du partenaire opérant. Dans les 2 cas, le cookie Realytics s’engage à respecter les données collectées et à ne collecter aucune donnée personnelle ou sensible et peut être désactivé depuis la page opt-out de Realytics.</p>


                <h4 className={'mt-5'}>Les cookies soumis à votre consentement</h4>
                <p>Il s’agit des cookies, y compris tiers, utilisés pour les finalités décrites ci-dessous.<br/>Vous pouvez accepter ou Vous opposer au dépôt de ces cookies en utilisant le module « Gestion cookies » présent sur le Site. Sans ces cookies Vous pourrez utiliser normalement le Site.</p>
                <p>A partir des liens présents dans le module « Gestion cookies » présent sur le Site Vous pouvez également accéder aux politiques de confidentialité des éditeurs de cookies tiers utilisés sur le Site, et ainsi :</p>
                <ul>
                    <li>consulter les informations relatives aux traitements que ces éditeurs réalisent pour leur propre compte et sous leur responsabilité à partir des données de navigation qu’ils collectent via les cookies qu’ils éditent ;</li>
                    <li>obtenir leurs coordonnées afin de les contacter directement pour exercer vos droits sur les données qu’ils collectent.</li>
                </ul>
                <h5>Cookies utilisés sur le Site :</h5>
                <strong>Les cookies de personnalisation de contenu :</strong>
                <p>Les cookies de personnalisation de contenu utilisés sur le Site permettent l’optimisation de l’ergonomie des contenus du Site.</p>

                <strong>Les cookies de mesure d’audience :</strong>
                <p>Les cookies de mesure d’audience permettent de générer des statistiques de fréquentation et d’utilisation des divers éléments composant le Site utiles à son amélioration, ainsi de générer des statistiques en lien avec notre page Facebook.</p>

                <strong>Les cookies publicitaires :</strong>
                <p>Les cookies publicitaires utilisés sur le Site permettent de Vous proposer des publicités adaptées à vos centres d’intérêts en fonction de votre navigation sur le Site, mais aussi sur d’autres sites.</p>

                <strong>Les cookies de partage de contenu sur les réseaux sociaux :</strong>
                <p>Les cookies de partage de contenu sur les réseaux sociaux Vous permettent de faciliter le partage de contenu.</p>

                <strong>Durée du traitement :</strong>
                <ul>
                    <li>durée de vie des cookies : 13 mois maximum</li>
                    <li>durée de conservation des données associées : 25 mois maximum</li>
                </ul>

                <h4 className={'mt-5'}>La gestion des Cookies</h4>
                <p>Lors de votre visite sur le Site, une fenêtre Vous informe de la présence des cookies et Vous invite à indiquer vos choix soit de manière globale soit par type de cookie.<br/>Vous pouvez à tout moment vous informer et paramétrer les cookies pour les accepter ou les refuser en Vous rendant dans le module « Gestion cookies » accessible sur chaque page du Site. Vous pouvez choisir vos préférences soit globalement pour le Site, soit par cookie.</p>
            </div>
        </div>
    );
};

export default Cookies;
