import React from 'react';
import {Link} from "react-router-dom";

const Mentions = () => {

    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mentions-legales'} className={'text-decoration-none'}>Mentions légales</Link></h6>
            </div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link>Mentions légales</h1>
            </div>
            <div className={'col-10 mt-5'}>
                <h2 className={'title'}><strong>APRIL Moto</strong></h2>
                <ul>
                    <li>Société de courtage d’assurances régie par le Code des Assurances</li>
                    <li>Société Anonyme Simplifiée au capital de 300 000 €</li>
                    <li>Intermédiaire en assurances RCS Tours B 397 855 867</li>
                    <li>Immatriculée à l’ORIAS sous le n° 07 008 730 – www.orias.fr</li>
                    <li>Une société du Groupe APRIL</li>
                    <li>Entreprise soumise au contrôle de l’Autorité de Contrôle Prudentiel et de Résolutions (ACPR) – 4 Place de Budapest CS 92459, 75436 Paris</li>
                </ul>
                <p>Nos garanties Responsabilité Civile Professionnelle et Garantie Financière sont conformes aux articles L512-14 et L512-7 du Code des Assurances</p>

                <h2 className={'mt-5 title'}><strong>Copyright</strong></h2>
                <p>Tous droits du propriétaire du contenu du site internet www.april-moto.com réservés.
                    Toute reproduction et toute utilisation du contenu du site autre que la consultation individuelle et privée sont interdites, sauf autorisation écrite de notre part. Les logos, marques et marques déposées sont la propriété de APRIL MOTO.</p>
                <ul>
                    <li>Directeur de la publication : <strong>Fanny LALUC-FAYET</strong></li>
                    <li>Conception : <strong>APRIL MOTO</strong></li>
                    <li>Hébergement : <a href={"https://www.cloud-temple.com/"} rel={'noreferrer'} target={'_blank'}>CLOUD TEMPLE</a>, Cloud Temple, 31 Rue de la Milletière, 37100 Tours – France</li>
                </ul>

                <h2 className={'mt-5 title'}><strong>Loi Applicable</strong></h2>
                <p>Les relations précontractuelles et contractuelles sont régies par la loi française et principalement le Code des Assurances.</p>

                <h2 className={'mt-5 title'}><strong>Réclamations</strong></h2>
                <p>Pour toutes réclamations vous pouvez consulter notre page dédiée : <a href={"https://www.april-moto.com/reclamations"} rel={'noreferrer'} target={'_blank'}>https://www.april-moto.com/reclamations</a></p>

                <h2 className={'mt-5 title'}><strong>Index d’égalité professionnelle entre les hommes et les femmes chez APRIL Moto : 90/100</strong></h2>
                <p>Index d’égalité professionnelle : qu’est-ce que c’est ?</p>
                <p>L’index de l’égalité professionnelle a été instauré par la Loi pour la liberté de choisir son avenir professionnel.</p>
                <p> Cet index permet aux entreprises de plus de 50 salariés d’évaluer sur 100 points le niveau d’égalité entre les hommes et les femmes en s’appuyant sur les critères suivants :</p>
                <ul>
                    <li>Indicateur 1 : Ecart de rémunération à 35/40</li>
                    <li>Indicateur 2 : Ecart d’augmentations : 35/35</li>
                    <li>Indicateur 3 : Augmentation au retour de congé maternité : 15/15</li>
                    <li>Indicateur 4 : Répartition des 10 plus hautes rémunérations : 5/10</li>
                </ul>
                <strong>Résultat de l’index à 90/100</strong>
            </div>
        </div>
    );
};

export default Mentions;
