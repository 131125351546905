import React from 'react';
import {Formik} from "formik";
import Checkbox from "../../../../../Commun/Inputs/Checkbox/Checkbox";
import SmallSpinner from "../../../../../Commun/Spinners/SmallSpinner";

const ChangeAbonnements = (props) => {
    const { initialValues, query, postCommunicationsPreferences, loading, query_user, fetchUser, name } = props

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je modifie mes préférences d'abonnement</h1>
            </div>

            <Formik initialValues={initialValues} onSubmit={values => postCommunicationsPreferences({query, form: values, successFunction: () => { fetchUser({query: query_user}); props.history.push('/mon-profil')} })}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col">
                                <div className={'mb-4 mt-4'}>
                                    <Checkbox
                                        name="optin"
                                        label={`Je souhaite être informé des nouvelles offres et des actualités de ${name}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.optin}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className={'mb-4 mt-4'}>
                                    <Checkbox
                                        name="optin_partenaires"
                                        label={`Je souhaite être informé des nouvelles offres et des actualités des partenaires de ${name}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.optin_partenaires}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-grid gap-2 mt-4">
                            {
                                loading ? <SmallSpinner /> : <button type="submit" className={'btn btn-primary btn-block'}>
                                    Je valide mes nouvelles préférences d'abonnement
                                </button>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default ChangeAbonnements;
