import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import moment from 'moment';
import SmallSpinner from '../../../../../../../../Commun/Spinners/SmallSpinner.jsx';
import Warning from "../../../../../Home/Warning/Warning.jsx";
import ImgWarning from '../../../../../../../../../assets/images/icons/warning.png'

const SortieVehicules = (props) => {
    const { initialValues, query, postDeleteVehicles, loadingDelete, validate, contractId, flotte } = props;

    const [minDate, setMinDate] = useState('');

    useEffect(() => {
        // Calculate the most recent entry date from all vehicles in flotte
        const mostRecentDate = flotte.data.reduce((latestDate, vehicule) => {
            const vehicleDate = moment(vehicule.dateEntree, 'YYYY-MM-DD');
            return vehicleDate.isAfter(latestDate) ? vehicleDate : latestDate;
        }, moment('1970-01-01')); // Initialize with a very early date

        // Format date as YYYY-MM-DD for the min attribute
        setMinDate(mostRecentDate.format('YYYY-MM-DD'));
    }, [flotte.data]);

    // Filter the vehicles to include only active ones
    const vehiculesActifs = flotte.data.filter(vehicule => !vehicule.inactif);

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Sortie des véhicules de la flotte</h1>
            </div>

            <Formik
                initialValues={{ ...initialValues, contract_id: contractId, selected_vehicules: [] }} // Ensure selected_vehicules is initialized
                onSubmit={(values) => postDeleteVehicles({
                    query,
                    form: values,
                    successFunction: () => props.history.push(`/mes-contrats/mon-contrat/${contractId}`)
                })}
                validate={validate}
            >
                {({ handleSubmit, values, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <p htmlFor="date_sortie">Date de sortie :</p>
                            <Field
                                id="date_sortie"
                                name="date_sortie"
                                type="date"
                                className="form-control"
                                min={minDate} // Set the min date here
                            />
                        </div>

                        <Field type="hidden" name="contract_id" value={contractId}/>
                        <br/>
                        <div className="form-group">
                            <p>Véhicules à supprimer :</p>
                            {vehiculesActifs.map((vehicule) => (
                                <div key={vehicule.adhesion} className="form-check mb-3">
                                    <input
                                        type="checkbox"
                                        id={`vehicule_${vehicule.adhesion}`}
                                        name="selected_vehicules"
                                        value={vehicule.adhesion}
                                        checked={values.selected_vehicules.includes(vehicule.adhesion)}
                                        onChange={(e) => {
                                            const selectedVehicules = [...values.selected_vehicules];
                                            if (e.target.checked) {
                                                selectedVehicules.push(vehicule.adhesion);
                                            } else {
                                                const index = selectedVehicules.indexOf(vehicule.adhesion);
                                                if (index !== -1) {
                                                    selectedVehicules.splice(index, 1);
                                                }
                                            }
                                            setFieldValue('selected_vehicules', selectedVehicules);
                                        }}
                                    />
                                    <label htmlFor={`vehicule_${vehicule.adhesion}`} style={{marginLeft: 10}}>
                                        {`${vehicule.marque} ${vehicule.modele} - ${vehicule.immatriculation}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        {vehiculesActifs.length > 0 && vehiculesActifs.every(vehicule => values.selected_vehicules.includes(vehicule.adhesion)) && (
                            <Warning messageWarning="Conseil APRIL Moto : En retirant le dernier véhicule de votre contrat, celui-ci sera automatiquement terminé. Si vous souhaitez remplacer votre 2-roues par un nouveau véhicule, ajoutez d'abord le nouveau 2-roues."/>
                        )}

                        <div className="alert alert-warning d-flex align-items-center" role="alert" style={{margin: '20px 0'}}>
                            <img src={ImgWarning} alt="Attention" className={'me-4'}/>
                            <p className={'m-0'}>
                                Par véhicule, vous déclarez qu’au moins une des conditions ci-dessous est respectée :
                                <br/>
                                - Le véhicule est vendu et le souscripteur dispose d’un certificat de vente <br/>- Le
                                véhicule est volé et le souscripteur dispose d’une plainte pour le vol du véhicule
                            </p>
                        </div>

                        <div className="d-grid gap-2">
                            {loadingDelete ? (
                                <SmallSpinner/>
                            ) : (
                                <button
                                    type="submit"
                                    className={`btn btn-primary btn-block ${loadingDelete && 'disabled'}`}
                                >
                                    Valider
                                </button>
                            )}
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default SortieVehicules;
