import React, {useState} from 'react';
import './infobulle.scss'
import Icons from "../../../Commun/Icon/Icon";
import bulle from '../../../../assets/images/icons/bulle-principale.png'
import accident from '../../../../assets/images/icons/accident.png'
import refinance from '../../../../assets/images/icons/refinance.png'
import addVehicule from '../../../../assets/images/icons/ajout-fleet-dark.png'
import exitVehicule from '../../../../assets/images/icons/delete-fleet-dark.png'
import Swal from "sweetalert2";
import _ from "lodash";

const Infobulle = (props) => {
    const { history, contracts = [], isWakam, contract } = props

    const [active, setActive] = useState(false)

    const chooseContract = action => {
        let options = []
        let optionsFilted = []

        contracts.map(c => {
            if (action === 'document' && c.nb_pieces > 0){
                optionsFilted[c.id] = `Contrat n°${c.numeroPolice} - ${c.vehicule.marque} ${c.vehicule.immatriculation}`
            } else {
                options[c.id] = `Contrat n°${c.numeroPolice} - ${c.vehicule.marque} ${c.vehicule.immatriculation}`
            }
        })

        Swal.fire({
            title: 'Choisissez votre contrat',
            input: 'select',
            inputOptions: optionsFilted.length > 0 ? optionsFilted : options,
            confirmButtonText: "Choisir ce contrat",
            allowOutsideClick: true,
            showCancelButton: true,
            cancelButtonText: 'Annuler',
        }).then(result => {
            if (result.isConfirmed) {
                setActive(false)
                const contract = _.find(contracts, ['id', Number(result.value)])
                switch (action) {
                    case 'document':
                        return window.open(contract.upload_app_link, '_blank')
                }
            }
        })
    }


    const canModifyRib = contracts.some(contract => contract.situation.statutCode === '1');

    const goTo = href => {
        history.push(href)
        setActive(false)
    }

    return (
        <div className={`full-bg ${active && 'active'}`} onClick={() => active && setActive(false)}>
            <div className={'container-fluid'}>
                <div className="row">
                    <ul className={`${active && 'active'} menu-infobulle`}>
                        { contracts && contracts.length === 1 && !isWakam && contracts[0].situation.statutCode === "1" && (contracts[0].vehicule.codeProduit !== '7311_QMCO' || contracts[0].vehicule.codeProduit !== '7311_2MCO') && <li><p onClick={() => goTo(`/mes-contrats/mon-contrat/${contracts[0].id}/je-demenage`)}>Je déménage <span className={'bg-icon'}><Icons icon={'move'} fontSize={15} /></span></p></li> }
                        { contracts && contracts.length === 1 && !isWakam && <li><p onClick={() => goTo('/mes-sinistres/je-declare-un-sinistre')}>Je déclare un sinistre <span className={'bg-icon'}><img src={accident} alt="Accident" width={25}/></span></p></li> }
                        {
                            isWakam &&
                            <>
                                <li><p><a target='_blank' href={contract.avenant_link} style={{textDecoration:'none', color: 'white'}}>Je modifie mon contrat</a><span className={'bg-icon'}><img src={addVehicule} alt="Ajouter un véhicule" width={25}/></span></p></li>
                                <li><p onClick={() => goTo(`/mes-contrats/mon-contrat/${contract.id}/sortie-vehicules`)}>Je retire un/des véhicule(s)<span className={'bg-icon'}><img src={exitVehicule} alt="Retirer un/des véhicule(s)" width={25}/></span></p></li>
                            </>
                        }
                        {canModifyRib && <li><p onClick={() => goTo('/mon-profil/je-change-mes-informations-bancaires')}>Je change de
                            RIB <span className={'bg-icon'}><img src={refinance} alt="Accident" width={25}/></span></p>
                        </li> }
                        <li><p onClick={() => chooseContract('document')}>J'envoie des documents <span className={'bg-icon'}><Icons icon={'documents'} fontSize={15} /></span></p></li>
                    </ul>
                    <div className="col">
                        <div className={`infobulle cursor-pointer rounded-medium ${active ? 'info-active' : 'info-close'}`} onClick={() => setActive(!active)}>
                            {active ? <Icons icon={'close'} fontSize={26} /> : <h6 className={'m-0 d-inline-block px-3'}><img src={bulle} alt="Bulle" width={30}/>
                                &nbsp;<span className={'d-none d-lg-inline-block d-xl-inline-block'}>Accès rapide</span> </h6>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Infobulle;
