import React from 'react';
import {Link} from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Badge from "../../../../../Commun/Badge/Badge";

const ListeContracts = (props) => {
    const { contracts } = props

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link> Mes contrats</h1>
            </div>
            {
                contracts.map(c => (
                    <div className={'bloc-white'} key={c.id}>
                        <div>
                            <h4 className={'fw-bold text-primary-light d-inline-block'}>Contrat N°{c.numeroPolice}</h4> <Link to={`/mes-contrats/mon-contrat/${c.id}`} className={'float-right d-none d-sm-block'}>Voir le détail</Link>
                        </div>

                        <div className={'mt-4'}>
                            <div className="row">
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Date d'effet</p>
                                    <p className={'m-0 text-secondary'}>{moment(c.situation.dateAffaireNouvelle).format('L')}</p>
                                </div>
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Situation</p>
                                    <Badge status={_.capitalize(c.situation.statut)}/>
                                </div>
                            </div>

                            <hr/>

                            <div className="row">
                                { c.vehicule.marque && <div className="col">
                                    <p className={'mb-1 fw-bold'}>Véhicule</p>
                                    <p className={'m-0 text-secondary'}>{c.vehicule.marque}</p>
                                </div> }
                                { c.vehicule.immatriculation && <div className="col">
                                    <p className={'mb-1 fw-bold'}>Immatriculation</p>
                                    <p className={'m-0 text-secondary'}>{c.vehicule.immatriculation || '-'}</p>
                                </div> }
                            </div>
                        </div>

                        <div className="text-center mt-4 d-sm-none d-block">
                            <Link to={`/mes-contrats/mon-contrat/${c.id}`}>Voir le détail</Link>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default ListeContracts;
