import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getContractId} from '../../../../../../../../redux/selectors/contracts/contract.selector'
import {getRequestDocumentLink} from "../../../../../../../../redux/selectors/links/links.selectors";
import RequestDocument from "./RequestDocument";
import {getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {fetchRequestDocument} from "../../../../../../../../redux/actions/app/requestDocument/requestDocument.actions";
import {CONTRACTS, fetchContracts} from "../../../../../../../../redux/actions/app/contracts/contracts.actions";
import {getDataContracts} from "../../../../../../../../redux/selectors/contracts/contracts.selectors";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        query: getRequestDocumentLink(state),
        contracts: getDataContracts(state),
        id_contrat: getContractId(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContracts,
    fetchRequestDocument
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query_contract, contracts } = stateProps
    const { fetchContracts } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query: query_contract}) : null,
    }
}


const RequestDocumentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(RequestDocument)

export default RequestDocumentContainer
