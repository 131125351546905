import {connect} from 'react-redux'
import _ from "lodash";
import {updateThirdPartyPayerLink} from "../../../../../../../redux/selectors/links/links.selectors";
import ChangeTiersPayeur from "./ChangeTiersPayeur";
import {
    POST_UPDATE_THIRD_PARTY_PAYER,
    postUpdateTiersPayeur
} from "../../../../../../../redux/actions/app/tiers_payeur/tiers_payeur.actions";
import {validate} from "./validate";
import {getContractId, getTiersPayeurContract} from "../../../../../../../redux/selectors/contracts/contract.selector";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    const tiersPayeur = getTiersPayeurContract(state)

    return {
        id_contrat: getContractId(state),
        validate,
        query: _.replace(updateThirdPartyPayerLink(state), '{id}', getContractId(state)),
        loading: getLoadingEntity(state, POST_UPDATE_THIRD_PARTY_PAYER),
        initialValues: {
            type : (tiersPayeur.nom && (tiersPayeur.relation ? 'P' : 'M')) || 'P',
            firstname: tiersPayeur.prenom ?? '',
            lastname: tiersPayeur.nom ??'',
            birthname: tiersPayeur.nom_naissance ?? '',
            birthdate: tiersPayeur.naissance ? tiersPayeur.naissance : '',
            birthcity: tiersPayeur.ville_naissance ?? '',
            relationship: tiersPayeur.relation ?? '',
            third_payeur_reason: tiersPayeur.raison_autre ?? ''
        }
    }
}

const mapDispatchToProps = {
    postUpdateTiersPayeur
}

const ChangeTiersPayeurContainer = connect(mapStateToProps, mapDispatchToProps)(ChangeTiersPayeur)

export default ChangeTiersPayeurContainer