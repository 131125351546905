import React from 'react';
import './header.scss'
import Icons from "../../../Commun/Icon/Icon";
import {NavLink, Link} from "react-router-dom";
import {HOST_MB} from "../../../../constants/constants";

const Header = (props) => {
    const { postLogout, logo, contracts, isWakam } = props

    return (
        <div className={'header d-none d-xl-block'}>
            <div className={'container-fluid'}>
                <div className="row">
                    <div className={"col d-flex align-items-center"}>
                        <Link to={'/'}><img src={logo} alt={'Logo'} width={90} /></Link>
                    </div>
                    <div className={"col-9"}>
                        <ul>
                            <li><NavLink to={'/mes-contrats'}>Mes contrats</NavLink></li>
                            <li><NavLink to={'/mes-paiements'}>Mes paiements</NavLink></li>
                            <li><NavLink to={'/mes-sinistres'}>Mes sinistres</NavLink></li>
                            {HOST_MB === 43397 && <li><NavLink to={'/mes-questions'}>Mes questions</NavLink></li>}
                            { HOST_MB === 43397 && !isWakam && <li><NavLink to={'/mes-avantages'}>Mes avantages</NavLink></li>}
                            {(contracts && contracts[0].vehicule.codeProduit && contracts[0].vehicule.codeProduit !== 'WAKAM_LIV') &&
                                <li><NavLink to={'/mes-documents'}>Mes documents</NavLink></li>
                            }
                            { HOST_MB === 43397 && <li><NavLink to={'/mes-parrainages'}>Mes parrainages</NavLink></li>}
                            {HOST_MB === 43397 && <li><NavLink to={'/resilier'}>Résilier</NavLink></li>}
                        </ul>
                    </div>
                    <div className={"col text-right"}>
                    <Link to={'/mon-profil'} className={'user'}><Icons icon={'user'} fontSize={26} /></Link>
                        <span className={'separator'}>&nbsp;</span>
                        <a href={'/'} onClick={() => postLogout()} className={'logout'}><Icons icon={'logout'} fontSize={26} /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
