import {AVANTAGES, FETCH_AVANTAGES, setAvantages} from '../../../actions/app/avantages/avantages.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const avantagesMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case FETCH_AVANTAGES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: AVANTAGES}),
                setLoader({state: true, entity: AVANTAGES})
            ])
            break

        case `${AVANTAGES} ${API_SUCCESS}`:
            next([
                setAvantages({data: action.payload.data}),
                setError({state: false, entity: AVANTAGES}),
                setLoader({state: false, entity: AVANTAGES})
            ])
            break

        case `${AVANTAGES} ${API_ERROR}`:
            next([
                setError({state: true, entity: AVANTAGES}),
                setLoader({state: false, entity: AVANTAGES})
            ])
            break

        default:
            break;
    }
    return null
}
