import React from 'react';
import './large-spinner.scss'
import SmallSpinner from "../SmallSpinner";


const LargeSpinner = ({logo}) => {

    return (
        <div id={'start-loader'} className={'mx-auto my-5'}>
            { logo && <img src={logo} alt="Logo" width={300}/> }
            <SmallSpinner />
        </div>
    );
};

export default LargeSpinner;
