import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import {APP_NAME} from "../../../constants/constants.js";

const PrivateRoute = (props) => {

    useEffect(() => {
        window.scroll(0,0)
    })

    const { login, token, fetchUser, fail, query, component: Component, ...rest } = props

    if ((!token && !login) || (fail && token && !login)){
        localStorage.setItem(`url-${APP_NAME}`, window.location)
        return <Redirect to={'/login'} />
    }

    if (fail || !token) {
        window.location.reload()
        return null
    }

    if (!login) {
        return fetchUser({query})
    }

    return (
        <Route
            {...rest}
            render={props => {
                return <Component {...props} />
            }}
        />
    )

}

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

export default PrivateRoute
