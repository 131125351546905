import React from 'react';
import moment from 'moment'
import {Link} from "react-router-dom";
import SelectContractContainer from "../../../../../Commun/SelectContract/SelectContractContainer";
import CotisationSelectedContractContainer from "./CotisationSelectedContract/CotisationSelectedContractContainer";
import woman from "../../../../../../assets/images/icons/businesswoman.png";
import _ from "lodash";

const Cotisations = (props) => {
    const { receipts: { data: { data } } } = props

    return (
        <div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link>Mes paiements</h1>
            </div>

            <div className={'col mt-5'}>
                {data.length >= 1 && <>
                    {
                        <div className={'bg-grey-light todo-block pb-0 my-4'}>
                            <h2 className="title-bloc d-inline-block">Cotisations à régler ({data.length})</h2>
                            <div className="row">
                                {
                                    data.map((r, i) => (
                                        <>
                                            <div className="bloc-white col-12 me-4 position-relative text-center p-4 px-5 d-none d-md-block" key={i}>
                                                <div className={'position-absolute info-block p-2'}><i className="bi bi-info-circle text-light fs-5" /></div>
                                                <div className="row">
                                                    <div className={'table-responsive'}>
                                                        <table className="table table-borderless">
                                                            <thead>
                                                            <tr>
                                                                <th className={'bg-grey-light'}>&nbsp;</th>
                                                                <th className={'bg-grey-light'}>Montant</th>
                                                                <th className={'bg-grey-light'}>Motif</th>
                                                                <th className={'bg-grey-light'}>Contrat</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td className={'bg-grey-light p-0 px-1 align-middle'}><p className={'m-0'}>Du {moment(r.dateDebut).format('L')}<br/> au {moment(r.dateFin).format('L')}</p></td>
                                                                <td className={'bg-grey-light p-0 px-1 align-middle'}><p className={'m-0'}>{r.montant}&nbsp;€</p></td>
                                                                <td className={'bg-grey-light p-0 px-1 align-middle'}><p className={'m-0'}>{r.type}</p></td>
                                                                <td className={'bg-grey-light p-0 px-1 align-middle'}>
                                                                    <p className={'m-0'}>{r.numeroPolice}</p>
                                                                </td>
                                                                <td className={'p-0 px-1 align-middle bg-grey-light'}>
                                                                    {r.pay_link
                                                                        ? <a target='_blank' href={`${r.pay_link}?url_return=${window.location.href}`} className={'fs-6'}>Payer ma cotisation</a>
                                                                        : <span className={`badge bg-info`}>{_.replace(r.pay_link_reason[0], '_', ' ')}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="bloc-white col-lg-5 col-md-6 col-xs-12 me-4 position-relative text-center d-md-none" key={i}>
                                                <div className={'position-absolute info-block p-3'}><i className="bi bi-info-circle text-light fs-4" /></div>
                                                <div>
                                                    <img src={woman} alt="Quittances" className={'my-2'} width={50}/>
                                                    <p className={'fw-bold text-danger fs-6'}>Vous avez une cotisation à régler&nbsp;!</p>
                                                    <p className={'fs-6'}>Du {moment(r.dateDebut).format('L')} au {moment(r.dateFin).format('L')}</p>
                                                    <p className={'fs-6'}><span className={'font-weight-bold'}>Motif&nbsp;:</span>&nbsp;{r.type}</p>
                                                    <p className={'fst-italic text-grey fs-6'}><span className={'fw-bold'}>Contrat&nbsp;:</span>&nbsp;{r.numeroPolice} - {r.montant}€</p>
                                                    {r.pay_link
                                                        ? <a target='_blank' href={`${r.pay_link}?url_return=${window.location.href}`} className={'fs-6'}>Payer ma cotisation</a>
                                                        : <span className={`badge bg-info`}>{_.replace(r.pay_link_reason[0], '_', ' ')}</span>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    }
                </>
                }

                <div className={'my-3'}>
                    <SelectContractContainer>
                        <CotisationSelectedContractContainer/>
                    </SelectContractContainer>
                </div>

            </div>
        </div>
    );
};

export default Cotisations;
