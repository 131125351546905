export const PARAMETERS = '[Parameters]'
export const DEMAT = '[Demat]'

export const POST_COMMUNICATION_PREFERENCES = `${PARAMETERS} Post Communications preferences`
export const POST_DEMAT_STATUT = `${DEMAT} Post Statut`

export const postCommunicationsPreferences = ({query, form, successFunction}) => ({
    type: POST_COMMUNICATION_PREFERENCES,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})


export const postDematStatut = ({query, form, successFunction}) => ({
    type: POST_DEMAT_STATUT,
    payload: {
        data: query,
        body: form,
        otherData: {
            successFunction
        }
    }
})
