import React from 'react';
import './accidents.scss'
import {Link} from "react-router-dom";
import Icons from "../../../../../Commun/Icon/Icon";
import _ from "lodash";

const Accidents = (props) => {
    const {accidents, isACL, isWakam} = props

    return (
        <div className={'col-xl-5 col-md-6 col-xs-12 ps-0'}>
            <div className={'bloc-white accidents'}>
                <h2 className={'title-bloc d-inline-block'}>Mes sinistres</h2>
                <Link to={'/mes-sinistres'} className={'link-underline d-inline-block float-right'}>Voir tout</Link>

                {accidents.length >= 1 ?
                    accidents.map((a,i) => (
                        <div className={'accident row'} key={i}>
                            <div className={'detail col-7'}>
                                <p>Sinistre n°{a.numCbt}</p>
                                <small>{_.capitalize(a.situation)}</small>
                            </div>
                            <div className={'col-5 align-right'}>
                                <Link to={`/mes-sinistres/contrat/${a.id_contrat}/sinistre/${a.id}`} className={'link-detail'}>
                                    Voir le détail <Icons icon={'chevrondroite'} fontSize={12}/>
                                </Link>
                            </div>
                        </div>
                    ))
                    : <p className={'mt-4 text-grey'}>Vous n'avez actuellement aucun sinistre déclaré.</p>
                }
                { !isACL && <Link className={'btn btn-primary full mt-4 mb-5'} to={'/mes-sinistres/je-declare-un-sinistre'} >Déclarer un sinistre</Link> }
            </div>
        </div>
    );
};

export default Accidents;
