import React from 'react';
import {Link, Route} from "react-router-dom";
import ContactezNous from "./ContactezNous/ContactezNous";


const Contact = () => {

    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light fw-bold'}>
                    <Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={`/contact`} className={'text-decoration-none'}>Contact</Link>
                </h6>
            </div>

            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Contact</h1>
            </div>

            <Route exact path={'/contact'} component={ContactezNous} />
        </div>
    );
};

export default Contact;
