import React from 'react';
import './situation_contrat.scss';
import moment from "moment";
import {Link} from "react-router-dom";


const SituationContrat = (props) => {
    const {isWakam, isPrelevement, id_contrat, souscripteur, num_client, num_police, crm, isNVEI, isVAE, date_souscription, echeance, fractionnement, statut_contrat, statut_code_contrat, avantage} = props

    return (
        <div className={'col-12 bloc-situation-contrat mt-5 mb-5'}>
            <div className={'content position-relative'}>
                <div className={`badge-title badge bg-${statut_code_contrat === "1" ? "success" : statut_code_contrat === "2" ? "warning" : "danger"} m-0 fw-bold`}>
                    Situation du contrat
                    <span className={'d-none d-sm-inline-block mx-2'}>-</span>
                    <span className={'d-block d-sm-inline-block text-start mt-2 mt-sm-0'}>{statut_contrat}</span>
                </div>

                <p><strong>Souscripteur :</strong> {souscripteur}</p>
                <p><strong>N° client :</strong> {num_client}</p>
                <p><strong>Contrat N° :</strong> {num_police}</p>
                { !isNVEI && !isVAE && !isWakam ? <p><strong>Bonus en cours :</strong> {crm}</p> : '' }
                <p><strong>Date de souscription :</strong> {moment(date_souscription).format('L')}</p>
                <p><strong>Echéance principale :</strong> {echeance}</p>
                <p><strong>Fractionnement :</strong> {fractionnement} { !isPrelevement && statut_code_contrat === "1" && <Link to={`/mes-contrats/mon-contrat/${id_contrat}/je-passe-en-prelevement`} className={'float-lg-end d-block text-center mt-3 mt-lg-0'}>Modifier</Link> }</p>
                {avantage && <p className={'mb-0'}><strong>Avantage :</strong> {avantage}</p>}
            </div>
        </div>
    );
};


export default SituationContrat;