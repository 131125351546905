import moment from 'moment'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import {PASS_URL, GRAVITEE_API_KEY, GRAVITEE_URL, SERVICES_URL} from "../constants/constants";
import _ from "lodash";

/**
 * Format number phone FR xx.xx.xx.xx.xx
 * @param phone
 * @returns {string}
 */
export const formatNumberPhone = phone => {
    return phone ? phone.toString().replace(/\./g,'').match(/.{2}/g).join('.') : ''
}

/**
 * Add space every 3 characters
 * @param number
 * @returns {string}
 */
export const formatThousandSpace = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(/' '/g, '&nbsp;')
}

/**
 * Format Euro amount with space and €
 * @param number
 * @returns {string}
 */
export const formatEuro = number => {
    return `${formatThousandSpace(Number(number).toFixed(2)).replace('.', ',')} €`.replace(/' '/g, '&nbsp;')
}

/**
 * Normalize input IBAN FR11-2222-3333-4444-5555-6666-777
 * @param value
 * @returns {string}
 */
export const normalizeIban = value => {
    if (!value) {
        return value
    }

    const separateur = '-'

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase()

    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}`
    }

    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}`
    }

    if (onlyNums.length <= 16) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}`
    }

    if (onlyNums.length <= 20) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}`
    }

    if (onlyNums.length <= 24) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(20, 24)}`
    }

    return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(20, 24)}${separateur}${onlyNums.slice(24, 27)}`
}

/**
 * Normalize input Tel XX.XX.XX.XX.XX
 * @param value
 * @returns {string}
 */
export const normalizeTel = value => {
    if (!value) {
        return value
    }

    const separateur = '.'

    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 2) {
        return onlyNums
    }
    if (onlyNums.length <= 4) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}`
    }
    if (onlyNums.length <= 6) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(4, 6)}`
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(4, 6)}${separateur}${onlyNums.slice(6, 8)}`
    }
    return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(4, 6)}${separateur}${onlyNums.slice(6, 8)}${separateur}${onlyNums.slice(
        8,
        10
    )}`
}

/**
 * Normalize Boolean
 * @param value
 * @returns {boolean}
 */
export const normalizeBooleanForNoobBelair = value => {

    if (value) {
        return "T";
    } else {
        return "F";
    }

};

export const JoursFeries = an => {

    const JourAn = moment(`${an}-01-01`).format('YYYY-MM-DD')
    const FeteTravail = moment(`${an}-05-01`).format('YYYY-MM-DD')
    const Victoire1945 = moment(`${an}-05-08`).format('YYYY-MM-DD')
    const FeteNationale = moment(`${an}-07-14`).format('YYYY-MM-DD')
    const Assomption = moment(`${an}-08-15`).format('YYYY-MM-DD')
    const Toussaint = moment(`${an}-11-01`).format('YYYY-MM-DD')
    const Armistice = moment(`${an}-11-11`).format('YYYY-MM-DD')
    const Noel = moment(`${an}-12-25`).format('YYYY-MM-DD')
    const SaintEtienne = moment(`${an}-12-26`).format('YYYY-MM-DD')

    const G = an%19
    const C = Math.floor(an/100)
    const H = (C - Math.floor(C/4) - Math.floor((8*C+13)/25) + 19*G + 15)%30
    const I = H - Math.floor(H/28)*(1 - Math.floor(H/28)*Math.floor(29/(H + 1))*Math.floor((21 - G)/11))
    const J = (an*1 + Math.floor(an/4) + I + 2 - C + Math.floor(C/4))%7
    const L = I - J
    const MoisPaques = 3 + Math.floor((L + 40)/44)
    const JourPaques = L + 28 - 31*Math.floor(MoisPaques/4)
    const PaquesOrigin = moment(`${an}-0${MoisPaques}-${JourPaques}`)

    const Paques = moment(`${an}-0${MoisPaques}-${JourPaques}`).format("YYYY-MM-DD")
    const VendrediSaint = moment(PaquesOrigin._i).subtract(2, 'days').format("YYYY-MM-DD")
    const LundiPaques = moment(PaquesOrigin._i).add(1, 'days').format("YYYY-MM-DD")
    const Ascension = moment(PaquesOrigin._i).add(39, 'days').format("YYYY-MM-DD")
    const Pentecote = moment(PaquesOrigin._i).add(49, 'days').format("YYYY-MM-DD")
    const LundiPentecote = moment(PaquesOrigin._i).add(50, 'days').format("YYYY-MM-DD")

    return [
        JourAn,
        VendrediSaint,
        Paques,
        LundiPaques,
        FeteTravail,
        Victoire1945,
        Ascension,
        Pentecote,
        LundiPentecote,
        FeteNationale,
        Assomption,
        Toussaint,
        Armistice,
        Noel,
        SaintEtienne
    ]

}

/**
 * Get city by postal code
 * use
 * async fetchCityByPostalCode(value){
 *      let cities = await getCity(value)
 *      console.log(cities)
 * }
 * @param value
 * @returns {string}
 */
export const getCity = value => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/towns/${value}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by search
 * use
 * async fetchCityBySearch(value){
 *      let cities = await getCity(value)
 *      console.log(cities)
 * }
 * @param value
 * @returns {string}
 */
export const getCityBySearch = value => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/towns/search/${value}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by value
 * use
 * async fetchCities(value){
 *      let cities = await getCitiesFromFrance(value)
 *      console.log(cities)
 * }
 * @param value
 * @returns [{}]
 */
export const getCitiesFromFrance = (value, request = "SearchBirthplaceWS") => {
    return new Promise(resolve => {
        axios.get(`${SERVICES_URL}/check-birth?value=${value}&function=${request}`)
            .then(response => {
                resolve(response.data)
            }).catch(error => {
            console.log(error)
        })
    });
}

/**
 * Hides part of the iban FR** 1*** 6*** 1*** 0*** 0**
 * @param value
 * @returns {string}
 */
export const maskIban = value => {
    if (!value) {
        return value
    }

    return `${value.slice(0, 2)}** ${value.slice(4, 5)}*** ${value.slice(8,9)}*** ${value.slice(12,13)}*** ${value.slice(16,17)}*** ${value.slice(20,21)}*** ${value.slice(24,25)}**`
}

/**
 * Get reparateur by code postal
 * use
 * async fetchReparateurByCodePostal(postalCode){
 *      let reparateurs = await getReparateurByCodePostal(postalCode)
 *      console.log(reparateurs)
 * }
 * @param postalCode
 * @returns {string}
 */
export const getReparateurByCodePostal = postalCode => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/garages/${postalCode}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}

/**
 * Get city by postal code
 * use
 * async fetchCompagnys(value){
 *      let compagnys = await getCompagnys(value)
 *      console.log(compagnys)
 * }
 * @param value
 * @returns {string}
 */
export const getCompagnys = () => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/companys`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}

/**
 * Get city by postal code
 * use
 * async fetchInsurers(value){
 *      let compagnys = await getInsurers(value)
 *      console.log(compagnys)
 * }
 * @param value
 * @returns {string}
 */
export const getCountries = () => {
    return new Promise(resolve => {
        axios.get(`${PASS_URL}/countries`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
        })
    });
}

/**
 * Lighten or Darken hexa color
 * @param hex
 * @param lum
 * @returns {string}
 * @constructor
 */
export const LightenDarkenColor = (hex, lum) => {
    hex = String(hex).replace(/[^0-9a-f]/gi, '')
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    lum = lum || 0

    let rgb = '#'
    let c, i
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16)
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
        rgb += ('00' + c).substr(c.length)
    }

    return rgb
}


/**
 * Retire les jours feries d'un tableau de date
 * @param array
 * @returns {array}
 * @constructor
 */
export const removeJoursFeries = (array) => {
    const joursFeries = JoursFeries(moment().weekYear())

    return _.filter(array, n => { return !_.includes(joursFeries, n) });
}

/**
 * Check iban
 * use
 * async fetchCheckIban(value){
 *      let iban = await checkIban(value)
 *      console.log(iban)
 * }
 * @param value
 * @returns {object}
 */
export const checkIban = (value) => {
    return new Promise(resolve => {
        axios({
            method: "GET",
            url: `${GRAVITEE_URL}/ibancheck/?iban=${value}`,
            headers: {
                "X-Gravitee-Api-Key": GRAVITEE_API_KEY
            },
        }).then((response) => {
            resolve(response.data);
        }).catch(error => {
            console.log(error)
        });
    });
}

/**
 * Get Address
 * use
 * async fetchAddress(value){
 *      let address = await getAddress(value)
 *      console.log(address)
 * }
 * @param value
 * @returns {object}
 */
export const getAddress = (value, validate = 'Undefined', localityId = null, postalCode = null, locality = null) => {
    return new Promise((resolve) => {

        const params = {
            value: encodeURIComponent(value),
            validate,
            localityId,
            postalCode,
            locality
        };

        const filteredParams = Object.entries(params)
            .filter(([key, val]) => val !== null)
            .map(([key, val]) => `${key}=${val}`)
            .join('&');

        const url = `${SERVICES_URL}/check-address?${filteredParams}`;

        axios.get(url)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    });
};

export const isMobile = () => {
    return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}



export const formatImmatriculation = value => {
    if (!value) {
        return value
    }

    const separateur = '-'

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<> ]/g, '').toUpperCase()

    // Nouvelle plaque immatriculation : AA123AA ou AA123A ou C254L
    if (/^[a-zA-Z]{1,2} ?-?[0-9]{2,3} ?-?[a-zA-Z]{1,2}$/.test(value)) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 5)}${separateur}${onlyNums.slice(5, 7)}`
    }

    // Ancienne plaque immatriculation : 1234AA12
    if (/^[0-9]{1,4} ?-?[a-zA-Z]{2,3} ?-?[0-9]{2}$/.test(value)) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 6)}${separateur}${onlyNums.slice(6, 8)}`
    }

    // Ancienne plaque corse : 1234AAA1A
    if (/^[0-9]{1,4} ?-?[a-zA-Z]{2,3} ?-?[0-9]{1}[a-bA-B]{1}$/.test(value)) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 7)}${separateur}${onlyNums.slice(7, 9)}`
    }

    return value.slice(0, 11)

}
