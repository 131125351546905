import React from 'react';
import {Link} from "react-router-dom";
import LibertyRider from '../../../../../assets/images/avantages/liberty-rider.png'
import InMotion from '../../../../../assets/images/avantages/in&motion.png'
import Sentidrive from '../../../../../assets/images/avantages/logo-sentidrive.png'

const Avantages = () => {
    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-avantages'} className={'text-decoration-none'}>Mes avantages</Link></h6>
            </div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link>Mes avantages</h1>
            </div>

            <div className={'mt-5'}>
                <h4>Découvrez les avantages de nos partenaires et <span className={'text-color-primary fw-bold'}>profitez d'offres exclusives</span> avec APRIL&nbsp;Moto&nbsp;!</h4>
            </div>


            <div className={'row'}>
                <Link to={'/mes-avantages/liberty-rider'} className={'col-md-3 mt-5 hover-translateY'} style={{minHeight: 320}}>
                    <div className={'border-color-primary border rounded p-4 d-inline-block text-center h-100 float-left position-relative w-100'}>
                        <img src={LibertyRider} alt="Liberty Rider" width={200} className={'mt-3'} style={{width: '100%'}}/>
                        <p className={'text-dark h6 fw-bold position-absolute'} style={{top: '45%', left: '50%', transform: 'translateX(-50%)', width: '100%'}}>Abonnement Premium <br/>offert</p>
                        <div className={'text-decoration-none border border-color-primary p-2 fw-bold mt-5 d-block position-absolute'} style={{bottom: 20, left: '50%', transform: 'translateX(-50%)', width: '90%'}}>
                            Voir le détail de l'offre
                        </div>
                    </div>
                </Link>

                <Link to={'/mes-avantages/in&motion'} className={'col-md-3 mt-5 hover-translateY'} style={{minHeight: 320}}>
                    <div className={'border-color-primary border rounded p-4 d-inline-block text-center h-100 position-relative w-100'}>
                        <img src={InMotion} alt="In&Motion" width={200} className={'mt-3'} style={{width: '100%'}} />
                        <p className={'text-dark h6 position-absolute'} style={{top: '45%', left: '50%', transform: 'translateX(-50%)', width: '100%'}}>Achetez un airbag <br/>
                            et bénéficiez de <br/>
                            <span className={'fw-bold '}>3 mois offerts à la In&box</span></p>
                        <div className={'text-decoration-none border border-color-primary p-2 fw-bold mt-5 d-block position-absolute'} style={{bottom: 20, left: '50%', transform: 'translateX(-50%)', width: '90%'}}>
                            Voir le détail de l'offre
                        </div>
                    </div>
                </Link>

                <Link to={'/mes-avantages/sentidrive'} className={'col-md-3 mt-5 hover-translateY'} style={{minHeight: 320}}>
                    <div className={'border-color-primary border rounded p-4 d-inline-block text-center h-100 position-relative w-100'}>
                        <img src={Sentidrive} alt="Sentidrive" width={200} className={'mt-3'} style={{width: '100%'}} />
                        <p className={'text-dark h6 position-absolute'} style={{top: '45%', left: '50%', transform: 'translateX(-50%)', width: '100%'}}><span className="fw-bold">15% de réduction</span> sur l'achat de votre traceur GPS</p>
                        <div className={'text-decoration-none border border-color-primary p-2 fw-bold mt-5 d-block position-absolute'} style={{bottom: 20, left: '50%', transform: 'translateX(-50%)', width: '90%'}}>
                            Voir le détail de l'offre
                        </div>
                    </div>
                </Link>
            </div>

        </div>
    );
};

export default Avantages;
