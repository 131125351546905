import {connect} from 'react-redux'
import {compose} from 'redux'
import Header from "./Header";
import {postLogout} from "../../../../redux/actions/app/login/login.actions";
import {getContractsLink, getUserLogoutLink} from "../../../../redux/selectors/links/links.selectors";
import {getLogoLightUrlWhitelabel} from "../../../../redux/selectors/whitelabel/whitelabel.selectors";
import {getIsWakamContract} from "../../../../redux/selectors/contracts/contract.selector.js";
import {
    getDataContracts,
    getDataFirstContract,
    getDateCV
} from "../../../../redux/selectors/contracts/contracts.selectors.js";
import {getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors.js";
import {CONTRACTS, fetchContracts} from "../../../../redux/actions/app/contracts/contracts.actions.js";
import _ from "lodash";

const mapStateToProps = state => {
    return {
        query: getUserLogoutLink(state),
        queryContracts: getContractsLink(state),
        isWakam: getIsWakamContract(state),
        logo: getLogoLightUrlWhitelabel(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        contracts: getDataContracts(state),
    }
}

const mapDispatchToProps = {
    postLogout,
    fetchContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, queryContracts } = stateProps
    const { postLogout, fetchContracts } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postLogout: () => postLogout({query}),
        load: () => fetchContracts({queryContracts}),
    }
}

const HeaderContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Header)

export default HeaderContainer
