import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import Select from "../../../../../../Commun/Inputs/Select/Select";
import moment from "moment";
import Text from "../../../../../../Commun/Inputs/Text/Text";
import {checkIban} from "../../../../../../../utils/functions";
import _ from "lodash";
import UploadInline from "../../../../../../Commun/UploadInline/UploadInline";
import {postChangeFractionnement} from "../../../../../../../redux/actions/app/amendment/amendment.actions";
import {Link} from "react-router-dom";

const Fractionnement = (props) => {
    const { souscripteur, initialValues, dateEcheance, loading, query, id_contrat, postChangeFractionnement } = props
    const [createChrono, setCreateChrono] = useState(null)
    const [ errorIban, setErrorIban ] = useState(null)
    const [ loadingIban, setLoadingIban ] = useState(false)
    const anneeEnCours = new Date().getFullYear();
    const date = moment(`${dateEcheance}/${anneeEnCours}`, 'DD/MM/YYYY');

    useEffect(() => {
        // Si la date de la demande (aujourd'hui) est entre 60j avant echeance et 15j après echeance
         if (moment().diff(date, 'days') >= -60 && moment().diff(date, 'days') <= 15){
           setCreateChrono(false)
         } else {
           setCreateChrono(true)
         }
    }, [])

    const fetchCheckIban = async (value, setFieldValue) => {
        setLoadingIban(true)
        let iban = await checkIban(value)
        setLoadingIban(false)
        setFieldValue('domiciliation', iban.bank_data.bank)
        setFieldValue('bic', iban.bank_data.bic)

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null

        _.forEach(fieldValidations, field => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)){
                error = iban.validations[field].message
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === "NO" || iban.sepa_data.SCT === "NO"){
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires")
        }

        return error ? setErrorIban(`Le format de L'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null)
    }

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je passe en prélèvement</h1>
            </div>

            <div className={'alert-warning alert mb-0 mt-3'}>
                <p className={'m-0'}>Pensez à vérifier votre email : <b>{souscripteur.email}</b></p>
                <p>Pensez à vérifier votre numéro de téléphone : <b>{souscripteur.phone}</b></p>
                Si ces informations sont fausses, pensez à les modifier dans l'onglet <Link to="/mon-profil">Mon
                profil</Link>
            </div>

            <Formik initialValues={initialValues} onSubmit={form => postChangeFractionnement({createChrono, query, form, successFunction: () => props.history.push(`/mes-contrats/mon-contrat/${id_contrat}`)})}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                    <form onSubmit={handleSubmit}>
                        <div className={'mb-4 mt-4'}>
                            <Select
                                required
                                name={"fractionnement"}
                                label={'Nouveau Fractionnement'}
                                error={errors.fractionnement}
                                value={values.fractionnement}
                            >
                                <option value={''}>Sélectionnez un fractionnement</option>
                                <option value={"A"}>Fractionnement annuel en prélèvement</option>
                                <option value={"M"}>Fractionnement mensuel en prélèvement</option>
                            </Select>
                        </div>

                        {
                            !createChrono ? <>
                                <div className="mb-5">
                                    <UploadInline
                                        name='upload'
                                        setFieldValue={setFieldValue}
                                        code={`files`}
                                        label={"Importer votre RIB"}
                                        required
                                    />
                                </div>
                                <div className={'mb-4'}>

                                    <Text
                                        type={'text'}
                                        name="iban"
                                        label={'IBAN'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.iban}
                                        required
                                    />

                                    {
                                        !loadingIban && errorIban && <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{__html: errorIban}}/>
                                    }

                                    {
                                        !loadingIban && Boolean(values.domiciliation && values.bic) && !errorIban && <div className={'alert-success alert mb-0'}>L'IBAN est valide</div>
                                    }

                                    <span className="btn btn-secondary btn-block rounded-medium mt-3" onClick={loadingIban ? null : () => fetchCheckIban(values.iban, setFieldValue)}>
                                            {
                                                loadingIban ?
                                                    <div className="spinner-border" role="status" style={{width: 19, height: 19}}>
                                                        <span className="sr-only" />
                                                    </div>
                                                    : 'Vérifier mon IBAN'
                                            }
                                        </span>

                                </div>

                                <div className={'mb-4'}>
                                    <Text
                                        type={'text'}
                                        name="domiciliation"
                                        label={'Domiciliation de la banque'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.domiciliation}
                                        required
                                    />
                                </div>

                                <div className={'mb-4'}>
                                    <Text
                                        type={'text'}
                                        name="bic"
                                        label={'Code BIC'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bic}
                                        required
                                    />
                                </div>

                            </>
                                : <>
                                    <div className="mb-4">
                                        <UploadInline
                                            name='upload'
                                            setFieldValue={setFieldValue}
                                            code={`files`}
                                            label={"Importer votre IBAN"}
                                            required
                                        />
                                    </div>
                                </>
                        }

                        <button type={"submit"} className="btn btn-primary btn-block rounded-medium mt-3">
                            {
                                loading ?
                                    <div className="spinner-border" role="status" style={{width: 19, height: 19}}>
                                        <span className="sr-only" />
                                    </div>
                                    : 'Mettre à jour le prélèvement'
                            }
                        </button>

                    </form>
                )}
            </Formik>

        </div>
    );
};

export default Fractionnement;