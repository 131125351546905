import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import DetailArticle from "./DetailArticle";
import {ARTICLES, fetchArticles} from "../../../../../../redux/actions/app/articles/articles.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getAllArticles} from "../../../../../../redux/selectors/articles/articles.selectors";
import {getArticlesLink} from "../../../../../../redux/selectors/links/links.selectors";
import loader from "../../../../../Commun/Spinners/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params

    return {
        article: _.find(getAllArticles(state), {'id': Number(id)}),
        articles: getAllArticles(state),
        query: getArticlesLink(state),
        loaded: getLoadingEntity(state, ARTICLES) === false,
        error: getErrorEntity(state, ARTICLES)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchArticles: ({query}) => fetchArticles({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, articles } = stateProps
    const { fetchArticles } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => articles ? null : fetchArticles({query})
    }
}

const DetailArticleContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DetailArticle)

export default DetailArticleContainer
