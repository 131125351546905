import {connect} from 'react-redux'
import ListeDocuments from "./ListeDocuments.jsx";
import {getContractId, getDataContract,} from "../../../../../../redux/selectors/contracts/contract.selector";
import _ from "lodash";
import {getContractReceiptsLink,} from "../../../../../../redux/selectors/links/links.selectors";
import {compose} from "redux";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors.js";
import {FETCH_RECEIPTS, fetchReceipts} from "../../../../../../redux/actions/app/payments/receipts/receipts.actions.js";
import {getLastReceipt} from "../../../../../../redux/selectors/payments/receipts/receipts.selectors.js";

const mapStateToProps = state => {
    return {
        contrat_id: getContractId(state),
        query: _.replace(getContractReceiptsLink(state), '{id}', getContractId(state)),
        loaded: getLoadingEntity(state, FETCH_RECEIPTS) === false,
        quittance: getLastReceipt(state),
        contract: getDataContract(state)
    }
}

const mapDispatchToProps = {
    fetchReceipts,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchReceipts } = dispatchProps
    const { query } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchReceipts({query})
    }
}

const ListeDocumentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ListeDocuments)

export default ListeDocumentsContainer
