import {connect} from 'react-redux'
import InfoClient from "./InfoClient";
import {
    getAddressUser, getCityUser,
    getCPUser,
    getEmailUser, getFirstnameUser, getLastnameUser,
    getPhoneUser
} from "../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        user: {
            nom: getLastnameUser(state),
            prenom: getFirstnameUser(state),
            adresse: getAddressUser(state),
            cp: getCPUser(state),
            ville: getCityUser(state),
            telephone: getPhoneUser(state),
            email: getEmailUser(state)
        },
    }
}

const InfoClientContainer = connect(mapStateToProps)(InfoClient)

export default InfoClientContainer
