import { createSelector } from 'reselect'

const whitelabelSelector = state => state.whitelabel

export const getLogoLightUrlWhitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.logo_light_url
)

export const getLogoDarkUrlWhitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.logo_dark_url
)

export const getEmailGestionWhitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.email_gestion
)

export const getNameWhitelabel = createSelector(
    whitelabelSelector,
    whitelabel => whitelabel.name
)
