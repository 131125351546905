import React from 'react';
import './actualites.scss'
import {Link} from "react-router-dom";
import actu from '../../../../../../assets/images/img_actu.png'

const Actualites = () => {
    return (
        <div className={'col-12 actualites'}>
            <div className={'content-img'}>
                <img src={actu} alt={'Actualités'} />
            </div>
            <div className={'content'}>
                <h2 className={'title'}><strong>Nos actualités</strong></h2>
                <p>Réforme 2-roues, partenariat, test de véhicule, offre promotionnelle ... Découvrez toutes nos actualités !</p>
                <div className={'mt-4 pb-5'}>
                    <Link className={'btn btn-primary full'} to={'/mes-actualites'}>Voir nos actualités</Link>
                </div>
            </div>
        </div>
    );
};

export default Actualites;
