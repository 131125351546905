import React from 'react';
import Page from "../Layout/Page/Page";

const Whitelabel = (props) => {

    document.title = `Espace client | ${props.name}`

    return (
        <Page {...props}/>
    );
};

export default Whitelabel;
