import {connect} from 'react-redux'
import ParrainagesDetail from "./ParrainagesDetail";
import {getIdUser, getNbParrainageUser} from "../../../../../../redux/selectors/user/user.selectors";
import {getParrainagesLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {PARRAINAGES, fetchParrainages} from "../../../../../../redux/actions/app/parrainages/parrainages.actions";
import {getDataParrainages} from "../../../../../../redux/selectors/parrainages/parrainages.selectors";
import {compose} from "redux";
import loader from "../../../../../Commun/Spinners/Loader/Loader";
import _ from "lodash";


const mapStateToProps = state => {
    return {
        query: getParrainagesLink(state),
        loaded: getLoadingEntity(state, PARRAINAGES) === false,
        parrainages : getDataParrainages(state),
        nb_parrainage : getNbParrainageUser(state),
        userId: getIdUser(state)
    }
}
const mapDispatchToProps = {
    fetchParrainages
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchParrainages } = dispatchProps
    const { query, userId } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchParrainages({query: _.replace(query, '{id}', userId)}),
    }
}

const ParrainagesDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ParrainagesDetail)


export default ParrainagesDetailContainer
