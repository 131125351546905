import {connect} from 'react-redux'
import ListeArticles from "./ListeArticles";
import {getAllArticles} from "../../../../../../redux/selectors/articles/articles.selectors";

const mapStateToProps = state => {
    return {
        articles : getAllArticles(state)
    }
}

const ListeArticlesContainer = connect(mapStateToProps)(ListeArticles)

export default ListeArticlesContainer
