import React from 'react';
import _ from 'lodash'
import moment from 'moment'
import {Link} from "react-router-dom";
import DetailSinistreDocuments from "./DetailSinistreDocuments/DetailSinistreDocuments";
import {formatEuro} from "../../../../../../../utils/functions";
import Badge from "../../../../../../Commun/Badge/Badge";
import Timeline from "../../../../../../Commun/Timeline/Timeline";

const DetailSinistre = (props) => {
    const { accident, isWakam } = props
    const warning = accident.situationCode === "1" && _.isEmpty(accident.reparateur) && accident.categorieCode !== '10'

    const hasCoordonneeGarage = _.find(accident.documents, ['code', '23'])
    const hasCoordonneeGarageNotReceived = hasCoordonneeGarage?.recu === false

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}><Link to={'/mes-sinistres'}><i className="bi bi-arrow-left-circle me-2" /></Link> Mon sinistre N°{accident.numCbt}</h1>
            </div>

            { accident.documents && !isWakam && <Timeline accident={accident} /> }

            { accident.situationCode === "A" &&
                <div className={'alert alert-warning mt-5'}>
                    Votre déclaration de sinistre du {moment(accident.dateOuverture).format('L')} a bien été prise en compte et est en attente d'ouverture par l'un de nos conseillers. Nous reviendrons rapidement vers vous après étude de votre dossier.
                </div>
            }

            { accident.situationCode === "3" &&
                <div className={'alert alert-warning mt-5'}>
                    {
                        _.find(accident.documents, ['code', 'C']) && _.find(accident.documents, ['code', 'C']).recu
                            ? <><br />Votre déclaration du sinistre du {moment(accident.dateOuverture).format('L')} a bien été prise en compte et est en attente d'ouverture par l'un de nos conseillers. Nous reviendrons rapidement vers vous après étude de vote dossier.</>
                            : <><br />La responsabilité n'est pas encore déterminée car le justificatif de la déclaration est en attente.</>
                    }
                </div>
            }

            { (accident.situationCode === "8" && accident.type === "Corporel") &&
                <div className={'alert alert-warning mt-5'}>
                    La partie matérielle de votre véhicule est clôturée, nous vous invitons à vous rapprocher d'Allianz pour toutes informations sur la partie corporelle :  corporel@allianz.fr
                </div>
            }

            {
                _.find(accident.documents, ['recu', false]) &&
                <DetailSinistreDocuments documents={accident.documents} situationCode={accident.situationCode} upload_app_link={accident.upload_app_link}/>
            }

            {(!accident.reparateur.raisonSociale && warning) && hasCoordonneeGarage && hasCoordonneeGarageNotReceived &&
                <div className={'alert alert-danger mt-5'} style={{minHeight: '50px'}}>
                    <div className="row align-items-center text-lg-start text-center">
                        <div className="col-lg-8 col-12">
                            Attention ! Aucun réparateur n'a été missionné pour votre sinistre.
                        </div>

                         <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                            <Link className={'btn btn-danger float-lg-end'}
                                  to={`/mes-sinistres/contrat/${accident.id_contrat}/sinistre/${accident.id}/ajouter-reparateur`}>Ajouter
                                un réparateur</Link>
                        </div>
                    </div>
                </div>
            }

            <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Informations</h4>
                </div>

                <div className={'mt-4'}>
                    <div className="row">
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Lieu de l'accident</p>
                            <p className={'m-0 text-secondary'}>{accident.villeAccident} ({accident.codePostalAccident})</p>
                        </div>
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Véhicule</p>
                            <p className={'m-0 text-secondary'}>{accident.libelle}</p>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Survenu le</p>
                            <p className={'m-0 text-secondary'}>{moment(accident.dateSurv).format('L')}</p>
                        </div>
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Circonstances</p>
                            <p className={'m-0 text-secondary'}>{accident.circonstances}</p>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Dernière intervention sur votre dossier</p>
                            <p className={'m-0 text-secondary'}>{accident.dateDerniereIntervention ? moment(accident.dateDerniereIntervention).format('L') : '-'}</p>
                        </div>
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Statut</p>
                            <Badge status={_.capitalize(accident.situation)}/>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Taux de responsabilité</p>
                            {
                                accident.situationCode === "3"
                                ? <p className={'m-0 text-secondary'}>Non déterminée</p>
                                : accident.situationCode === "A"
                                    ? <p className={'m-0 text-secondary'}>En attente</p>
                                    : <p className={'m-0 text-secondary'}>{accident.tauxResp}%</p>
                            }
                        </div>
                        <div className="col">
                            <p className={'mb-1 fw-bold'}>Type de sinistre</p>
                            <p className={'m-0 text-secondary'}>{accident.categorie}</p>
                        </div>
                    </div>
                </div>
            </div>

            {
                accident.situationCode !== "A" && <div className={'bloc-white'}>
                    <div>
                        <h4 className={'fw-bold text-primary-light d-inline-block'}>Expert</h4>
                    </div>

                    {accident.expert.raisonSociale ? <div className={'mt-4'}>
                        <div className="row">
                            <div className="col">
                                <p className={'mb-1 fw-bold'}>Expert missionné</p>
                                <p className={'m-0 text-secondary'}>{accident.expert.raisonSociale}</p>
                            </div>
                            <div className="col">
                                <p className={'mb-1 fw-bold'}>Numéro de référence</p>
                                <p className={'m-0 text-secondary'}>{accident.expert.reference}</p>
                            </div>
                        </div>

                        <hr/>

                        <div className="row">
                            <div className="col">
                                <p className={'mb-1 fw-bold'}>Passage programmé pour</p>
                                <p className={'m-0 text-secondary'}>{moment(accident.datePassageExpert).format('L')}</p>
                            </div>
                            {
                                accident.expert.id !== "365585" && <div className="col">
                                    <p className={'mb-1 fw-bold'}>Contactez l'expert</p>
                                    <p className={'m-0 text-secondary'}>{accident.expert.phone} ou par mail à {accident.expert.email}</p>
                                </div>
                            }
                        </div>

                        <hr/>

                        {
                            accident.expert.id !== "365585" && <div className="row">
                            <div className="col">
                                <p className={'mb-1 fw-bold'}>Adresse postale</p>
                                <p className={'m-0 text-secondary'}>{accident.expert.rue1} {accident.expert.rue2 !== '' ? `- ${accident.expert.rue2}` : ''} - {accident.expert.codePostal} {accident.expert.ville}</p>
                            </div>
                        </div>
                        }
                    </div> : <small>Aucun expert n'a été missionné pour votre sinistre.</small> }

                </div>
            }

            {accident.reparateur.raisonSociale &&
                <div className={'bloc-white'}>
                    <div>
                        <h4 className={'fw-bold text-primary-light d-inline-block'}>Réparateur</h4>
                    </div>
                    <div className={'mt-4'}>
                        <div className="row">
                            <div className="col">
                                <p className={'mb-1 fw-bold'}>Réparateur agréé pour votre sinistre</p>
                                <p className={'m-0 text-secondary'}>{accident.reparateur.raisonSociale}</p>
                            </div>
                            <div className="col">
                                <p className={'mb-1 fw-bold'}>Contactez le réparateur</p>
                                <p className={'m-0 text-secondary'}>{accident.reparateur.phone} ou par mail
                                    à {accident.reparateur.email}</p>
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className="col">
                                <p className={'mb-1 fw-bold'}>Adresse postale</p>
                                <p className={'m-0 text-secondary'}>{accident.reparateur.rue1} {accident.reparateur.rue2 !== '' ? `- ${accident.reparateur.rue2}` : ''} - {accident.reparateur.codePostal} {accident.reparateur.ville}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(!accident.reparateur.raisonSociale && !warning) &&
                <div className={'bloc-white'}>
                    <div>
                        <h4 className={'fw-bold text-primary-light d-inline-block'}>Réparateur</h4>
                    </div>
                    <div className={''}>
                        <div className="row align-items-center">
                            <div className="col">
                                <small>Aucun réparateur n'a été missionné pour votre sinistre.</small>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className={'bloc-white'}>
                <div>
                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Règlement effectué</h4>
                </div>

                {accident.reglements.length > 0 ?
                    accident.reglements.map((reglement, index) => (
                        reglement.debit > 0 ?
                            <div key={index}>
                                {index > 0 && <hr/>}
                                <div className="row">
                                    <div className="col">
                                        <p className={'mb-1 fw-bold d-inline-block'}>Règlement émis auprés de {reglement.raisonSociale} le {moment(reglement.date).format('L')}</p>
                                        <p className={'float-right m-0 text-secondary'}>Le montant du règlement est de {formatEuro(reglement.debit)}</p>
                                    </div>
                                </div>
                            </div> : '' ))
                    : <small>Aucun règlement n'a encore été effectué.</small> }

            </div>

            {
                !_.find(accident.documents, ['recu', false]) &&
                <DetailSinistreDocuments documents={accident.documents} situationCode={accident.situationCode} upload_app_link={accident.upload_app_link ?? null}/>
            }
        </div>
    );
};

export default DetailSinistre;
