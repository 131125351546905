import React from 'react';
import RadioGroup from "../../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import Select from "../../../../../../Commun/Inputs/Select/Select";

const Police = (props) => {
    const { errors, values, setFieldValue} = props

    return (
        <div className={'bloc-white'}>
            <div>
                <h4 className={'fw-bold text-primary-light d-inline-block mb-5'}>Les forces de polices sont-elles intervenues ?</h4>
            </div>

            <div className="mb-5">
                <RadioGroup
                    label={"Il y a-t-il eu intervention des forces de police ?"}
                    name={'intervention_police'}
                    setFieldValue={setFieldValue}
                    options={[
                        {value: 'T', label: 'Oui'},
                        {value: 'F', label: 'Non'}
                    ]}
                    value={values.intervention_police}
                    error={errors.intervention_police}
                    required
                />
            </div>

            {
                values.intervention_police === "T" && <div className="mb-5">
                    <Select
                        required
                        name={"document_police"}
                        error={errors.document_police}
                        label={'Sélectionnez le type de document reçu'}
                        value={values.document_police}
                    >
                        <option value={''}>Choisissez le document</option>
                        <option value='O'>Procès verbal</option>
                        <option value='M'>Main courante</option>
                        <option value='V'>Rapport des autorités</option>
                        <option value={null}>Ne sait pas</option>
                    </Select>
                    <div className="alert alert-info mt-5" role="alert">
                        <p>Si vous avez un doute, fiez vous à l’entête du document transmis par les forces de police :</p>
                        <ul>
                            <li>S’il y a indiqué <b>“Procès verbal”</b> en tête, choisissez le document <b>Procès Verbal</b></li>
                            <li>S’il y a indiqué <b>“Main courante”</b> en entête, choisissez le document <b>Main Courante</b></li>
                            <li>S’il y a indiqué <b>“Rapport”</b> ou <b>“Triplicata d’accident”</b>, choisissez le document <b>“Rapport des autorités”</b></li>
                            <li>Pour tout autre document, indiquez <b>“Ne sait pas”</b>.</li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    );
};

export default Police;
