import {ANALYTICS_SET_EVENT, setAnalytics} from '../../../actions/core/analytics/analytics.actions'
import _ from 'lodash'

export const analyticsMiddleware = ({dispatch}) => next => action => {
    next(action)

    switch (action.type) {
        case ANALYTICS_SET_EVENT:
            const {event, datas} = action.meta.analytics;
            window.dataLayer = window.dataLayer || [];

            if (datas) {
                dataLayer.push({
                    'event': event,
                    ...datas
                });
            } else {
                dataLayer.push({'event': event});
            }
            break;

        default:
            if (action.meta && action.meta.analytics) {

                dispatch(setAnalytics({entity: action.meta.entity}))

                const {event, actionGoogle, libelle, categorie, valeur, unique} = action.meta.analytics

                let found = unique || false

                if (unique) {
                    found = window.dataLayer.some((el) => {
                        return el.libelle === libelle
                    })
                }

                if (!found) {
                    window.dataLayer.push({
                        event,
                        'action': actionGoogle,
                        libelle,
                        categorie,
                        valeur
                    })
                }

                const datalayer = window.dataLayer

                const env = _.find(datalayer, 'environnement') ? _.find(datalayer, 'environnement') : {environnement: 'production'}

                if (!_.find(datalayer, 'environnement')) {
                    window.dataLayer.push(env)
                }
            }
            break;
    }
}
