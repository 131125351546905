import {connect} from 'react-redux'
import _ from 'lodash'
import Fractionnement from "./Fractionnement";
import {
    getContractDateEcheance,
    getContractId,
    getSouscripteurContract
} from "../../../../../../../redux/selectors/contracts/contract.selector";
import {postContractFractionnementUpdateLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {
    POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT,
    postChangeFractionnement
} from "../../../../../../../redux/actions/app/amendment/amendment.actions";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import moment from "moment";

const mapStateToProps = state => {
    return {
        dateEcheance: getContractDateEcheance(state),
        id_contrat: getContractId(state),
        query: _.replace(postContractFractionnementUpdateLink(state), '{id}', getContractId(state)),
        initialValues: {
            fractionnement: "",
            createChrono: !moment().isBetween(moment(getContractDateEcheance(state)).subtract(60, "day"), moment(getContractDateEcheance(state)).add(15, "day"), null, '[]')
        },
        loading: getLoadingEntity(state, POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT),
        souscripteur: getSouscripteurContract(state)
    }
}

const mapDispatchToProps = {
    postChangeFractionnement: ({createChrono, query, form, successFunction}) => postChangeFractionnement({createChrono, query, form, successFunction})
}

const FractionnementContainer = connect(mapStateToProps, mapDispatchToProps)(Fractionnement)

export default FractionnementContainer
