import React, {useState} from 'react';
import PropTypes from 'prop-types'
import './upload.scss'

const UploadInline = (props) => {
    const { setFieldValue, label, code = 'X', file, isForm, required } = props;
    const [name, setName] = useState(null)

    const handleChange = file => {
        if (file){
            setName(file.name)
            setFieldValue(code, file)
        }
    }

    isForm && !file && name && setName(null)

    return (
        <div className={'form-group'}>
            {label && <label>{label}{required ? <sup className={'text-danger'}>*</sup> : ''}</label> }
            <div className="position-relative">
                <input required={required} id="file" type="file" className="custom-file-input" onChange={(e) => handleChange(e.target.files[0])}/>
                <label htmlFor={"file"} className={`${name ? 'valid' : ''} custom-file-label overflow-hidden w-100 cursor-pointer`} style={{height: 55, paddingTop: 14, border: '1px solid black'}}>{`${name || 'Choisissez un fichier...'}`}</label>
            </div>
        </div>
    );
};

UploadInline.propTypes = {
    label: PropTypes.string,
    code: PropTypes.string,
    file: PropTypes.object
};

export default UploadInline;
