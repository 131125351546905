import React from 'react';
import _ from 'lodash'
import Shield from '../../../../../assets/images/shield.svg'
import Welcome from "./Welcome/Welcome";
import ContractsContainer from "./Contracts/ContractsContainer";
import Contact from "./Contact/Contact";
import AccidentsContainer from "./Accidents/AccidentsContainer";
import Warning from "./Warning/Warning";
import TodoBlocContainer from "./TodoBloc/TodoBlocContainer";
import {HOST_MB} from "../../../../../constants/constants";
import moment from "moment";
import {Link} from "react-router-dom";

const Home = (props) => {
    const { messageWarning, userId, firstName , nameApporteur, derniereCv, paysNaissance, villeNaissance, nomNaissance, contracts} = props
    let diff = (moment(moment().format("YYYY-MM-DD")).diff(derniereCv, 'days'))

    return (
        <>
            {messageWarning !== '' && <Warning messageWarning={messageWarning}/>}
            {(paysNaissance === null || villeNaissance === null || nomNaissance === null) &&
                <Warning messageWarning={'Vos informations de naissance sont incomplètes, merci de les compléter dans votre profil.'}/>
            }

            {
                _.find(contracts, ['prelevement', false]) &&
                <div className="mt-4 col-xl-11 col-md-12 d-lg-flex alert-amo rounded text-white align-items-center p-2">
                    <div className="col-12 col-lg-2 text-center">
                        <img src={Shield} alt="Shield" style={{filter: 'invert(1)'}} width={80}/>
                    </div>
                    <div className="col px-2">
                        <b>Passez au paiement par prélèvement pour plus de simplicité et de sécurité</b>
                    </div>
                    <div className="col-12 col-lg-4 text-center">
                        <Link to={'/mes-contrats'} style={{color: "#639E30"}} className={"btn btn-light"}>Je passe au prélèvement</Link>
                    </div>
                </div>
            }

            <Welcome firstName={firstName} userId={userId}/>

            <TodoBlocContainer />
            <ContractsContainer />
            <AccidentsContainer/>
            { HOST_MB === 43397 && <Contact/> }
        </>
    );
};

export default Home;
