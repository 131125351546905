export const APP_NAME = 'perso-v3'
export const APP_ENV = import.meta.env.VITE_ENV
export const PASS_URL = import.meta.env.VITE_URL
export const API_END_POINT = import.meta.env.VITE_API_END_POINT
export const HOST_MB = JSON.parse(import.meta.env.VITE_HOST_MB)[window.location.host] || 43397
export const APP_CLIENT_ID = import.meta.env.VITE_CLIENT_ID
export const APP_GRANT_TYPE = import.meta.env.VITE_GRANT_TYPE
export const APP_PROVIDER = import.meta.env.VITE_PROVIDER
export const GRAVITEE_API_KEY = import.meta.env.VITE_GRAVITEE_API_KEY
export const GRAVITEE_URL = import.meta.env.VITE_GRAVITEE_URL
export const SERVICES_URL = import.meta.env.VITE_SERVICES_URL
