import {API_ERROR, API_SUCCESS, apiRequest} from '../../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../../actions/core/notifications/notifications.actions";
import {FETCH_RECEIPTS, setReceipts} from "../../../../actions/app/payments/receipts/receipts.actions";

export const receiptsMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_RECEIPTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: FETCH_RECEIPTS}),
                setLoader({state: true, entity: FETCH_RECEIPTS})
            ])
            break

        case `${FETCH_RECEIPTS} ${API_SUCCESS}`:
            next([
                setReceipts(action.payload.data),
                setError({state: false, entity: FETCH_RECEIPTS}),
                setLoader({state: false, entity: FETCH_RECEIPTS})
            ])
            break

        case `${FETCH_RECEIPTS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: FETCH_RECEIPTS,
                    html: '<p>Une erreur est survenue lors de la récupération des quittances payées.</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: FETCH_RECEIPTS}),
                setLoader({state: false, entity: FETCH_RECEIPTS})
            ])
            break

        default:
            break
    }
    return null
}
